import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { HmsReport, ReportLevel, ReportLevel$reflection } from "./HmsReport.js";
import { WasteReport, ReportLevel as ReportLevel_1, ReportLevel$reflection as ReportLevel$reflection_1 } from "./WasteReport.js";
import { QualityReport, ReportLevel as ReportLevel_2, ReportLevel$reflection as ReportLevel$reflection_2 } from "../Quality/QualityReport.js";
import { obj_type, record_type, string_type, int32_type, option_type, array_type, class_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { addYears, now, toString, compare, parse, addMonths, utcNow, year as year_2, month as month_2 } from "../.fable/fable-library.3.2.4/Date.js";
import { format, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { ProjectMeta$reflection, Company$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { monthsInPeriod, toLastDayOfMonth, toFirstDayOfMonth } from "../Utils.js";
import { map, tryHead, tryFind } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "../.fable/fable-library.3.2.4/Int32.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import { snackbarError, RotationAngle, rotatingChevronButton } from "../ViewHelpers.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

class ReportView extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Hms", "Waste", "Quality"];
    }
}

function ReportView$reflection() {
    return union_type("Report.ReportView", [], ReportView, () => [[["Item", ReportLevel$reflection()]], [["Item", ReportLevel$reflection_1()]], [["Item", ReportLevel$reflection_2()]]]);
}

function ReportView__ToSelectReportLevel(this$) {
    switch (this$.tag) {
        case 1: {
            const x_1 = this$.fields[0];
            switch (x_1.tag) {
                case 1: {
                    return 2;
                }
                case 0: {
                    return 3;
                }
                default: {
                    return 1;
                }
            }
        }
        case 2: {
            if (this$.fields[0].tag === 0) {
                return 2;
            }
            else {
                return 1;
            }
        }
        default: {
            const x = this$.fields[0];
            switch (x.tag) {
                case 1: {
                    return 2;
                }
                case 0: {
                    return 3;
                }
                default: {
                    return 1;
                }
            }
        }
    }
}

function ReportView__ShouldShowCompanySelect(this$) {
    switch (this$.tag) {
        case 1: {
            if (this$.fields[0].tag === 1) {
                return true;
            }
            else {
                return false;
            }
        }
        case 2: {
            if (this$.fields[0].tag === 0) {
                return true;
            }
            else {
                return false;
            }
        }
        default: {
            if (this$.fields[0].tag === 1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
}

function ReportView__ShouldShowProjectSelect(this$) {
    switch (this$.tag) {
        case 1: {
            if (this$.fields[0].tag === 0) {
                return true;
            }
            else {
                return false;
            }
        }
        case 2: {
            return false;
        }
        default: {
            if (this$.fields[0].tag === 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }
}

function ReportView__ShouldShowPeriodSelect(this$) {
    switch (this$.tag) {
        case 1: {
            return true;
        }
        case 2: {
            return false;
        }
        default: {
            return true;
        }
    }
}

function ReportView__GetSourceId(this$) {
    switch (this$.tag) {
        case 1: {
            const x_3 = this$.fields[0];
            switch (x_3.tag) {
                case 1: {
                    return x_3.fields[0] | 0;
                }
                case 0: {
                    return x_3.fields[0] | 0;
                }
                default: {
                    return -1;
                }
            }
        }
        case 2: {
            const x_6 = this$.fields[0];
            if (x_6.tag === 0) {
                return x_6.fields[0] | 0;
            }
            else {
                return -1;
            }
        }
        default: {
            const x = this$.fields[0];
            switch (x.tag) {
                case 1: {
                    return x.fields[0] | 0;
                }
                case 0: {
                    return x.fields[0] | 0;
                }
                default: {
                    return -1;
                }
            }
        }
    }
}

function ReportView__HmsSelected(this$) {
    if (this$.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

function ReportView__WasteSelected(this$) {
    if (this$.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

function ReportView__QualitySelected(this$) {
    if (this$.tag === 2) {
        return true;
    }
    else {
        return false;
    }
}

function ReportView__ToKey(this$, ps, pe) {
    let dt;
    const arg40 = month_2(pe) | 0;
    const arg30 = year_2(pe) | 0;
    const arg20 = month_2(ps) | 0;
    const arg10 = year_2(ps) | 0;
    dt = toText(printf("%i-%i-%i-%i"))(arg10)(arg20)(arg30)(arg40);
    switch (this$.tag) {
        case 1: {
            const x_3 = this$.fields[0];
            switch (x_3.tag) {
                case 0: {
                    return toText(printf("waste-project-%i-report-%s"))(x_3.fields[0])(dt);
                }
                case 1: {
                    return toText(printf("waste-company-%i-report-%s"))(x_3.fields[0])(dt);
                }
                default: {
                    return toText(printf("waste-group-report-%s"))(dt);
                }
            }
        }
        case 2: {
            const x_6 = this$.fields[0];
            if (x_6.tag === 0) {
                return toText(printf("quality-company-%i"))(x_6.fields[0]);
            }
            else {
                return "quality-group";
            }
        }
        default: {
            const x = this$.fields[0];
            switch (x.tag) {
                case 0: {
                    return toText(printf("hms-project-%i-report-%s"))(x.fields[0])(dt);
                }
                case 1: {
                    return toText(printf("hms-company-%i-report-%s"))(x.fields[0])(dt);
                }
                default: {
                    return toText(printf("hms-group-report-%s"))(dt);
                }
            }
        }
    }
}

class Model extends Record {
    constructor(SelectedView, PeriodStart, PeriodEnd, Companies, HmsProjects, WasteProjects, PrevCompId, PrevProjId, ErrorMsg) {
        super();
        this.SelectedView = SelectedView;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.Companies = Companies;
        this.HmsProjects = HmsProjects;
        this.WasteProjects = WasteProjects;
        this.PrevCompId = PrevCompId;
        this.PrevProjId = PrevProjId;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Report.Model", [], Model, () => [["SelectedView", ReportView$reflection()], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["Companies", option_type(array_type(Company$reflection()))], ["HmsProjects", option_type(array_type(ProjectMeta$reflection()))], ["WasteProjects", option_type(array_type(ProjectMeta$reflection()))], ["PrevCompId", option_type(int32_type)], ["PrevProjId", option_type(int32_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Goto", "CompaniesResponse", "HmsProjectsResponse", "WasteProjectsResponse", "SelectReportLevel", "SelectCompany", "SelectProject", "SelectPeriodStart", "SelectPeriodEnd", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("Report.Message", [], Message, () => [[["Item", ReportView$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectMeta$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectMeta$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], []]);
}

function init() {
    let copyOfStruct, copyOfStruct_1;
    return new Model(new ReportView(0, new ReportLevel(2)), toFirstDayOfMonth((copyOfStruct = utcNow(), addMonths(copyOfStruct, -13))), toLastDayOfMonth((copyOfStruct_1 = utcNow(), addMonths(copyOfStruct_1, -2))), void 0, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Noe gikk galt. Kunne ikke laste selskap.");
            }
            else {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, res.fields[0], model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
            }
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Noe gikk galt. Kunne ikke laste prosjekter.");
            }
            else {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, res_1.fields[0], model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
            }
        }
        case 3: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Noe gikk galt. Kunne ikke laste prosjekter.");
            }
            else {
                return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, res_2.fields[0], model.PrevCompId, model.PrevProjId, model.ErrorMsg);
            }
        }
        case 4: {
            const x_4 = msg.fields[0] | 0;
            const matchValue = model.SelectedView;
            switch (matchValue.tag) {
                case 1: {
                    switch (x_4) {
                        case 1: {
                            return new Model(new ReportView(1, new ReportLevel_1(2)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
                        }
                        case 2: {
                            const matchValue_5 = model.Companies;
                            let pattern_matching_result, c_3;
                            if (matchValue_5 != null) {
                                if (matchValue_5.length > 0) {
                                    pattern_matching_result = 0;
                                    c_3 = matchValue_5;
                                }
                                else {
                                    pattern_matching_result = 1;
                                }
                            }
                            else {
                                pattern_matching_result = 1;
                            }
                            switch (pattern_matching_result) {
                                case 0: {
                                    let _arg3;
                                    const matchValue_6 = model.PrevCompId;
                                    if (matchValue_6 != null) {
                                        const b_2 = matchValue_6 | 0;
                                        _arg3 = tryFind((y_2) => (y_2.Id === b_2), c_3);
                                    }
                                    else {
                                        _arg3 = tryHead(c_3);
                                    }
                                    if (_arg3 != null) {
                                        const y_3 = _arg3;
                                        return new Model(new ReportView(1, new ReportLevel_1(1, y_3.Id)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, y_3.Id, model.PrevProjId, model.ErrorMsg);
                                    }
                                    else {
                                        return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert");
                                    }
                                }
                                case 1: {
                                    return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert.");
                                }
                            }
                        }
                        default: {
                            const matchValue_7 = model.WasteProjects;
                            let pattern_matching_result_1, p_3;
                            if (matchValue_7 != null) {
                                if (matchValue_7.length > 0) {
                                    pattern_matching_result_1 = 0;
                                    p_3 = matchValue_7;
                                }
                                else {
                                    pattern_matching_result_1 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                            switch (pattern_matching_result_1) {
                                case 0: {
                                    let _arg4;
                                    const matchValue_8 = model.PrevProjId;
                                    if (matchValue_8 != null) {
                                        const b_3 = matchValue_8 | 0;
                                        _arg4 = tryFind((y_4) => (y_4.Id === b_3), p_3);
                                    }
                                    else {
                                        _arg4 = tryHead(p_3);
                                    }
                                    if (_arg4 != null) {
                                        const y_5 = _arg4;
                                        return new Model(new ReportView(1, new ReportLevel_1(0, y_5.Id)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, y_5.Id, model.ErrorMsg);
                                    }
                                    else {
                                        return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen prosjekter registrert");
                                    }
                                }
                                case 1: {
                                    return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen prosjekter registrert");
                                }
                            }
                        }
                    }
                }
                case 2: {
                    switch (x_4) {
                        case 1: {
                            return new Model(new ReportView(2, new ReportLevel_2(1)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
                        }
                        case 2: {
                            const matchValue_9 = model.Companies;
                            let pattern_matching_result_2, c_5;
                            if (matchValue_9 != null) {
                                if (matchValue_9.length > 0) {
                                    pattern_matching_result_2 = 0;
                                    c_5 = matchValue_9;
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                            switch (pattern_matching_result_2) {
                                case 0: {
                                    let _arg5;
                                    const matchValue_10 = model.PrevCompId;
                                    if (matchValue_10 != null) {
                                        const b_4 = matchValue_10 | 0;
                                        _arg5 = tryFind((y_6) => (y_6.Id === b_4), c_5);
                                    }
                                    else {
                                        _arg5 = tryHead(c_5);
                                    }
                                    if (_arg5 != null) {
                                        const y_7 = _arg5;
                                        return new Model(new ReportView(2, new ReportLevel_2(0, y_7.Id)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, y_7.Id, model.PrevProjId, model.ErrorMsg);
                                    }
                                    else {
                                        return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert");
                                    }
                                }
                                case 1: {
                                    return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert");
                                }
                            }
                        }
                        default: {
                            return model;
                        }
                    }
                }
                default: {
                    switch (x_4) {
                        case 1: {
                            return new Model(new ReportView(0, new ReportLevel(2)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
                        }
                        case 2: {
                            const matchValue_1 = model.Companies;
                            let pattern_matching_result_3, c_1;
                            if (matchValue_1 != null) {
                                if (matchValue_1.length > 0) {
                                    pattern_matching_result_3 = 0;
                                    c_1 = matchValue_1;
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                            switch (pattern_matching_result_3) {
                                case 0: {
                                    let _arg1;
                                    const matchValue_2 = model.PrevCompId;
                                    if (matchValue_2 != null) {
                                        const b = matchValue_2 | 0;
                                        _arg1 = tryFind((y) => (y.Id === b), c_1);
                                    }
                                    else {
                                        _arg1 = tryHead(c_1);
                                    }
                                    if (_arg1 != null) {
                                        const y_1 = _arg1;
                                        return new Model(new ReportView(0, new ReportLevel(1, y_1.Id)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, y_1.Id, model.PrevProjId, model.ErrorMsg);
                                    }
                                    else {
                                        return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert");
                                    }
                                }
                                case 1: {
                                    return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen selskap registrert");
                                }
                            }
                        }
                        default: {
                            const matchValue_3 = model.HmsProjects;
                            let pattern_matching_result_4, p_1;
                            if (matchValue_3 != null) {
                                if (matchValue_3.length > 0) {
                                    pattern_matching_result_4 = 0;
                                    p_1 = matchValue_3;
                                }
                                else {
                                    pattern_matching_result_4 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_4 = 1;
                            }
                            switch (pattern_matching_result_4) {
                                case 0: {
                                    let _arg2;
                                    const matchValue_4 = model.PrevProjId;
                                    if (matchValue_4 != null) {
                                        const b_1 = matchValue_4 | 0;
                                        _arg2 = tryFind((z) => (z.Id === b_1), p_1);
                                    }
                                    else {
                                        _arg2 = tryHead(p_1);
                                    }
                                    if (_arg2 != null) {
                                        const z_1 = _arg2;
                                        return new Model(new ReportView(0, new ReportLevel(0, z_1.Id)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, z_1.Id, model.ErrorMsg);
                                    }
                                    else {
                                        return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen prosjekter registrert");
                                    }
                                }
                                case 1: {
                                    return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, "Ingen prosjekter registrert");
                                }
                            }
                        }
                    }
                }
            }
        }
        case 5: {
            const x_5 = msg.fields[0] | 0;
            const matchValue_11 = model.SelectedView;
            switch (matchValue_11.tag) {
                case 1: {
                    const y_9 = matchValue_11.fields[0];
                    let pattern_matching_result_5;
                    if (y_9.tag === 1) {
                        if (y_9.fields[0] !== x_5) {
                            pattern_matching_result_5 = 0;
                        }
                        else {
                            pattern_matching_result_5 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                    switch (pattern_matching_result_5) {
                        case 0: {
                            return new Model(new ReportView(1, new ReportLevel_1(1, x_5)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, x_5, model.PrevProjId, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
                case 2: {
                    const y_10 = matchValue_11.fields[0];
                    let pattern_matching_result_6;
                    if (y_10.tag === 0) {
                        if (y_10.fields[0] !== x_5) {
                            pattern_matching_result_6 = 0;
                        }
                        else {
                            pattern_matching_result_6 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_6 = 1;
                    }
                    switch (pattern_matching_result_6) {
                        case 0: {
                            return new Model(new ReportView(2, new ReportLevel_2(0, x_5)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, x_5, model.PrevProjId, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
                default: {
                    const y_8 = matchValue_11.fields[0];
                    let pattern_matching_result_7;
                    if (y_8.tag === 1) {
                        if (y_8.fields[0] !== x_5) {
                            pattern_matching_result_7 = 0;
                        }
                        else {
                            pattern_matching_result_7 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_7 = 1;
                    }
                    switch (pattern_matching_result_7) {
                        case 0: {
                            return new Model(new ReportView(0, new ReportLevel(1, x_5)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, x_5, model.PrevProjId, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
            }
        }
        case 6: {
            const x_6 = msg.fields[0] | 0;
            const matchValue_12 = model.SelectedView;
            switch (matchValue_12.tag) {
                case 1: {
                    const y_12 = matchValue_12.fields[0];
                    let pattern_matching_result_8;
                    if (y_12.tag === 0) {
                        if (y_12.fields[0] !== x_6) {
                            pattern_matching_result_8 = 0;
                        }
                        else {
                            pattern_matching_result_8 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_8 = 1;
                    }
                    switch (pattern_matching_result_8) {
                        case 0: {
                            return new Model(new ReportView(1, new ReportLevel_1(0, x_6)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, x_6, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
                case 2: {
                    return model;
                }
                default: {
                    const y_11 = matchValue_12.fields[0];
                    let pattern_matching_result_9;
                    if (y_11.tag === 0) {
                        if (y_11.fields[0] !== x_6) {
                            pattern_matching_result_9 = 0;
                        }
                        else {
                            pattern_matching_result_9 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_9 = 1;
                    }
                    switch (pattern_matching_result_9) {
                        case 0: {
                            return new Model(new ReportView(0, new ReportLevel(0, x_6)), model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, x_6, model.ErrorMsg);
                        }
                        case 1: {
                            return model;
                        }
                    }
                }
            }
        }
        case 7: {
            try {
                const y_13 = parse(msg.fields[0]);
                return new Model(model.SelectedView, y_13, (compare(y_13, model.PeriodEnd) >= 0) ? addMonths(y_13, 1) : model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
            }
            catch (matchValue_13) {
                return model;
            }
        }
        case 8: {
            try {
                const y_14 = parse(msg.fields[0]);
                return new Model(model.SelectedView, (compare(y_14, model.PeriodStart) <= 0) ? addMonths(y_14, -1) : model.PeriodStart, y_14, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
            }
            catch (matchValue_14) {
                return model;
            }
        }
        case 9: {
            return new Model(model.SelectedView, model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, void 0);
        }
        default: {
            return new Model(msg.fields[0], model.PeriodStart, model.PeriodEnd, model.Companies, model.HmsProjects, model.WasteProjects, model.PrevCompId, model.PrevProjId, model.ErrorMsg);
        }
    }
}

function fetchCompanies(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchHmsProjects(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/hms/projects", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchWasteProjects(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/projects", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProjectMeta$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["height", toText(printf("calc(100vh - %ipx)"))(52)]]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["top", 52]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(240)], ["marginLeft", 240]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["top", 52]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        content: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["marginLeft", op_UnaryNegation_Int32(240)], ["overflowY", "scroll"]])),
        contentShift: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", toText(printf("calc(100%% - %ipx)"))(240)]])),
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        menuEntry: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 16], ["paddingBottom", 8], ["paddingTop", 8]])),
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setDrawerOpen = patternInput[1];
    const drawerOpen = patternInput[0];
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", drawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["color", "inherit"], ["edge", "start"], ["onClick", (_arg1) => {
            setDrawerOpen(!drawerOpen);
        }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]]), MuiHelpers_createElement(Typography, [["children", "Rapporter"]])])]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                setDrawerOpen(false);
            })]),
        })), delay(() => append(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => append(singleton_1(MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListSubheader, [["children", "Rapporttype"]]), MuiHelpers_createElement(ListItem, [["selected", ReportView__HmsSelected(model.SelectedView)], ["button", true], ["key", "hms"], ["onClick", (_arg3) => {
            dispatch(new Message(0, new ReportView(0, new ReportLevel(2))));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", "HMS"]])])]]), MuiHelpers_createElement(ListItem, [["selected", ReportView__WasteSelected(model.SelectedView)], ["button", true], ["key", "waste"], ["onClick", (_arg4) => {
            dispatch(new Message(0, new ReportView(1, new ReportLevel_1(2))));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", "Sorteringsgrad"]])])]]), MuiHelpers_createElement(ListItem, [["selected", ReportView__QualitySelected(model.SelectedView)], ["button", true], ["key", "quality"], ["onClick", (_arg5) => {
            dispatch(new Message(0, new ReportView(2, new ReportLevel_2(1))));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", "Kvalitet"]])])]])])]])), delay(() => append(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => append(singleton_1(createElement("div", {
            className: s.menuEntry,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Rapporteringsnivå"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", ReportView__ToSelectReportLevel(model.SelectedView)], ["onChange", (e, _arg24) => {
                dispatch(new Message(4, Browser_Types_Event__Event_get_Value(e)));
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(MenuItem, [["value", 1], ["children", "Konsern"]])), delay(() => append(singleton_1(MuiHelpers_createElement(MenuItem, [["value", 2], ["children", "Selskap"]])), delay(() => (ReportView__ShouldShowProjectSelect(model.SelectedView) ? singleton_1(MuiHelpers_createElement(MenuItem, [["value", 3], ["children", "Prosjekt"]])) : empty_1())))))))))]])])]])]),
        })), delay(() => append(ReportView__ShouldShowCompanySelect(model.SelectedView) ? singleton_1(createElement("div", {
            className: s.menuEntry,
            style: {},
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = model.Companies;
                if (matchValue != null) {
                    const x = matchValue;
                    return singleton_1(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Selskap"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", ReportView__GetSourceId(model.SelectedView)], ["onChange", (e_1, _arg24_1) => {
                        dispatch(new Message(5, Browser_Types_Event__Event_get_Value(e_1)));
                    }], ["children", Interop_reactApi.Children.toArray([map((c) => MuiHelpers_createElement(MenuItem, [["value", c.Id], ["children", c.Name]]), x)])]])])]]));
                }
                else {
                    return singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"]])]),
                    }));
                }
            })))),
        })) : empty_1(), delay(() => append(ReportView__ShouldShowProjectSelect(model.SelectedView) ? singleton_1(createElement("div", {
            className: s.menuEntry,
            style: {},
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue_1 = model.SelectedView;
                switch (matchValue_1.tag) {
                    case 1: {
                        const matchValue_3 = model.WasteProjects;
                        if (matchValue_3 != null) {
                            const x_2 = matchValue_3;
                            return singleton_1(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Prosjekt"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", ReportView__GetSourceId(model.SelectedView)], ["onChange", (e_3, _arg24_3) => {
                                dispatch(new Message(6, Browser_Types_Event__Event_get_Value(e_3)));
                            }], ["children", Interop_reactApi.Children.toArray([map((c_2) => MuiHelpers_createElement(MenuItem, [["value", c_2.Id], ["children", c_2.Name]]), x_2)])]])])]]));
                        }
                        else {
                            return singleton_1(createElement("div", {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"]])]),
                            }));
                        }
                    }
                    case 2: {
                        return empty_1();
                    }
                    default: {
                        const matchValue_2 = model.HmsProjects;
                        if (matchValue_2 != null) {
                            const x_1 = matchValue_2;
                            return singleton_1(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Prosjekt"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", ReportView__GetSourceId(model.SelectedView)], ["onChange", (e_2, _arg24_2) => {
                                dispatch(new Message(6, Browser_Types_Event__Event_get_Value(e_2)));
                            }], ["children", Interop_reactApi.Children.toArray([map((c_1) => MuiHelpers_createElement(MenuItem, [["value", c_1.Id], ["children", c_1.Name]]), x_1)])]])])]]));
                        }
                        else {
                            return singleton_1(createElement("div", {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                },
                                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"]])]),
                            }));
                        }
                    }
                }
            })))),
        })) : empty_1(), delay(() => append(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => (ReportView__ShouldShowPeriodSelect(model.SelectedView) ? append(singleton_1(MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListSubheader, [["children", "Rapporteringsperiode"]])])]])), delay(() => {
            let copyOfStruct, copyOfStruct_1;
            return append(singleton_1(createElement("div", {
                className: s.menuEntry,
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Fra"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", toString(model.PeriodStart, "yyyy-MM")], ["onChange", (e_4, _arg24_4) => {
                    dispatch(new Message(7, Browser_Types_Event__Event_get_Value(e_4)));
                }], ["SelectDisplayProps", {
                    style: {
                        textAlign: "center",
                    },
                }], ["children", Interop_reactApi.Children.toArray([map((tupledArg) => {
                    let arg20_2, arg10_3;
                    const year = tupledArg[0] | 0;
                    const month = tupledArg[1] | 0;
                    return MuiHelpers_createElement(MenuItem, [["value", (arg20_2 = format('{0:' + "00" + '}', month), toText(printf("%i-%s"))(year)(arg20_2))], ["children", (arg10_3 = format('{0:' + "00" + '}', month), toText(printf("%s/%i"))(arg10_3)(year))]]);
                }, monthsInPeriod((copyOfStruct = now(), addYears(copyOfStruct, -2)), (copyOfStruct_1 = now(), addMonths(copyOfStruct_1, -2))))])]])])]])]),
            })), delay(() => {
                let copyOfStruct_3, copyOfStruct_2, copyOfStruct_4;
                return append(singleton_1(createElement("div", {
                    className: s.menuEntry,
                    children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Til"]]), MuiHelpers_createElement(Select, [["fullWidth", true], ["value", toString(model.PeriodEnd, "yyyy-MM")], ["onChange", (e_5, _arg24_5) => {
                        dispatch(new Message(8, Browser_Types_Event__Event_get_Value(e_5)));
                    }], ["SelectDisplayProps", {
                        style: {
                            textAlign: "center",
                        },
                    }], ["children", Interop_reactApi.Children.toArray([map((tupledArg_1) => {
                        let arg20_4, arg10_5;
                        const year_1 = tupledArg_1[0] | 0;
                        const month_1 = tupledArg_1[1] | 0;
                        return MuiHelpers_createElement(MenuItem, [["value", (arg20_4 = format('{0:' + "00" + '}', month_1), toText(printf("%i-%s"))(year_1)(arg20_4))], ["children", (arg10_5 = format('{0:' + "00" + '}', month_1), toText(printf("%s/%i"))(arg10_5)(year_1))]]);
                    }, monthsInPeriod((copyOfStruct_3 = ((copyOfStruct_2 = now(), addYears(copyOfStruct_2, -2))), addMonths(copyOfStruct_3, 1)), (copyOfStruct_4 = now(), addMonths(copyOfStruct_4, -1))))])]])])]])]),
                })), delay(() => singleton_1(MuiHelpers_createElement(Divider, []))));
            }));
        })) : empty_1())))))))))))))))))))))]]), createElement("main", {
            className: drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: s.drawerHeader,
            }), snackbarError(model.ErrorMsg, () => {
                dispatch(new Message(9));
            }), createElement("div", {
                style: {},
                key: ReportView__ToKey(model.SelectedView, model.PeriodStart, model.PeriodEnd),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    const matchValue_4 = model.SelectedView;
                    switch (matchValue_4.tag) {
                        case 0: {
                            return singleton_1(createElement(HmsReport, {
                                lvl: matchValue_4.fields[0],
                                pStart: model.PeriodStart,
                                pEnd: model.PeriodEnd,
                            }));
                        }
                        case 2: {
                            return singleton_1(createElement(QualityReport, {
                                lvl: matchValue_4.fields[0],
                            }));
                        }
                        default: {
                            return singleton_1(createElement(WasteReport, {
                                level: matchValue_4.fields[0],
                                pStart: model.PeriodStart,
                                pEnd: model.PeriodEnd,
                            }));
                        }
                    }
                })))),
            })]),
        })]),
    });
}

export function Report() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchCompanies(dispatch);
        fetchHmsProjects(dispatch);
        fetchWasteProjects(dispatch);
    });
    return view(patternInput[0], dispatch);
}

