import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Waste_WastePlan, Waste_WastePlan$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { hasPermission } from "../LoginState.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { format, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { parse } from "../.fable/fable-library.3.2.4/Double.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorMedium, errorHeader, loadingIndicatorSmall } from "../ViewHelpers.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";

class Model extends Record {
    constructor(ProjectId, Plan, CanEdit, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Plan = Plan;
        this.CanEdit = CanEdit;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("WastePlan.Model", [], Model, () => [["ProjectId", int32_type], ["Plan", option_type(array_type(Waste_WastePlan$reflection()))], ["CanEdit", option_type(bool_type)], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PlanResponse", "UpdateResponse", "PermissionsResponse"];
    }
}

function Message$reflection() {
    return union_type("WastePlan.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WastePlan$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WastePlan$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_WastePlan$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_WastePlan$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init(projId) {
    return new Model(projId, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue, x_3;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.Plan, model.CanEdit, "Noe gikk galt. Kunne ikke oppdatere prosjektplan.");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model(model.ProjectId, (matchValue = model.Plan, (matchValue != null) ? map((z) => {
                    if (z.Type.Id === x_1.Type.Id) {
                        return x_1;
                    }
                    else {
                        return z;
                    }
                }, matchValue) : [x_1]), model.CanEdit, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.Plan, res_2.fields[0].some((x_3 = (Functionality__ToInt(new Functionality(12)) | 0), (y_2) => (x_3 === y_2))) ? true : hasPermission(new Functionality(57)), model.ErrorMsg);
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.Plan, model.CanEdit, "Noe gikk galt. Kunne ikke laste avfallsplan for prosjekt.");
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.CanEdit, model.ErrorMsg);
            }
        }
    }
}

function fetchWastePlan(projId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/plan/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Waste_WastePlan$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Waste_WastePlan$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function updateWastePlan(projId, x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/plan/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Waste_WastePlan$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Waste_WastePlan$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Waste_WastePlan$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchPermissions(projId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

function planInput(x, projId, canEdit, dispatch) {
    return FunctionComponent_Of_Z5A158BBF((plan) => {
        let children;
        const patternInput = useFeliz_React__React_useState_Static_1505(true);
        const setIsValid = patternInput[1];
        return createElement("tr", {
            key: plan.Type.Id,
            children: Interop_reactApi.Children.toArray([createElement("td", {
                children: [plan.Type.Name],
            }), (children = singleton(MuiHelpers_createElement(TextField, [["size", "small"], ["inputProps", {
                style: {
                    margin: "auto",
                    textAlign: "center",
                },
            }], ["error", !patternInput[0]], ["defaultValue", format('{0:' + "0.000" + '}', plan.Quantity)], ["disabled", !canEdit], ["onChange", (ev) => {
                const x_1 = ev.target.value;
                try {
                    const asFloat = parse(x_1);
                    if (asFloat !== plan.Quantity) {
                        const pr = updateWastePlan(projId, new Waste_WastePlan(plan.Id, plan.Type, asFloat), dispatch);
                        pr.then();
                    }
                    setIsValid(true);
                }
                catch (matchValue) {
                    setIsValid(false);
                }
            }]])), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))]),
        });
    }, void 0, uncurry(2, void 0), void 0, "planInput", "/builds/serit/score/score/src/Client/Waste/WastePlan.fs", 98)(x);
}

function drawPlan(model, disptach, plan) {
    let props_11, children_2, children, children_8, props_25, children_12, children_10, children_18;
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                margin: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.4 + "rem",
                },
                children: "Ordinært avfall",
            }), (props_11 = ofArray([["style", {
                maxWidth: 500 + "px",
                width: 100 + "%",
            }], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton((children = ofArray([createElement("th", {
                children: ["Avfallstype"],
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Mengde (tonn)",
            }), createElement("th", {
                style: {
                    width: 40 + "px",
                },
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_8 = toList(delay(() => {
                let children_6, children_4;
                const matchValue = model.CanEdit;
                if (matchValue != null) {
                    const edit = matchValue;
                    return singleton_1(map((x_1) => planInput(x_1, model.ProjectId, edit, disptach), plan.filter((x) => (!x.Type.Dangerous))));
                }
                else {
                    return singleton_1((children_6 = ofArray([createElement("td", {}), (children_4 = singleton(loadingIndicatorSmall()), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    })), createElement("td", {})]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    })));
                }
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_11))))]),
        }), createElement("div", {
            style: {
                padding: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 1.4 + "rem",
                },
                children: "Farlig avfall",
            }), (props_25 = ofArray([["className", "is-fullwidth"], ["style", {
                maxWidth: 500 + "px",
            }], ["children", Interop_reactApi.Children.toArray([(children_12 = singleton((children_10 = ofArray([createElement("th", {
                children: ["Avfallstype"],
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Mengde (tonn)",
            }), createElement("th", {
                style: {
                    width: 35 + "px",
                },
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            })), (children_18 = toList(delay(() => {
                let children_16, children_14;
                const matchValue_1 = model.CanEdit;
                if (matchValue_1 != null) {
                    const edit_1 = matchValue_1;
                    return singleton_1(map((x_3) => planInput(x_3, model.ProjectId, edit_1, disptach), plan.filter((x_2) => x_2.Type.Dangerous)));
                }
                else {
                    return singleton_1((children_16 = ofArray([createElement("td", {}), (children_14 = singleton(loadingIndicatorSmall()), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                    })), createElement("td", {})]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                    })));
                }
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_18)),
            }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_25))))]),
        })]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue;
            return append((matchValue = model.ErrorMsg, (matchValue != null) ? singleton_1(errorHeader(matchValue)) : ((empty_1()))), delay(() => {
                const matchValue_1 = model.Plan;
                return (matchValue_1 != null) ? singleton_1(drawPlan(model, dispatch, matchValue_1)) : singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        padding: 10 + "vh",
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                }));
            }));
        })))),
    });
}

export function wastePlan(x) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.projectId));
        const dispatch = patternInput[1];
        useReact_useEffect_Z101E1A95(() => {
            const pr = fetchWastePlan(props.projectId, dispatch);
            pr.then();
            const pr_1 = fetchPermissions(props.projectId, dispatch);
            pr_1.then();
        }, []);
        return view(patternInput[0], dispatch);
    }, void 0, uncurry(2, void 0), void 0, "wastePlan", "/builds/serit/score/score/src/Client/Waste/WastePlan.fs", 270)({
        projectId: x,
    });
}

