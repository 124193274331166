import { ProgramModule_view, ProgramModule_withSetState } from "../Fable.Elmish.3.1.0/program.fs.js";
import * as react$002Ddom from "react-dom";
import { uncurry } from "../fable-library.3.2.4/Util.js";
import { Common_lazyView2With } from "./common.fs.js";

export function Program_Internal_withReactBatchedUsing(lazyView2With, placeholderId, program) {
    let lastRequest = void 0;
    return ProgramModule_withSetState((model, dispatch) => {
        if (lastRequest != null) {
            window.cancelAnimationFrame(lastRequest);
        }
        lastRequest = window.requestAnimationFrame((_arg1) => {
            react$002Ddom.render(lazyView2With((x, y) => (x === y), uncurry(2, ProgramModule_view(program)), model, dispatch), document.getElementById(placeholderId));
        });
    }, program);
}

export function Program_Internal_withReactSynchronousUsing(lazyView2With, placeholderId, program) {
    return ProgramModule_withSetState((model, dispatch) => {
        react$002Ddom.render(lazyView2With((x, y) => (x === y), uncurry(2, ProgramModule_view(program)), model, dispatch), document.getElementById(placeholderId));
    }, program);
}

export function Program_Internal_withReactHydrateUsing(lazyView2With, placeholderId, program) {
    return ProgramModule_withSetState((model, dispatch) => {
        react$002Ddom.hydrate(lazyView2With((x, y) => (x === y), uncurry(2, ProgramModule_view(program)), model, dispatch), document.getElementById(placeholderId));
    }, program);
}

export function Program_withReactBatched(placeholderId, program) {
    return Program_Internal_withReactBatchedUsing((equal, view, state, dispatch) => Common_lazyView2With(equal, view, state, dispatch), placeholderId, program);
}

export function Program_withReactSynchronous(placeholderId, program) {
    return Program_Internal_withReactSynchronousUsing((equal, view, state, dispatch) => Common_lazyView2With(equal, view, state, dispatch), placeholderId, program);
}

export function Program_withReact(placeholderId, program) {
    return Program_Internal_withReactBatchedUsing((equal, view, state, dispatch) => Common_lazyView2With(equal, view, state, dispatch), placeholderId, program);
}

export function Program_withReactUnoptimized(placeholderId, program) {
    return Program_Internal_withReactSynchronousUsing((equal, view, state, dispatch) => Common_lazyView2With(equal, view, state, dispatch), placeholderId, program);
}

export function Program_withReactHydrate(placeholderId, program) {
    return Program_Internal_withReactHydrateUsing((equal, view, state, dispatch) => Common_lazyView2With(equal, view, state, dispatch), placeholderId, program);
}

