import { Union, Record } from "./.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, int32_type, option_type, bool_type, string_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { LoginForm$reflection, LoginForm, Helpers_ResultMessage$reflection } from "./Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";
import { deleteRememberMe, storeRememberMe, tryGetRememberMe } from "./LocalStorage.js";
import { Page, toPath } from "./Pages.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, empty } from "./.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { createElement } from "react";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.45.0/React.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.45.0/Interop.fs.js";
import { append, empty as empty_1, singleton, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { tryFocus } from "./Utils.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { loadingIndicatorSmallColored } from "./ViewHelpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";

class Model extends Record {
    constructor(Username, Password, AutoFocusPwd, ErrorMsg, Processing, ValidUsername, ValidPassword, AuthModule) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.AutoFocusPwd = AutoFocusPwd;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
        this.ValidUsername = ValidUsername;
        this.ValidPassword = ValidPassword;
        this.AuthModule = AuthModule;
    }
}

function Model$reflection() {
    return record_type("Login.Model", [], Model, () => [["Username", string_type], ["Password", string_type], ["AutoFocusPwd", bool_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type], ["ValidUsername", bool_type], ["ValidPassword", bool_type], ["AuthModule", option_type(int32_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdatePassword", "TryLogin", "LoginResponse", "DismissError", "ModuleResponse"];
    }
}

function Message$reflection() {
    return union_type("Login.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init() {
    const rememberMe = tryGetRememberMe();
    return new Model((rememberMe.tag === 0) ? rememberMe.fields[0] : "", "", (rememberMe.tag === 0) ? true : false, void 0, false, true, true, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model(model.Username, x_1, model.AutoFocusPwd, model.ErrorMsg, model.Processing, model.ValidUsername, x_1.length > 0, model.AuthModule);
        }
        case 2: {
            const validUsername = model.Username.length > 0;
            const validPassword = model.Password.length > 0;
            if (validUsername ? validPassword : false) {
                return new Model(model.Username, model.Password, model.AutoFocusPwd, void 0, true, true, true, model.AuthModule);
            }
            else {
                return new Model(model.Username, model.Password, model.AutoFocusPwd, model.ErrorMsg, model.Processing, validUsername, validPassword, model.AuthModule);
            }
        }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                if (err.tag === 2) {
                    const x_3 = err.fields[0];
                    if (((x_3.status) === 301) ? true : ((x_3.status) === 302)) {
                        window.location.reload(true);
                        return new Model(model.Username, model.Password, model.AutoFocusPwd, "Allerede logget inn. Tøm buffer med CTRL + SHIFT + R.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                    }
                    else {
                        return new Model(model.Username, model.Password, model.AutoFocusPwd, "Noe gikk galt. Kunne ikke gjennomføre innlogging.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                    }
                }
                else {
                    return new Model(model.Username, model.Password, model.AutoFocusPwd, "Noe gikk galt. Kunne ikke logge deg inn.", false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                }
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    window.location.replace(toPath(new Page(33)));
                    window.location.reload();
                    return model;
                }
                else {
                    return new Model(model.Username, model.Password, model.AutoFocusPwd, x_2.Message, false, model.ValidUsername, model.ValidPassword, model.AuthModule);
                }
            }
        }
        case 4: {
            return new Model(model.Username, model.Password, model.AutoFocusPwd, void 0, model.Processing, model.ValidUsername, model.ValidPassword, model.AuthModule);
        }
        case 5: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                return new Model(model.Username, model.Password, model.AutoFocusPwd, model.ErrorMsg, model.Processing, model.ValidUsername, model.ValidPassword, res_1.fields[0]);
            }
            else {
                return model;
            }
        }
        default: {
            const x = msg.fields[0];
            return new Model(x, model.Password, model.AutoFocusPwd, model.ErrorMsg, model.Processing, x.length > 0, model.ValidPassword, model.AuthModule);
        }
    }
}

function fetchAuthModule(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => int32_type,
        });
        return Fetch_tryGet_5760677E("/api/auth/module", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => int32_type,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(5, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function tryLogin(username, password, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(2));
        if ((username.length > 0) ? (password.length > 0) : false) {
            const req = new LoginForm(username, password);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: LoginForm$reflection,
                })(req);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/auth/login", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(3, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            return Promise.resolve();
        }
    }));
    pr.then();
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const submit = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#FFFFFF"], ["borderColor", "#6495ED"], ["backgroundColor", "#6495ED"], ["marginTop", 10]]));
    o = {
        inpt: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginTop", 10], ["marginBottom", 10]])),
        submit: submit,
    };
    return Object.assign({}, o);
});

function authModuleText(x) {
    return createElement("span", {
        style: {
            color: "#777",
            fontSize: 0.8 + "rem",
        },
        children: (x === 2) ? "Logg inn med din AD bruker" : ((x === 3) ? "Logg inn med din Office-365 bruker" : "Logg inn med din sCore bruker"),
    });
}

function loginForm(model, dispatch) {
    let children_2, children, matchValue_2;
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505((tryGetRememberMe().tag === 0) ? true : false);
    const remember = patternInput[0];
    const elms = ofArray([createElement("div", {
        style: {
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("img", {
            src: "/images/logo_score.png",
            style: {
                marginBottom: 15 + "px",
            },
        })]),
    }), createElement("div", {
        style: {
            minHeight: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue_1 = model.AuthModule;
            if (matchValue_1 != null) {
                return singleton(authModuleText(matchValue_1));
            }
            else {
                return empty_1();
            }
        })))),
    }), (children_2 = ofArray([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "login-username"]), delay(() => append(singleton(["autoFocus", !model.AutoFocusPwd]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Brukernavn"]), delay(() => append(singleton(["onChange", (e) => {
        dispatch(new Message(0, Browser_Types_Event__Event_get_Value(e)));
    }]), delay(() => append(singleton(["defaultValue", model.Username]), delay(() => append((!model.ValidUsername) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Ugyldig brukernavn"]))) : empty_1(), delay(() => singleton(["onKeyUp", (kc) => {
        kc.preventDefault();
        if (kc.key === "Enter") {
            tryFocus("login-pwd");
        }
    }])))))))))))))))))))))))))), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["className", s.inpt]), delay(() => append(singleton(["id", "login-pwd"]), delay(() => append(singleton(["autoFocus", model.AutoFocusPwd]), delay(() => append(singleton(["required", true]), delay(() => append(singleton(["size", "small"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Passord"]), delay(() => append(singleton(["fullWidth", true]), delay(() => append(singleton(["type", "password"]), delay(() => append(singleton(["onChange", (e_1) => {
        dispatch(new Message(1, Browser_Types_Event__Event_get_Value(e_1)));
    }]), delay(() => append((!model.ValidPassword) ? append(singleton(["error", true]), delay(() => singleton(["helperText", "Ugyldig passord"]))) : empty_1(), delay(() => singleton(["onKeyUp", (kc_1) => {
        kc_1.preventDefault();
        if ((kc_1.key === "Enter") ? (!model.Processing) : false) {
            if (remember) {
                storeRememberMe(model.Username);
            }
            tryLogin(model.Username, model.Password, dispatch);
        }
    }])))))))))))))))))))))))))), (children = singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Husk meg"], ["control", MuiHelpers_createElement(Checkbox, [["color", "primary"], ["checked", remember], ["onChange", (e_2) => {
        const x = Browser_Types_Event__Event_get_Checked(e_2);
        if (x) {
            storeRememberMe(model.Username);
        }
        else {
            deleteRememberMe();
        }
        patternInput[1](x);
    }]])]])), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), MuiHelpers_createElement(Button, [["className", s.submit], ["type", "submit"], ["fullWidth", true], ["variant", "contained"], ["color", "primary"], ["children", "Logg inn"], ["onClick", (e_3) => {
        e_3.preventDefault();
        if (!model.Processing) {
            if (remember) {
                storeRememberMe(model.Username);
            }
            tryLogin(model.Username, model.Password, dispatch);
        }
    }], ["style", {
        height: 37,
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!model.Processing) ? singleton("Logg inn") : singleton(loadingIndicatorSmallColored("#fff")))))))]]), MuiHelpers_createElement(Snackbar, [["open", model.ErrorMsg != null], ["onClose", (_arg33, v) => {
        dispatch(new Message(4));
    }], ["autoHideDuration", 20000], ["anchorOrigin", {
        vertical: "top",
        horizontal: "center",
    }], ["children", MuiHelpers_createElement(Alert, [["onClose", (_arg2) => {
        dispatch(new Message(4));
    }], ["severity", "error"], ["variant", "filled"], ["children", (matchValue_2 = model.ErrorMsg, (matchValue_2 == null) ? "" : matchValue_2)]])]])]), createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))]);
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

function view(model, dispatch) {
    let elms_1, elms, props_2, props;
    const props_6 = ofArray([["className", "is-fullheight"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton_1((elms = singleton_1((props_2 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-one-third"], ["children", Interop_reactApi.Children.toArray([loginForm(model, dispatch)])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_2))))), createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "hero-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_6)));
}

export function Login() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAuthModule(dispatch);
    });
    return view(patternInput[0], dispatch);
}

