import { makeUnion, getUnionCases, array_type, union_type, bool_type, record_type, option_type, int32_type, string_type, class_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { Auto_generateDecoder_7848D058, fromString } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { Auto_toString_5A41365E } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Union, Record } from "./.fable/fable-library.3.2.4/Types.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";

export class Tab {
    constructor() {
    }
}

export function Tab$reflection() {
    return class_type("LocalStorage.Tab", void 0, Tab);
}

export function Tab_GetSelectedTab() {
    const value = localStorage.getItem("sCore.Client.SelectedTab");
    return fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: () => string_type,
    })), value);
}

export function Tab_SaveSelectedTab_Z721C83C5(title) {
    try {
        localStorage.setItem("sCore.Client.SelectedTab", Auto_toString_5A41365E(4, title, void 0, void 0, void 0, {
            ResolveType: () => string_type,
        }));
    }
    catch (matchValue) {
    }
}

export function Tab_DeleteMyPageDisplay() {
    localStorage.removeItem("sCore.Client.SelectedTab");
}

export class LocalStorageData extends Record {
    constructor(AutoSelectProject, AutoSelectCompany) {
        super();
        this.AutoSelectProject = AutoSelectProject;
        this.AutoSelectCompany = AutoSelectCompany;
    }
}

export function LocalStorageData$reflection() {
    return record_type("LocalStorage.LocalStorageData", [], LocalStorageData, () => [["AutoSelectProject", option_type(int32_type)], ["AutoSelectCompany", option_type(int32_type)]]);
}

const localStorageParser = Auto_generateDecoder_7848D058(void 0, void 0, {
    ResolveType: LocalStorageData$reflection,
});

export function loadLocalStorage() {
    return fromString(uncurry(2, localStorageParser), localStorage.getItem("constoks"));
}

export function saveLocalStorage(localStorageData) {
    try {
        localStorage.setItem("constoks", Auto_toString_5A41365E(4, localStorageData, void 0, void 0, void 0, {
            ResolveType: LocalStorageData$reflection,
        }));
    }
    catch (matchValue) {
    }
}

export function deleteLocalStorage() {
    localStorage.removeItem("constoks");
}

export class MyPageDisplay extends Record {
    constructor(Access, Projects, Revisions, Search) {
        super();
        this.Access = Access;
        this.Projects = Projects;
        this.Revisions = Revisions;
        this.Search = Search;
    }
}

export function MyPageDisplay$reflection() {
    return record_type("LocalStorage.MyPageDisplay", [], MyPageDisplay, () => [["Access", bool_type], ["Projects", bool_type], ["Revisions", bool_type], ["Search", bool_type]]);
}

export function loadMyPageDisplay() {
    const value = localStorage.getItem("ssMyPage");
    return fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: MyPageDisplay$reflection,
    })), value);
}

export function saveMyPageDisplay(myPageDisplay) {
    try {
        localStorage.setItem("ssMyPage", Auto_toString_5A41365E(4, myPageDisplay, void 0, void 0, void 0, {
            ResolveType: MyPageDisplay$reflection,
        }));
    }
    catch (matchValue) {
    }
}

export function deleteMyPageDisplay() {
    localStorage.removeItem("ssMyPage");
}

export class ManagementDisplay extends Record {
    constructor(Information, Tags, Remarks, Versions, Notifications, Files) {
        super();
        this.Information = Information;
        this.Tags = Tags;
        this.Remarks = Remarks;
        this.Versions = Versions;
        this.Notifications = Notifications;
        this.Files = Files;
    }
}

export function ManagementDisplay$reflection() {
    return record_type("LocalStorage.ManagementDisplay", [], ManagementDisplay, () => [["Information", bool_type], ["Tags", bool_type], ["Remarks", bool_type], ["Versions", bool_type], ["Notifications", bool_type], ["Files", bool_type]]);
}

export function loadManagementDisplay() {
    const value = localStorage.getItem("ssManagement");
    return fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: ManagementDisplay$reflection,
    })), value);
}

export function saveManagementDisplay(managementDisplay) {
    try {
        localStorage.setItem("ssManagement", Auto_toString_5A41365E(4, managementDisplay, void 0, void 0, void 0, {
            ResolveType: ManagementDisplay$reflection,
        }));
    }
    catch (matchValue) {
    }
}

export function deleteManagementDisplay() {
    localStorage.removeItem("ssManagement");
}

export class ProjectFilters extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectedCompanies", "SelectedBuilders", "SelectedProjectOutcomeTypes", "SelectedStatuses", "SelectedEnergyClasses", "SelectedBreeams", "ShowActive", "ShowInactive"];
    }
}

export function ProjectFilters$reflection() {
    return union_type("LocalStorage.ProjectFilters", [], ProjectFilters, () => [[], [], [], [], [], [], [], []]);
}

export function ProjectFilters__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "SelectedBuilders";
        }
        case 2: {
            return "SelectedProjectOutcomeTypes";
        }
        case 3: {
            return "SelectedStatuses";
        }
        case 4: {
            return "SelectedEnergyClasses";
        }
        case 5: {
            return "SelectedBreeams";
        }
        case 6: {
            return "ShowActive";
        }
        case 7: {
            return "ShowInactive";
        }
        default: {
            return "SelectedCompanies";
        }
    }
}

export function GetProjectFilter(key) {
    let x;
    const value = localStorage.getItem("sCore.Client.ProjectFilter_" + ProjectFilters__get_toString(key));
    x = fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: () => array_type(int32_type),
    })), value);
    if (x.tag === 0) {
        return x.fields[0];
    }
    else {
        return new Int32Array([]);
    }
}

export function SaveProjectFilter(key, newFilter) {
    try {
        const dta = Auto_toString_5A41365E(4, newFilter, void 0, void 0, void 0, {
            ResolveType: () => array_type(int32_type),
        });
        localStorage.setItem("sCore.Client.ProjectFilter_" + ProjectFilters__get_toString(key), dta);
    }
    catch (matchValue) {
    }
}

export function DeleteProjectFilter(key) {
    localStorage.removeItem("sCore.Client.ProjectFilter_" + ProjectFilters__get_toString(key));
}

export function ClearProjectFilters() {
    getUnionCases(ProjectFilters$reflection()).forEach((case$) => {
        DeleteProjectFilter(makeUnion(case$, []));
    });
}

export class ProjectShowStates extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowActive", "ShowInactive"];
    }
}

export function ProjectShowStates$reflection() {
    return union_type("LocalStorage.ProjectShowStates", [], ProjectShowStates, () => [[], []]);
}

export function ProjectShowStates__get_toString(this$) {
    if (this$.tag === 1) {
        return "ShowInactive";
    }
    else {
        return "ShowActive";
    }
}

export function GetProjectShowState(key) {
    let x;
    const value = localStorage.getItem("sCore.Client.ProjectShowStat_" + ProjectShowStates__get_toString(key));
    x = fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: () => bool_type,
    })), value);
    if (x.tag === 0) {
        return x.fields[0];
    }
    else if (key.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function SaveProjectShowState(key, newStatus) {
    try {
        const dta = Auto_toString_5A41365E(4, newStatus, void 0, void 0, void 0, {
            ResolveType: () => bool_type,
        });
        localStorage.setItem("sCore.Client.ProjectShowStat_" + ProjectShowStates__get_toString(key), dta);
    }
    catch (matchValue) {
    }
}

export function DeleteProjectShowState(key) {
    localStorage.removeItem("sCore.Client.ProjectShowStat_" + ProjectShowStates__get_toString(key));
}

export function ClearProjectShowStates() {
    getUnionCases(ProjectShowStates$reflection()).forEach((case$) => {
        DeleteProjectShowState(makeUnion(case$, []));
    });
}

export class MemberSearchBar extends Record {
    constructor(Company, Office, Function$, Education, Title, AutoSelectCompany, AutoSelectOffice, AutoSelectFunction, AutoSelectEducation, AutoSelectTitle) {
        super();
        this.Company = (Company | 0);
        this.Office = (Office | 0);
        this.Function = (Function$ | 0);
        this.Education = (Education | 0);
        this.Title = (Title | 0);
        this.AutoSelectCompany = AutoSelectCompany;
        this.AutoSelectOffice = AutoSelectOffice;
        this.AutoSelectFunction = AutoSelectFunction;
        this.AutoSelectEducation = AutoSelectEducation;
        this.AutoSelectTitle = AutoSelectTitle;
    }
}

export function MemberSearchBar$reflection() {
    return record_type("LocalStorage.MemberSearchBar", [], MemberSearchBar, () => [["Company", int32_type], ["Office", int32_type], ["Function", int32_type], ["Education", int32_type], ["Title", int32_type], ["AutoSelectCompany", array_type(int32_type)], ["AutoSelectOffice", array_type(int32_type)], ["AutoSelectFunction", array_type(string_type)], ["AutoSelectEducation", array_type(int32_type)], ["AutoSelectTitle", array_type(string_type)]]);
}

export function MemberSearchBar_get_Default() {
    return new MemberSearchBar(1, 2, 3, 4, 5, new Int32Array([]), new Int32Array([]), [], new Int32Array([]), []);
}

export function loadMemberSearchBar() {
    let x;
    const value = localStorage.getItem("ssMemberSearch");
    x = fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
        ResolveType: MemberSearchBar$reflection,
    })), value);
    if (x.tag === 0) {
        return x.fields[0];
    }
    else {
        return MemberSearchBar_get_Default();
    }
}

export function saveMemberSearchBar(entry) {
    try {
        localStorage.setItem("ssMemberSearch", Auto_toString_5A41365E(4, entry, void 0, void 0, void 0, {
            ResolveType: MemberSearchBar$reflection,
        }));
    }
    catch (matchValue) {
    }
}

export function deleteMemberSearchBar() {
    localStorage.removeItem("ssMemberSearch");
}

export function tryGetRememberMe() {
    try {
        const value = localStorage.getItem("remember-me");
        return fromString(uncurry(2, Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => string_type,
        })), value);
    }
    catch (matchValue) {
        return new FSharpResult$2(1, "");
    }
}

export function storeRememberMe(username) {
    try {
        localStorage.setItem("remember-me", Auto_toString_5A41365E(4, username, void 0, void 0, void 0, {
            ResolveType: () => string_type,
        }));
    }
    catch (matchValue) {
    }
}

export function deleteRememberMe() {
    try {
        localStorage.removeItem("remember-me");
    }
    catch (matchValue) {
    }
}

