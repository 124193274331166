import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { UserSearchResult$reflection, DocumentSearchEntry$reflection, CompanyPermission$reflection, DocumentNotificationForm$reflection, DocumentNotification$reflection, DocumentRevision$reflection, ProcessActivityDocVersionResponse$reflection, ProcessActivityDocVersionRequest$reflection, Attestation$reflection, AttestationForm$reflection, UserEducation$reflection, FilingKeyRef$reflection, FilingKey$reflection, DocumentOverviewProcess$reflection, Process$reflection, ProcessActivityLink$reflection, ProcessActivity$reflection, CompanyProcessActivityDoc$reflection, ProcessActivityDocVersion$reflection, FileStoreUploadCompVer$reflection, FileStoreUploadVer$reflection, FileStoreUploadDoc$reflection, FileStore$reflection, ProcessActivityDoc$reflection, ProjectProcessActivityFile$reflection, ProjectProcessActivityFileForm$reflection, ProjectProcessActivityLink$reflection, ProjectProcessActivityComment$reflection, ProjectActivityDocRole$reflection, ProjectPlan$reflection, ProjectPreloaded$reflection, Project$reflection, Helpers_ResultMessage$reflection, ProjectDetails$reflection, UserProjectVacation$reflection, ProjectVacationRequest$reflection, UserVacation$reflection, UserPictureForm$reflection, UserAllocation$reflection, Helpers_TimePeriod$reflection, User$reflection, CompanyRoleUser$reflection, CompanyRole$reflection, ProjectRoleUser$reflection, RoleSearchResult$reflection, SearchInput$reflection, Role$reflection, ActionClass$reflection, Helpers_IdNameRowpos$reflection, DeleteResponse$reflection, Helpers_IdNameDesc$reflection, KeyValue$reflection, Config$reflection, ProfessionalGroup$reflection, Department$reflection, Office$reflection, Helpers_IdName$reflection, Company$reflection, UserProfileAccess$reflection, ExternalProjectReference$reflection, UserCourse$reflection, UserEmployment$reflection, UserReferenceProject$reflection, UserIdentity$reflection, UserInfo$reflection } from "./Shared/ApiDataTypes.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Fetch_post_5760677E, Fetch_get_5760677E } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { uncurry } from "./.fable/fable-library.3.2.4/Util.js";
import { list_type, string_type, array_type, int32_type, obj_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { Auto_generateEncoder_Z127D9D79 } from "./.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { Types_RequestProperties } from "./.fable/Fable.Fetch.2.1.0/Fetch.fs.js";
import { singleton } from "./.fable/fable-library.3.2.4/List.js";
import { FileStore_AddFileSystemProject, FileStore_GetById, FilingKey_GetByParentId, Process_GetByParentId, ProcessActivity_AddDoc, ProcessActivity_GetByProcessId, ProcessActivity_GetById, ProcessActivityDoc_GetCompVersionsByDocId, ProcessActivityDoc_GetVersionsByDocId, ProcessActivityDoc_GetDocumentByDocId, ProcessActivityDoc_GetDocumentsByPaId, ProcessActivityDoc_DeleteCompVersionById, ProcessActivityDoc_DeleteVersionById, ProcessActivityDoc_GetSupervisorByProcessActivityDocId, ProcessActivityDoc_GetSupervisorByProjectProcessActivityDocId, ProcessActivityDoc_GetByProcessActivityId, ProcessActivityDoc_GetById, Project_GetProjectPreloaded, Config_GetByName as Config_GetByName_1 } from "./Shared/Urls.js";

export function MyPage_getLoggedInUserInfo() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserInfo$reflection,
        });
        return Fetch_get_5760677E("/api/mypage/userinfo", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserInfo$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserInfo(userId) {
    const requestPath = toText(printf("/api/mypage/userinfo/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserInfo$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserInfo$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getRights(userId) {
    const requestPath = toText(printf("/api/mypage/rights/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(int32_type),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(int32_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserIdentity(userId) {
    const requestPath = toText(printf("/api/mypage/identity/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserIdentity$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserIdentity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_addOrUpdateUserIdentity(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserIdentity$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserIdentity$reflection,
        });
        return Fetch_post_5760677E("/api/mypage/addidentity", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserIdentity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserReferenceProjects(userId) {
    const requestPath = toText(printf("/api/mypage/referenceprojects/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserReferenceProject$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserReferenceProject$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserEmployments(userId) {
    const requestPath = toText(printf("/api/mypage/employments/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserEmployment$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserEmployment$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserCourses(userId) {
    const requestPath = toText(printf("/api/mypage/courses/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserCourse$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserCourse$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getExternalProjectReferences(userId) {
    const requestPath = toText(printf("/api/mypage/externalreferences/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ExternalProjectReference$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ExternalProjectReference$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_addOrUpdateExternalReference(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        });
        return Fetch_post_5760677E("/api/mypage/externalreference/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ExternalProjectReference$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_removeExternalReference(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ExternalProjectReference$reflection,
        });
        return Fetch_post_5760677E("/api/mypage/externalreference/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ExternalProjectReference$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function MyPage_getUserProfileAccess(userId) {
    const requestPath = toText(printf("/api/mypage/hasaccess/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserProfileAccess$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserProfileAccess$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Company_getCompanies() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Company$reflection()),
        });
        return Fetch_get_5760677E("/api/company/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Company_getCompanyOfLoggedInUser() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Company$reflection,
        });
        return Fetch_get_5760677E("/api/company/ofuser", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Company$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Company_updateCompanyOrgNr(compId, entry) {
    const requestPath = toText(printf("/api/company/orgnr/update/%i"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Company$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Company$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Office_getOffices() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Office$reflection()),
        });
        return Fetch_get_5760677E("/api/office/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Department_getDepartments() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Department$reflection()),
        });
        return Fetch_get_5760677E("/api/department/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Department$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Department_getDistinctByName() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(string_type),
        });
        return Fetch_get_5760677E("/api/department/distinctbyname", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(string_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProfessionalGroup_getProfessionalGroups() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProfessionalGroup$reflection()),
        });
        return Fetch_get_5760677E("/api/professionalgroup/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProfessionalGroup$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProfessionalGroup_getDistinctByName() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(string_type),
        });
        return Fetch_get_5760677E("/api/professionalgroup/distinctbyname", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(string_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Config$reflection()),
        });
        return Fetch_get_5760677E("/api/config/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Config$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_GetByName(name) {
    const requestPath = Config_GetByName_1(name);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => string_type,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => string_type,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_GetAllStartingWith(key) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => string_type,
        })(key);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(KeyValue$reflection()),
        });
        return Fetch_post_5760677E("/api/config/getallstartingwith", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(KeyValue$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_add(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Config$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Config$reflection,
        });
        return Fetch_post_5760677E("/api/config/create", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Config$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Config$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Config$reflection,
        });
        return Fetch_post_5760677E("/api/config/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Config$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Config_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Config$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Config$reflection,
        });
        return Fetch_post_5760677E("/api/config/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Config$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Tag_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => list_type(Helpers_IdName$reflection()),
        });
        return Fetch_get_5760677E("/api/documenttag/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => list_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Tag_add(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/documenttag/create", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Tag_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/documenttag/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Tag_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/documenttag/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Builder_getBuilders() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        });
        return Fetch_get_5760677E("/api/builder/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Builder_addOrUpdateBuilder(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        });
        return Fetch_post_5760677E("/api/builder/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Builder_removeBuilder(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: DeleteResponse$reflection,
        });
        return Fetch_post_5760677E("/api/builder/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: DeleteResponse$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Breeam_getBreeams() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameRowpos$reflection()),
        });
        return Fetch_get_5760677E("/api/envprofile/breeams", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameRowpos$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Breeam_addOrUpdateBreeam(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        });
        return Fetch_post_5760677E("/api/envprofile/breeam/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameRowpos$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Breeam_removeBreeam(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        });
        return Fetch_post_5760677E("/api/envprofile/breeam/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameRowpos$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function EnergyClass_getEnergyClasses() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameRowpos$reflection()),
        });
        return Fetch_get_5760677E("/api/envprofile/energyclasses", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameRowpos$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function EnergyClass_addOrUpdateEnergyClass(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        });
        return Fetch_post_5760677E("/api/envprofile/energyclass/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameRowpos$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function EnergyClass_removeEnergyClass(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameRowpos$reflection,
        });
        return Fetch_post_5760677E("/api/envprofile/energyclass/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameRowpos$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ActionClass_getActionClasses() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ActionClass$reflection()),
        });
        return Fetch_get_5760677E("/api/actionclass/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ActionClass$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ActionClass_addOrUpdateActionClass(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ActionClass$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ActionClass$reflection,
        });
        return Fetch_post_5760677E("/api/actionclass/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ActionClass$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ActionClass_removeActionClass(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ActionClass$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ActionClass$reflection,
        });
        return Fetch_post_5760677E("/api/actionclass/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ActionClass$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectOutcomeType_getProjectOutcomeTypes() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_get_5760677E("/api/projectoutcometype/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectOutcomeType_addOrUpdateProjectOutcomeType(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/projectoutcometype/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectOutcomeType_removeProjectOutcomeType(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/projectoutcometype/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_getRoles() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Role$reflection()),
        });
        return Fetch_get_5760677E("/api/role/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Role$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_search(inpt) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: SearchInput$reflection,
        })(inpt);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: RoleSearchResult$reflection,
        });
        return Fetch_post_5760677E("/api/role/search", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: RoleSearchResult$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_addOrUpdateRole(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Role$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Role$reflection,
        });
        return Fetch_post_5760677E("/api/role/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Role$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_removeRole(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Role$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: DeleteResponse$reflection,
        });
        return Fetch_post_5760677E("/api/role/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: DeleteResponse$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_ProjectRoleUser_SetAlloccation(pru) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectRoleUser$reflection,
        })(pru);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectRoleUser$reflection,
        });
        return Fetch_post_5760677E("/api/project/role/allocation", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectRoleUser$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRole_getCompanyRoles(companyId) {
    const requestPath = toText(printf("/api/companyrole/company/%i"))(companyId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(CompanyRole$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(CompanyRole$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRole_getCompanyRoleUsersForCompany(companyId) {
    const requestPath = toText(printf("/api/companyrole/users/%i"))(companyId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(CompanyRoleUser$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(CompanyRoleUser$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRole_addCompanyRole(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyRole$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyRole$reflection,
        });
        return Fetch_post_5760677E("/api/companyrole/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyRole$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRole_removeCompanyRole(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyRole$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyRole$reflection,
        });
        return Fetch_post_5760677E("/api/companyrole/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyRole$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRoleUser_assign(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        });
        return Fetch_post_5760677E("/api/companyroleuser/assign", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyRoleUser$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRoleUser_unassign(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        });
        return Fetch_post_5760677E("/api/companyroleuser/unassign", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyRoleUser$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Role_CompanyRoleUser_SetAlloccation(cru) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        })(cru);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyRoleUser$reflection,
        });
        return Fetch_post_5760677E("/api/companyroleuser/setallocation", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyRoleUser$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getAllUsers() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(User$reflection()),
        });
        return Fetch_get_5760677E("/api/user/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(User$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getUsersInOfficeByProject(projId) {
    const requestPath = toText(printf("/api/user/forofficewithproj/%i"))(projId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(User$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(User$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getUserAllocationInCompany(period, compId) {
    const requestPath = toText(printf("/api/users/allocated/company/%i"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_TimePeriod$reflection,
        })(period);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserAllocation$reflection()),
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserAllocation$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getUserInfo(userId) {
    const requestPath = toText(printf("/api/user/info/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserInfo$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserInfo$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_uploadProfilePicture(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserPictureForm$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserPictureForm$reflection,
        });
        return Fetch_post_5760677E("/api/user/uploadpicture", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserPictureForm$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getProfilePictureUrlData(userId) {
    const requestPath = toText(printf("/api/user/profilepicturedata/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserPictureForm$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserPictureForm$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getUsersInCompany(compId) {
    const requestPath = toText(printf("/api/user/company/%i"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(User$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(User$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getUsersVacations(usrId) {
    const requestPath = toText(printf("/api/calendar/vacations/%i"))(usrId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserVacation$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserVacation$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_addUserVacation(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserVacation$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserVacation$reflection,
        });
        return Fetch_post_5760677E("/api/user/vacation/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserVacation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getAllTitles() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(string_type),
        });
        return Fetch_get_5760677E("/api/user/titles", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(string_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function User_getProjectsAndVacationsForPeriod(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectVacationRequest$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserProjectVacation$reflection,
        });
        return Fetch_post_5760677E("/api/user/allocation/period", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserProjectVacation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Project_saveProjectDetails(projDetails) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectDetails$reflection,
        })(projDetails);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_post_5760677E("/api/project/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Project_getByUserOffice() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Project$reflection()),
        });
        return Fetch_get_5760677E("/api/project/getbyuseroffice", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Project$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Project_getProjectDetails(pId) {
    const requestPath = toText(printf("/api/project/details/%i"))(pId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectDetails$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectDetails$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Project_getProjectPreloaded(pId) {
    const requestPath = Project_GetProjectPreloaded(pId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectPreloaded$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectPreloaded$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Project_deleteProject(projId) {
    const requestPath = toText(printf("/api/project/delete/%i"))(projId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => string_type,
        })("");
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_getProjectPlan(projId) {
    const requestPath = toText(printf("/api/projectplan/%i"))(projId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectPlan$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectPlan$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_setProjectProcessActivityDocResponsible(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectActivityDocRole$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectActivityDocRole$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/setprocessdocrole", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectActivityDocRole$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_addComment(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityComment$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityComment$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/comment/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityComment$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_removeComment(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityComment$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityComment$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/comment/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityComment$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_addLink(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityLink$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityLink$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/link/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityLink$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_removeLink(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityLink$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityLink$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/link/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityLink$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_addFile(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityFileForm$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityFile$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/doc/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityFile$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPlan_removeFile(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProjectProcessActivityFile$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProjectProcessActivityFile$reflection,
        });
        return Fetch_post_5760677E("/api/projectplan/doc/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProjectProcessActivityFile$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessActivityDoc$reflection()),
        });
        return Fetch_get_5760677E("/api/processactivitydoc/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessActivityDoc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getById(id) {
    const requestPath = ProcessActivityDoc_GetById(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getByProcessId(processId) {
    const requestPath = ProcessActivityDoc_GetByProcessActivityId(processId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessActivityDoc$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessActivityDoc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getSupervisorByProjectProcessActivityDocId(docId) {
    const requestPath = ProcessActivityDoc_GetSupervisorByProjectProcessActivityDocId(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getSupervisorByProcessActivityDocId(docId) {
    const requestPath = ProcessActivityDoc_GetSupervisorByProcessActivityDocId(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_uploadDoc(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStoreUploadDoc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStoreUploadDoc$reflection,
        });
        return Fetch_post_5760677E("/api/processactivitydoc/uploaddoc", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStoreUploadDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_uploadDocVer(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStoreUploadVer$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStoreUploadVer$reflection,
        });
        return Fetch_post_5760677E("/api/processactivitydoc/uploaddocver", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStoreUploadVer$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_uploadCompVer(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStoreUploadCompVer$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStoreUploadCompVer$reflection,
        });
        return Fetch_post_5760677E("/api/processactivitydoc/uploadcompver", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStoreUploadCompVer$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E("/api/processactivitydoc/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E("/api/processactivitydoc/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_deleteVersionById(id) {
    const requestPath = ProcessActivityDoc_DeleteVersionById(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDocVersion$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDocVersion$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_deleteCompVersionById(id) {
    const requestPath = ProcessActivityDoc_DeleteCompVersionById(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyProcessActivityDoc$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getProcessActivityDoc(id) {
    const requestPath = toText(printf("/api/processactivitydoc/filestoredoc/%i"))(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getTags() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_get_5760677E("/api/processactivitydoc/gettags", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getDocumentsByPaId(paId) {
    const requestPath = ProcessActivityDoc_GetDocumentsByPaId(paId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStoreUploadDoc$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStoreUploadDoc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getDocumentByDocId(docId) {
    const requestPath = ProcessActivityDoc_GetDocumentByDocId(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStoreUploadDoc$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStoreUploadDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getVersionsByDocId(docId) {
    const requestPath = ProcessActivityDoc_GetVersionsByDocId(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStoreUploadVer$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStoreUploadVer$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivityDoc_getCompVersionsByDocId(docId) {
    const requestPath = ProcessActivityDoc_GetCompVersionsByDocId(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStoreUploadCompVer$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStoreUploadCompVer$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessActivity$reflection()),
        });
        return Fetch_get_5760677E("/api/processactivity/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessActivity$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_getById(id) {
    const requestPath = ProcessActivity_GetById(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_getByProcessId(processId) {
    const requestPath = ProcessActivity_GetByProcessId(processId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(ProcessActivity$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(ProcessActivity$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_addDoc(processActivityId, entry) {
    const requestPath = ProcessActivity_AddDoc(processActivityId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_addLink(link) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityLink$reflection,
        })(link);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/addlink", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_updateLink(link) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityLink$reflection,
        })(link);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/updatelink", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_deleteLink(link) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityLink$reflection,
        })(link);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/deletelink", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_add(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProcessActivity_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivity$reflection,
        });
        return Fetch_post_5760677E("/api/processactivity/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivity$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Process$reflection()),
        });
        return Fetch_get_5760677E("/api/process/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Process$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_getByParentId(parentId) {
    const requestPath = Process_GetByParentId(parentId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Process$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Process$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_add(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Process$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Process$reflection,
        });
        return Fetch_post_5760677E("/api/process/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Process$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Process$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Process$reflection,
        });
        return Fetch_post_5760677E("/api/process/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Process$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Process$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Process$reflection,
        });
        return Fetch_post_5760677E("/api/process/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Process$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Process_getDocTree() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(DocumentOverviewProcess$reflection()),
        });
        return Fetch_get_5760677E("/api/process/doctree", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(DocumentOverviewProcess$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKey_getFilingKeys() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FilingKey$reflection()),
        });
        return Fetch_get_5760677E("/api/filingkey/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FilingKey$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKey_getByParentId(parentId) {
    const requestPath = FilingKey_GetByParentId(parentId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FilingKey$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FilingKey$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKey_addOrUpdate(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FilingKey$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FilingKey$reflection,
        });
        return Fetch_post_5760677E("/api/filingkey/addorupdate", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FilingKey$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKey_Delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FilingKey$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FilingKey$reflection,
        });
        return Fetch_post_5760677E("/api/filingkey/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FilingKey$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKeyRef_GetAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FilingKeyRef$reflection()),
        });
        return Fetch_get_5760677E("/api/filingkeyref/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FilingKeyRef$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKeyRef_AddOrUpdate(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FilingKeyRef$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FilingKeyRef$reflection,
        });
        return Fetch_post_5760677E("/api/filingkeyref/addorupdate", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FilingKeyRef$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FilingKeyRef_Delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FilingKeyRef$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FilingKeyRef$reflection,
        });
        return Fetch_post_5760677E("/api/filingkeyref/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FilingKeyRef$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Interaction_getInteractions() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        });
        return Fetch_get_5760677E("/api/interaction/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Interaction_addOrUpdateInteraction(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        });
        return Fetch_post_5760677E("/api/interaction/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Interaction_removeInteraction(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdNameDesc$reflection,
        });
        return Fetch_post_5760677E("/api/interaction/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdNameDesc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_getAll() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStore$reflection()),
        });
        return Fetch_get_5760677E("/api/filestore/all", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStore$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_getById(id) {
    const requestPath = FileStore_GetById(id);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_addFileSystemOverriding() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStore$reflection()),
        });
        return Fetch_get_5760677E("/api/filestore/filesys/overriding", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStore$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_addFileSystemProject(prName) {
    const requestPath = FileStore_AddFileSystemProject(prName);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStore$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStore$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_add(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStore$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_post_5760677E("/api/filestore/upload", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_update(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStore$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_post_5760677E("/api/filestore/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function FileStore_delete(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: FileStore$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: FileStore$reflection,
        });
        return Fetch_post_5760677E("/api/filestore/delete", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: FileStore$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_getUserEducation(userId) {
    const requestPath = toText(printf("/api/mypage/education/%i"))(userId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserEducation$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserEducation$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_addOrUpdateUserEducation(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserEducation$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserEducation$reflection,
        });
        return Fetch_post_5760677E("/api/mypage/addeducation", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserEducation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_deleteEducation(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserEducation$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserEducation$reflection,
        });
        return Fetch_post_5760677E("/api/mypage/deleteeducation", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserEducation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_getEducationLevels() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_get_5760677E("/api/education/levels", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_addOrUpdateEducationLevel(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/education/level/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_removeEducationLevel(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        });
        return Fetch_post_5760677E("/api/education/level/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_IdName$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_uploadEducationAttesatation(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: AttestationForm$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Attestation$reflection,
        });
        return Fetch_post_5760677E("/api/education/attestation/add", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Attestation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Education_deleteEducationAttestation(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Attestation$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Attestation$reflection,
        });
        return Fetch_post_5760677E("/api/education/attestation/remove", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Attestation$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_getManagementOverview(parentProcess) {
    const requestPath = toText(printf("/api/management/overview/%i"))(parentProcess);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Process$reflection,
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Process$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_getDocFiles(docId) {
    const requestPath = toText(printf("/api/management/docfiles/%i"))(docId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(FileStore$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(FileStore$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_getAllTags() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_get_5760677E("/api/management/tags", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_assignProcessActivityDocTag(procActivityDocId, tag) {
    const requestPath = toText(printf("/api/management/docfile/assigntag/%i"))(procActivityDocId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(tag);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_addAndAssignProcessActivityDocTag(procActivityDocId, tag) {
    const requestPath = toText(printf("/api/management/docfile/createassigntag/%i"))(procActivityDocId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: Helpers_IdName$reflection,
        })(tag);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_updateProcessActivityDoc(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDoc$reflection,
        });
        return Fetch_post_5760677E("/api/management/docfile/update", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDoc$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_getProcessActivityDocVersions(versionRequest) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: ProcessActivityDocVersionRequest$reflection,
        })(versionRequest);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: ProcessActivityDocVersionResponse$reflection,
        });
        return Fetch_post_5760677E("/api/management/doc/versions", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: ProcessActivityDocVersionResponse$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Management_getLastRevisions() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(DocumentRevision$reflection()),
        });
        return Fetch_get_5760677E("/api/management/revisions/latest", void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(DocumentRevision$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Notification_getCompanyRoleNotificationsProcessActivityDoc(docId, compId) {
    const requestPath = toText(printf("/api/notification/document/%i/%i"))(docId)(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(DocumentNotification$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(DocumentNotification$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Notification_subscribeDocumentNotification(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: DocumentNotificationForm$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: DocumentNotification$reflection,
        });
        return Fetch_post_5760677E("/api/notification/document/assign", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: DocumentNotification$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Notification_unsubscribeDocumentNotification(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: DocumentNotification$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: DocumentNotification$reflection,
        });
        return Fetch_post_5760677E("/api/notification/document/unassign", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: DocumentNotification$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function ProjectPermission_getUserPermissionsInProject(projId) {
    const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(int32_type),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(int32_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function CompanyPermission_getUserPermissionsInCompany(projId) {
    const requestPath = toText(printf("/api/companypermission/user/%i"))(projId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(int32_type),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(int32_type),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function CompanyPermission_getCompanyPermissions(compId) {
    const requestPath = toText(printf("/api/companypermission/permissions/%i"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(CompanyPermission$reflection()),
        });
        return Fetch_get_5760677E(requestPath, void 0, void 0, void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(CompanyPermission$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function CompanyPermission_assignCompanyPermission(compId, entry) {
    const requestPath = toText(printf("/api/companypermission/%i/assign"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyPermission$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyPermission$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyPermission$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function CompanyPermission_revokeCompanyPermission(compId, entry) {
    const requestPath = toText(printf("/api/companypermission/%i/revoke"))(compId);
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: CompanyPermission$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: CompanyPermission$reflection,
        });
        return Fetch_post_5760677E(requestPath, some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: CompanyPermission$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Search_searchDocuments(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: SearchInput$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(DocumentSearchEntry$reflection()),
        });
        return Fetch_post_5760677E("/api/search/documents", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(DocumentSearchEntry$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

export function Search_searchUsers(entry) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: SearchInput$reflection,
        })(entry);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserSearchResult$reflection,
        });
        return Fetch_post_5760677E("/api/search/users", some(body), singleton(new Types_RequestProperties(3, "cors")), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserSearchResult$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    }));
}

