import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { ProjectForm, ProjectForm$reflection, Helpers_ResultMessage$reflection, Helpers_IdNameDesc$reflection, Office$reflection, Company$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, int32_type, union_type, record_type, lambda_type, unit_type, class_type, bool_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { parse, toString, addMonths, addDays, now } from "../.fable/fable-library.3.2.4/Date.js";
import { sortBy, map, tryFind } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, int32ToString, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, append, singleton as singleton_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import Check from "@material-ui/icons/Check";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { tryFocus } from "../Utils.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { snackbarError, loadingIndicatorSmall } from "../ViewHelpers.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";

class Model extends Record {
    constructor(Companies, Offices, Builders, Name, ProjectNumber, Company, Office, Builder, Construction, Landscaping, Start, End, ErrorMsg, OnClose, Created) {
        super();
        this.Companies = Companies;
        this.Offices = Offices;
        this.Builders = Builders;
        this.Name = Name;
        this.ProjectNumber = ProjectNumber;
        this.Company = Company;
        this.Office = Office;
        this.Builder = Builder;
        this.Construction = Construction;
        this.Landscaping = Landscaping;
        this.Start = Start;
        this.End = End;
        this.ErrorMsg = ErrorMsg;
        this.OnClose = OnClose;
        this.Created = Created;
    }
}

function Model$reflection() {
    return record_type("ProjectForm.Model", [], Model, () => [["Companies", option_type(array_type(Company$reflection()))], ["Offices", option_type(array_type(Office$reflection()))], ["Builders", option_type(array_type(Helpers_IdNameDesc$reflection()))], ["Name", string_type], ["ProjectNumber", string_type], ["Company", option_type(Company$reflection())], ["Office", option_type(Office$reflection())], ["Builder", option_type(Helpers_IdNameDesc$reflection())], ["Construction", bool_type], ["Landscaping", bool_type], ["Start", class_type("System.DateTime")], ["End", class_type("System.DateTime")], ["ErrorMsg", option_type(string_type)], ["OnClose", lambda_type(bool_type, unit_type)], ["Created", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "OfficesResponse", "BuildersResponse", "UpdateName", "UpdateProjectNumber", "SelectCompany", "SelectOffice", "SelectBuilder", "ToggleConstruction", "ToggleLandscaping", "UpdateStart", "UpdateEnd", "CreateResponse", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectForm.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdNameDesc$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdNameDesc$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function init(x) {
    let copyOfStruct, copyOfStruct_1;
    return new Model(void 0, void 0, void 0, "", "", void 0, void 0, void 0, true, false, (copyOfStruct = now(), addDays(copyOfStruct, 7)), (copyOfStruct_1 = now(), addMonths(copyOfStruct_1, 2)), void 0, x, false);
}

function update(model, msg) {
    let matchValue_1, off_1;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Noe gikk galt. Kunne ikke laste kontorer.", model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, res_1.fields[0], model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Noe gikk galt. Kunne ikke laste byggherrer.", model.OnClose, model.Created);
            }
            else {
                return new Model(model.Companies, model.Offices, res_2.fields[0], model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
        case 3: {
            return new Model(model.Companies, model.Offices, model.Builders, msg.fields[0], model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 4: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, msg.fields[0], model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 5: {
            const matchValue = model.Companies;
            if (matchValue != null) {
                const _arg1 = tryFind((y) => (y.Id === msg.fields[0]), matchValue);
                if (_arg1 != null) {
                    const y_1 = _arg1;
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, y_1, (matchValue_1 = model.Office, (matchValue_1 != null) ? ((matchValue_1.CompanyId !== y_1.Id) ? ((off_1 = matchValue_1, void 0)) : matchValue_1) : matchValue_1), model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Ugyldig selskap", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Kunne ikke velge selskap", model.OnClose, model.Created);
            }
        }
        case 6: {
            const matchValue_2 = model.Offices;
            if (matchValue_2 != null) {
                const _arg2 = tryFind((y_2) => (y_2.Id === msg.fields[0]), matchValue_2);
                if (_arg2 != null) {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, _arg2, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Ugyldig kontor", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Kunne ikke velge kontor", model.OnClose, model.Created);
            }
        }
        case 7: {
            const matchValue_3 = model.Builders;
            if (matchValue_3 != null) {
                const _arg3 = tryFind((y_4) => (y_4.id === msg.fields[0]), matchValue_3);
                if (_arg3 != null) {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, _arg3, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Ugyldig byggherre", model.OnClose, model.Created);
                }
            }
            else {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Kunne ikke velge byggherre", model.OnClose, model.Created);
            }
        }
        case 8: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, msg.fields[0], model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 9: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, msg.fields[0], model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 10: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, msg.fields[0], model.End, model.ErrorMsg, model.OnClose, model.Created);
        }
        case 11: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, msg.fields[0], model.ErrorMsg, model.OnClose, model.Created);
        }
        case 12: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                const err_2 = res_3.fields[0];
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, (err_2.tag === 2) ? (((err_2.fields[0].status) === 401) ? "Autentisering feilet" : "Noe gikk galt. Kunne ikke opprette prosjekt. Prøv laste siden på nytt.") : "Noe gikk galt. Kunne ikke opprette prosjekt. Prøv laste siden på nytt.", model.OnClose, model.Created);
            }
            else {
                const x_12 = res_3.fields[0];
                if (x_12.Result === "success") {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, true);
                }
                else {
                    return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, x_12.Message, model.OnClose, model.Created);
                }
            }
        }
        case 13: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, msg.fields[0], model.OnClose, model.Created);
        }
        case 14: {
            return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, void 0, model.OnClose, model.Created);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Companies, model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, "Noe gikk galt. Kunne ikke laste selskap.", model.OnClose, model.Created);
            }
            else {
                return new Model(res.fields[0], model.Offices, model.Builders, model.Name, model.ProjectNumber, model.Company, model.Office, model.Builder, model.Construction, model.Landscaping, model.Start, model.End, model.ErrorMsg, model.OnClose, model.Created);
            }
        }
    }
}

function fetchCompanies(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function fetchOffices(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Office$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/office/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
}

function fetchBuilders(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/builder/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
}

function tryCreate(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if ((x.Name.length > 0) ? (x.ProjectNumber.length > 0) : false) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProjectForm$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E("/api/project/create", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(12, _arg1));
                return Promise.resolve();
            }));
        }
        else if (x.Name.length === 0) {
            dispatch(new Message(13, "Ugyldig prosjektnavn"));
            return Promise.resolve();
        }
        else {
            dispatch(new Message(13, "Ugyldig prosjektnummer"));
            return Promise.resolve();
        }
    }));
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    const textField = StyleCreator$1__create_4EAC9E1E(styles, singleton(["marginBottom", 15]));
    o = {
        selectBox: StyleCreator$1__create_4EAC9E1E(styles, singleton(["minWidth", 200])),
        textField: textField,
    };
    return Object.assign({}, o);
});

function drawForm(model, dispatch) {
    const s = useStyles();
    const children_11 = toList(delay(() => (model.Created ? singleton_1(createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: 150,
        },
        children: Interop_reactApi.Children.toArray([react.createElement(Check, {
            style: {
                fontSize: 50 + "px",
                color: "#32CD32",
            },
        }), createElement("span", {
            style: {
                fontSize: 1.2 + "rem",
            },
            children: "Prosjekt opprettet",
        })]),
    })) : append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.textField], ["id", "project-form-name"], ["variant", "outlined"], ["size", "small"], ["autoFocus", true], ["fullWidth", true], ["defaultValue", model.Name], ["label", "Prosjektnavn"], ["onChange", (e) => {
        dispatch(new Message(3, Browser_Types_Event__Event_get_Value(e)));
    }], ["required", true], ["onKeyUp", (e_1) => {
        if (e_1.key === "Enter") {
            tryFocus("project-form-projnr");
        }
    }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.textField], ["id", "project-form-projnr"], ["variant", "outlined"], ["size", "small"], ["fullWidth", true], ["defaultValue", model.ProjectNumber], ["label", "Prosjektnummer"], ["onChange", (e_2) => {
        dispatch(new Message(4, Browser_Types_Event__Event_get_Value(e_2)));
    }], ["required", true]])), delay(() => {
        let children_1, children_3;
        return append(singleton_1(createElement("div", {
            style: createObj(toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append((model.Company == null) ? singleton_1(["justifyContent", "center"]) : singleton_1(["justify-content", "space-evenly"]), delay(() => singleton_1(["flexWrap", "wrap"])))))))),
            children: Interop_reactApi.Children.toArray([(children_1 = toList(delay(() => {
                let matchValue_1;
                const matchValue = model.Companies;
                if (matchValue != null) {
                    const companies = matchValue;
                    return singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Selskap"]]), MuiHelpers_createElement(Select, [["value", (matchValue_1 = model.Company, (matchValue_1 != null) ? int32ToString(matchValue_1.Id) : "")], ["onChange", (e_3, _arg24) => {
                        dispatch(new Message(5, Browser_Types_Event__Event_get_Value(e_3)));
                    }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((c_1) => MuiHelpers_createElement(MenuItem, [["value", c_1.Id], ["children", c_1.Name]]), sortBy((c) => c.Name, companies, {
                        Compare: (x_1, y) => comparePrimitives(x_1, y),
                    }))])]])])]]));
                }
                else {
                    return singleton_1(loadingIndicatorSmall());
                }
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            })), (children_3 = toList(delay(() => {
                let matchValue_4;
                const matchValue_2 = model.Offices;
                if (matchValue_2 != null) {
                    const offices = matchValue_2;
                    const matchValue_3 = model.Company;
                    if (matchValue_3 != null) {
                        const company = matchValue_3;
                        return singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Kontor"]]), MuiHelpers_createElement(Select, [["value", (matchValue_4 = model.Office, (matchValue_4 != null) ? int32ToString(matchValue_4.Id) : "")], ["onChange", (e_4, _arg24_1) => {
                            dispatch(new Message(6, Browser_Types_Event__Event_get_Value(e_4)));
                        }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((o_2) => MuiHelpers_createElement(MenuItem, [["value", o_2.Id], ["children", o_2.Name]]), sortBy((o_1) => o_1.Name, offices.filter((o) => (o.CompanyId === company.Id)), {
                            Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
                        }))])]])])]]));
                    }
                    else {
                        return empty_1();
                    }
                }
                else {
                    return singleton_1(loadingIndicatorSmall());
                }
            })), createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children_3)),
            }))]),
        })), delay(() => {
            let children_5;
            return append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    ["justify-content"]: "space-evenly",
                    flexWrap: "wrap",
                    marginTop: 20,
                },
                children: Interop_reactApi.Children.toArray([(children_5 = ofArray([MuiHelpers_createElement(FormControlLabel, [["value", "top"], ["labelPlacement", "top"], ["label", "Bygg prosjekt"], ["control", MuiHelpers_createElement(Switch, [["color", "primary"], ["checked", model.Construction], ["onChange", (ev) => {
                    dispatch(new Message(8, ev.target.checked));
                }]])]]), MuiHelpers_createElement(FormControlLabel, [["value", "top"], ["labelPlacement", "top"], ["label", "Anlegg prosjekt"], ["control", MuiHelpers_createElement(Switch, [["color", "primary"], ["checked", model.Landscaping], ["onChange", (ev_1) => {
                    dispatch(new Message(9, ev_1.target.checked));
                }]])]])]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                }))]),
            })), delay(() => append(singleton_1(createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                    let matchValue_6;
                    const matchValue_5 = model.Builders;
                    if (matchValue_5 != null) {
                        const builders = matchValue_5;
                        return singleton_1(MuiHelpers_createElement(FormControl, [["className", s.selectBox], ["required", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["children", "Byggherre"]]), MuiHelpers_createElement(Select, [["value", (matchValue_6 = model.Builder, (matchValue_6 != null) ? int32ToString(matchValue_6.id) : "")], ["onChange", (e_5, _arg24_2) => {
                            dispatch(new Message(7, Browser_Types_Event__Event_get_Value(e_5)));
                        }], ["displayEmpty", true], ["children", Interop_reactApi.Children.toArray([map((c_3) => MuiHelpers_createElement(MenuItem, [["value", c_3.id], ["children", c_3.name]]), sortBy((c_2) => c_2.name, builders, {
                            Compare: (x_5, y_2) => comparePrimitives(x_5, y_2),
                        }))])]])])]]));
                    }
                    else {
                        return singleton_1(loadingIndicatorSmall());
                    }
                })))),
            })), delay(() => {
                let children_7, children_9;
                return singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        ["justify-content"]: "space-evenly",
                        flexWrap: "wrap",
                        marginTop: 20,
                    },
                    children: Interop_reactApi.Children.toArray([(children_7 = singleton(MuiHelpers_createElement(TextField, [["type", "date"], ["defaultValue", toString(model.Start, "yyyy-MM-dd")], ["onChange", (e_6) => {
                        const x_6 = Browser_Types_Event__Event_get_Value(e_6);
                        try {
                            dispatch(new Message(10, parse(x_6)));
                        }
                        catch (matchValue_7) {
                        }
                    }], ["label", "Planlagt start"]])), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
                    })), (children_9 = singleton(MuiHelpers_createElement(TextField, [["type", "date"], ["defaultValue", toString(model.End, "yyyy-MM-dd")], ["onChange", (e_7) => {
                        const x_7 = Browser_Types_Event__Event_get_Value(e_7);
                        try {
                            dispatch(new Message(11, parse(x_7)));
                        }
                        catch (matchValue_8) {
                        }
                    }], ["label", "Planlagt slutt"]])), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
                    }))]),
                }));
            }))));
        }));
    })))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_11)),
    });
}

function view(model, dispatch) {
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        model.OnClose(model.Created);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Nytt prosjekt"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 400,
            minHeight: 200,
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(14));
        }), drawForm(model, dispatch)]),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["children", "Lukk"], ["onClick", (_arg3) => {
        model.OnClose(model.Created);
    }]])), delay(() => ((!model.Created) ? singleton_1(MuiHelpers_createElement(Button, [["variant", "outlined"], ["size", "small"], ["color", "primary"], ["disabled", ((((model.Name.length === 0) ? true : (model.ProjectNumber.length === 0)) ? true : (model.Company == null)) ? true : (model.Office == null)) ? true : (model.Builder == null)], ["children", "Opprett"], ["onClick", (_arg4) => {
        const matchValue = [model.Company, model.Office, model.Builder];
        if (matchValue[0] == null) {
            dispatch(new Message(13, "Velg selskap før du kan opprette prosjekt"));
        }
        else if (matchValue[1] == null) {
            dispatch(new Message(13, "Velg kontor før du kan opprette prosjekt"));
        }
        else if (matchValue[2] == null) {
            dispatch(new Message(13, "Velg byggherre før du kan opprette prosjekt"));
        }
        else {
            const b = matchValue[2];
            const c = matchValue[0];
            const o = matchValue[1];
            const pr = tryCreate(new ProjectForm(model.Name, model.ProjectNumber, c.Id, o.Id, b.id, model.Construction, model.Landscaping, toString(model.Start, "yyyy-MM-dd"), toString(model.End, "yyyy-MM-dd")), dispatch);
            pr.then();
        }
    }]])) : empty_1())))))))]])])]]);
}

export const projectForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.onClose));
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchCompanies(dispatch);
        pr.then();
        const pr_1 = fetchOffices(dispatch);
        pr_1.then();
        const pr_2 = fetchBuilders(dispatch);
        pr_2.then();
    }, []);
    return view(patternInput[0], dispatch);
}, void 0, uncurry(2, void 0), void 0, "projectForm", "/builds/serit/score/score/src/Client/Project/ProjectForm.fs", 530);

