import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { UserIdentity, UserInfo$reflection, UserIdentity$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, record_type, bool_type, string_type, int32_type, option_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { toArray, singleton, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { equals, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { toDateString, tryParseDateString } from "../Utils.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import date$002Dfns from "@date-io/date-fns";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { ProfilePicture } from "./ProfilePicture.js";
import Button from "@material-ui/core/Button";
import { loadingIndicatorCenteredSmall, snackbarError } from "../ViewHelpers.js";

class Model extends Record {
    constructor(Identity, UserInfo, UserId, ErrorMsg, EditPerm) {
        super();
        this.Identity = Identity;
        this.UserInfo = UserInfo;
        this.UserId = (UserId | 0);
        this.ErrorMsg = ErrorMsg;
        this.EditPerm = EditPerm;
    }
}

function Model$reflection() {
    return record_type("MyPage.Identity.Model", [], Model, () => [["Identity", option_type(UserIdentity$reflection())], ["UserInfo", option_type(UserInfo$reflection())], ["UserId", int32_type], ["ErrorMsg", option_type(string_type)], ["EditPerm", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IdentityResponse", "InfoResponse", "IdentityUpdateResponse", "SetError"];
    }
}

function Message$reflection() {
    return union_type("MyPage.Identity.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserIdentity$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserIdentity$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserIdentity$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserIdentity$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function init(uid, canedit, userInfoOpt) {
    return new Model(void 0, userInfoOpt, uid, void 0, canedit);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const resp_1 = msg.fields[0];
            if (resp_1.tag === 1) {
                return new Model(model.Identity, model.UserInfo, model.UserId, "Noe gikk galt. Kunne ikke laste bruker data", model.EditPerm);
            }
            else {
                return new Model(model.Identity, resp_1.fields[0], model.UserId, model.ErrorMsg, model.EditPerm);
            }
        }
        case 2: {
            const resp_2 = msg.fields[0];
            if (resp_2.tag === 1) {
                return new Model(model.Identity, model.UserInfo, model.UserId, "Noe gikk galt. Kunne ikke oppdatere bruker data", model.EditPerm);
            }
            else {
                return new Model(resp_2.fields[0], model.UserInfo, model.UserId, model.ErrorMsg, model.EditPerm);
            }
        }
        case 3: {
            return new Model(model.Identity, model.UserInfo, model.UserId, msg.fields[0], model.EditPerm);
        }
        default: {
            const resp = msg.fields[0];
            if (resp.tag === 1) {
                return new Model(model.Identity, model.UserInfo, model.UserId, "Noe gikk galt. Kunne ikke laste bruker data", model.EditPerm);
            }
            else {
                return new Model(resp.fields[0], model.UserInfo, model.UserId, model.ErrorMsg, model.EditPerm);
            }
        }
    }
}

function getUserIdentity(uid, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const url = "/api/mypage/identity" + toText(printf("/%i"))(uid);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserIdentity$reflection,
            });
            return Fetch_tryGet_5760677E(url, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserIdentity$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function getUserInfo(uid, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const url = "/api/mypage/userinfo" + toText(printf("/%i"))(uid);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserInfo$reflection,
            });
            return Fetch_tryGet_5760677E(url, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function updateIdentity(newIdent, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const validDesc = newIdent.desc.length < 500;
        const validKC = newIdent.keycompetencies.length < 500;
        if ((!validDesc) ? (!validKC) : false) {
            dispatch(new Message(3, "Beskrivelse og nøkkelkompetanse er for lang, kan ikke lagre"));
            return Promise.resolve();
        }
        else if (!validDesc) {
            dispatch(new Message(3, "Beskrivelse er for lang, kan ikke lagre"));
            return Promise.resolve();
        }
        else if (!validKC) {
            dispatch(new Message(3, "Nøkkelkompetanse er for lang, kan ikke lagre"));
            return Promise.resolve();
        }
        else {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: UserIdentity$reflection,
                })(newIdent);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: UserIdentity$reflection,
                });
                return Fetch_tryPost_5760677E("/api/mypage/addidentity", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: UserIdentity$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(2, _arg1));
                return Promise.resolve();
            }));
        }
    }));
}

function UserBody(userBodyInputProps) {
    let props_20, children_18, children, children_2, children_4, children_8, children_6, children_12, children_10, props_13, el, children_16, children_14, children_20, children_22;
    const dispatch = userBodyInputProps.dispatch;
    const canEdit = userBodyInputProps.canEdit;
    const userIdent = userBodyInputProps.userIdent;
    const usr = userBodyInputProps.usr;
    const patternInput = useFeliz_React__React_useState_Static_1505(userIdent);
    const state = patternInput[0];
    const setState = patternInput[1];
    const initBday = tryParseDateString(userIdent.birthday);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initBday);
    const bdayState = patternInput_1[0];
    const th$0027 = (x) => createElement("th", {
        style: {
            fontSize: 0.8 + "rem",
        },
        children: x,
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                flexWrap: "wrap-reverse",
                justifyContent: "space-around",
            },
            children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {}), (children_18 = ofArray([(children = ofArray([th$0027("Navn"), createElement("td", {
                children: [usr.name],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = ofArray([th$0027("Selskap"), createElement("td", {
                children: [usr.company],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = ofArray([th$0027("Kontor"), createElement("td", {
                children: [usr.office],
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), (children_8 = ofArray([th$0027("Stilling"), (children_6 = singleton(MuiHelpers_createElement(TextField, [["variant", "outlined"], ["size", "small"], ["defaultValue", state.position], ["disabled", !canEdit], ["onChange", (ev) => {
                setState(new UserIdentity(state.userid, state.desc, state.keycompetencies, state.language, state.birthday, ev.target.value));
            }]])), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            })), (children_12 = ofArray([th$0027("Fødselsdag"), (children_10 = singleton((props_13 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DatePicker, [["value", defaultArg(bdayState, null)], ["autoOk", true], ["variant", "inline"], ["onChange", patternInput_1[1]], ["format", "dd MMMM yyyy"], ["inputVariant", "outlined"], ["inputProps", {
                className: "MuiInputBase-inputMarginDense \n                                                MuiOutlinedInput-inputMarginDense",
            }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
                let value_32;
                return append(singleton_1((value_32 = date$002Dfns, ["utils", value_32])), delay(() => props_13));
            })))))), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            })), (children_16 = ofArray([th$0027("Språk"), (children_14 = singleton(MuiHelpers_createElement(TextField, [["variant", "outlined"], ["size", "small"], ["defaultValue", state.language], ["disabled", !canEdit], ["onChange", (ev_1) => {
                setState(new UserIdentity(state.userid, state.desc, state.keycompetencies, ev_1.target.value, state.birthday, state.position));
            }]])), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_14)),
            }))]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_16)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_18)),
            }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_20)))), createElement("div", {
                style: {
                    width: 100 + "%",
                    padding: 10,
                    maxWidth: 300,
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement(ProfilePicture, {
                    canEdit: canEdit,
                    uid: usr.id,
                })]),
            })]),
        }), createElement("div", {
            style: {
                margin: 5 + "px",
                minHeight: 45 + "px",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((((!equals(state, userIdent)) ? true : (!equals(bdayState, initBday))) ? canEdit : false) ? append(singleton_1(MuiHelpers_createElement(Button, [["variant", "contained"], ["size", "small"], ["color", "primary"], ["onClick", (_arg1) => {
                if (bdayState != null) {
                    const newIdentity = new UserIdentity(state.userid, state.desc, state.keycompetencies, state.language, toDateString(bdayState), state.position);
                    setState(newIdentity);
                    const pr_1 = updateIdentity(newIdentity, dispatch);
                    pr_1.then();
                }
                else {
                    const pr = updateIdentity(state, dispatch);
                    pr.then();
                }
            }], ["children", "Lagre endringer"], ["style", {
                margin: 5,
            }]])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "primary"], ["size", "small"], ["onClick", (_arg2) => {
                setState(userIdent);
                window.location.reload();
            }], ["children", "Tilbakestill"], ["style", {
                margin: 5,
            }]])))) : empty_1()))))),
        }), (children_20 = ofArray([createElement("h3", {
            className: "title is-3",
            children: "Sammendrag",
        }), createElement("div", {
            style: {
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["multiline", true]), delay(() => append(singleton_1(["rowsMax", 8]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append((state.desc.length > 500) ? singleton_1(["error", true]) : empty_1(), delay(() => {
                let arg10;
                return append(singleton_1(["helperText", (arg10 = (state.desc.length | 0), toText(printf("%i/%i"))(arg10)(500))]), delay(() => append(singleton_1(["defaultValue", state.desc]), delay(() => append(singleton_1(["disabled", !canEdit]), delay(() => singleton_1(["onChange", (ev_2) => {
                    setState(new UserIdentity(state.userid, ev_2.target.value, state.keycompetencies, state.language, state.birthday, state.position));
                }])))))));
            })))))))))))))]),
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_20)),
        })), (children_22 = ofArray([createElement("h3", {
            className: "title is-3",
            children: "Nøkkelkompetanse",
        }), createElement("div", {
            style: {
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["multiline", true]), delay(() => append(singleton_1(["rowsMax", 8]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append((state.keycompetencies.length > 500) ? singleton_1(["error", true]) : empty_1(), delay(() => {
                let arg10_1;
                return append(singleton_1(["helperText", (arg10_1 = (state.keycompetencies.length | 0), toText(printf("%i/%i"))(arg10_1)(500))]), delay(() => append(singleton_1(["defaultValue", state.keycompetencies]), delay(() => append(singleton_1(["disabled", !canEdit]), delay(() => singleton_1(["onChange", (ev_3) => {
                    setState(new UserIdentity(state.userid, state.desc, ev_3.target.value, state.language, state.birthday, state.position));
                }])))))));
            })))))))))))))]),
        })]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_22)),
        }))]),
    });
}

function view(model, dispatch) {
    const matchValue = model.Identity;
    if (matchValue != null) {
        const uident = matchValue;
        const matchValue_1 = model.UserInfo;
        if (matchValue_1 != null) {
            const uinfo = matchValue_1;
            return toArray(ofArray([createElement(UserBody, {
                usr: uinfo,
                userIdent: uident,
                canEdit: model.EditPerm,
                dispatch: dispatch,
            }), snackbarError(model.ErrorMsg, () => {
                dispatch(new Message(3, void 0));
            })]));
        }
        else {
            return loadingIndicatorCenteredSmall();
        }
    }
    else {
        return loadingIndicatorCenteredSmall();
    }
}

export function Identity(props) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.uId, props.canedit, props.uinfoOpt));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = getUserIdentity(props.uId, dispatch);
        pr.then();
        if (props.uinfoOpt == null) {
            const pr_1 = getUserInfo(props.uId, dispatch);
            pr_1.then();
        }
    });
    return view(patternInput[0], dispatch);
}

