import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, class_type, tuple_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Hms_ProjectRegistration, Hms_ProjectRegistrationListing, Hms_ProjectRegistration$reflection, Hms_ProjectRegistrationListing$reflection, Hms_ProjectInfo$reflection, Hms_HmsType$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { toUtcString, asMonth, toLastDayOfMonth, toFirstDayOfMonth, fromUtcString, monthsInPeriod } from "../Utils.js";
import { addDays, create, year, month, parse, toString, addMonths, now as now_1 } from "../.fable/fable-library.3.2.4/Date.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { join, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../.fable/fable-library.3.2.4/List.js";
import { numberHash, comparePrimitives, createObj, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { append, empty as empty_1, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Check from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import { parse as parse_1 } from "../.fable/fable-library.3.2.4/Int32.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { loadingIndicatorCenteredSmall } from "../ViewHelpers.js";
import { Parent, RegistrationComment } from "../Project/RegistrationComment.js";

class Model extends Record {
    constructor(ProjectId, Types, ProjectInfo, Registrations, CanReg, Months, SelectedDate, ErrorMsg) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.Types = Types;
        this.ProjectInfo = ProjectInfo;
        this.Registrations = Registrations;
        this.CanReg = CanReg;
        this.Months = Months;
        this.SelectedDate = SelectedDate;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("HmsRegistration.Model", [], Model, () => [["ProjectId", int32_type], ["Types", option_type(array_type(Hms_HmsType$reflection()))], ["ProjectInfo", option_type(Hms_ProjectInfo$reflection())], ["Registrations", option_type(array_type(Hms_ProjectRegistrationListing$reflection()))], ["CanReg", bool_type], ["Months", array_type(tuple_type(int32_type, int32_type))], ["SelectedDate", option_type(class_type("System.DateTime"))], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypesResponse", "InfoResponse", "PermissionsResponse", "RegStatusResponse", "SelectDate", "ResetRegStatus", "Commit", "RegisterResponse"];
    }
}

function Message$reflection() {
    return union_type("HmsRegistration.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_HmsType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_HmsType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Hms_ProjectInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Hms_ProjectInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_ProjectRegistrationListing$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_ProjectRegistrationListing$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.DateTime")]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Hms_ProjectRegistration$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Hms_ProjectRegistration$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init(projId) {
    return new Model(projId, void 0, void 0, void 0, false, [], void 0, void 0);
}

function update(model, msg) {
    let copyOfStruct, x_3, matchValue;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, "Noe gikk galt. Kunne ikke laste prosjektinfo.");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model(model.ProjectId, model.Types, x_1, model.Registrations, model.CanReg, monthsInPeriod(fromUtcString(x_1.Start), (copyOfStruct = now_1(), addMonths(copyOfStruct, -1))), model.SelectedDate, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, res_2.fields[0].some((x_3 = (Functionality__ToInt(new Functionality(11)) | 0), (y_1) => (x_3 === y_1))), model.Months, model.SelectedDate, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, "Noe gikk galt. Kunne ikke laste registreringer for periode.");
            }
            else {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, res_3.fields[0], model.CanReg, model.Months, model.SelectedDate, model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, msg.fields[0], model.ErrorMsg);
        }
        case 5: {
            return new Model(model.ProjectId, model.Types, model.ProjectInfo, void 0, model.CanReg, model.Months, model.SelectedDate, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, void 0);
        }
        case 7: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, "Noe gikk galt. Kunne ikke registrere HMS-hendelse.");
            }
            else {
                const x_6 = res_4.fields[0];
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, (matchValue = model.Registrations, (matchValue != null) ? map((r) => {
                    if (r.Type.Id === x_6.Type.Id) {
                        return new Hms_ProjectRegistrationListing(true, r.Sum, x_6.Quantity, r.Type);
                    }
                    else {
                        return r;
                    }
                }, matchValue) : (void 0)), model.CanReg, model.Months, model.SelectedDate, model.ErrorMsg);
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.Types, model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, "Noe gikk galt. Kunne ikke laste HMS-typer.");
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.ProjectInfo, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.ErrorMsg);
            }
        }
    }
}

function fetchProjectInfo(projId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/hms/project/info/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Hms_ProjectInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Hms_ProjectInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchTypes(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/hms/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function fetchPermissions(projId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchRegStatus(projId, dte, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5));
        const periodStart = toFirstDayOfMonth(dte);
        const periodEnd = toLastDayOfMonth(dte);
        const s = toString(periodStart, "yyyy-MM-dd");
        const e = toString(periodEnd, "yyyy-MM-dd");
        const requestPath = toText(printf("/api/hms/project/registration/%i?start=%s\u0026end=%s"))(projId)(s)(e);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Hms_ProjectRegistrationListing$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Hms_ProjectRegistrationListing$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
}

function register(projId, x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(6));
        const requestPath = toText(printf("/api/hms/project/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Hms_ProjectRegistration$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Hms_ProjectRegistration$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Hms_ProjectRegistration$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(7, _arg1));
            return Promise.resolve();
        }));
    }));
}

function periodSelect(model, dispatch, x) {
    let matchValue;
    return createElement("div", {
        style: {
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControl, [["style", {
            minWidth: 200,
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(InputLabel, [["shrink", true], ["children", "Registreringsmåned"]]), MuiHelpers_createElement(Select, [["id", "reg-period-select"], ["value", (matchValue = model.SelectedDate, (matchValue != null) ? toString(matchValue, "yyyy-MM") : "")], ["onChange", (e, _arg24) => {
            const y = Browser_Types_Event__Event_get_Value(e);
            try {
                const asDateTime = parse(y);
                let adjustedDate;
                const now = now_1();
                if ((month(asDateTime) === month(now)) ? (year(asDateTime) === year(now)) : false) {
                    adjustedDate = now;
                }
                else {
                    let copyOfStruct_1;
                    let copyOfStruct = create(year(asDateTime), month(asDateTime), 1, 0, 0, 0);
                    copyOfStruct_1 = addMonths(copyOfStruct, 1);
                    adjustedDate = addDays(copyOfStruct_1, -1);
                }
                dispatch(new Message(4, adjustedDate));
                const pr = fetchRegStatus(x.Id, adjustedDate, dispatch);
                pr.then();
            }
            catch (matchValue_1) {
            }
        }], ["children", Interop_reactApi.Children.toArray([map((tupledArg) => {
            let arg10_2;
            const y_1 = tupledArg[0] | 0;
            const m = tupledArg[1] | 0;
            const m$0027 = (m < 10) ? toText(printf("0%i"))(m) : int32ToString(m);
            return MuiHelpers_createElement(MenuItem, [["value", toText(printf("%i-%s"))(y_1)(m$0027)], ["children", (arg10_2 = asMonth(m), toText(printf("%s %i"))(arg10_2)(y_1))]]);
        }, model.Months)])]])])]])]),
    });
}

function regFormEntry(x, y, projId, canReg, dispatch$0027) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        let props_17, children_7, children_1, children_3, children_5;
        const patternInput = useFeliz_React__React_useState_Static_1505(0);
        const qty = patternInput[0] | 0;
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
        const validQty = patternInput_1[0];
        const setValidQty = patternInput_1[1];
        return createElement("div", {
            style: {
                marginTop: 25 + "px",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    padding: 7,
                    backgroundColor: "#eee",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {},
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontWeight: "bold",
                            fontSize: 15 + "px",
                            marginLeft: 8 + "px",
                            color: "#313131",
                        },
                        children: toText(printf("%s (%s)"))(props.y.Type.Name)(props.y.Type.Code),
                    })]),
                }), createElement("div", {
                    style: {
                        display: "flex",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        let x_1;
                        return (!props.y.Registered) ? (validQty ? singleton(MuiHelpers_createElement(Button, [["onClick", (_arg1) => {
                            const pr = register(projId, new Hms_ProjectRegistration(-1, projId, toUtcString(props.selectedDate), qty, props.y.Type), props.dispatch);
                            pr.then();
                        }], ["size", "small"], ["color", "primary"], ["children", Interop_reactApi.Children.toArray(["Registrer"])]])) : empty_1()) : append((permissions).some((x_1 = (Functionality__ToInt(new Functionality(39)) | 0), (y_1) => (x_1 === y_1))) ? singleton(createElement("a", {
                            href: "/hms/control",
                            style: {
                                fontSize: 11 + "px",
                                marginRight: 20,
                            },
                            children: "Endre denne registreringen",
                        })) : empty_1(), delay(() => singleton(MuiHelpers_createElement(Chip, [["size", "small"], ["variant", "outlined"], ["color", "primary"], ["label", "Registrert"], ["icon", react.createElement(Check, {})]]))));
                    })))),
                })]),
            }), (props_17 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([(children_7 = singleton_1(createElement("tr", {
                children: Interop_reactApi.Children.toArray([(children_1 = singleton_1(MuiHelpers_createElement(TextField, [["label", "Forrige total"], ["disabled", true], ["value", props.y.Sum], ["variant", "outlined"], ["size", "small"]])), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                })), (children_3 = singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["disabled", (!canReg) ? true : props.y.Registered]), delay(() => {
                    let arg20_1, copyOfStruct_1, arg10_1, copyOfStruct;
                    return append(singleton(["id", (arg20_1 = (((copyOfStruct_1 = props.selectedDate, month(copyOfStruct_1))) | 0), (arg10_1 = (((copyOfStruct = props.selectedDate, year(copyOfStruct))) | 0), toText(printf("qty-%i-%i-%i"))(arg10_1)(arg20_1)(props.y.Type.Id)))]), delay(() => append((!props.y.Registered) ? singleton(["defaultValue", 0]) : singleton(["value", props.y.Quantity]), delay(() => append(singleton(["onChange", (e) => {
                        const q = Browser_Types_Event__Event_get_Value(e);
                        try {
                            patternInput[1](parse_1(q, 511, false, 32));
                            setValidQty(true);
                        }
                        catch (matchValue) {
                            setValidQty(false);
                        }
                    }]), delay(() => append(singleton(["error", !validQty]), delay(() => {
                        let arg20_2, copyOfStruct_3, arg10_2, copyOfStruct_2;
                        return append(singleton(["label", (arg20_2 = (((copyOfStruct_3 = props.selectedDate, year(copyOfStruct_3))) | 0), (arg10_2 = asMonth((copyOfStruct_2 = props.selectedDate, month(copyOfStruct_2))), toText(printf("%s %i"))(arg10_2)(arg20_2)))]), delay(() => append(singleton(["variant", "outlined"]), delay(() => singleton(["size", "small"])))));
                    }))))))));
                })))))), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_3)),
                })), (children_5 = singleton_1(MuiHelpers_createElement(TextField, [["disabled", true], ["label", "Ny total"], ["value", (!props.y.Registered) ? (props.y.Sum + qty) : (props.y.Sum + props.y.Quantity)], ["variant", "outlined"], ["size", "small"]])), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_5)),
                }))]),
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_7)),
            }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_17))))]),
        });
    }, void 0, uncurry(2, void 0), void 0, "regFormEntry", "/builds/serit/score/score/src/Client/HMS/HmsRegistration.fs", 227)({
        dispatch: dispatch$0027,
        selectedDate: y,
        y: x,
    });
}

function drawRegistrationForm(model, selectedDate, dispatch, x) {
    const children = singleton_1(map((y_2) => {
        let arg30, arg20, arg30_1, arg20_1;
        return createElement("div", {
            key: (arg30 = (year(selectedDate) | 0), (arg20 = (month(selectedDate) | 0), toText(printf("%i-%i-%i"))(y_2.Type.Id)(arg20)(arg30))),
            id: (arg30_1 = (year(selectedDate) | 0), (arg20_1 = (month(selectedDate) | 0), toText(printf("%i-%i-%i"))(y_2.Type.Id)(arg20_1)(arg30_1))),
            children: Interop_reactApi.Children.toArray([regFormEntry(y_2, selectedDate, model.ProjectId, model.CanReg, dispatch)]),
        });
    }, sortBy((y) => y.Type.Name, x, {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    })));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function registrations(model, dispatch, hmsTypes) {
    const children = singleton_1(createElement("div", {
        style: {
            marginTop: 30 + "px",
            marginBottom: 5 + "vh",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.SelectedDate;
            if (matchValue != null) {
                const sd = matchValue;
                const matchValue_1 = model.Registrations;
                if (matchValue_1 != null) {
                    const reg = matchValue_1;
                    const missing = map((y_3) => y_3.Name, Array_distinctBy((y) => y.Id, hmsTypes.filter((wt) => wt.Required), {
                        Equals: (x, y_1) => (x === y_1),
                        GetHashCode: (x) => numberHash(x),
                    }).filter((y_2) => (!reg.some((z) => {
                        if (z.Type.Id === y_2.Id) {
                            return z.Registered;
                        }
                        else {
                            return false;
                        }
                    }))));
                    return append((missing.length > 0) ? singleton(createElement("div", {
                        style: {
                            fontSize: 0.8 + "rem",
                            marginTop: 10 + "px",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            style: {
                                marginRight: 5 + "px",
                            },
                            className: "fas fa-exclamation-circle",
                        }), createElement("span", {
                            style: {
                                marginRight: 10 + "px",
                            },
                            children: toText(printf("Påkrevde HMS-typer som ikke er registrert for denne perioden:")),
                        }), createElement("span", {
                            style: {
                                fontStyle: "italic",
                            },
                            children: join(", ", missing),
                        })]),
                    })) : empty_1(), delay(() => append(singleton(drawRegistrationForm(model, sd, dispatch, reg)), delay(() => ((reg.length === 0) ? singleton(createElement("div", {
                        style: {
                            textAlign: "center",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("span", {
                            children: ["Ingen registreringer gjort for denne perioden."],
                        })]),
                    })) : empty_1())))));
                }
                else {
                    return singleton(loadingIndicatorCenteredSmall());
                }
            }
            else {
                return empty_1();
            }
        })))),
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function nonRegisterProject() {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
            textAlign: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("h3", {
            className: "title is-3",
            children: "Dette prosjektet skal ikke registrere HMS",
        }), createElement("p", {
            children: ["Dette prosjektet er satt til å ikke registrere HMS. Dersom dette er galt kan dette endres under prosjektredigering."],
        })]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            marginTop: 20,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue_1, hmsTypes;
            const matchValue = model.ProjectInfo;
            if (matchValue != null) {
                const projInfo = matchValue;
                return (!projInfo.Register) ? singleton(nonRegisterProject()) : append((matchValue_1 = model.Types, (matchValue_1 != null) ? ((hmsTypes = matchValue_1, append(singleton(periodSelect(model, dispatch, projInfo)), delay(() => singleton(registrations(model, dispatch, hmsTypes)))))) : singleton(loadingIndicatorCenteredSmall())), delay(() => {
                    let arg20, arg10;
                    const matchValue_2 = model.SelectedDate;
                    if (matchValue_2 != null) {
                        const sd = matchValue_2;
                        return singleton(createElement("div", {
                            key: (arg20 = (month(sd) | 0), (arg10 = (year(sd) | 0), toText(printf("%i-%i"))(arg10)(arg20))),
                            children: Interop_reactApi.Children.toArray([createElement(RegistrationComment, {
                                src: new Parent(1, model.ProjectId),
                                year: year(sd),
                                month: month(sd),
                                canEdit: model.CanReg,
                            })]),
                        }));
                    }
                    else {
                        return empty_1();
                    }
                }));
            }
            else {
                return singleton(loadingIndicatorCenteredSmall());
            }
        })))),
    });
}

export function HmsReg(hmsRegInputProps) {
    const projectId = hmsRegInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = fetchTypes(dispatch);
        pr.then();
        const pr_1 = fetchProjectInfo(projectId, dispatch);
        pr_1.then();
        const pr_2 = fetchPermissions(projectId, dispatch);
        pr_2.then();
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([view(patternInput[0], dispatch)]),
    });
}

