import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Hms_HmsType, Helpers_ResultMessage$reflection, Hms_HmsType$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, int32_type, union_type, record_type, string_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_reactElement, MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import { genericDeleteDialog, snackbarError, insertMaterialTableNorwegianLocalization, loadingIndicatorSmall, PageElements_inlineCheckbox } from "../ViewHelpers.js";
import Dialog from "@material-ui/core/Dialog";
import { printf, toText, join } from "../.fable/fable-library.3.2.4/String.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { StyleImports_useTheme } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import material$002Dtable from "material-table";
import Icon from "@material-ui/core/Icon";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";

class Model extends Record {
    constructor(Types, AddForm, Selected, DeleteForm, ErrorMsg) {
        super();
        this.Types = Types;
        this.AddForm = AddForm;
        this.Selected = Selected;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("HmsType.Model", [], Model, () => [["Types", option_type(array_type(Hms_HmsType$reflection()))], ["AddForm", option_type(Hms_HmsType$reflection())], ["Selected", option_type(Hms_HmsType$reflection())], ["DeleteForm", option_type(Hms_HmsType$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypesResponse", "ToggleAddForm", "UpdateName", "UpdateCode", "UpdateMinValue", "UpdateMaxValue", "ToggleRequired", "Commit", "AddResponse", "SetSelected", "UpdateResponse", "SetDelete", "DeleteResponse", "SetError"];
    }
}

function Message$reflection() {
    return union_type("HmsType.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Hms_HmsType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Hms_HmsType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", int32_type]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Hms_HmsType$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Hms_HmsType$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Hms_HmsType$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Hms_HmsType$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Hms_HmsType$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Hms_HmsType$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function init() {
    return new Model(void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let matchValue_7, matchValue_8, matchValue_10;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Types, (model.AddForm == null) ? (new Hms_HmsType(-1, "", "", true, 0, 20)) : (void 0), void 0, model.DeleteForm, model.ErrorMsg);
        }
        case 2: {
            const x_2 = msg.fields[0];
            const matchValue_1 = [model.AddForm, model.Selected];
            if (matchValue_1[0] != null) {
                const y = matchValue_1[0];
                return new Model(model.Types, new Hms_HmsType(y.Id, x_2, y.Code, y.Required, y.WarnLimitMin, y.WarnLimitMax), model.Selected, model.DeleteForm, model.ErrorMsg);
            }
            else if (matchValue_1[1] != null) {
                const y_1 = matchValue_1[1];
                return new Model(model.Types, model.AddForm, new Hms_HmsType(y_1.Id, x_2, y_1.Code, y_1.Required, y_1.WarnLimitMin, y_1.WarnLimitMax), model.DeleteForm, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 3: {
            const x_3 = msg.fields[0];
            const matchValue_2 = [model.AddForm, model.Selected];
            if (matchValue_2[0] != null) {
                const y_2 = matchValue_2[0];
                return new Model(model.Types, new Hms_HmsType(y_2.Id, y_2.Name, x_3, y_2.Required, y_2.WarnLimitMin, y_2.WarnLimitMax), model.Selected, model.DeleteForm, model.ErrorMsg);
            }
            else if (matchValue_2[1] != null) {
                const y_3 = matchValue_2[1];
                return new Model(model.Types, model.AddForm, new Hms_HmsType(y_3.Id, y_3.Name, x_3, y_3.Required, y_3.WarnLimitMin, y_3.WarnLimitMax), model.DeleteForm, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 6: {
            const matchValue_3 = [model.AddForm, model.Selected];
            if (matchValue_3[0] != null) {
                const y_4 = matchValue_3[0];
                return new Model(model.Types, new Hms_HmsType(y_4.Id, y_4.Name, y_4.Code, !y_4.Required, y_4.WarnLimitMin, y_4.WarnLimitMax), model.Selected, model.DeleteForm, model.ErrorMsg);
            }
            else if (matchValue_3[1] != null) {
                const y_5 = matchValue_3[1];
                return new Model(model.Types, model.AddForm, new Hms_HmsType(y_5.Id, y_5.Name, y_5.Code, !y_5.Required, y_5.WarnLimitMin, y_5.WarnLimitMax), model.DeleteForm, model.ErrorMsg);
            }
            else {
                return model;
            }
        }
        case 4: {
            const i = msg.fields[0] | 0;
            const verifyMin = (y_6) => {
                if ((i >= 0) ? (y_6.WarnLimitMax >= i) : false) {
                    return new Model(model.Types, new Hms_HmsType(y_6.Id, y_6.Name, y_6.Code, y_6.Required, i, y_6.WarnLimitMax), model.Selected, model.DeleteForm, void 0);
                }
                else {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Min. verdi må være positiv og mindre enn max.");
                }
            };
            const matchValue_4 = [model.AddForm, model.Selected];
            if (matchValue_4[0] != null) {
                return verifyMin(matchValue_4[0]);
            }
            else if (matchValue_4[1] != null) {
                return verifyMin(matchValue_4[1]);
            }
            else {
                return model;
            }
        }
        case 5: {
            const i_1 = msg.fields[0] | 0;
            const verifyMax = (y_9) => {
                if (y_9.WarnLimitMin <= i_1) {
                    return new Model(model.Types, new Hms_HmsType(y_9.Id, y_9.Name, y_9.Code, y_9.Required, y_9.WarnLimitMin, i_1), model.Selected, model.DeleteForm, void 0);
                }
                else {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Max. verdi må være større enn min.");
                }
            };
            const matchValue_5 = [model.AddForm, model.Selected];
            if (matchValue_5[0] != null) {
                return verifyMax(matchValue_5[0]);
            }
            else if (matchValue_5[1] != null) {
                return verifyMax(matchValue_5[1]);
            }
            else {
                return model;
            }
        }
        case 7: {
            const matchValue_6 = [model.AddForm, model.Selected];
            if (matchValue_6[0] != null) {
                const x_4 = matchValue_6[0];
                if ((x_4.Name.length > 0) ? (x_4.Code.length > 0) : false) {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, void 0);
                }
                else if (x_4.Name.length === 0) {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Ugyldig navn på ny HMS-type.");
                }
                else {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Ugyldig kode på ny HMS-type");
                }
            }
            else if (matchValue_6[1] != null) {
                const x_5 = matchValue_6[1];
                if ((x_5.Name.length > 0) ? (x_5.Code.length > 0) : false) {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, void 0);
                }
                else if (x_5.Name.length === 0) {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Ugyldig nytt navn.");
                }
                else {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Ugyldig ny kode.");
                }
            }
            else {
                return model;
            }
        }
        case 8: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Noe gikk galt. Kunne ikke opprette ny HMS-type.");
            }
            else {
                const x_6 = res_1.fields[0];
                return new Model((matchValue_7 = model.Types, (matchValue_7 != null) ? append([x_6], matchValue_7) : [x_6]), void 0, model.Selected, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 9: {
            return new Model(model.Types, void 0, msg.fields[0], model.DeleteForm, model.ErrorMsg);
        }
        case 10: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Noe gikk galt. Kunne ikke oppdatere HMS-type.");
            }
            else {
                const x_8 = res_2.fields[0];
                return new Model((matchValue_8 = model.Types, (matchValue_8 != null) ? map((e) => {
                    if (e.Id === x_8.Id) {
                        return x_8;
                    }
                    else {
                        return e;
                    }
                }, matchValue_8) : [x_8]), model.AddForm, void 0, model.DeleteForm, void 0);
            }
        }
        case 11: {
            return new Model(model.Types, model.AddForm, model.Selected, msg.fields[0], model.ErrorMsg);
        }
        case 12: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Noe gikk galt. Kunne ikke slette HMS-type.");
            }
            else {
                const x_10 = res_3.fields[0];
                if (x_10.Result === "success") {
                    const matchValue_9 = model.DeleteForm;
                    if (matchValue_9 != null) {
                        const df = matchValue_9;
                        return new Model((matchValue_10 = model.Types, (matchValue_10 != null) ? matchValue_10.filter((e_1) => (e_1.Id !== df.Id)) : (void 0)), model.AddForm, model.Selected, void 0, model.ErrorMsg);
                    }
                    else {
                        return model;
                    }
                }
                else {
                    return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, x_10.Message);
                }
            }
        }
        case 13: {
            return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Types, model.AddForm, model.Selected, model.DeleteForm, "Noe gikk galt. Kunne ikke laste HMS-typer.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.Selected, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function fetchTypes(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/hms/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Hms_HmsType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function tryAdd(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(7));
        return ((((x.Name.length > 0) ? (x.Code.length > 0) : false) ? (x.WarnLimitMin >= 0) : false) ? (x.WarnLimitMin <= x.WarnLimitMax) : false) ? (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Hms_HmsType$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Hms_HmsType$reflection,
            });
            return Fetch_tryPost_5760677E("/api/hms/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Hms_HmsType$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(8, _arg1));
            return Promise.resolve();
        }))) : (Promise.resolve());
    }));
}

function tryUpdate(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(7));
        if ((((x.Name.length > 0) ? (x.Code.length > 0) : false) ? (x.WarnLimitMin >= 0) : false) ? (x.WarnLimitMin <= x.WarnLimitMax) : false) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Hms_HmsType$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Hms_HmsType$reflection,
                });
                return Fetch_tryPost_5760677E("/api/hms/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Hms_HmsType$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(10, _arg1));
                return Promise.resolve();
            }));
        }
        else if (x.WarnLimitMin < 0) {
            dispatch(new Message(13, "Min kan ikke være mindre enn 0"));
            return Promise.resolve();
        }
        else if (x.WarnLimitMax < x.WarnLimitMin) {
            dispatch(new Message(13, "Max kan ikke være mindre enn min"));
            return Promise.resolve();
        }
        else {
            return Promise.resolve();
        }
    }));
}

function tryDelete(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => int32_type,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryDelete_5760677E("/api/hms/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(12, _arg1));
        return Promise.resolve();
    })))));
}

function drawAddForm(dispatch, x) {
    let children;
    const body = createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", x.Code], ["label", "Kode"], ["style", {
            marginBottom: 15,
        }], ["onChange", (e) => {
            dispatch(new Message(3, Browser_Types_Event__Event_get_Value(e)));
        }]]), MuiHelpers_createElement(TextField, [["defaultValue", x.Name], ["label", "Navn"], ["style", {
            marginBottom: 15,
        }], ["onChange", (e_1) => {
            dispatch(new Message(2, Browser_Types_Event__Event_get_Value(e_1)));
        }]]), MuiHelpers_createElement(TextField, [["defaultValue", x.WarnLimitMin], ["label", "Rimelig minimum verdi"], ["style", {
            marginBottom: 15,
        }], ["type", "number"], ["onChange", (e_2) => {
            const s = Browser_Types_Event__Event_get_Value(e_2);
            dispatch(new Message(4, (() => {
                try {
                    return parse(s, 511, false, 32) | 0;
                }
                catch (matchValue) {
                    return 0;
                }
            })()));
        }]]), MuiHelpers_createElement(TextField, [["defaultValue", x.WarnLimitMax], ["label", "Rimelig maximum verdi"], ["style", {
            marginBottom: 15,
        }], ["type", "number"], ["onChange", (e_3) => {
            const s_1 = Browser_Types_Event__Event_get_Value(e_3);
            dispatch(new Message(5, (() => {
                try {
                    return parse(s_1, 511, false, 32) | 0;
                }
                catch (matchValue_1) {
                    return 20;
                }
            })()));
        }]]), (children = singleton(PageElements_inlineCheckbox((_arg1) => {
            dispatch(new Message(6));
        }, "Påkrevd", x.Required)), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]),
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(1));
    }], ["aria-labelledby", join(" ", ["Legg til HMS-type"])], ["aria-describedby", join(" ", [""])], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til HMS-type"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([body])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        dispatch(new Message(1));
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["disabled", (((x.Name.length === 0) ? true : (x.Code.length === 0)) ? true : (x.WarnLimitMin < 0)) ? true : (x.WarnLimitMin > x.WarnLimitMax)], ["color", "primary"], ["onClick", (_arg4) => {
        const pr = tryAdd(x, dispatch);
        pr.then();
    }], ["children", "Legg til"]])])]])])]]);
}

function drawMaterialTable(model, dispatch) {
    let value_35;
    const matchValue = model.Types;
    if (matchValue == null) {
        return loadingIndicatorSmall();
    }
    else {
        const entries = matchValue;
        const theme = StyleImports_useTheme();
        return MuiHelpers_reactElement(material$002Dtable, createObj(ofArray([["style", {
            backgroundColor: theme.palette.background.paper,
            maxWidth: 1000,
            padding: 2 + "vw",
            width: 90 + "%",
        }], ["title", "Hms-Typer"], ["data", Array.from(ofArray(entries))], ["columns", [{
            title: "Kode",
            field: "Code",
        }, {
            title: "Navn",
            field: "Name",
        }, {
            title: "Påkrevd",
            field: "Required",
            type: "boolean",
        }, {
            title: "Minimumsverdi",
            field: "WarnLimitMin",
            type: "numeric",
        }, {
            title: "Maximumsverdi",
            field: "WarnLimitMax",
            type: "numeric",
        }]], ["editable", {
            onRowUpdate: (delegateArg0, delegateArg1) => tryUpdate(delegateArg0, dispatch),
        }], ["actions", [createObj(ofArray([(value_35 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_35]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_1, delegateArg1_1) => {
            dispatch(new Message(1));
        }]])), (arg) => {
            let value_43;
            return createObj(ofArray([(value_43 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_43]), ["tooltip", "Slett"], ["onClick", (delegateArg0_2, delegateArg1_2) => {
                dispatch(new Message(11, arg));
            }]]));
        }]], insertMaterialTableNorwegianLocalization(), ["options", {
            grouping: false,
            search: false,
            pageSize: (entries.length > 5) ? 10 : 5,
        }]])));
    }
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(13, void 0));
        })), delay(() => {
            let matchValue;
            return append_1((matchValue = model.AddForm, (matchValue == null) ? ((empty_1())) : singleton_1(drawAddForm(dispatch, matchValue))), delay(() => {
                let matchValue_1, delRow, value_7;
                return append_1((matchValue_1 = model.DeleteForm, (matchValue_1 == null) ? ((empty_1())) : ((delRow = matchValue_1, singleton_1(genericDeleteDialog(() => {
                    dispatch(new Message(11, void 0));
                }, () => tryDelete(delRow.Id, dispatch), [(value_7 = toText(printf("Valgt kode: %A"))(delRow.Code), createElement("h3", {
                    children: [value_7],
                }))]))))), delay(() => singleton_1(drawMaterialTable(model, dispatch))));
            }));
        })))))),
    });
}

export function hmsTypes() {
    return FunctionComponent_Of_Z5A158BBF(() => {
        const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
        const dispatch = patternInput[1];
        useReact_useEffect_Z101E1A95(() => {
            const pr = fetchTypes(dispatch);
            pr.then();
        }, []);
        return view(patternInput[0], dispatch);
    }, void 0, uncurry(2, void 0), void 0, "hmsTypes", "/builds/serit/score/score/src/Client/HMS/HmsType.fs", 432)();
}

