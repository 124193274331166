import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, option_type, int32_type, union_type, string_type, bool_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Print_ProjectReferenceData, Helpers_ResultMessage$reflection, Print_ProjectReferenceData$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { join, split, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { map } from "../.fable/fable-library.3.2.4/Array.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Button from "@material-ui/core/Button";
import { loadingIndicatorCenteredSmall, errorBanner, loadingIndicatorSmall } from "../ViewHelpers.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import Modal from "@material-ui/core/Modal";

class GenerationStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waiting", "Failed", "Success"];
    }
}

function GenerationStep$reflection() {
    return union_type("ProjectPrint.GenerationStep", [], GenerationStep, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(ProjectId, ProjectData, Step, ErrorMsg, FormError) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectData = ProjectData;
        this.Step = Step;
        this.ErrorMsg = ErrorMsg;
        this.FormError = FormError;
    }
}

function Model$reflection() {
    return record_type("ProjectPrint.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectData", option_type(Print_ProjectReferenceData$reflection())], ["Step", GenerationStep$reflection()], ["ErrorMsg", option_type(string_type)], ["FormError", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectDataResponse", "UpdateDesc", "UpdateShortDesc", "UpdateEnvProfile", "UpdateValue", "UpdateEnterpriseForm", "UpdateAreal", "UpdateActionClasses", "UpdateBuildTime", "UpdateEnvDemands", "UpdateEnergyClass", "UpdateBuilder", "UpdateContactPerson", "UpdateContactPhone", "UpdateContactEmail", "ToggleDesc", "ToggleShortDesc", "ToggleEnvProfile", "ToggleValue", "ToggleEnterpriseForm", "ToggleAreal", "ToggleActionClasses", "ToggleBuildTime", "ToggleEnvDemands", "ToggleEnergyClass", "ToggleBuilder", "ToggleContactPerson", "ToggleContactPhone", "ToggleContactMail", "SetStep", "Generate", "GenerateResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectPrint.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Print_ProjectReferenceData$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Print_ProjectReferenceData$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [["Item", GenerationStep$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(projId) {
    return new Model(projId, void 0, new GenerationStep(0, false), void 0, void 0);
}

function update$0027(model, x) {
    const matchValue = model.ProjectData;
    if (matchValue == null) {
        return model;
    }
    else {
        return new Model(model.ProjectId, x(matchValue), model.Step, model.ErrorMsg, model.FormError);
    }
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return update$0027(model, (y) => (new Print_ProjectReferenceData(y.ProjId, msg.fields[0], y.ShortDesc, y.EnvProfile, y.Areal, y.Sum, y.Builder, y.BuildTime, y.EnterpriseForm, y.EnergyClass, y.EnvDemands, y.ActionClasses, y.ContactPerson, y.ContactPhone, y.ContactEmail, y.IncludeDesc, y.IncludeRole, y.IncludeEnvProfile, y.IncludeSum, y.IncludeAreal, y.IncludeEnterpriseForm, y.IncludeActionClasses, y.IncludeBuildTime, y.IncludeEnvDemand, y.IncludeEnergyClass, y.IncludeBuilder, y.IncludeContactPerson, y.IncludeContactPhone, y.IncludeContactMail)));
        }
        case 2: {
            return update$0027(model, (y_1) => (new Print_ProjectReferenceData(y_1.ProjId, y_1.Desc, msg.fields[0], y_1.EnvProfile, y_1.Areal, y_1.Sum, y_1.Builder, y_1.BuildTime, y_1.EnterpriseForm, y_1.EnergyClass, y_1.EnvDemands, y_1.ActionClasses, y_1.ContactPerson, y_1.ContactPhone, y_1.ContactEmail, y_1.IncludeDesc, y_1.IncludeRole, y_1.IncludeEnvProfile, y_1.IncludeSum, y_1.IncludeAreal, y_1.IncludeEnterpriseForm, y_1.IncludeActionClasses, y_1.IncludeBuildTime, y_1.IncludeEnvDemand, y_1.IncludeEnergyClass, y_1.IncludeBuilder, y_1.IncludeContactPerson, y_1.IncludeContactPhone, y_1.IncludeContactMail)));
        }
        case 3: {
            return update$0027(model, (y_2) => (new Print_ProjectReferenceData(y_2.ProjId, y_2.Desc, y_2.ShortDesc, msg.fields[0], y_2.Areal, y_2.Sum, y_2.Builder, y_2.BuildTime, y_2.EnterpriseForm, y_2.EnergyClass, y_2.EnvDemands, y_2.ActionClasses, y_2.ContactPerson, y_2.ContactPhone, y_2.ContactEmail, y_2.IncludeDesc, y_2.IncludeRole, y_2.IncludeEnvProfile, y_2.IncludeSum, y_2.IncludeAreal, y_2.IncludeEnterpriseForm, y_2.IncludeActionClasses, y_2.IncludeBuildTime, y_2.IncludeEnvDemand, y_2.IncludeEnergyClass, y_2.IncludeBuilder, y_2.IncludeContactPerson, y_2.IncludeContactPhone, y_2.IncludeContactMail)));
        }
        case 4: {
            return update$0027(model, (y_3) => (new Print_ProjectReferenceData(y_3.ProjId, y_3.Desc, y_3.ShortDesc, y_3.EnvProfile, y_3.Areal, msg.fields[0], y_3.Builder, y_3.BuildTime, y_3.EnterpriseForm, y_3.EnergyClass, y_3.EnvDemands, y_3.ActionClasses, y_3.ContactPerson, y_3.ContactPhone, y_3.ContactEmail, y_3.IncludeDesc, y_3.IncludeRole, y_3.IncludeEnvProfile, y_3.IncludeSum, y_3.IncludeAreal, y_3.IncludeEnterpriseForm, y_3.IncludeActionClasses, y_3.IncludeBuildTime, y_3.IncludeEnvDemand, y_3.IncludeEnergyClass, y_3.IncludeBuilder, y_3.IncludeContactPerson, y_3.IncludeContactPhone, y_3.IncludeContactMail)));
        }
        case 5: {
            return update$0027(model, (y_4) => (new Print_ProjectReferenceData(y_4.ProjId, y_4.Desc, y_4.ShortDesc, y_4.EnvProfile, y_4.Areal, y_4.Sum, y_4.Builder, y_4.BuildTime, msg.fields[0], y_4.EnergyClass, y_4.EnvDemands, y_4.ActionClasses, y_4.ContactPerson, y_4.ContactPhone, y_4.ContactEmail, y_4.IncludeDesc, y_4.IncludeRole, y_4.IncludeEnvProfile, y_4.IncludeSum, y_4.IncludeAreal, y_4.IncludeEnterpriseForm, y_4.IncludeActionClasses, y_4.IncludeBuildTime, y_4.IncludeEnvDemand, y_4.IncludeEnergyClass, y_4.IncludeBuilder, y_4.IncludeContactPerson, y_4.IncludeContactPhone, y_4.IncludeContactMail)));
        }
        case 6: {
            return update$0027(model, (y_5) => (new Print_ProjectReferenceData(y_5.ProjId, y_5.Desc, y_5.ShortDesc, y_5.EnvProfile, msg.fields[0], y_5.Sum, y_5.Builder, y_5.BuildTime, y_5.EnterpriseForm, y_5.EnergyClass, y_5.EnvDemands, y_5.ActionClasses, y_5.ContactPerson, y_5.ContactPhone, y_5.ContactEmail, y_5.IncludeDesc, y_5.IncludeRole, y_5.IncludeEnvProfile, y_5.IncludeSum, y_5.IncludeAreal, y_5.IncludeEnterpriseForm, y_5.IncludeActionClasses, y_5.IncludeBuildTime, y_5.IncludeEnvDemand, y_5.IncludeEnergyClass, y_5.IncludeBuilder, y_5.IncludeContactPerson, y_5.IncludeContactPhone, y_5.IncludeContactMail)));
        }
        case 7: {
            return update$0027(model, (y_6) => (new Print_ProjectReferenceData(y_6.ProjId, y_6.Desc, y_6.ShortDesc, y_6.EnvProfile, y_6.Areal, y_6.Sum, y_6.Builder, y_6.BuildTime, y_6.EnterpriseForm, y_6.EnergyClass, y_6.EnvDemands, msg.fields[0], y_6.ContactPerson, y_6.ContactPhone, y_6.ContactEmail, y_6.IncludeDesc, y_6.IncludeRole, y_6.IncludeEnvProfile, y_6.IncludeSum, y_6.IncludeAreal, y_6.IncludeEnterpriseForm, y_6.IncludeActionClasses, y_6.IncludeBuildTime, y_6.IncludeEnvDemand, y_6.IncludeEnergyClass, y_6.IncludeBuilder, y_6.IncludeContactPerson, y_6.IncludeContactPhone, y_6.IncludeContactMail)));
        }
        case 8: {
            return update$0027(model, (y_7) => (new Print_ProjectReferenceData(y_7.ProjId, y_7.Desc, y_7.ShortDesc, y_7.EnvProfile, y_7.Areal, y_7.Sum, y_7.Builder, msg.fields[0], y_7.EnterpriseForm, y_7.EnergyClass, y_7.EnvDemands, y_7.ActionClasses, y_7.ContactPerson, y_7.ContactPhone, y_7.ContactEmail, y_7.IncludeDesc, y_7.IncludeRole, y_7.IncludeEnvProfile, y_7.IncludeSum, y_7.IncludeAreal, y_7.IncludeEnterpriseForm, y_7.IncludeActionClasses, y_7.IncludeBuildTime, y_7.IncludeEnvDemand, y_7.IncludeEnergyClass, y_7.IncludeBuilder, y_7.IncludeContactPerson, y_7.IncludeContactPhone, y_7.IncludeContactMail)));
        }
        case 9: {
            return update$0027(model, (y_8) => (new Print_ProjectReferenceData(y_8.ProjId, y_8.Desc, y_8.ShortDesc, y_8.EnvProfile, y_8.Areal, y_8.Sum, y_8.Builder, y_8.BuildTime, y_8.EnterpriseForm, y_8.EnergyClass, msg.fields[0], y_8.ActionClasses, y_8.ContactPerson, y_8.ContactPhone, y_8.ContactEmail, y_8.IncludeDesc, y_8.IncludeRole, y_8.IncludeEnvProfile, y_8.IncludeSum, y_8.IncludeAreal, y_8.IncludeEnterpriseForm, y_8.IncludeActionClasses, y_8.IncludeBuildTime, y_8.IncludeEnvDemand, y_8.IncludeEnergyClass, y_8.IncludeBuilder, y_8.IncludeContactPerson, y_8.IncludeContactPhone, y_8.IncludeContactMail)));
        }
        case 10: {
            return update$0027(model, (y_9) => (new Print_ProjectReferenceData(y_9.ProjId, y_9.Desc, y_9.ShortDesc, y_9.EnvProfile, y_9.Areal, y_9.Sum, y_9.Builder, y_9.BuildTime, y_9.EnterpriseForm, msg.fields[0], y_9.EnvDemands, y_9.ActionClasses, y_9.ContactPerson, y_9.ContactPhone, y_9.ContactEmail, y_9.IncludeDesc, y_9.IncludeRole, y_9.IncludeEnvProfile, y_9.IncludeSum, y_9.IncludeAreal, y_9.IncludeEnterpriseForm, y_9.IncludeActionClasses, y_9.IncludeBuildTime, y_9.IncludeEnvDemand, y_9.IncludeEnergyClass, y_9.IncludeBuilder, y_9.IncludeContactPerson, y_9.IncludeContactPhone, y_9.IncludeContactMail)));
        }
        case 11: {
            return update$0027(model, (y_10) => (new Print_ProjectReferenceData(y_10.ProjId, y_10.Desc, y_10.ShortDesc, y_10.EnvProfile, y_10.Areal, y_10.Sum, msg.fields[0], y_10.BuildTime, y_10.EnterpriseForm, y_10.EnergyClass, y_10.EnvDemands, y_10.ActionClasses, y_10.ContactPerson, y_10.ContactPhone, y_10.ContactEmail, y_10.IncludeDesc, y_10.IncludeRole, y_10.IncludeEnvProfile, y_10.IncludeSum, y_10.IncludeAreal, y_10.IncludeEnterpriseForm, y_10.IncludeActionClasses, y_10.IncludeBuildTime, y_10.IncludeEnvDemand, y_10.IncludeEnergyClass, y_10.IncludeBuilder, y_10.IncludeContactPerson, y_10.IncludeContactPhone, y_10.IncludeContactMail)));
        }
        case 12: {
            return update$0027(model, (y_11) => (new Print_ProjectReferenceData(y_11.ProjId, y_11.Desc, y_11.ShortDesc, y_11.EnvProfile, y_11.Areal, y_11.Sum, y_11.Builder, y_11.BuildTime, y_11.EnterpriseForm, y_11.EnergyClass, y_11.EnvDemands, y_11.ActionClasses, msg.fields[0], y_11.ContactPhone, y_11.ContactEmail, y_11.IncludeDesc, y_11.IncludeRole, y_11.IncludeEnvProfile, y_11.IncludeSum, y_11.IncludeAreal, y_11.IncludeEnterpriseForm, y_11.IncludeActionClasses, y_11.IncludeBuildTime, y_11.IncludeEnvDemand, y_11.IncludeEnergyClass, y_11.IncludeBuilder, y_11.IncludeContactPerson, y_11.IncludeContactPhone, y_11.IncludeContactMail)));
        }
        case 13: {
            return update$0027(model, (y_12) => (new Print_ProjectReferenceData(y_12.ProjId, y_12.Desc, y_12.ShortDesc, y_12.EnvProfile, y_12.Areal, y_12.Sum, y_12.Builder, y_12.BuildTime, y_12.EnterpriseForm, y_12.EnergyClass, y_12.EnvDemands, y_12.ActionClasses, y_12.ContactPerson, msg.fields[0], y_12.ContactEmail, y_12.IncludeDesc, y_12.IncludeRole, y_12.IncludeEnvProfile, y_12.IncludeSum, y_12.IncludeAreal, y_12.IncludeEnterpriseForm, y_12.IncludeActionClasses, y_12.IncludeBuildTime, y_12.IncludeEnvDemand, y_12.IncludeEnergyClass, y_12.IncludeBuilder, y_12.IncludeContactPerson, y_12.IncludeContactPhone, y_12.IncludeContactMail)));
        }
        case 14: {
            return update$0027(model, (y_13) => (new Print_ProjectReferenceData(y_13.ProjId, y_13.Desc, y_13.ShortDesc, y_13.EnvProfile, y_13.Areal, y_13.Sum, y_13.Builder, y_13.BuildTime, y_13.EnterpriseForm, y_13.EnergyClass, y_13.EnvDemands, y_13.ActionClasses, y_13.ContactPerson, y_13.ContactPhone, msg.fields[0], y_13.IncludeDesc, y_13.IncludeRole, y_13.IncludeEnvProfile, y_13.IncludeSum, y_13.IncludeAreal, y_13.IncludeEnterpriseForm, y_13.IncludeActionClasses, y_13.IncludeBuildTime, y_13.IncludeEnvDemand, y_13.IncludeEnergyClass, y_13.IncludeBuilder, y_13.IncludeContactPerson, y_13.IncludeContactPhone, y_13.IncludeContactMail)));
        }
        case 15: {
            return update$0027(model, (y_14) => (new Print_ProjectReferenceData(y_14.ProjId, y_14.Desc, y_14.ShortDesc, y_14.EnvProfile, y_14.Areal, y_14.Sum, y_14.Builder, y_14.BuildTime, y_14.EnterpriseForm, y_14.EnergyClass, y_14.EnvDemands, y_14.ActionClasses, y_14.ContactPerson, y_14.ContactPhone, y_14.ContactEmail, !y_14.IncludeDesc, y_14.IncludeRole, y_14.IncludeEnvProfile, y_14.IncludeSum, y_14.IncludeAreal, y_14.IncludeEnterpriseForm, y_14.IncludeActionClasses, y_14.IncludeBuildTime, y_14.IncludeEnvDemand, y_14.IncludeEnergyClass, y_14.IncludeBuilder, y_14.IncludeContactPerson, y_14.IncludeContactPhone, y_14.IncludeContactMail)));
        }
        case 16: {
            return update$0027(model, (y_15) => (new Print_ProjectReferenceData(y_15.ProjId, y_15.Desc, y_15.ShortDesc, y_15.EnvProfile, y_15.Areal, y_15.Sum, y_15.Builder, y_15.BuildTime, y_15.EnterpriseForm, y_15.EnergyClass, y_15.EnvDemands, y_15.ActionClasses, y_15.ContactPerson, y_15.ContactPhone, y_15.ContactEmail, y_15.IncludeDesc, !y_15.IncludeRole, y_15.IncludeEnvProfile, y_15.IncludeSum, y_15.IncludeAreal, y_15.IncludeEnterpriseForm, y_15.IncludeActionClasses, y_15.IncludeBuildTime, y_15.IncludeEnvDemand, y_15.IncludeEnergyClass, y_15.IncludeBuilder, y_15.IncludeContactPerson, y_15.IncludeContactPhone, y_15.IncludeContactMail)));
        }
        case 17: {
            return update$0027(model, (y_16) => (new Print_ProjectReferenceData(y_16.ProjId, y_16.Desc, y_16.ShortDesc, y_16.EnvProfile, y_16.Areal, y_16.Sum, y_16.Builder, y_16.BuildTime, y_16.EnterpriseForm, y_16.EnergyClass, y_16.EnvDemands, y_16.ActionClasses, y_16.ContactPerson, y_16.ContactPhone, y_16.ContactEmail, y_16.IncludeDesc, y_16.IncludeRole, !y_16.IncludeEnvProfile, y_16.IncludeSum, y_16.IncludeAreal, y_16.IncludeEnterpriseForm, y_16.IncludeActionClasses, y_16.IncludeBuildTime, y_16.IncludeEnvDemand, y_16.IncludeEnergyClass, y_16.IncludeBuilder, y_16.IncludeContactPerson, y_16.IncludeContactPhone, y_16.IncludeContactMail)));
        }
        case 18: {
            return update$0027(model, (y_17) => (new Print_ProjectReferenceData(y_17.ProjId, y_17.Desc, y_17.ShortDesc, y_17.EnvProfile, y_17.Areal, y_17.Sum, y_17.Builder, y_17.BuildTime, y_17.EnterpriseForm, y_17.EnergyClass, y_17.EnvDemands, y_17.ActionClasses, y_17.ContactPerson, y_17.ContactPhone, y_17.ContactEmail, y_17.IncludeDesc, y_17.IncludeRole, y_17.IncludeEnvProfile, !y_17.IncludeSum, y_17.IncludeAreal, y_17.IncludeEnterpriseForm, y_17.IncludeActionClasses, y_17.IncludeBuildTime, y_17.IncludeEnvDemand, y_17.IncludeEnergyClass, y_17.IncludeBuilder, y_17.IncludeContactPerson, y_17.IncludeContactPhone, y_17.IncludeContactMail)));
        }
        case 19: {
            return update$0027(model, (y_18) => (new Print_ProjectReferenceData(y_18.ProjId, y_18.Desc, y_18.ShortDesc, y_18.EnvProfile, y_18.Areal, y_18.Sum, y_18.Builder, y_18.BuildTime, y_18.EnterpriseForm, y_18.EnergyClass, y_18.EnvDemands, y_18.ActionClasses, y_18.ContactPerson, y_18.ContactPhone, y_18.ContactEmail, y_18.IncludeDesc, y_18.IncludeRole, y_18.IncludeEnvProfile, y_18.IncludeSum, y_18.IncludeAreal, !y_18.IncludeEnterpriseForm, y_18.IncludeActionClasses, y_18.IncludeBuildTime, y_18.IncludeEnvDemand, y_18.IncludeEnergyClass, y_18.IncludeBuilder, y_18.IncludeContactPerson, y_18.IncludeContactPhone, y_18.IncludeContactMail)));
        }
        case 20: {
            return update$0027(model, (y_19) => (new Print_ProjectReferenceData(y_19.ProjId, y_19.Desc, y_19.ShortDesc, y_19.EnvProfile, y_19.Areal, y_19.Sum, y_19.Builder, y_19.BuildTime, y_19.EnterpriseForm, y_19.EnergyClass, y_19.EnvDemands, y_19.ActionClasses, y_19.ContactPerson, y_19.ContactPhone, y_19.ContactEmail, y_19.IncludeDesc, y_19.IncludeRole, y_19.IncludeEnvProfile, y_19.IncludeSum, !y_19.IncludeAreal, y_19.IncludeEnterpriseForm, y_19.IncludeActionClasses, y_19.IncludeBuildTime, y_19.IncludeEnvDemand, y_19.IncludeEnergyClass, y_19.IncludeBuilder, y_19.IncludeContactPerson, y_19.IncludeContactPhone, y_19.IncludeContactMail)));
        }
        case 21: {
            return update$0027(model, (y_20) => (new Print_ProjectReferenceData(y_20.ProjId, y_20.Desc, y_20.ShortDesc, y_20.EnvProfile, y_20.Areal, y_20.Sum, y_20.Builder, y_20.BuildTime, y_20.EnterpriseForm, y_20.EnergyClass, y_20.EnvDemands, y_20.ActionClasses, y_20.ContactPerson, y_20.ContactPhone, y_20.ContactEmail, y_20.IncludeDesc, y_20.IncludeRole, y_20.IncludeEnvProfile, y_20.IncludeSum, y_20.IncludeAreal, y_20.IncludeEnterpriseForm, !y_20.IncludeActionClasses, y_20.IncludeBuildTime, y_20.IncludeEnvDemand, y_20.IncludeEnergyClass, y_20.IncludeBuilder, y_20.IncludeContactPerson, y_20.IncludeContactPhone, y_20.IncludeContactMail)));
        }
        case 22: {
            return update$0027(model, (y_21) => (new Print_ProjectReferenceData(y_21.ProjId, y_21.Desc, y_21.ShortDesc, y_21.EnvProfile, y_21.Areal, y_21.Sum, y_21.Builder, y_21.BuildTime, y_21.EnterpriseForm, y_21.EnergyClass, y_21.EnvDemands, y_21.ActionClasses, y_21.ContactPerson, y_21.ContactPhone, y_21.ContactEmail, y_21.IncludeDesc, y_21.IncludeRole, y_21.IncludeEnvProfile, y_21.IncludeSum, y_21.IncludeAreal, y_21.IncludeEnterpriseForm, y_21.IncludeActionClasses, !y_21.IncludeBuildTime, y_21.IncludeEnvDemand, y_21.IncludeEnergyClass, y_21.IncludeBuilder, y_21.IncludeContactPerson, y_21.IncludeContactPhone, y_21.IncludeContactMail)));
        }
        case 23: {
            return update$0027(model, (y_22) => (new Print_ProjectReferenceData(y_22.ProjId, y_22.Desc, y_22.ShortDesc, y_22.EnvProfile, y_22.Areal, y_22.Sum, y_22.Builder, y_22.BuildTime, y_22.EnterpriseForm, y_22.EnergyClass, y_22.EnvDemands, y_22.ActionClasses, y_22.ContactPerson, y_22.ContactPhone, y_22.ContactEmail, y_22.IncludeDesc, y_22.IncludeRole, y_22.IncludeEnvProfile, y_22.IncludeSum, y_22.IncludeAreal, y_22.IncludeEnterpriseForm, y_22.IncludeActionClasses, y_22.IncludeBuildTime, !y_22.IncludeEnvDemand, y_22.IncludeEnergyClass, y_22.IncludeBuilder, y_22.IncludeContactPerson, y_22.IncludeContactPhone, y_22.IncludeContactMail)));
        }
        case 24: {
            return update$0027(model, (y_23) => (new Print_ProjectReferenceData(y_23.ProjId, y_23.Desc, y_23.ShortDesc, y_23.EnvProfile, y_23.Areal, y_23.Sum, y_23.Builder, y_23.BuildTime, y_23.EnterpriseForm, y_23.EnergyClass, y_23.EnvDemands, y_23.ActionClasses, y_23.ContactPerson, y_23.ContactPhone, y_23.ContactEmail, y_23.IncludeDesc, y_23.IncludeRole, y_23.IncludeEnvProfile, y_23.IncludeSum, y_23.IncludeAreal, y_23.IncludeEnterpriseForm, y_23.IncludeActionClasses, y_23.IncludeBuildTime, y_23.IncludeEnvDemand, !y_23.IncludeEnergyClass, y_23.IncludeBuilder, y_23.IncludeContactPerson, y_23.IncludeContactPhone, y_23.IncludeContactMail)));
        }
        case 25: {
            return update$0027(model, (y_24) => (new Print_ProjectReferenceData(y_24.ProjId, y_24.Desc, y_24.ShortDesc, y_24.EnvProfile, y_24.Areal, y_24.Sum, y_24.Builder, y_24.BuildTime, y_24.EnterpriseForm, y_24.EnergyClass, y_24.EnvDemands, y_24.ActionClasses, y_24.ContactPerson, y_24.ContactPhone, y_24.ContactEmail, y_24.IncludeDesc, y_24.IncludeRole, y_24.IncludeEnvProfile, y_24.IncludeSum, y_24.IncludeAreal, y_24.IncludeEnterpriseForm, y_24.IncludeActionClasses, y_24.IncludeBuildTime, y_24.IncludeEnvDemand, y_24.IncludeEnergyClass, !y_24.IncludeBuilder, y_24.IncludeContactPerson, y_24.IncludeContactPhone, y_24.IncludeContactMail)));
        }
        case 26: {
            return update$0027(model, (y_25) => (new Print_ProjectReferenceData(y_25.ProjId, y_25.Desc, y_25.ShortDesc, y_25.EnvProfile, y_25.Areal, y_25.Sum, y_25.Builder, y_25.BuildTime, y_25.EnterpriseForm, y_25.EnergyClass, y_25.EnvDemands, y_25.ActionClasses, y_25.ContactPerson, y_25.ContactPhone, y_25.ContactEmail, y_25.IncludeDesc, y_25.IncludeRole, y_25.IncludeEnvProfile, y_25.IncludeSum, y_25.IncludeAreal, y_25.IncludeEnterpriseForm, y_25.IncludeActionClasses, y_25.IncludeBuildTime, y_25.IncludeEnvDemand, y_25.IncludeEnergyClass, y_25.IncludeBuilder, !y_25.IncludeContactPerson, y_25.IncludeContactPhone, y_25.IncludeContactMail)));
        }
        case 27: {
            return update$0027(model, (y_26) => (new Print_ProjectReferenceData(y_26.ProjId, y_26.Desc, y_26.ShortDesc, y_26.EnvProfile, y_26.Areal, y_26.Sum, y_26.Builder, y_26.BuildTime, y_26.EnterpriseForm, y_26.EnergyClass, y_26.EnvDemands, y_26.ActionClasses, y_26.ContactPerson, y_26.ContactPhone, y_26.ContactEmail, y_26.IncludeDesc, y_26.IncludeRole, y_26.IncludeEnvProfile, y_26.IncludeSum, y_26.IncludeAreal, y_26.IncludeEnterpriseForm, y_26.IncludeActionClasses, y_26.IncludeBuildTime, y_26.IncludeEnvDemand, y_26.IncludeEnergyClass, y_26.IncludeBuilder, y_26.IncludeContactPerson, !y_26.IncludeContactPhone, y_26.IncludeContactMail)));
        }
        case 28: {
            return update$0027(model, (y_27) => (new Print_ProjectReferenceData(y_27.ProjId, y_27.Desc, y_27.ShortDesc, y_27.EnvProfile, y_27.Areal, y_27.Sum, y_27.Builder, y_27.BuildTime, y_27.EnterpriseForm, y_27.EnergyClass, y_27.EnvDemands, y_27.ActionClasses, y_27.ContactPerson, y_27.ContactPhone, y_27.ContactEmail, y_27.IncludeDesc, y_27.IncludeRole, y_27.IncludeEnvProfile, y_27.IncludeSum, y_27.IncludeAreal, y_27.IncludeEnterpriseForm, y_27.IncludeActionClasses, y_27.IncludeBuildTime, y_27.IncludeEnvDemand, y_27.IncludeEnergyClass, y_27.IncludeBuilder, y_27.IncludeContactPerson, y_27.IncludeContactPhone, !y_27.IncludeContactMail)));
        }
        case 29: {
            return new Model(model.ProjectId, model.ProjectData, msg.fields[0], model.ErrorMsg, model.FormError);
        }
        case 30: {
            const matchValue = model.ProjectData;
            if (matchValue == null) {
                return model;
            }
            else {
                const pd = matchValue;
                const validShortDesc = pd.ShortDesc.length <= 500;
                const validDesc = pd.Desc.length <= 900;
                const validEnvProfile = pd.EnvProfile.length <= 900;
                const matchValue_1 = [validShortDesc, validDesc, validEnvProfile];
                let pattern_matching_result;
                if (matchValue_1[0]) {
                    if (matchValue_1[1]) {
                        if (matchValue_1[2]) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.ProjectId, model.ProjectData, new GenerationStep(0, true), model.ErrorMsg, void 0);
                    }
                    case 1: {
                        return new Model(model.ProjectId, model.ProjectData, model.Step, model.ErrorMsg, (!validDesc) ? "Beskrivelse er for lang" : ((!validShortDesc) ? "\"Consto\u0027s rolle\" er for lang" : ((!validEnvProfile) ? "Miljøprofil er for lang" : "")));
                    }
                }
            }
        }
        case 31: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.ProjectData, new GenerationStep(1, "Noe gikk galt. Kunne ikke generere prosjektutskrift."), model.ErrorMsg, model.FormError);
            }
            else {
                const x_16 = res_1.fields[0];
                if (x_16.Result === "success") {
                    return new Model(model.ProjectId, model.ProjectData, new GenerationStep(2, x_16.Message), model.ErrorMsg, model.FormError);
                }
                else {
                    return new Model(model.ProjectId, model.ProjectData, new GenerationStep(1, x_16.Message), model.ErrorMsg, model.FormError);
                }
            }
        }
        case 32: {
            return new Model(model.ProjectId, model.ProjectData, model.Step, void 0, model.FormError);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.ProjectData, model.Step, "Noe gikk galt. Kunne ikke laste prosjektdata.", model.FormError);
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.Step, model.ErrorMsg, model.FormError);
            }
        }
    }
}

function fetchData(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/projectprint/data/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Print_ProjectReferenceData$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Print_ProjectReferenceData$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function generate(projData, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(30));
        if (((projData.ShortDesc.length <= 500) ? (projData.Desc.length <= 900) : false) ? (projData.EnvProfile.length <= 900) : false) {
            const requestPath = toText(printf("/api/projectprint/generate"));
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Print_ProjectReferenceData$reflection,
                })(projData);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(31, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            return Promise.resolve();
        }
    }));
    pr.then();
}

function textarea$0027(defaultVal, onChange, isActive) {
    return MuiHelpers_createElement(TextField, [["defaultValue", defaultVal], ["multiline", true], ["variant", "outlined"], ["disabled", !isActive], ["style", {
        width: 100 + "%",
        minHeight: 100 + "px",
    }], ["onChange", (ev) => {
        onChange(ev.target.value);
    }]]);
}

function input$0027(defaultVal, onChange, isActive) {
    return MuiHelpers_createElement(TextField, [["defaultValue", defaultVal], ["disabled", !isActive], ["style", {
        marginBottom: 10 + "px",
    }], ["onChange", (ev) => {
        onChange(ev.target.value);
    }]]);
}

function inlineBox(children) {
    return createElement("div", {
        style: {
            width: 200 + "px",
            marginRight: 10 + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function drawPrintForm(model, dispatch, projData) {
    let children_2;
    const span$0027 = (title, isChecked, msg) => MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", isChecked], ["onChange", (e) => {
        const _arg1 = Browser_Types_Event__Event_get_Checked(e);
        dispatch(msg);
    }], ["color", "primary"]])], ["label", title]]);
    const lengthSpan = (len, maxLen) => createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
            color: (len <= maxLen) ? "#313131" : "red",
        },
        children: toText(printf("%i/%i"))(len)(maxLen),
    });
    const children_4 = ofArray([createElement("div", {
        style: {
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([span$0027("Beskrivelse", projData.IncludeDesc, new Message(15)), textarea$0027(projData.Desc, (arg) => {
            dispatch(new Message(1, arg));
        }, projData.IncludeDesc), lengthSpan(projData.Desc.length, 900)]),
    }), createElement("div", {
        style: {
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([span$0027("Kort beskrivelse", projData.IncludeRole, new Message(16)), textarea$0027(projData.ShortDesc, (arg_1) => {
            dispatch(new Message(2, arg_1));
        }, projData.IncludeRole), lengthSpan(projData.ShortDesc.length, 500)]),
    }), createElement("div", {
        style: {
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([span$0027("Miljøprofil", projData.IncludeEnvProfile, new Message(17)), textarea$0027(projData.EnvProfile, (arg_2) => {
            dispatch(new Message(3, arg_2));
        }, projData.IncludeEnvProfile), lengthSpan(projData.EnvProfile.length, 900)]),
    }), createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([inlineBox([span$0027("Oppdragets verdi", projData.IncludeSum, new Message(18)), input$0027(projData.Sum, (arg_3) => {
            dispatch(new Message(4, arg_3));
        }, projData.IncludeSum)]), inlineBox([span$0027("Enterpriseform", projData.IncludeEnterpriseForm, new Message(19)), input$0027(projData.EnterpriseForm, (arg_4) => {
            dispatch(new Message(5, arg_4));
        }, projData.IncludeEnterpriseForm)]), inlineBox([span$0027("Bruttoareal", projData.IncludeAreal, new Message(20)), input$0027(projData.Areal, (arg_5) => {
            dispatch(new Message(6, arg_5));
        }, projData.IncludeAreal)]), inlineBox([span$0027("Byggetid", projData.IncludeBuildTime, new Message(22)), input$0027(projData.BuildTime, (arg_6) => {
            dispatch(new Message(8, arg_6));
        }, projData.IncludeBuildTime)]), inlineBox([span$0027("Miljøkrav", projData.IncludeEnvDemand, new Message(23)), input$0027(projData.EnvDemands, (arg_7) => {
            dispatch(new Message(9, arg_7));
        }, projData.IncludeEnvDemand)]), inlineBox([span$0027("Energikrav", projData.IncludeEnergyClass, new Message(24)), input$0027(projData.EnergyClass, (arg_8) => {
            dispatch(new Message(10, arg_8));
        }, projData.IncludeEnergyClass)]), inlineBox([span$0027("Oppdragsgiver", projData.IncludeBuilder, new Message(25)), input$0027(projData.Builder, (arg_9) => {
            dispatch(new Message(11, arg_9));
        }, projData.IncludeBuilder)]), inlineBox([span$0027("Kontaktperson", projData.IncludeContactPerson, new Message(26)), input$0027(projData.ContactPerson, (arg_10) => {
            dispatch(new Message(12, arg_10));
        }, projData.IncludeContactPerson)]), inlineBox([span$0027("Kontakttelefon", projData.IncludeContactPhone, new Message(27)), input$0027(projData.ContactPhone, (arg_11) => {
            dispatch(new Message(13, arg_11));
        }, projData.IncludeContactPhone)]), inlineBox([span$0027("Kontaktepost", projData.IncludeContactMail, new Message(28)), input$0027(projData.ContactEmail, (arg_12) => {
            dispatch(new Message(14, arg_12));
        }, projData.IncludeContactMail)]), createElement("div", {
            style: {
                display: "inline-block",
                width: 150 + "px",
                marginRight: 10 + "px",
                textAlign: "left",
                fontSize: 0 + "px",
            },
            children: Interop_reactApi.Children.toArray([span$0027("Ansvarsretter", projData.IncludeActionClasses, new Message(21)), input$0027(projData.ActionClasses, (arg_13) => {
                dispatch(new Message(7, arg_13));
            }, projData.IncludeActionClasses), createElement("span", {
                style: {
                    fontSize: 0.7 + "rem",
                },
                children: "Separer med ;",
            }), createElement("table", {
                style: {
                    fontSize: 0.7 + "rem",
                    textAlign: "left",
                },
                children: Interop_reactApi.Children.toArray([createElement("thead", {}), (children_2 = singleton(map((x) => {
                    const y = ((x.indexOf(" ") === 0) ? (x.length > 1) : false) ? x.slice(1, x.length) : x;
                    const children = singleton(createElement("td", {
                        children: [y],
                    }));
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    });
                }, split(projData.ActionClasses, [";"], null, 0))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                }))]),
            })]),
        })]),
    }), createElement("div", {
        style: {
            textAlign: "center",
            marginTop: 20 + "px",
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let matchValue;
            return append((matchValue = model.Step, (matchValue.tag === 1) ? append(singleton_1(createElement("span", {
                children: [matchValue.fields[0]],
            })), delay(() => append(singleton_1(createElement("br", {})), delay(() => singleton_1(createElement("a", {
                onClick: (_arg3) => {
                    dispatch(new Message(29, new GenerationStep(0, false)));
                },
                children: "Prøv på nytt",
            })))))) : ((matchValue.tag === 2) ? append(singleton_1(createElement("span", {
                children: ["PDF-generert!"],
            })), delay(() => append(singleton_1(createElement("br", {})), delay(() => append(singleton_1(createElement("a", {
                href: toText(printf("/printcache/download/%s"))(matchValue.fields[0]),
                target: "_blank",
                children: "Last ned",
            })), delay(() => singleton_1(createElement("a", {
                onClick: (_arg4) => {
                    dispatch(new Message(29, new GenerationStep(0, false)));
                },
                children: "Generer ny",
            })))))))) : ((!matchValue.fields[0]) ? singleton_1(MuiHelpers_createElement(Button, [["color", "primary"], ["onClick", (_arg2) => {
                dispatch(new Message(30));
            }], ["children", "Generer referanseark"]])) : singleton_1(loadingIndicatorSmall())))), delay(() => {
                const matchValue_1 = model.FormError;
                if (matchValue_1 != null) {
                    const errMsg_1 = matchValue_1;
                    return append(singleton_1(createElement("br", {})), delay(() => singleton_1(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: errMsg_1,
                    }))));
                }
                else {
                    return empty_1();
                }
            }));
        })))),
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

function view(model, dispatch) {
    const children = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.ErrorMsg, (matchValue != null) ? singleton_1(errorBanner(matchValue, (_arg1) => {
            dispatch(new Message(32));
        })) : ((empty_1()))), delay(() => append(singleton_1(createElement("h3", {
            className: "title is-3",
            children: "Utskrift av referanseark",
        })), delay(() => {
            const matchValue_1 = model.ProjectData;
            return (matchValue_1 != null) ? singleton_1(drawPrintForm(model, dispatch, matchValue_1)) : singleton_1(loadingIndicatorCenteredSmall());
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ProjectPrint(projectPrintInputProps) {
    const projectId = projectPrintInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchData(projectId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

export function fullscreenProjectPrint(projectId) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([createElement(ProjectPrint, {
            projectId: projectId,
        })]),
    });
}

export const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    o = {
        paper: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), ofArray([["position", "absolute"], ["width", 80 + "vw"], ["maxHeight", 80 + "vh"], ["backgroundColor", theme.palette.background.paper], ["box-shadow", theme.shadows[5]], ["padding", theme.spacing(2, 4, 3)], ["overflowY", "scroll"]])),
    };
    return Object.assign({}, o);
});

export function ProjectPrintModal(projectPrintModalInputProps) {
    const onClose = projectPrintModalInputProps.onClose;
    const projId = projectPrintModalInputProps.projId;
    const st = useStyles();
    return MuiHelpers_createElement(Modal, [["open", true], ["onClose", (_arg17, v) => {
        onClose();
    }], ["aria-labelledby", join(" ", ["Skriv ut prosjektreferanse"])], ["aria-describedby", join(" ", [""])], ["children", createElement("div", {
        className: st.paper,
        style: {
            textAlign: "center",
            top: 50 + "%",
            left: 50 + "%",
            translate: "-50% -50%",
        },
        children: Interop_reactApi.Children.toArray([createElement(ProjectPrint, {
            projectId: projId,
        })]),
    })]]);
}

