import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, bool_type, string_type, tuple_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProjectRoleNotification, Helpers_ResultMessage$reflection, ProjectRoleNotification$reflection, ProjectRole$reflection } from "../Shared/ApiDataTypes.js";
import { Notification_NotificationType_FromInt_Z524259A4, Notification_NotificationTopic_FromInt_Z524259A4, Notification_NotificationType, Notification_NotificationTopic, Notification_NotificationType__ToInt, Notification_NotificationTopic__ToInt, Notification_NotificationType$reflection, Notification_NotificationTopic$reflection } from "../Shared/Shared.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { hasProjectPermission } from "./ProjectPermission.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import { hasPermission } from "../LoginState.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { createObj, comparePrimitives, equals, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Delete from "@material-ui/icons/Delete";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { snackbarError, loadingIndicatorMedium } from "../ViewHelpers.js";

class Model extends Record {
    constructor(ProjectId, ProjectRoles, Notifications, SubscribeForm, UnsubscribeForm, ErrorMsg, CanEdit) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectRoles = ProjectRoles;
        this.Notifications = Notifications;
        this.SubscribeForm = SubscribeForm;
        this.UnsubscribeForm = UnsubscribeForm;
        this.ErrorMsg = ErrorMsg;
        this.CanEdit = CanEdit;
    }
}

function Model$reflection() {
    return record_type("ProjectNotification.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectRoles", option_type(array_type(ProjectRole$reflection()))], ["Notifications", option_type(array_type(ProjectRoleNotification$reflection()))], ["SubscribeForm", option_type(tuple_type(int32_type, Notification_NotificationTopic$reflection(), Notification_NotificationType$reflection()))], ["UnsubscribeForm", option_type(ProjectRoleNotification$reflection())], ["ErrorMsg", option_type(string_type)], ["CanEdit", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectRolesResponse", "NotificationsResponse", "PermissionsResponse", "Subscribe", "SubscribeResponse", "Unsubscribe", "UnsubscribeResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectNotification.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectRole$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectRole$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectRoleNotification$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectRoleNotification$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item1", int32_type], ["Item2", Notification_NotificationTopic$reflection()], ["Item3", Notification_NotificationType$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ProjectRoleNotification$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(projId) {
    return new Model(projId, void 0, void 0, void 0, void 0, void 0, false);
}

function update(model, msg) {
    let matchValue, topic, prId, nType, y_1, matchValue_2, y_3, matchValue_5;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, "Noe gikk galt. Kunne ikke laste varslinger.", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, model.ProjectRoles, res_1.fields[0], model.SubscribeForm, model.UnsubscribeForm, model.ErrorMsg, model.CanEdit);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, "Noe gikk galt. Kunne ikke laste prosjektrettigheter.", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, model.ErrorMsg, hasProjectPermission(new Functionality(8), res_2.fields[0]) ? true : hasPermission(new Functionality(54)));
            }
        }
        case 3: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, [msg.fields[0], msg.fields[1], msg.fields[2]], model.UnsubscribeForm, model.ErrorMsg, model.CanEdit);
        }
        case 4: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, "Noe gikk galt. Kunne ikke abonnere på varsling.", model.CanEdit);
            }
            else {
                const x_4 = res_3.fields[0];
                if (x_4.Result === "success") {
                    return new Model(model.ProjectId, model.ProjectRoles, (matchValue = model.SubscribeForm, (matchValue != null) ? ((topic = matchValue[1], (prId = (matchValue[0] | 0), (nType = matchValue[2], (y_1 = (new ProjectRoleNotification((() => {
                        try {
                            return parse(x_4.Message, 511, false, 32) | 0;
                        }
                        catch (matchValue_1) {
                            return -1;
                        }
                    })(), prId, Notification_NotificationTopic__ToInt(topic), Notification_NotificationType__ToInt(nType))), (matchValue_2 = model.Notifications, (matchValue_2 == null) ? [y_1] : Array_distinctBy((e) => e.Id, append([y_1], matchValue_2), {
                        Equals: (x_5, y_2) => (x_5 === y_2),
                        GetHashCode: (x_5) => numberHash(x_5),
                    }))))))) : model.Notifications), void 0, model.UnsubscribeForm, model.ErrorMsg, model.CanEdit);
                }
                else {
                    return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, x_4.Message, model.CanEdit);
                }
            }
        }
        case 5: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, msg.fields[0], model.ErrorMsg, model.CanEdit);
        }
        case 6: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, "Noe gikk galt. Kunne ikke fjerne varsling for prosjektrolle.", model.CanEdit);
            }
            else {
                const x_7 = res_4.fields[0];
                if (x_7.Result === "success") {
                    let nId;
                    const matchValue_3 = model.UnsubscribeForm;
                    if (matchValue_3 == null) {
                        try {
                            nId = parse(x_7.Message, 511, false, 32);
                        }
                        catch (matchValue_4) {
                            nId = (void 0);
                        }
                    }
                    else {
                        nId = matchValue_3.Id;
                    }
                    return new Model(model.ProjectId, model.ProjectRoles, (nId != null) ? ((y_3 = (nId | 0), (matchValue_5 = model.Notifications, (matchValue_5 != null) ? matchValue_5.filter((e_1) => (e_1.Id !== y_3)) : (void 0)))) : model.Notifications, model.SubscribeForm, model.UnsubscribeForm, model.ErrorMsg, model.CanEdit);
                }
                else {
                    return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, x_7.Message, model.CanEdit);
                }
            }
        }
        case 7: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, void 0, model.CanEdit);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Notifications, model.SubscribeForm, model.UnsubscribeForm, "Noe gikk galt. Kunne ikke laste prosjektroller.", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.Notifications, model.SubscribeForm, model.UnsubscribeForm, model.ErrorMsg, model.CanEdit);
            }
        }
    }
}

function fetchProjectRoles(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project/roles/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectRole$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectRole$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchProjectPermissions(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchNotifications(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/notification/project/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectRoleNotification$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectRoleNotification$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function trySubscribe(projectRoleId, topic, nType, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(3, projectRoleId, topic, nType));
        const req = new ProjectRoleNotification(-1, projectRoleId, Notification_NotificationTopic__ToInt(topic), Notification_NotificationType__ToInt(nType));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectRoleNotification$reflection,
            })(req);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryPost_5760677E("/api/notification/project", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryUnsubscribe(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5, x));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectRoleNotification$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryDelete_5760677E("/api/notification/project", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(6, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

const availableNotifications = [[new Notification_NotificationTopic(0), new Notification_NotificationType(0)], [new Notification_NotificationTopic(0), new Notification_NotificationType(1)], [new Notification_NotificationTopic(2), new Notification_NotificationType(0)], [new Notification_NotificationTopic(2), new Notification_NotificationType(1)]];

function addForm(dispatch$0027, a, b) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const anchor = useReact_useRef_1505(void 0);
        const patternInput = useFeliz_React__React_useState_Static_1505(false);
        const setOpen = patternInput[1];
        const isOpen = patternInput[0];
        const menuOptions = map((tupledArg) => {
            let arg20, arg10;
            const x = tupledArg[0];
            const y = tupledArg[1];
            return MuiHelpers_createElement(MenuItem, [["children", (arg20 = toString(y), (arg10 = toString(x), toText(printf("%s - %s"))(arg10)(arg20)))], ["onClick", (_arg1) => {
                trySubscribe(props.projectRoleId, x, y, props.dispatch);
                setOpen(false);
            }]]);
        }, props.notifications);
        const children_2 = toList(delay(() => ((menuOptions.length > 0) ? append_1(singleton(MuiHelpers_createElement(IconButton, [["aria-label", "Legg til"], ["ref", anchor], ["onClick", (_arg2) => {
            setOpen(!isOpen);
        }], ["size", "small"], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Add, {})])]])]])), delay(() => singleton(MuiHelpers_createElement(Menu, [["open", isOpen], ["onClose", (_arg16, v) => {
            setOpen(false);
        }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray(Array.from(menuOptions))]])))) : empty_1())));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, void 0, uncurry(2, void 0), void 0, "addForm", "/builds/serit/score/score/src/Client/Project/ProjectNotification.fs", 212)({
        dispatch: dispatch$0027,
        notifications: b,
        projectRoleId: a,
    });
}

function drawNotifications(model, dispatch) {
    let children_2, children, children_14;
    const matchValue = [model.ProjectRoles, model.Notifications];
    let pattern_matching_result, x, y;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            x = matchValue[0];
            y = matchValue[1];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props_19 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("th", {
                style: {
                    width: 40 + "%",
                },
                children: "Rolle",
            }), createElement("th", {
                style: {
                    width: "auto",
                },
                children: "Varsling",
            }), createElement("th", {
                style: {
                    width: 5 + "%",
                },
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_14 = singleton_1(map((r_1) => {
                let children_8, children_10;
                const notifications = y.filter((n) => (n.ProjectRoleId === r_1.Id));
                const children_12 = ofArray([createElement("td", {
                    children: [r_1.Role.title],
                }), (children_8 = singleton_1(MuiHelpers_createElement(List, [["dense", true], ["style", {
                    maxWidth: 160,
                }], ["children", Interop_reactApi.Children.toArray([map((n_1) => {
                    const topic = Notification_NotificationTopic_FromInt_Z524259A4(n_1.Topic);
                    const nType = Notification_NotificationType_FromInt_Z524259A4(n_1.NotificationType);
                    return MuiHelpers_createElement(ListItem, [["key", n_1.Id], ["dense", true], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(ListItemIcon, [["style", {
                        minWidth: 35,
                        width: 35,
                        display: "flex",
                        justifyContent: "center",
                    }], ["children", equals(nType, new Notification_NotificationType(1)) ? react.createElement(AlternateEmail, {}) : react.createElement(PhoneIphone, {})]])), delay(() => append_1(singleton(MuiHelpers_createElement(ListItemText, [["primary", toString(topic)]])), delay(() => (model.CanEdit ? singleton(MuiHelpers_createElement(ListItemSecondaryAction, [["children", MuiHelpers_createElement(IconButton, [["style", {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }], ["aria-label", "Fjern"], ["onClick", (_arg1) => {
                        tryUnsubscribe(n_1, dispatch);
                    }], ["size", "small"], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Delete, {})])]])]])]])) : empty_1())))))))))]]);
                }, notifications)])]])), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                })), (children_10 = toList(delay(() => {
                    if (model.CanEdit) {
                        const selectable = availableNotifications.filter((tupledArg) => (!notifications.some((n_2) => {
                            if (n_2.Topic === Notification_NotificationTopic__ToInt(tupledArg[0])) {
                                return n_2.NotificationType === Notification_NotificationType__ToInt(tupledArg[1]);
                            }
                            else {
                                return false;
                            }
                        })));
                        return (selectable.length > 0) ? singleton(addForm(dispatch, r_1.Id, selectable)) : empty_1();
                    }
                    else {
                        return empty_1();
                    }
                })), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                }))]);
                return createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                });
            }, sortBy((r) => r.Role.title, x, {
                Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
            }))), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_14)),
            }))])]]);
            return createElement("table", createObj(Helpers_combineClasses("table", props_19)));
        }
        case 1: {
            return createElement("div", {
                style: {
                    position: "fixed",
                    top: 50 + "%",
                    left: 50 + "%",
                },
                children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
            });
        }
    }
}

function view(model, dispatch) {
    const children = ofArray([snackbarError(model.ErrorMsg, () => {
        dispatch(new Message(7));
    }), createElement("span", {
        style: {
            marginRight: 10 + "px",
            fontSize: 0.8 + "rem",
        },
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: "fas fa-at",
            style: {
                marginRight: 5 + "px",
            },
        }), "Varsling på e-post"]),
    }), createElement("span", {
        style: {
            fontSize: 0.8 + "rem",
        },
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: "fas fa-mobile-alt",
            style: {
                marginRight: 5 + "px",
            },
        }), "Varsling på SMS"]),
    }), drawNotifications(model, dispatch)]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ProjectNotification(projectNotificationInputProps) {
    const projectId = projectNotificationInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjectRoles(projectId, dispatch);
        fetchProjectPermissions(projectId, dispatch);
        fetchNotifications(projectId, dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                maxWidth: 1000,
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([view(patternInput[0], dispatch)]),
        })]),
    });
}

