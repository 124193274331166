import { Record, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { record_type, bool_type, union_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { map, toArray, singleton, ofArray } from "./.fable/fable-library.3.2.4/List.js";
import { Tab_SaveSelectedTab_Z721C83C5, Tab_GetSelectedTab } from "./LocalStorage.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { singleton as singleton_1, append, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "./.fable/fable-library.3.2.4/Int32.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "./.fable/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import { RotationAngle, rotatingChevronButton } from "./ViewHelpers.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { equals } from "./.fable/fable-library.3.2.4/Util.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { actionClass } from "./Administration/ActionClass.js";
import { breeams } from "./Administration/Breeam.js";
import { processTree } from "./Administration/Process.js";
import { builders } from "./Administration/Builder.js";
import { energyclasss } from "./Administration/EnergyClass.js";
import { projectOutcomeType } from "./Administration/ProjectOutcomeType.js";
import { roles } from "./Administration/Role.js";
import { hmsControl } from "./HMS/HmsControl.js";
import { interaction } from "./Administration/Interaction.js";
import { educations } from "./Administration/EducationLevel.js";
import { hmsTypes } from "./HMS/HmsType.js";
import { WasteTypes } from "./Waste/WasteType.js";
import { wasteControl } from "./Waste/WasteControl.js";
import { AccessControl } from "./Administration/AccessControl.js";
import { useReact_useReducer_2B9E6EA0 } from "./.fable/Feliz.1.45.0/React.fs.js";

class SubModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AccessControlModel", "ActionClassModel", "BreeamModel", "BuilderModel", "EducationLevelModel", "EnergyClassModel", "HmsControlModel", "HmsTypeModel", "InteractionModel", "ProcessModel", "ProjectOutcomeTypeModel", "RoleModel", "WasteTypeModel", "WasteControlModel"];
    }
}

function SubModel$reflection() {
    return union_type("Administration.SubModel", [], SubModel, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

const getAllSubModels = ofArray([new SubModel(0), new SubModel(1), new SubModel(2), new SubModel(3), new SubModel(4), new SubModel(5), new SubModel(6), new SubModel(7), new SubModel(8), new SubModel(9), new SubModel(10), new SubModel(11), new SubModel(12), new SubModel(13)]);

class Model extends Record {
    constructor(SubMdl, DrawerOpen) {
        super();
        this.SubMdl = SubMdl;
        this.DrawerOpen = DrawerOpen;
    }
}

function Model$reflection() {
    return record_type("Administration.Model", [], Model, () => [["SubMdl", SubModel$reflection()], ["DrawerOpen", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GotoView", "ToggleDrawer"];
    }
}

function Message$reflection() {
    return union_type("Administration.Message", [], Message, () => [[["Item", SubModel$reflection()]], []]);
}

function mapModelToTitle(m) {
    switch (m.tag) {
        case 1: {
            return "Ansvarsretter";
        }
        case 2: {
            return "Breeams";
        }
        case 3: {
            return "Byggherrer";
        }
        case 4: {
            return "Utdanningsnivå";
        }
        case 5: {
            return "Energiklasser";
        }
        case 6: {
            return "Hms-Kontroll";
        }
        case 7: {
            return "Hms-Type";
        }
        case 8: {
            return "Samspill";
        }
        case 9: {
            return "Prosesser";
        }
        case 10: {
            return "Byggningskategori";
        }
        case 11: {
            return "Roller";
        }
        case 13: {
            return "Avfallskontroll";
        }
        case 12: {
            return "Avfallstyper";
        }
        default: {
            return "Brukerrettigheter";
        }
    }
}

function getSelectedTab() {
    const matchValue = Tab_GetSelectedTab();
    if (matchValue.tag === 1) {
        return new SubModel(9);
    }
    else {
        const matchValue_1 = matchValue.fields[0].toLocaleLowerCase();
        switch (matchValue_1) {
            case "brukerrettigheter": {
                return new SubModel(0);
            }
            case "ansvarsretter": {
                return new SubModel(1);
            }
            case "breeams": {
                return new SubModel(2);
            }
            case "byggherrer": {
                return new SubModel(3);
            }
            case "utdanningsnivå": {
                return new SubModel(4);
            }
            case "energiklasser": {
                return new SubModel(5);
            }
            case "hms-kontroll": {
                return new SubModel(6);
            }
            case "hms-type": {
                return new SubModel(7);
            }
            case "samspill": {
                return new SubModel(8);
            }
            case "prosesser": {
                return new SubModel(9);
            }
            case "byggningskategori": {
                return new SubModel(10);
            }
            case "roller": {
                return new SubModel(11);
            }
            case "avfallskontroll": {
                return new SubModel(13);
            }
            case "avfallstyper": {
                return new SubModel(12);
            }
            default: {
                return new SubModel(9);
            }
        }
    }
}

function init(page) {
    const pageModel = (page.tag === 4) ? (new SubModel(0)) : ((page.tag === 28) ? (new SubModel(1)) : ((page.tag === 26) ? (new SubModel(2)) : ((page.tag === 25) ? (new SubModel(3)) : ((page.tag === 35) ? (new SubModel(4)) : ((page.tag === 27) ? (new SubModel(5)) : ((page.tag === 10) ? (new SubModel(6)) : ((page.tag === 11) ? (new SubModel(7)) : ((page.tag === 18) ? (new SubModel(8)) : ((page.tag === 22) ? (new SubModel(9)) : ((page.tag === 29) ? (new SubModel(10)) : ((page.tag === 23) ? (new SubModel(11)) : ((page.tag === 12) ? (new SubModel(13)) : ((page.tag === 14) ? (new SubModel(12)) : getSelectedTab())))))))))))));
    Tab_SaveSelectedTab_Z721C83C5(mapModelToTitle(pageModel));
    return new Model(pageModel, true);
}

function update(model, msg) {
    if (msg.tag === 1) {
        return new Model(model.SubMdl, !model.DrawerOpen);
    }
    else {
        const x = msg.fields[0];
        Tab_SaveSelectedTab_Z721C83C5(mapModelToTitle(x));
        return new Model(x, model.DrawerOpen);
    }
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["top", 52]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(200)], ["marginLeft", 200]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 200], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 200], ["top", 52]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => append(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    const content = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["marginLeft", op_UnaryNegation_Int32(200)], ["backgroundColor", "#fff"]]));
    const contentShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", toText(printf("calc(100%% - %ipx)"))(200)]]));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        closeButton: StyleCreator$1__create_4EAC9E1E(styles, singleton(["\u0026:hover", ["backgroundColor", "#ffffff"]])),
        content: content,
        contentShift: contentShift,
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    let matchValue;
    const s = useStyles();
    const wrapInPaper = (child) => MuiHelpers_createElement(Paper, [["elevation", 4], ["style", {
        overflow: "auto",
        padding: 2 + "vw",
        maxWidth: 1000,
        width: 90 + "%",
    }], ["children", Interop_reactApi.Children.toArray([child])]]);
    const wrapInCenterDiv = (child_1) => createElement("main", {
        style: {
            marginTop: 3 + "rem",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },
        children: child_1,
    });
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", model.DrawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["edge", "start"], ["onClick", (_arg2) => {
            dispatch(new Message(1));
        }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]]), MuiHelpers_createElement(Typography, [["children", "Administrasjon"]])])]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", model.DrawerOpen], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                dispatch(new Message(1));
            })]),
        }), MuiHelpers_createElement(Divider, []), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([toArray(map((x) => {
            const isActive = equals(model.SubMdl, x);
            const title = mapModelToTitle(x);
            return MuiHelpers_createElement(ListItem, [["button", true], ["dense", false], ["selected", isActive], ["key", title], ["onClick", (_arg1) => {
                dispatch(new Message(0, x));
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
        }, getAllSubModels))])]])])]]), createElement("main", {
            className: model.DrawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: s.drawerHeader,
            }), createElement("div", {
                style: {
                    alignContent: "center",
                    overflowX: "auto",
                    maxWidth: 100 + "vw",
                    paddingBottom: 1 + "rem",
                },
                children: Interop_reactApi.Children.toArray([(matchValue = model.SubMdl, (matchValue.tag === 1) ? actionClass() : ((matchValue.tag === 2) ? breeams() : ((matchValue.tag === 9) ? wrapInCenterDiv(wrapInPaper(processTree())) : ((matchValue.tag === 3) ? builders() : ((matchValue.tag === 5) ? energyclasss() : ((matchValue.tag === 10) ? projectOutcomeType() : ((matchValue.tag === 11) ? roles() : ((matchValue.tag === 6) ? wrapInCenterDiv(wrapInPaper(hmsControl())) : ((matchValue.tag === 8) ? interaction() : ((matchValue.tag === 4) ? educations() : ((matchValue.tag === 7) ? hmsTypes() : ((matchValue.tag === 12) ? createElement(WasteTypes, null) : ((matchValue.tag === 13) ? wrapInCenterDiv(wrapInPaper(wasteControl())) : createElement(AccessControl, null))))))))))))))]),
            })]),
        })]),
    });
}

export function AdminView(props) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.page));
    return view(patternInput[0], patternInput[1]);
}

