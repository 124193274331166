import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton } from "../.fable/fable-library.3.2.4/List.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { ProcessActivityDoc$reflection, Helpers_ResultMessage$reflection, ProcessActivity$reflection, ProcessActivity } from "../Shared/ApiDataTypes.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorSmall, snackbarError } from "../ViewHelpers.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryGet_5760677E, Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { array_type, record_type, lambda_type, unit_type, int32_type, obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Record } from "../.fable/fable-library.3.2.4/Types.js";
import { TreeEntryProps as TreeEntryProps_1, drawDocumentTreeEntry, documentForm } from "./ProcessActivityDoc.js";
import { map, append as append_1 } from "../.fable/fable-library.3.2.4/Array.js";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        tf: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["margin", 10])),
    };
    return Object.assign({}, o);
});

export const activityForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(new ProcessActivity(-1, props.processId, "", "", "", 1, [], [], 0));
    const updateForm = patternInput[1];
    const activityForm_1 = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrorMsg = patternInput_4[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(snackbarError(patternInput_4[0], () => {
            setErrorMsg(void 0);
        })), delay(() => ((result == null) ? append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
            updateForm(new ProcessActivity(activityForm_1.Id, activityForm_1.ProcessId, activityForm_1.Name, activityForm_1.Desc, Browser_Types_Event__Event_get_Value(e), activityForm_1.RowPos, activityForm_1.Docs, activityForm_1.Links, activityForm_1.NumDocs));
        }], ["label", "Aktivitetkode/prefix (valgfritt)"], ["defaultValue", activityForm_1.RowPrefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
            const x_3 = Browser_Types_Event__Event_get_Value(e_1);
            updateForm(new ProcessActivity(activityForm_1.Id, activityForm_1.ProcessId, x_3, activityForm_1.Desc, activityForm_1.RowPrefix, activityForm_1.RowPos, activityForm_1.Docs, activityForm_1.Links, activityForm_1.NumDocs));
            setValidName(x_3.length > 0);
        }]), delay(() => append(singleton_1(["label", "Aktivitetnavn"]), delay(() => append(singleton_1(["defaultValue", activityForm_1.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Aktivitetnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append((activityForm_1.RowPrefix.length > 0) ? singleton_1(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                marginLeft: 10,
                fontStyle: "italic",
            },
            children: toText(printf("%s - %s"))(activityForm_1.RowPrefix)(activityForm_1.Name),
        })) : empty(), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
            updateForm(new ProcessActivity(activityForm_1.Id, activityForm_1.ProcessId, activityForm_1.Name, Browser_Types_Event__Event_get_Value(e_2), activityForm_1.RowPrefix, activityForm_1.RowPos, activityForm_1.Docs, activityForm_1.Links, activityForm_1.NumDocs));
        }], ["label", "Beskrivelse"], ["defaultValue", activityForm_1.Desc], ["variant", "outlined"]])), delay(() => singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
            const x_5 = Browser_Types_Event__Event_get_Value(e_3);
            try {
                const asInt = parse(x_5, 511, false, 32) | 0;
                updateForm(new ProcessActivity(activityForm_1.Id, activityForm_1.ProcessId, activityForm_1.Name, activityForm_1.Desc, activityForm_1.RowPrefix, asInt, activityForm_1.Docs, activityForm_1.Links, activityForm_1.NumDocs));
                setValidRowPos(asInt > 0);
            }
            catch (matchValue) {
                setValidRowPos(false);
            }
        }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", activityForm_1.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
            style: {
                textAlign: "center",
                maxWidth: 80,
            },
        }]), delay(() => ((!patternInput_2[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty()))))))))))))))))))))))))))) : singleton_1(createElement("span", {
            children: ["Aktivitet lagt til"],
        }))))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg3) => {
        props.onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Legg til"], ["onClick", (_arg4) => {
        if (activityForm_1.Name.length > 0) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProcessActivity$reflection,
                })(activityForm_1);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProcessActivity$reflection,
                });
                return Fetch_tryPost_5760677E("/api/processactivity", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ProcessActivity$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    patternInput_3[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setErrorMsg("Noe gikk galt. Kunne ikke legge til aktivitet");
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
        else {
            setValidName(false);
        }
    }]])) : empty())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "activityForm", "/builds/serit/score/score/src/Client/Administration/ProcessActivity.fs", 2553);

const deleteForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput_1[0];
    const setError = useFeliz_React__React_useState_Static_1505(void 0)[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett aktivitet"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette denne aktiviteten?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle tilhørende dokumenter",
        })))) : singleton_1(createElement("span", {
            children: ["Aktivitet slettet"],
        }))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        props.onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => int32_type,
            })(props.activityId);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryDelete_5760677E("/api/processactivity", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            if (res.tag === 0) {
                const x = res.fields[0];
                if (x.Result === "success") {
                    patternInput_1[1](true);
                    return Promise.resolve();
                }
                else {
                    setError(x.Message);
                    return Promise.resolve();
                }
            }
            else {
                setError("Noe gikk galt. Kunne ikke slette aktivitet");
                return Promise.resolve();
            }
        })))));
        pr.then();
    }]])) : empty())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "deleteForm", "/builds/serit/score/score/src/Client/Administration/ProcessActivity.fs", 2704);

export class TreeEntryProps extends Record {
    constructor(x, onDelete) {
        super();
        this.x = x;
        this.onDelete = onDelete;
    }
}

export function TreeEntryProps$reflection() {
    return record_type("ProcessActivity.TreeEntryProps", [], TreeEntryProps, () => [["x", ProcessActivity$reflection()], ["onDelete", lambda_type(unit_type, unit_type)]]);
}

export function drawActivityTreeEntry() {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useFeliz_React__React_useState_Static_1505(false);
        const setOpen = patternInput[1];
        const isOpen = patternInput[0];
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
        const setMenuOpen = patternInput_1[1];
        const isMenuOpen = patternInput_1[0];
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
        const setDocuments = patternInput_2[1];
        const documents = patternInput_2[0];
        const anchor = useReact_useRef_1505(void 0);
        const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
        const showAddForm = patternInput_3[0];
        const setShowAddForm = patternInput_3[1];
        const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
        const showDeleteForm = patternInput_4[0];
        const setShowDeleteForm = patternInput_4[1];
        const fetchDocuments = () => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const requestPath = toText(printf("/api/processactivitydoc/ofactivity/%i"))(props.x.Id);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: () => array_type(ProcessActivityDoc$reflection()),
                    });
                    return Fetch_tryGet_5760677E(requestPath, void 0, empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: () => array_type(ProcessActivityDoc$reflection()),
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 0) {
                        setDocuments(res.fields[0]);
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                }));
            }));
            pr.then();
        };
        return createElement("div", {
            style: {
                marginTop: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let d_1;
                const hasDocuments = ((documents != null) ? ((documents.length > 0) ? ((d_1 = documents, true)) : false) : false) ? true : (props.x.NumDocs > 0);
                return append(showAddForm ? singleton_1(documentForm({
                    activityId: props.x.Id,
                    onClose: (x_1) => {
                        if (x_1 != null) {
                            const d_2 = x_1;
                            setDocuments((documents != null) ? append_1([d_2], documents) : [d_2]);
                        }
                        setShowAddForm(false);
                    },
                })) : empty(), delay(() => append(showDeleteForm ? singleton_1(deleteForm({
                    activityId: props.x.Id,
                    onClose: (didDelete) => {
                        if (didDelete) {
                            setDocuments([]);
                            props.onDelete();
                        }
                        setShowDeleteForm(false);
                    },
                })) : empty(), delay(() => append(singleton_1(createElement("div", {
                    style: createObj(toList(delay(() => append(singleton_1(["cursor", "pointer"]), delay(() => append(singleton_1(["display", "flex"]), delay(() => append(singleton_1(["alignItems", "center"]), delay(() => ((!hasDocuments) ? singleton_1(["paddingLeft", 24]) : empty())))))))))),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        onClick: (_arg1_1) => {
                            if (((!isOpen) ? (documents == null) : false) ? (props.x.NumDocs > 0) : false) {
                                fetchDocuments();
                            }
                            setOpen(!isOpen);
                        },
                        style: {
                            display: "flex",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (hasDocuments ? (isOpen ? singleton_1(react.createElement(ExpandMore, {})) : singleton_1(react.createElement(ChevronRight, {}))) : empty()))))),
                    }), createElement("span", {
                        onClick: (_arg2) => {
                            if (((!isOpen) ? (documents == null) : false) ? (props.x.NumDocs > 0) : false) {
                                fetchDocuments();
                            }
                            setOpen(!isOpen);
                        },
                        style: {
                            color: "#1E90FF",
                        },
                        children: (props.x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(props.x.RowPrefix)(props.x.Name) : props.x.Name,
                    }), MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                        marginLeft: 20,
                    }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg3) => {
                        setMenuOpen(!isMenuOpen);
                    }]]), MuiHelpers_createElement(Menu, [["open", isMenuOpen], ["onClose", (_arg16, v) => {
                        setMenuOpen(false);
                    }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Legg til dokument"], ["onClick", (_arg5) => {
                        setShowAddForm(!showAddForm);
                        setMenuOpen(false);
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett aktivitet"], ["onClick", (_arg6) => {
                        setShowDeleteForm(!showDeleteForm);
                        setMenuOpen(false);
                    }]])])]])]),
                })), delay(() => singleton_1(createElement("div", {
                    style: {
                        marginLeft: 12,
                        borderLeft: (((1 + "px ") + "dashed") + " ") + "#aaa",
                        paddingLeft: 10,
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        if (!isOpen) {
                            return empty();
                        }
                        else {
                            return hasDocuments ? ((documents != null) ? singleton_1(map((y) => drawDocumentTreeEntry()(new TreeEntryProps_1(y, () => {
                                setDocuments((documents != null) ? documents.filter((d_4) => (d_4.Id !== y.Id)) : (void 0));
                            })), documents)) : singleton_1(loadingIndicatorSmall())) : empty();
                        }
                    })))),
                }))))))));
            })))),
        });
    }, void 0, uncurry(2, void 0), void 0, "drawActivityTreeEntry", "/builds/serit/score/score/src/Client/Administration/ProcessActivity.fs", 2783);
}

