import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, map, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { toConsole, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { empty, item as item_1, length } from "../.fable/fable-library.3.2.4/List.js";
import { toString } from "../.fable/fable-library.3.2.4/Types.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { UserPictureForm$reflection, UserPictureForm } from "../Shared/ApiDataTypes.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";

export function ProfilePicture(props) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("img", {
            style: {
                width: 100 + "%",
                height: 100 + "%",
                borderRadius: 10 + "px",
                maxWidth: 300,
                ["object-fit"]: "cover",
            },
            src: toText(printf("/api/user/profilepicture/%i"))(props.uid),
        })), delay(() => (props.canEdit ? singleton(createElement("label", {
            style: {
                marginTop: "auto",
                marginBottom: "auto",
                cursor: "pointer",
                display: "block",
            },
            children: Interop_reactApi.Children.toArray(["Endre profilbilde", createElement("input", {
                type: "file",
                accept: "image/png, image/jpeg",
                onChange: (ev_1) => {
                    const fileList = ev_1.target.files;
                    if (!(fileList == null)) {
                        const ev = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                        if (length(ev) > 0) {
                            const file = item_1(0, ev);
                            const reader = new FileReader();
                            reader.onload = ((_arg1_1) => {
                                let pr;
                                const tupledArg = [file.name, toString(reader.result)];
                                pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                    const uploadForm = new UserPictureForm(props.uid, tupledArg[1], tupledArg[0]);
                                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                            ResolveType: UserPictureForm$reflection,
                                        })(uploadForm);
                                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                            ResolveType: UserPictureForm$reflection,
                                        });
                                        return Fetch_tryPost_5760677E("/api/user/uploadpicture", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                            ResolveType: UserPictureForm$reflection,
                                        }, {
                                            ResolveType: () => obj_type,
                                        });
                                    })).then(((_arg1) => {
                                        const res = _arg1;
                                        if (res.tag === 1) {
                                            toConsole(printf("error on post: exn: %A"))(res.fields[0]);
                                            return Promise.resolve();
                                        }
                                        else {
                                            window.location.reload();
                                            return Promise.resolve();
                                        }
                                    }));
                                }));
                                pr.then();
                            });
                            reader.readAsDataURL(file);
                        }
                    }
                },
                style: {
                    display: "none",
                },
            })]),
        })) : empty_1()))))))),
    });
}

