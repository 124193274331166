import { Union } from "../fable-library.3.2.4/Types.js";
import { fullName, union_type, string_type, class_type } from "../fable-library.3.2.4/Reflection.js";
import { keyValueList } from "../fable-library.3.2.4/MapUtil.js";
import { Types_RequestProperties, Types_HttpRequestHeaders } from "../Fable.Fetch.2.1.0/Fetch.fs.js";
import { ofArray, empty, append, cons } from "../fable-library.3.2.4/List.js";
import { Auto_generateEncoderCached_Z127D9D79, toString } from "../Thoth.Json.4.0.0/Encode.fs.js";
import { value as value_2, map, defaultArg } from "../fable-library.3.2.4/Option.js";
import { FSharpResult$2 } from "../fable-library.3.2.4/Choice.js";
import { int32ToString, uncurry, curry } from "../fable-library.3.2.4/Util.js";
import { fromString, Auto_generateDecoderCached_7848D058 } from "../Thoth.Json.4.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../Fable.Promise.2.1.0/PromiseImpl.fs.js";

export class FetchError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PreparingRequestFailed", "DecodingFailed", "FetchFailed", "NetworkError"];
    }
}

export function FetchError$reflection() {
    return union_type("Thoth.Fetch.FetchError", [], FetchError, () => [[["Item", class_type("System.Exception")]], [["Item", string_type]], [["Item", class_type("Fetch.Types.Response")]], [["Item", class_type("System.Exception")]]]);
}

export function Helper_fetch(url, init) {
    return fetch(url, keyValueList(init, 1));
}

export function Helper_withContentTypeJson(data, headers) {
    if (data != null) {
        return cons(new Types_HttpRequestHeaders(11, "application/json"), headers);
    }
    else {
        return headers;
    }
}

export function Helper_encode(data, caseStrategy, extra, dataResolver) {
    return toString(0, Auto_generateEncoderCached_Z127D9D79(caseStrategy, extra, void 0, dataResolver)(data));
}

export function Helper_withBody(data, caseStrategy, extra, dataResolver, properties) {
    return defaultArg(map((data_1) => cons(new Types_RequestProperties(2, Helper_encode(data_1, caseStrategy, extra, dataResolver)), properties), data), properties);
}

export function Helper_withProperties(custom, properties) {
    return defaultArg(map((list2) => append(properties, list2), custom), properties);
}

export function Helper_eitherUnit(responseResolver, cont) {
    if (fullName(responseResolver.ResolveType()) === "Microsoft.FSharp.Core.Unit") {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return cont();
    }
}

export function Helper_resolve(response, caseStrategy, extra, decoder, responseResolver) {
    const decoder_1 = defaultArg(curry(2, decoder), Auto_generateDecoderCached_7848D058(caseStrategy, extra, responseResolver));
    let eitherUnitOr;
    const responseResolver_1 = value_2(responseResolver);
    eitherUnitOr = ((cont) => Helper_eitherUnit(responseResolver_1, cont));
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (((response.ok) ? PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (response.text().then(((_arg1) => (Promise.resolve(eitherUnitOr(() => {
        const matchValue = fromString(uncurry(2, decoder_1), _arg1);
        return (matchValue.tag === 1) ? (new FSharpResult$2(1, new FetchError(1, matchValue.fields[0]))) : (new FSharpResult$2(0, matchValue.fields[0]));
    })))))))) : (Promise.resolve((new FSharpResult$2(1, new FetchError(2, response)))))).then(((_arg2) => (Promise.resolve(_arg2)))))));
}

export function Helper_message(error) {
    switch (error.tag) {
        case 1: {
            return "[Thoth.Fetch] Error while decoding the response:\n\n" + error.fields[0];
        }
        case 2: {
            const response = error.fields[0];
            return (((("[Thoth.Fetch] Request failed:\n\n" + int32ToString(response.status)) + " ") + (response.statusText)) + " for URL ") + (response.url);
        }
        case 3: {
            return "[Thoth.Fetch] A network error occured:\n\n" + error.fields[0].message;
        }
        default: {
            return "[Thoth.Fetch] Request preparation failed:\n\n" + error.fields[0].message;
        }
    }
}

export class Fetch {
    constructor() {
    }
}

export function Fetch$reflection() {
    return class_type("Thoth.Fetch.Fetch", void 0, Fetch);
}

export function Fetch_tryFetchAs_25B10BBE(url, decoder, data, httpMethod, properties, headers, caseStrategy, extra, responseResolver, dataResolver) {
    let headers_2;
    try {
        const properties_3 = Helper_withProperties(properties, Helper_withBody(data, caseStrategy, extra, dataResolver, ofArray([new Types_RequestProperties(0, defaultArg(httpMethod, "GET")), (headers_2 = Helper_withContentTypeJson(data, defaultArg(headers, empty())), new Types_RequestProperties(1, keyValueList(headers_2, 0)))])));
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Helper_fetch(url, properties_3).then(((_arg1) => (Helper_resolve(_arg1, caseStrategy, extra, decoder, responseResolver)))))));
        return pr.then(void 0, ((arg) => (new FSharpResult$2(1, new FetchError(3, arg)))));
    }
    catch (exn) {
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Promise.resolve((new FSharpResult$2(1, new FetchError(0, exn)))))));
    }
}

export function Fetch_fetchAs_25B10BBE(url, decoder, data, httpMethod, properties, headers, caseStrategy, extra, responseResolver, dataResolver) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Fetch_tryFetchAs_25B10BBE(url, decoder, data, httpMethod, properties, headers, caseStrategy, extra, responseResolver, dataResolver).then(((_arg2) => {
        const result = _arg2;
        let response_1;
        if (result.tag === 1) {
            throw (new Error(Helper_message(result.fields[0])));
        }
        else {
            response_1 = result.fields[0];
        }
        return Promise.resolve(response_1);
    })))));
}

export function Fetch_get_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_fetchAs_25B10BBE(url, decoder, data, void 0, properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_tryGet_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_tryFetchAs_25B10BBE(url, decoder, data, void 0, properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_post_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_fetchAs_25B10BBE(url, decoder, data, "POST", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_tryPost_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_tryFetchAs_25B10BBE(url, decoder, data, "POST", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_put_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_fetchAs_25B10BBE(url, decoder, data, "PUT", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_tryPut_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_tryFetchAs_25B10BBE(url, decoder, data, "PUT", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_patch_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_fetchAs_25B10BBE(url, decoder, data, "PATCH", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_tryPatch_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_tryFetchAs_25B10BBE(url, decoder, data, "PATCH", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_delete_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_fetchAs_25B10BBE(url, decoder, data, "DELETE", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

export function Fetch_tryDelete_5760677E(url, data, properties, headers, caseStrategy, extra, decoder, responseResolver, dataResolver) {
    return Fetch_tryFetchAs_25B10BBE(url, decoder, data, "DELETE", properties, headers, caseStrategy, extra, responseResolver, dataResolver);
}

