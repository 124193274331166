import { Record } from "./.fable/fable-library.3.2.4/Types.js";
import { record_type, string_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import * as browser from "@sentry/browser";

export class Dsn extends Record {
    constructor(dsn) {
        super();
        this.dsn = dsn;
    }
}

export function Dsn$reflection() {
    return record_type("Sentry.Dsn", [], Dsn, () => [["dsn", string_type]]);
}

export function init() {
    browser.init(new Dsn(sentryUrl));
}

