import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, bool_type, string_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ProjectPermission as ProjectPermission_1, Helpers_ResultMessage$reflection, ProjectPermission$reflection, ProjectRole$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { ProjectList, Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { hasPermission } from "../LoginState.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import { collect, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import MenuItem from "@material-ui/core/MenuItem";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Delete from "@material-ui/icons/Delete";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { errorBanner, loadingIndicatorMedium } from "../ViewHelpers.js";

class Model extends Record {
    constructor(ProjectId, ProjectRoles, Permissions, AssignForm, RevokeForm, ErrorMsg, CanEdit) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectRoles = ProjectRoles;
        this.Permissions = Permissions;
        this.AssignForm = AssignForm;
        this.RevokeForm = RevokeForm;
        this.ErrorMsg = ErrorMsg;
        this.CanEdit = CanEdit;
    }
}

function Model$reflection() {
    return record_type("ProjectPermission.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectRoles", option_type(array_type(ProjectRole$reflection()))], ["Permissions", option_type(array_type(ProjectPermission$reflection()))], ["AssignForm", option_type(ProjectPermission$reflection())], ["RevokeForm", option_type(ProjectPermission$reflection())], ["ErrorMsg", option_type(string_type)], ["CanEdit", bool_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectRolesResponse", "PermissionResponse", "UserPermissionsResponse", "Assign", "AssignResponse", "Revoke", "RevokeResponse", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectPermission.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectRole$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectRole$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ProjectPermission$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ProjectPermission$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ProjectPermission$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ProjectPermission$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], []]);
}

function init(projId) {
    return new Model(projId, void 0, void 0, void 0, void 0, void 0, false);
}

export function hasProjectPermission(funct, perms) {
    const functId = Functionality__ToInt(funct) | 0;
    if (perms.some((y) => (functId === y))) {
        return true;
    }
    else {
        return hasPermission(new Functionality(54));
    }
}

function update(model, msg) {
    let matchValue_2, matchValue_3, p, revokeId, matchValue_4;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, "Noe gikk galt. Kunne ikke laste prosjektrettigheter.", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, model.ProjectRoles, res_1.fields[0], model.AssignForm, model.RevokeForm, model.ErrorMsg, model.CanEdit);
            }
        }
        case 3: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, msg.fields[0], model.RevokeForm, model.ErrorMsg, model.CanEdit);
        }
        case 4: {
            const res_2 = msg.fields[0];
            const matchValue = model.AssignForm;
            if (matchValue != null) {
                const af = matchValue;
                if (res_2.tag === 1) {
                    return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, "Noe gikk galt. Kunne ikke tildele prosjektrettighet til rolle.", model.CanEdit);
                }
                else {
                    const x_3 = res_2.fields[0];
                    if (x_3.Result === "success") {
                        const newEntry = new ProjectPermission_1((() => {
                            try {
                                return parse(x_3.Message, 511, false, 32) | 0;
                            }
                            catch (matchValue_1) {
                                return -1;
                            }
                        })(), af.ProjectRoleId, af.PermissionId);
                        return new Model(model.ProjectId, model.ProjectRoles, (matchValue_2 = model.Permissions, (matchValue_2 != null) ? append([newEntry], matchValue_2) : [newEntry]), void 0, model.RevokeForm, model.ErrorMsg, model.CanEdit);
                    }
                    else {
                        return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, x_3.Message, model.CanEdit);
                    }
                }
            }
            else {
                return model;
            }
        }
        case 5: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, msg.fields[0], model.ErrorMsg, model.CanEdit);
        }
        case 6: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, "Noe gikk galt. Kunne ikke fjerne rettighet for prosjektrolle.", model.CanEdit);
            }
            else {
                const x_5 = res_3.fields[0];
                if (x_5.Result === "success") {
                    return new Model(model.ProjectId, model.ProjectRoles, (matchValue_3 = model.Permissions, (matchValue_3 != null) ? ((p = matchValue_3, (revokeId = (((matchValue_4 = model.RevokeForm, (matchValue_4 == null) ? (() => {
                        try {
                            return parse(x_5.Message, 511, false, 32) | 0;
                        }
                        catch (matchValue_5) {
                            return -1;
                        }
                    })() : matchValue_4.Id)) | 0), p.filter((e) => (e.Id !== revokeId))))) : (void 0)), model.AssignForm, model.RevokeForm, model.ErrorMsg, model.CanEdit);
                }
                else {
                    return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, x_5.Message, model.CanEdit);
                }
            }
        }
        case 2: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, "Noe gikk galt. Kunne ikke laste brukerrettigheter.", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, model.ErrorMsg, hasProjectPermission(new Functionality(9), res_4.fields[0]) ? true : hasPermission(new Functionality(54)));
            }
        }
        case 7: {
            return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, void 0, model.CanEdit);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProjectId, model.ProjectRoles, model.Permissions, model.AssignForm, model.RevokeForm, "Noe gikk galt. Kunne ikke laste prosjektroller", model.CanEdit);
            }
            else {
                return new Model(model.ProjectId, res.fields[0], model.Permissions, model.AssignForm, model.RevokeForm, model.ErrorMsg, model.CanEdit);
            }
        }
    }
}

function fetchProjectRoles(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/project/roles/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectRole$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectRole$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchUserPermissions(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchPermissions(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/permissions/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ProjectPermission$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ProjectPermission$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryAssign(x, projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(3, x));
        const requestPath = toText(printf("/api/permission/project/%i/assign"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectPermission$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function tryRevoke(x, projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5, x));
        const requestPath = toText(printf("/api/permission/project/%i/revoke"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectPermission$reflection,
            })(x);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(6, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function assignForm(dispatch$0027, projId, perm, roles) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const anchor = useReact_useRef_1505(void 0);
        const patternInput = useFeliz_React__React_useState_Static_1505(false);
        const setOpen = patternInput[1];
        const isOpen = patternInput[0];
        const menuOptions = map((x) => MuiHelpers_createElement(MenuItem, [["children", x.Role.title], ["onClick", (_arg1) => {
            tryAssign(new ProjectPermission_1(-1, x.Id, Functionality__ToInt(props.permission)), props.projectId, props.dispatch);
            setOpen(false);
        }]]), props.projRoles);
        const children_2 = toList(delay(() => ((menuOptions.length > 0) ? append_1(singleton(MuiHelpers_createElement(IconButton, [["aria-label", "Legg til"], ["ref", anchor], ["onClick", (_arg2) => {
            setOpen(!isOpen);
        }], ["size", "small"], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Add, {})])]])]])), delay(() => singleton(MuiHelpers_createElement(Menu, [["open", isOpen], ["onClose", (_arg16, v) => {
            setOpen(false);
        }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray(Array.from(menuOptions))]])))) : empty_1())));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        });
    }, void 0, uncurry(2, void 0), void 0, "assignForm", "/builds/serit/score/score/src/Client/Project/ProjectPermission.fs", 205)({
        dispatch: dispatch$0027,
        permission: perm,
        projRoles: roles,
        projectId: projId,
    });
}

function drawPermissions(model, dispatch) {
    let children_2, children, children_12;
    const matchValue = [model.ProjectRoles, model.Permissions];
    let pattern_matching_result, x, y;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            x = matchValue[0];
            y = matchValue[1];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props_18 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton_1((children = ofArray([createElement("th", {
                style: {
                    width: 40 + "%",
                },
                children: "Funksjonalitet",
            }), createElement("th", {
                style: {
                    width: "auto",
                },
                children: "Rolle(r)",
            }), createElement("th", {
                style: {
                    width: 5 + "%",
                },
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_12 = singleton_1(map((p) => {
                let value_17, children_6, children_8;
                const e = collect((z_1) => map((r_1) => [z_1, r_1], x.filter((r) => (r.Id === z_1.ProjectRoleId))), y.filter((z) => (z.PermissionId === Functionality__ToInt(p))));
                const children_10 = ofArray([(value_17 = toString(p), createElement("td", {
                    children: [value_17],
                })), (children_6 = singleton_1(MuiHelpers_createElement(List, [["dense", true], ["style", {
                    maxWidth: 200,
                }], ["children", Interop_reactApi.Children.toArray([map((tupledArg) => {
                    const r_2 = tupledArg[1];
                    return MuiHelpers_createElement(ListItem, [["key", r_2.Id], ["dense", true], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(MuiHelpers_createElement(ListItemIcon, [["style", {
                        minWidth: 25,
                        width: 25,
                        display: "flex",
                        justifyContent: "center",
                    }], ["children", createElement("i", {
                        className: "fas fa-user",
                    })]])), delay(() => append_1(singleton(MuiHelpers_createElement(ListItemText, [["primary", r_2.Role.title]])), delay(() => (model.CanEdit ? singleton(MuiHelpers_createElement(ListItemSecondaryAction, [["children", MuiHelpers_createElement(IconButton, [["style", {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }], ["aria-label", "Fjern"], ["onClick", (_arg1) => {
                        tryRevoke(tupledArg[0], model.ProjectId, dispatch);
                    }], ["size", "small"], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Delete, {})])]])]])]])) : empty_1())))))))))]]);
                }, e)])]])), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                })), (children_8 = toList(delay(() => {
                    if (model.CanEdit) {
                        const selectable = x.filter((r_3) => (!y.some((n) => {
                            if (n.ProjectRoleId === r_3.Id) {
                                return n.PermissionId === Functionality__ToInt(p);
                            }
                            else {
                                return false;
                            }
                        })));
                        return (selectable.length > 0) ? singleton(assignForm(dispatch, model.ProjectId, p, selectable)) : empty_1();
                    }
                    else {
                        return empty_1();
                    }
                })), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                }))]);
                return createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                });
            }, ProjectList)), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))])]]);
            return createElement("table", createObj(Helpers_combineClasses("table", props_18)));
        }
        case 1: {
            return createElement("div", {
                style: {
                    position: "fixed",
                    top: 50 + "%",
                    left: 50 + "%",
                },
                children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
            });
        }
    }
}

function view(model, dispatch) {
    const children = toList(delay(() => {
        let matchValue;
        return append_1((matchValue = model.ErrorMsg, (matchValue != null) ? singleton(errorBanner(matchValue, (_arg1) => {
            dispatch(new Message(7));
        })) : ((empty_1()))), delay(() => {
            const matchValue_1 = [model.ProjectRoles, model.Permissions];
            let pattern_matching_result;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return append_1(singleton(createElement("span", {
                        style: {
                            fontSize: 0.8 + "rem",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: "fas fa-info",
                            style: {
                                marginRight: 5 + "px",
                            },
                        }), "Disse rettighetene kan overstyres for administratorer på applikasjonsnivå"]),
                    })), delay(() => singleton(drawPermissions(model, dispatch))));
                }
                case 1: {
                    return singleton(createElement("div", {
                        style: {
                            position: "fixed",
                            top: 50 + "%",
                            left: 50 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                    }));
                }
            }
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function ProjectPermission(projectPermissionInputProps) {
    const projectId = projectPermissionInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjectRoles(projectId, dispatch);
        fetchUserPermissions(projectId, dispatch);
        fetchPermissions(projectId, dispatch);
    });
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                maxWidth: 1000,
                width: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([view(patternInput[0], dispatch)]),
        })]),
    });
}

