import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";

export const Permission_GetPermissionsForUser = toText(printf("/api/permissions/user"));

export const AccessControl_GetPermissionsForGroup = (() => {
    const clo1 = toText(printf("/api/accesscontrol/permissions/%i"));
    return (arg10) => clo1(arg10);
})();

export const Config_GetByName = (() => {
    const clo1 = toText(printf("/api/config/getbyname/%s"));
    return (arg10) => clo1(arg10);
})();

export const Company_GetById = (() => {
    const clo1 = toText(printf("/api/company/%i"));
    return (arg10) => clo1(arg10);
})();

export const Department_ByCompany = (() => {
    const clo1 = toText(printf("/api/department/company/%i"));
    return (arg10) => clo1(arg10);
})();

export const Office_ByCompany = (() => {
    const clo1 = toText(printf("/api/office/company/%i"));
    return (arg10) => clo1(arg10);
})();

export const CVPrint_PrintUserCV = "/api/cv/user/print";

export const Project_GetProjectPreloaded = (() => {
    const clo1 = toText(printf("/api/project/getprojectpreloaded/%i"));
    return (arg10) => clo1(arg10);
})();

export const Project_GetProjectsByCompany = (() => {
    const clo1 = toText(printf("/api/project/bycompany/%i"));
    return (arg10) => clo1(arg10);
})();

export const Project_Delete = "/api/project/delete/%i";

export const ProcessActivityDoc_DeleteVersionById = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/deleteversionbyid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivityDoc_DeleteCompVersionById = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/deletecompversionbyid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivityDoc_GetById = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/getbyid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivityDoc_GetByProcessActivityId = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/getbyprocessactivityid/%i"));
    return (arg10) => clo1(arg10);
})();

export function ProcessActivityDoc_GetDocumentsByPaId(id) {
    return toText(printf("/api/processactivitydoc/getdocumentsbypaid/%i"))(id);
}

export function ProcessActivityDoc_GetDocumentByDocId(id) {
    return toText(printf("/api/processactivitydoc/getdocumentbydocid/%i"))(id);
}

export function ProcessActivityDoc_GetVersionsByDocId(id) {
    return toText(printf("/api/processactivitydoc/getversionsbydocid/%i"))(id);
}

export function ProcessActivityDoc_GetCompVersionsByDocId(id) {
    return toText(printf("/api/processactivitydoc/getcompversionsbydocid/%i"))(id);
}

export const ProcessActivityDoc_GetSupervisorByProjectProcessActivityDocId = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/getsupervisorbyprojectprocessactivitydocid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivityDoc_GetSupervisorByProcessActivityDocId = (() => {
    const clo1 = toText(printf("/api/processactivitydoc/getsupervisorbyprocessactivitydocid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivity_GetById = (() => {
    const clo1 = toText(printf("/api/processactivity/getbyid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivity_GetByProcessId = (() => {
    const clo1 = toText(printf("/api/processactivity/getbyprocessid/%i"));
    return (arg10) => clo1(arg10);
})();

export const ProcessActivity_AddDoc = (() => {
    const clo1 = toText(printf("/api/processactivity/adddoc/%i"));
    return (arg10) => clo1(arg10);
})();

export const Process_GetByParentId = (() => {
    const clo1 = toText(printf("/api/process/parentid/%i"));
    return (arg10) => clo1(arg10);
})();

export const FilingKey_GetByParentId = (() => {
    const clo1 = toText(printf("/api/filingkey/parentid/%i"));
    return (arg10) => clo1(arg10);
})();

export function FileStore_GetById(id) {
    return toText(printf("/api/filestore/getbyid/%i"))(id);
}

export function FileStore_AddFileSystemProject(prName) {
    return toText(printf("/api/filestore/filesys/project/%s"))(prName);
}

