import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Helpers_ResultMessage$reflection, Waste_ProjectRegistration, Waste_ProjectRegistrationListing, Waste_ProjectRegistration$reflection, Waste_ProjectRegistrationListing$reflection, Waste_ProjectInfo$reflection, Waste_WasteType$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, string_type, class_type, tuple_type, array_type, int32_type, record_type, bool_type, float64_type, option_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Functionality, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import { sumBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { trySetValue, tryFocus, toUtcString, asMonth, toLastDayOfMonth, toFirstDayOfMonth, fromUtcString, monthsInPeriod } from "../Utils.js";
import { addDays, create, year, month, parse, toString, addMonths, now as now_1 } from "../.fable/fable-library.3.2.4/Date.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { format, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, int32ToString, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { loadingIndicatorCenteredSmall, loadingIndicatorSmall, snackbarError } from "../ViewHelpers.js";
import { parse as parse_1 } from "../.fable/fable-library.3.2.4/Double.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { GrossAreaUnit, GrossAreaUnit__ToInt } from "../Shared/Shared.js";
import { Parent, RegistrationComment } from "../Project/RegistrationComment.js";

class RegistrationForm extends Record {
    constructor(SelectedType, Quantity, DirectQuantity, ValidQuantity, ValidDirectQuantity) {
        super();
        this.SelectedType = SelectedType;
        this.Quantity = Quantity;
        this.DirectQuantity = DirectQuantity;
        this.ValidQuantity = ValidQuantity;
        this.ValidDirectQuantity = ValidDirectQuantity;
    }
}

function RegistrationForm$reflection() {
    return record_type("WasteRegistration.RegistrationForm", [], RegistrationForm, () => [["SelectedType", option_type(Waste_WasteType$reflection())], ["Quantity", float64_type], ["DirectQuantity", float64_type], ["ValidQuantity", bool_type], ["ValidDirectQuantity", bool_type]]);
}

class Model extends Record {
    constructor(ProjectId, ProjectInfo, WasteTypes, ProjectRequired, Registrations, CanReg, Months, SelectedDate, Processing, ErrorMsg, SelectedType) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectInfo = ProjectInfo;
        this.WasteTypes = WasteTypes;
        this.ProjectRequired = ProjectRequired;
        this.Registrations = Registrations;
        this.CanReg = CanReg;
        this.Months = Months;
        this.SelectedDate = SelectedDate;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
        this.SelectedType = SelectedType;
    }
}

function Model$reflection() {
    return record_type("WasteRegistration.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectInfo", option_type(Waste_ProjectInfo$reflection())], ["WasteTypes", option_type(array_type(Waste_WasteType$reflection()))], ["ProjectRequired", option_type(array_type(Waste_WasteType$reflection()))], ["Registrations", option_type(array_type(Waste_ProjectRegistrationListing$reflection()))], ["CanReg", bool_type], ["Months", array_type(tuple_type(int32_type, int32_type))], ["SelectedDate", option_type(class_type("System.DateTime"))], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)], ["SelectedType", option_type(Waste_WasteType$reflection())]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InfoResponse", "TypesResponse", "ProjectRequiredResponse", "EmptyRegistrations", "RegistrationsResponse", "PermissionsResponse", "SelectDate", "UpdateQuantity", "RegisterResponse", "DismissError", "SetErrorMsg", "SetSelected"];
    }
}

function Message$reflection() {
    return union_type("WasteRegistration.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_ProjectInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_ProjectInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WasteType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WasteType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WasteType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WasteType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_ProjectRegistrationListing$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_ProjectRegistrationListing$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(int32_type), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(int32_type)]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.DateTime")]], [["Item1", int32_type], ["Item2", float64_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_ProjectRegistration$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_ProjectRegistration$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", string_type]], [["Item", option_type(Waste_WasteType$reflection())]]]);
}

function init(x) {
    return new Model(x, void 0, void 0, void 0, void 0, false, [], void 0, false, void 0, void 0);
}

function update(model, msg) {
    let x_7, matchValue_1, copyOfStruct;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, "Noe gikk galt. Kunne ikke laste avfallstyper.", model.SelectedType);
            }
            else {
                return new Model(model.ProjectId, model.ProjectInfo, res_1.fields[0], model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, "Noe gikk galt. Kunne ikke laste påkrevde avfallstyper for dette prosjektet.", model.SelectedType);
            }
            else {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, res_2.fields[0], model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
        case 4: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, "Noe gikk galt. Kunne ikke laste registreringer for gitt periode.", model.SelectedType);
            }
            else {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, res_3.fields[0], model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
        case 5: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, res_4.fields[0].some((x_7 = (Functionality__ToInt(new Functionality(12)) | 0), (y_1) => (x_7 === y_1))), model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
        case 6: {
            return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, msg.fields[0], model.Processing, model.ErrorMsg, void 0);
        }
        case 7: {
            const matchValue = model.Registrations;
            if (matchValue == null) {
                return model;
            }
            else {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, map((z) => {
                    if (z.Type.Id === msg.fields[0]) {
                        return new Waste_ProjectRegistrationListing(z.Sum, z.SumDirectlyDelivered, msg.fields[1], z.DirectlyDelivered, z.Registered, z.Type);
                    }
                    else {
                        return z;
                    }
                }, matchValue), model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
        case 3: {
            return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, void 0, model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
        }
        case 8: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, false, "Noe gikk galt. Kunne ikke registrere sorteringsgrad.", model.SelectedType);
            }
            else {
                const x_10 = res_5.fields[0];
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, (matchValue_1 = model.Registrations, (matchValue_1 != null) ? map((r) => {
                    if (r.Type.Id === x_10.Type.Id) {
                        return new Waste_ProjectRegistrationListing(r.Sum, r.SumDirectlyDelivered, x_10.Quantity, x_10.DirectlyDelivered, true, r.Type);
                    }
                    else {
                        return r;
                    }
                }, matchValue_1) : (void 0)), model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, void 0);
            }
        }
        case 9: {
            return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, void 0, model.SelectedType);
        }
        case 10: {
            return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, msg.fields[0], model.SelectedType);
        }
        case 11: {
            return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, model.ErrorMsg, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                return new Model(model.ProjectId, model.ProjectInfo, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, model.Months, model.SelectedDate, model.Processing, (err.tag === 2) ? (((err.fields[0].status) === 404) ? "Kunne ikke laste prosjektinfo. Fant ikke prosjekt." : "Noe gikk galt. Kunne ikke laste prosjektinfo.") : "Noe gikk galt. Kunne ikke laste prosjektinfo.", model.SelectedType);
            }
            else {
                const x = res.fields[0];
                return new Model(model.ProjectId, x, model.WasteTypes, model.ProjectRequired, model.Registrations, model.CanReg, monthsInPeriod(fromUtcString(x.Start), (copyOfStruct = now_1(), addMonths(copyOfStruct, -1))), model.SelectedDate, model.Processing, model.ErrorMsg, model.SelectedType);
            }
        }
    }
}

function fetchProjInfo(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/project/info/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Waste_ProjectInfo$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Waste_ProjectInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchTypes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchProjectRequired(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/waste/types/project/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Waste_WasteType$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Waste_WasteType$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchRegistrations(projId, dte, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(3));
        const periodStart = toFirstDayOfMonth(dte);
        const periodEnd = toLastDayOfMonth(dte);
        const s = toString(periodStart, "yyyy-MM-dd");
        const e = toString(periodEnd, "yyyy-MM-dd");
        const requestPath = toText(printf("/api/waste/project/registrations/%i?start=%s\u0026end=%s"))(projId)(s)(e);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Waste_ProjectRegistrationListing$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Waste_ProjectRegistrationListing$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function getPermissions(projId, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permission/project/user/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(int32_type),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(int32_type),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(5, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function periodSelect(model, dispatch, x) {
    let children_4, children_2, children, matchValue;
    return createElement("table", {
        className: "table",
        style: {
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([(children_4 = singleton((children_2 = ofArray([createElement("td", {
            style: {
                width: 50 + "%",
                padding: 0,
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 45,
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    style: {
                        fontSize: 1.1 + "rem",
                    },
                    children: "Registreringsmåned",
                })]),
            })]),
        }), (children = singleton(createElement("select", {
            className: "form-control",
            value: (matchValue = model.SelectedDate, (matchValue != null) ? toString(matchValue, "yyyy-MM") : "none"),
            onChange: (ev) => {
                const y = ev.target.value;
                try {
                    const asDateTime = parse(y);
                    let adjustedDate;
                    const now = now_1();
                    if ((month(asDateTime) === month(now)) ? (year(asDateTime) === year(now)) : false) {
                        adjustedDate = now;
                    }
                    else {
                        let copyOfStruct_1;
                        let copyOfStruct = create(year(asDateTime), month(asDateTime), 1, 0, 0, 0);
                        copyOfStruct_1 = addMonths(copyOfStruct, 1);
                        adjustedDate = addDays(copyOfStruct_1, -1);
                    }
                    dispatch(new Message(6, adjustedDate));
                    fetchRegistrations(x.Id, adjustedDate, dispatch);
                }
                catch (matchValue_1) {
                }
            },
            children: Interop_reactApi.Children.toArray([createElement("option", {
                hidden: true,
                value: "none",
                children: "Velg periode",
            }), map((tupledArg) => {
                let arg10_2;
                const y_1 = tupledArg[0] | 0;
                const m = tupledArg[1] | 0;
                const m$0027 = (m < 10) ? toText(printf("0%i"))(m) : int32ToString(m);
                return createElement("option", {
                    value: toText(printf("%i-%s"))(y_1)(m$0027),
                    children: (arg10_2 = asMonth(m), toText(printf("%s %i"))(arg10_2)(y_1)),
                });
            }, model.Months)]),
        })), createElement("td", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))]), createElement("tr", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        }))]),
    });
}

function circle(col, txt) {
    return createElement("div", {
        style: {
            marginRight: 5 + "px",
            fontSize: 0.8 + "rem",
        },
        children: Interop_reactApi.Children.toArray([createElement("i", {
            className: "fas fa-circle",
            style: {
                color: col,
                marginRight: 5 + "px",
            },
        }), createElement("span", {
            children: [txt],
        })]),
    });
}

function drawColorCodes() {
    return createElement("div", {
        style: {
            display: "flex",
            flexWrap: "wrap",
        },
        children: Interop_reactApi.Children.toArray([circle("limegreen", "Sortert avfall"), circle("orange", "Usortert avfall"), circle("tomato", "Farlig avfall")]),
    });
}

const regForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const updateQty = patternInput[1];
    const qty = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const updateDirectQty = patternInput_1[1];
    const directQty = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validQty = patternInput_2[0];
    const setValidQty = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(true);
    const validDirect = patternInput_3[0];
    const setValidDirect = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrMsg = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const regStatus = patternInput_5[0];
    useReact_useEffect_Z101E1A95(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let m;
            let copyOfStruct = props.selectedPeriod;
            m = month(copyOfStruct);
            let y;
            let copyOfStruct_1 = props.selectedPeriod;
            y = year(copyOfStruct_1);
            const requestPath_2 = toText(printf("/api/waste/project/registration/status/%i?type=%i\u0026month=%i\u0026year=%i"))(props.projId)(props.wt.Id)(m)(y);
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Waste_ProjectRegistrationListing$reflection,
                });
                return Fetch_tryGet_5760677E(requestPath_2, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                    ResolveType: Waste_ProjectRegistrationListing$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg2) => {
                const res_1 = _arg2;
                if (res_1.tag === 0) {
                    patternInput_5[1](res_1.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setErrMsg("Noe gikk galt. Kunne ikke laste registreringsstatus.");
                    return Promise.resolve();
                }
            }));
        }));
        pr_1.then();
    }, []);
    const col = props.wt.Dangerous ? "tomato" : ((!props.wt.Sorted) ? "orange" : "limegreen");
    return createElement("div", {
        style: {
            marginTop: 25,
            marginBottom: 60,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let arg40_1, copyOfStruct_3, arg30_1, copyOfStruct_2;
            return append(singleton_1(createElement("div", {
                style: {
                    borderLeft: (((8 + "px ") + "solid") + " ") + col,
                    padding: 7,
                    backgroundColor: "#eee",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 45,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    style: {},
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        style: {
                            fontSize: 15 + "px",
                            marginLeft: 8 + "px",
                            color: "#313131",
                        },
                        children: (arg40_1 = (((copyOfStruct_3 = props.selectedPeriod, year(copyOfStruct_3))) | 0), (arg30_1 = asMonth((copyOfStruct_2 = props.selectedPeriod, month(copyOfStruct_2))), toText(printf("Registrer \"%s %s\" for %s %i"))(props.wt.Code)(props.wt.Name)(arg30_1)(arg40_1))),
                    })]),
                }), createElement("div", {
                    style: {
                        display: "flex",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        if (regStatus != null) {
                            return (!regStatus.Registered) ? ((validQty ? validDirect : false) ? singleton_1(createElement("button", {
                                className: "btn btn-info btn-sm",
                                children: "Registrer",
                                onClick: (_arg2_1) => {
                                    const x = new Waste_ProjectRegistration(-1, props.projId, toUtcString(props.selectedPeriod), qty, directQty, props.wt);
                                    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                        const requestPath = toText(printf("/api/waste/project/%i"))(props.projId);
                                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                                ResolveType: Waste_ProjectRegistration$reflection,
                                            })(x);
                                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                                ResolveType: Waste_ProjectRegistration$reflection,
                                            });
                                            return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                                ResolveType: Waste_ProjectRegistration$reflection,
                                            }, {
                                                ResolveType: () => obj_type,
                                            });
                                        })).then(((_arg1) => {
                                            const res = _arg1;
                                            props.dispatch(new Message(8, res));
                                            if (res.tag === 0) {
                                                fetchRegistrations(props.projId, props.selectedPeriod, props.dispatch);
                                                return Promise.resolve();
                                            }
                                            else {
                                                return Promise.resolve();
                                            }
                                        }));
                                    }));
                                    pr.then();
                                },
                            })) : empty_1()) : append(singleton_1(createElement("span", {
                                style: {
                                    fontSize: 14 + "px",
                                    color: "#4dc942",
                                },
                                children: "Registrert",
                            })), delay(() => {
                                let x_2;
                                return (permissions).some((x_2 = (Functionality__ToInt(new Functionality(37)) | 0), (y_1) => (x_2 === y_1))) ? singleton_1(createElement("a", {
                                    href: "/waste/control",
                                    style: {
                                        fontSize: 11 + "px",
                                        marginLeft: 20,
                                    },
                                    children: "Endre denne registreringen",
                                })) : empty_1();
                            }));
                        }
                        else {
                            return empty_1();
                        }
                    })))),
                })]),
            })), delay(() => append(singleton_1(snackbarError(patternInput_4[0], () => {
                setErrMsg(void 0);
            })), delay(() => {
                let children_18, children, children_2, arg20_2, copyOfStruct_5, arg10_3, copyOfStruct_4, children_4, children_6, children_8, children_10, copyOfStruct_10, copyOfStruct_11, children_12, children_14, children_16, copyOfStruct_14, copyOfStruct_15;
                if (regStatus != null) {
                    const rs_1 = regStatus;
                    return singleton_1(createElement("table", {
                        className: "table",
                        style: {
                            width: 100 + "%",
                        },
                        children: Interop_reactApi.Children.toArray([(children_18 = ofArray([createElement("tr", {
                            style: {
                                borderLeft: (((8 + "px ") + "solid") + " ") + col,
                            },
                            children: Interop_reactApi.Children.toArray([createElement("td", {
                                style: {
                                    maxWidth: 150 + "px",
                                },
                            }), (children = singleton(createElement("span", {
                                style: {
                                    fontSize: 14 + "px",
                                    padding: 5,
                                    color: "#000",
                                },
                                children: "Forrige total",
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })), (children_2 = singleton(createElement("span", {
                                style: {
                                    fontSize: 14 + "px",
                                    padding: 5,
                                    color: "#000",
                                },
                                children: (arg20_2 = (((copyOfStruct_5 = props.selectedPeriod, year(copyOfStruct_5))) | 0), (arg10_3 = asMonth((copyOfStruct_4 = props.selectedPeriod, month(copyOfStruct_4))), toText(printf("%s %i"))(arg10_3)(arg20_2))),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                            })), (children_4 = singleton(createElement("span", {
                                style: {
                                    fontSize: 14 + "px",
                                    padding: 5,
                                    color: "#000",
                                },
                                children: "Ny total",
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                            }))]),
                        }), createElement("tr", {
                            style: {
                                borderLeft: (((8 + "px ") + "solid") + " ") + col,
                            },
                            children: Interop_reactApi.Children.toArray([createElement("td", {
                                style: {
                                    maxWidth: 150 + "px",
                                    textAlign: "center",
                                    fontSize: 0.8 + "rem",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: ["Mengde levert til godkjent avfallsanlegg"],
                                })]),
                            }), (children_6 = singleton(createElement("input", {
                                disabled: true,
                                className: "form-control form-control-sm",
                                value: format('{0:' + "0.000" + '}', rs_1.Sum),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                            })), (children_8 = singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["disabled", (!props.canReg) ? true : rs_1.Registered]), delay(() => append(singleton_1(["className", "form-control form-control-sm"]), delay(() => {
                                    let arg20_3, copyOfStruct_7, arg10_4, copyOfStruct_6;
                                    return append(singleton_1(["id", (arg20_3 = (((copyOfStruct_7 = props.selectedPeriod, month(copyOfStruct_7))) | 0), (arg10_4 = (((copyOfStruct_6 = props.selectedPeriod, year(copyOfStruct_6))) | 0), toText(printf("qty-%i-%i-%i"))(arg10_4)(arg20_3)(props.wt.Id)))]), delay(() => append((!rs_1.Registered) ? singleton_1(["defaultValue", ""]) : singleton_1(["value", format('{0:' + "0.000" + '}', rs_1.Quantity)]), delay(() => append(singleton_1(["autoFocus", true]), delay(() => append(singleton_1(["style", createObj(toList(delay(() => ((!validQty) ? singleton_1(["color", "red"]) : empty_1()))))]), delay(() => append(singleton_1(["onChange", (ev) => {
                                        const q = ev.target.value;
                                        try {
                                            if (q === "") {
                                                updateQty(0);
                                                setValidQty(true);
                                            }
                                            else {
                                                updateQty(parse_1(q));
                                                setValidQty(true);
                                            }
                                        }
                                        catch (matchValue) {
                                            setValidQty(false);
                                        }
                                    }]), delay(() => singleton_1(["onKeyUp", (kc) => {
                                        let arg20_4, copyOfStruct_9, arg10_5, copyOfStruct_8;
                                        if ((kc.key === "Enter") ? props.canReg : false) {
                                            tryFocus((arg20_4 = (((copyOfStruct_9 = props.selectedPeriod, month(copyOfStruct_9))) | 0), (arg10_5 = (((copyOfStruct_8 = props.selectedPeriod, year(copyOfStruct_8))) | 0), toText(printf("dd-%i-%i-%i"))(arg10_5)(arg20_4)(props.wt.Id))));
                                        }
                                    }])))))))))));
                                })))))))), createElement("div", {
                                    style: {
                                        width: 30 + "px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!validQty) ? singleton_1(createElement("i", {
                                        style: {
                                            color: "red",
                                        },
                                        className: "fas fa-exclamation-circle",
                                    })) : empty_1()))))),
                                })]),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                            })), (children_10 = singleton(createElement("input", {
                                disabled: true,
                                className: "form-control form-control-sm",
                                value: (!rs_1.Registered) ? ((copyOfStruct_10 = (rs_1.Sum + qty), format('{0:' + "0.000" + '}', copyOfStruct_10))) : ((copyOfStruct_11 = (rs_1.Sum + rs_1.Quantity), format('{0:' + "0.000" + '}', copyOfStruct_11))),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                            }))]),
                        }), createElement("tr", {
                            style: {
                                borderLeft: (((8 + "px ") + "solid") + " ") + col,
                            },
                            children: Interop_reactApi.Children.toArray([createElement("td", {
                                style: {
                                    maxWidth: 150 + "px",
                                    fontSize: 0.8 + "rem",
                                    textAlign: "center",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("span", {
                                    children: ["Mengde levert direkte til ombruk/gjenvinning"],
                                })]),
                            }), (children_12 = singleton(createElement("input", {
                                disabled: true,
                                className: "form-control form-control-sm",
                                value: format('{0:' + "0.000" + '}', rs_1.SumDirectlyDelivered),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                            })), (children_14 = singleton(createElement("div", {
                                style: {
                                    display: "flex",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("input", createObj(toList(delay(() => append(singleton_1(["disabled", (!props.canReg) ? true : rs_1.Registered]), delay(() => append(singleton_1(["className", "form-control form-control-sm"]), delay(() => {
                                    let arg20_5, copyOfStruct_13, arg10_6, copyOfStruct_12;
                                    return append(singleton_1(["id", (arg20_5 = (((copyOfStruct_13 = props.selectedPeriod, month(copyOfStruct_13))) | 0), (arg10_6 = (((copyOfStruct_12 = props.selectedPeriod, year(copyOfStruct_12))) | 0), toText(printf("dd-%i-%i-%i"))(arg10_6)(arg20_5)(props.wt.Id)))]), delay(() => append((!rs_1.Registered) ? singleton_1(["defaultValue", ""]) : singleton_1(["value", format('{0:' + "0.000" + '}', rs_1.DirectlyDelivered)]), delay(() => append(singleton_1(["style", createObj(toList(delay(() => ((!validDirect) ? singleton_1(["color", "red"]) : empty_1()))))]), delay(() => singleton_1(["onChange", (ev_1) => {
                                        const q_1 = ev_1.target.value;
                                        try {
                                            if (q_1 === "") {
                                                updateDirectQty(0);
                                                setValidDirect(true);
                                            }
                                            else {
                                                updateDirectQty(parse_1(q_1));
                                                setValidDirect(true);
                                            }
                                        }
                                        catch (matchValue_1) {
                                            setValidDirect(false);
                                        }
                                    }])))))));
                                })))))))), createElement("div", {
                                    style: {
                                        width: 30 + "px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    },
                                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!validDirect) ? singleton_1(createElement("i", {
                                        style: {
                                            color: "red",
                                        },
                                        className: "fas fa-exclamation-circle",
                                    })) : empty_1()))))),
                                })]),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                            })), (children_16 = singleton(createElement("input", {
                                disabled: true,
                                className: "form-control form-control-sm",
                                value: (!rs_1.Registered) ? ((copyOfStruct_14 = (rs_1.SumDirectlyDelivered + directQty), format('{0:' + "0.000" + '}', copyOfStruct_14))) : ((copyOfStruct_15 = (rs_1.SumDirectlyDelivered + rs_1.DirectlyDelivered), format('{0:' + "0.000" + '}', copyOfStruct_15))),
                            })), createElement("td", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                            }))]),
                        })]), createElement("tbody", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                        }))]),
                    }));
                }
                else {
                    return singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                        },
                        children: Interop_reactApi.Children.toArray([loadingIndicatorSmall()]),
                    }));
                }
            }))));
        })))),
    });
}, void 0, uncurry(2, void 0), void 0, "regForm", "/builds/serit/score/score/src/Client/Waste/WasteRegistration.fs", 344);

const wasteTypeSearch = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505([]);
    const setRes = patternInput[1];
    const res = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const updateTerm = patternInput_1[1];
    const term = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const setError = patternInput_3[1];
    return createElement("div", {
        style: {
            marginBottom: 15,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                marginTop: 10,
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["fullWidth", true], ["size", "small"], ["defaultValue", term], ["variant", "outlined"], ["label", "Avfallstype"], ["id", "waste-search"], ["style", {
                backgroundColor: "#FFFFFF",
            }], ["onChange", (e) => {
                const x_1 = Browser_Types_Event__Event_get_Value(e);
                window.clearTimeout(patternInput_2[0]);
                patternInput_2[1](window.setTimeout((_arg1_1) => {
                    if (x_1.length > 0) {
                        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                ResolveType: () => string_type,
                            })(x_1);
                            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                ResolveType: () => array_type(Waste_WasteType$reflection()),
                            });
                            return Fetch_tryPost_5760677E("/api/waste/types/search", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                ResolveType: () => array_type(Waste_WasteType$reflection()),
                            }, {
                                ResolveType: () => obj_type,
                            });
                        })).then(((_arg1) => {
                            const res_1 = _arg1;
                            if (res_1.tag === 1) {
                                setError("Noe gikk galt. Kunne ikke laste avfallstyper.");
                                return Promise.resolve();
                            }
                            else {
                                setRes(res_1.fields[0]);
                                return Promise.resolve();
                            }
                        })))));
                        pr.then();
                    }
                    else {
                        setRes([]);
                    }
                    updateTerm(x_1);
                }, 500));
            }]])]),
        }), createElement("div", {
            style: {
                position: "relative",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((term.length > 0) ? singleton_1(MuiHelpers_createElement(Paper, [["style", {
                position: "absolute",
                zIndex: 10,
                backgroundColor: "#FFFFFF",
                width: 100 + "%",
                padding: 5,
            }], ["elevation", 2], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((res.length === 0) ? singleton_1(createElement("span", {
                children: "Søk ga ingen avfallstyper",
            })) : empty_1(), delay(() => {
                let children;
                return singleton_1(createElement("table", {
                    className: "table",
                    style: {
                        width: 100 + "%",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("thead", {}), (children = singleton(map((x_2) => {
                        const col = x_2.Dangerous ? "tomato" : ((!x_2.Sorted) ? "orange" : "limegreen");
                        return createElement("tr", {
                            style: {
                                cursor: "pointer",
                            },
                            onClick: (_arg2) => {
                                props.dispatch(new Message(11, x_2));
                                setRes([]);
                                updateTerm("");
                                trySetValue("waste-search", "");
                            },
                            children: Interop_reactApi.Children.toArray([createElement("td", {
                                children: [x_2.Code],
                            }), createElement("td", {
                                children: [x_2.Name],
                            }), createElement("td", {
                                children: [x_2.Desc],
                            }), createElement("td", {
                                style: {
                                    width: 30,
                                },
                                children: Interop_reactApi.Children.toArray([circle(col, "")]),
                            })]),
                        });
                    }, res)), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))]),
                }));
            }))))))]])) : empty_1()))))),
        }), snackbarError(patternInput_3[0], () => {
            setError(void 0);
        })]),
    });
}, void 0, uncurry(2, void 0), void 0, "wasteTypeSearch", "/builds/serit/score/score/src/Client/Waste/WasteRegistration.fs", 721);

function drawRegistrationForm(model, selectedDate, dispatch, x) {
    const children = toList(delay(() => append(singleton_1(wasteTypeSearch({
        dispatch: dispatch,
    })), delay(() => {
        let arg30, arg20;
        const matchValue = model.SelectedType;
        if (matchValue != null) {
            const x_1 = matchValue;
            return singleton_1(MuiHelpers_createElement(Paper, [["elevation", 1], ["key", (arg30 = (month(selectedDate) | 0), (arg20 = (year(selectedDate) | 0), toText(printf("%i-%i-%i"))(x_1.Id)(arg20)(arg30)))], ["children", Interop_reactApi.Children.toArray([regForm({
                canReg: model.CanReg,
                dispatch: dispatch,
                projId: model.ProjectId,
                selectedPeriod: selectedDate,
                wt: x_1,
            })])]]));
        }
        else {
            return empty_1();
        }
    }))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function drawRegEntry(x, selectedPeriod) {
    let children_14, children, children_2, arg20_1, arg10_1, children_4, children_6, children_8, copyOfStruct, children_10, children_12, copyOfStruct_1;
    const col = x.Type.Dangerous ? "tomato" : ((!x.Type.Sorted) ? "orange" : "limegreen");
    return MuiHelpers_createElement(Paper, [["elevation", 1], ["style", {
        marginTop: 25 + "px",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            borderLeft: (((8 + "px ") + "solid") + " ") + col,
            padding: 7,
            backgroundColor: "#eee",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontSize: 15 + "px",
                    marginLeft: 8 + "px",
                    color: "#313131",
                },
                children: toText(printf("%s %s"))(x.Type.Code)(x.Type.Name),
            })]),
        }), createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let x_1;
                return (permissions).some((x_1 = (Functionality__ToInt(new Functionality(37)) | 0), (y) => (x_1 === y))) ? singleton_1(createElement("a", {
                    href: "/waste/control",
                    style: {
                        fontSize: 11 + "px",
                        marginLeft: 20,
                    },
                    children: "Endre denne registreringen",
                })) : empty_1();
            })))),
        })]),
    }), createElement("table", {
        className: "table",
        style: {
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([(children_14 = ofArray([createElement("tr", {
            style: {
                borderLeft: (((8 + "px ") + "solid") + " ") + col,
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    maxWidth: 150 + "px",
                },
            }), (children = singleton(createElement("span", {
                style: {
                    fontSize: 14 + "px",
                    padding: 5,
                    color: "#000",
                },
                children: "Forrige total",
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = singleton(createElement("span", {
                style: {
                    fontSize: 14 + "px",
                    padding: 5,
                    color: "#000",
                },
                children: (arg20_1 = (year(selectedPeriod) | 0), (arg10_1 = asMonth(month(selectedPeriod)), toText(printf("%s %i"))(arg10_1)(arg20_1))),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = singleton(createElement("span", {
                style: {
                    fontSize: 14 + "px",
                    padding: 5,
                    color: "#000",
                },
                children: "Ny total",
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            }))]),
        }), createElement("tr", {
            style: {
                borderLeft: (((8 + "px ") + "solid") + " ") + col,
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    maxWidth: 150 + "px",
                    fontSize: 0.8 + "rem",
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Mengde levert til godkjent avfallsanlegg"],
                })]),
            }), (children_6 = singleton(createElement("span", {
                children: format('{0:' + "0.000" + '}', x.Sum),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            })), createElement("td", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: format('{0:' + "0.000" + '}', x.Quantity),
                })]),
            }), (children_8 = singleton(createElement("span", {
                children: (copyOfStruct = (x.Sum + x.Quantity), format('{0:' + "0.000" + '}', copyOfStruct)),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            }))]),
        }), createElement("tr", {
            style: {
                borderLeft: (((8 + "px ") + "solid") + " ") + col,
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    maxWidth: 150 + "px",
                    fontSize: 0.8 + "rem",
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: ["Mengde levert direkte til ombruk/gjenvinning"],
                })]),
            }), (children_10 = singleton(createElement("span", {
                children: format('{0:' + "0.000" + '}', x.SumDirectlyDelivered),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            })), createElement("td", {
                style: {
                    display: "flex",
                },
                children: Interop_reactApi.Children.toArray([createElement("span", {
                    children: format('{0:' + "0.000" + '}', x.DirectlyDelivered),
                })]),
            }), (children_12 = singleton(createElement("span", {
                children: (copyOfStruct_1 = (x.SumDirectlyDelivered + x.DirectlyDelivered), format('{0:' + "0.000" + '}', copyOfStruct_1)),
            })), createElement("td", {
                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
            }))]),
        })]), createElement("tbody", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        }))]),
    })])]]);
}

function regNone(model, dispatch, sd) {
    let arg20, arg10_1;
    return MuiHelpers_createElement(Button, [["size", "small"], ["variant", "contained"], ["color", "primary"], ["children", (arg20 = (year(sd) | 0), (arg10_1 = asMonth(month(sd)), toText(printf("Registrer ingen avfall for %s %i"))(arg10_1)(arg20)))], ["onClick", (_arg1_1) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let item;
            const requestPath = toText(printf("/api/waste/project/none/%i"))(model.ProjectId);
            return ((item = toString(sd, "yyyy-MM-dd"), PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: () => string_type,
                })(item);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Helpers_ResultMessage$reflection,
                });
                return Fetch_tryPost_5760677E(requestPath, some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Helpers_ResultMessage$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })))).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    const x = res.fields[0];
                    if (x.Result === "success") {
                        fetchRegistrations(model.ProjectId, sd, dispatch);
                        return Promise.resolve();
                    }
                    else {
                        dispatch(new Message(10, x.Message));
                        return Promise.resolve();
                    }
                }
                else {
                    dispatch(new Message(10, "Kunne ikke registrere null avfall"));
                    return Promise.resolve();
                }
            }));
        }));
        pr.then();
    }]]);
}

function registrations(model, dispatch) {
    const children_18 = singleton(createElement("div", {
        style: {
            marginTop: 30 + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let arg20, arg10;
            const matchValue = model.SelectedDate;
            if (matchValue != null) {
                const sd = matchValue;
                const matchValue_1 = [model.Registrations, model.WasteTypes];
                let pattern_matching_result, reg, wasteTypes;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] != null) {
                        pattern_matching_result = 0;
                        reg = matchValue_1[0];
                        wasteTypes = matchValue_1[1];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return append(singleton_1(createElement("div", {
                            style: {
                                backgroundColor: "#778899",
                                padding: 10,
                                color: "#FFFFFF",
                                ["box-shadow"]: "2px 2px #aaa",
                                marginBottom: 10 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: (arg20 = (year(sd) | 0), (arg10 = asMonth(month(sd)), toText(printf("Registreringer for %s %i (i tonn)"))(arg10)(arg20))),
                            })]),
                        })), delay(() => append(singleton_1(createElement("div", {
                            style: {
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            },
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(drawColorCodes()), delay(() => ((reg.length === 0) ? singleton_1(regNone(model, dispatch, sd)) : empty_1()))))))),
                        })), delay(() => append(singleton_1(drawRegistrationForm(model, sd, dispatch, reg)), delay(() => append((reg.length === 0) ? singleton_1(createElement("div", {
                            style: {
                                textAlign: "center",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: ["Ingen registreringer gjort for denne perioden."],
                            })]),
                        })) : singleton_1(map((x) => drawRegEntry(x, sd), reg)), delay(() => append(singleton_1(createElement("div", {
                            style: {
                                backgroundColor: "#778899",
                                padding: 10,
                                color: "#FFFFFF",
                                ["box-shadow"]: "2px 2px #aaa",
                                marginBottom: 10 + "px",
                                marginTop: 20 + "px",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                children: "Oppsummering for denne perioden",
                            })]),
                        })), delay(() => {
                            let children_16;
                            const sorted = sumBy((x_2) => x_2.Quantity, reg.filter((x_1) => {
                                if (x_1.Type.Sorted ? x_1.Type.Calculate : false) {
                                    return !x_1.Type.Dangerous;
                                }
                                else {
                                    return false;
                                }
                            }), {
                                GetZero: () => 0,
                                Add: (x_3, y) => (x_3 + y),
                            });
                            const dangerous = sumBy((x_5) => x_5.Quantity, reg.filter((x_4) => {
                                if (x_4.Type.Dangerous) {
                                    return x_4.Type.Calculate;
                                }
                                else {
                                    return false;
                                }
                            }), {
                                GetZero: () => 0,
                                Add: (x_6, y_1) => (x_6 + y_1),
                            });
                            const unsorted = sumBy((x_8) => x_8.Quantity, reg.filter((x_7) => {
                                if ((!x_7.Type.Sorted) ? x_7.Type.Calculate : false) {
                                    return !x_7.Type.Dangerous;
                                }
                                else {
                                    return false;
                                }
                            }), {
                                GetZero: () => 0,
                                Add: (x_9, y_2) => (x_9 + y_2),
                            });
                            const ordinary = sumBy((x_11) => x_11.Quantity, reg.filter((x_10) => {
                                if (x_10.Type.Calculate) {
                                    return !x_10.Type.Dangerous;
                                }
                                else {
                                    return false;
                                }
                            }), {
                                GetZero: () => 0,
                                Add: (x_12, y_3) => (x_12 + y_3),
                            });
                            const total = (sorted + unsorted) + dangerous;
                            const nonCalculate = sumBy((x_14) => x_14.Quantity, reg.filter((x_13) => (!x_13.Type.Calculate)), {
                                GetZero: () => 0,
                                Add: (x_15, y_4) => (x_15 + y_4),
                            });
                            const rate = (total > 0) ? (((sorted + dangerous) / total) * 100) : 0;
                            let wastePrSqareMtr;
                            const matchValue_2 = model.ProjectInfo;
                            if (matchValue_2 != null) {
                                const pi = matchValue_2;
                                let sqrMtr;
                                const matchValue_3 = pi.BraUnit | 0;
                                sqrMtr = ((matchValue_3 === GrossAreaUnit__ToInt(new GrossAreaUnit(1))) ? (~(~(pi.Bra / 100))) : ((matchValue_3 === GrossAreaUnit__ToInt(new GrossAreaUnit(3))) ? (pi.Bra * 1000) : pi.Bra));
                                wastePrSqareMtr = ((sqrMtr > 0) ? ((total * 1000) / sqrMtr) : (void 0));
                            }
                            else {
                                wastePrSqareMtr = (void 0);
                            }
                            return singleton_1(MuiHelpers_createElement(Paper, [["elevation", 1], ["children", Interop_reactApi.Children.toArray([createElement("table", {
                                className: "table",
                                style: {
                                    width: 100 + "%",
                                },
                                children: Interop_reactApi.Children.toArray([(children_16 = toList(delay(() => {
                                    let children, value_51;
                                    return append(singleton_1((children = ofArray([createElement("th", {
                                        children: ["Sum sortert ordinært avfall"],
                                    }), (value_51 = format('{0:' + "0.00" + '}', sorted), createElement("td", {
                                        children: [value_51],
                                    }))]), createElement("tr", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                                    }))), delay(() => {
                                        let children_2, value_53;
                                        return append(singleton_1((children_2 = ofArray([createElement("th", {
                                            children: ["Sum usortert ordinært avfall"],
                                        }), (value_53 = format('{0:' + "0.00" + '}', unsorted), createElement("td", {
                                            children: [value_53],
                                        }))]), createElement("tr", {
                                            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                                        }))), delay(() => {
                                            let children_4, value_55;
                                            return append(singleton_1((children_4 = ofArray([createElement("th", {
                                                children: ["Sum ordinært avfall (tonn)"],
                                            }), (value_55 = format('{0:' + "0.00" + '}', ordinary), createElement("td", {
                                                children: [value_55],
                                            }))]), createElement("tr", {
                                                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                                            }))), delay(() => {
                                                let children_6, value_57;
                                                return append(singleton_1((children_6 = ofArray([createElement("th", {
                                                    children: ["Sum farlig avfall (tonn)"],
                                                }), (value_57 = format('{0:' + "0.00" + '}', dangerous), createElement("td", {
                                                    children: [value_57],
                                                }))]), createElement("tr", {
                                                    children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                                                }))), delay(() => {
                                                    let children_8, value_59;
                                                    return append(singleton_1((children_8 = ofArray([createElement("th", {
                                                        children: ["Sum avfall i alt (tonn)"],
                                                    }), (value_59 = format('{0:' + "0.00" + '}', dangerous), createElement("td", {
                                                        children: [value_59],
                                                    }))]), createElement("tr", {
                                                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                                                    }))), delay(() => {
                                                        let children_10, value_61, arg10_1;
                                                        return append(singleton_1((children_10 = ofArray([createElement("th", {
                                                            children: ["Sorteringsgrad inneværende periode"],
                                                        }), (value_61 = ((arg10_1 = format('{0:' + "0.00" + '}', rate), toText(printf("%s%%"))(arg10_1))), createElement("td", {
                                                            children: [value_61],
                                                        }))]), createElement("tr", {
                                                            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                                                        }))), delay(() => {
                                                            let children_12, value_63;
                                                            return append(singleton_1((children_12 = ofArray([createElement("th", {
                                                                children: ["Sum avfall ikke inkl. i sorteringsgrad (tonn)"],
                                                            }), (value_63 = format('{0:' + "0.00" + '}', nonCalculate), createElement("td", {
                                                                children: [value_63],
                                                            }))]), createElement("tr", {
                                                                children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                                                            }))), delay(() => {
                                                                let children_14, value_65;
                                                                if (wastePrSqareMtr != null) {
                                                                    const x_20 = wastePrSqareMtr;
                                                                    return singleton_1((children_14 = ofArray([createElement("th", {
                                                                        children: ["Avfall/kvadratmeter(kg/m2)"],
                                                                    }), (value_65 = format('{0:' + "0.00" + '}', x_20), createElement("td", {
                                                                        children: [value_65],
                                                                    }))]), createElement("tr", {
                                                                        children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                                                                    })));
                                                                }
                                                                else {
                                                                    return empty_1();
                                                                }
                                                            }));
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    }));
                                })), createElement("tbody", {
                                    children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                                }))]),
                            })])]]));
                        }))))))))));
                    }
                    case 1: {
                        return singleton_1(loadingIndicatorCenteredSmall());
                    }
                }
            }
            else {
                return empty_1();
            }
        })))),
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_18)),
    });
}

function view(model, dispatch) {
    let children, children_2;
    return createElement("div", {
        className: "registration-holder",
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(9));
        }), createElement("div", {
            className: "registration-left",
            children: Interop_reactApi.Children.toArray([createElement("span", {
                style: {
                    fontWeight: "bold",
                    fontSize: 1 + "rem",
                },
                children: "Sorteringsgrad registrering",
            }), createElement("p", {
                children: ["Registreres måned for måned i prosjektets driftstid. Verdier legges inn og aktiveres ved å klikke på «registrer». Verdiene hentes fra avfallsmottak- rapport."],
            }), createElement("span", {
                children: ["Fraksjoner kategoriseres som:"],
            }), (children = ofArray([createElement("li", {
                children: ["Sortert avfall (grønn)"],
            }), createElement("li", {
                children: ["Usortert avfall (oransje)"],
            }), createElement("li", {
                children: ["Farlig avfall (rød)"],
            })]), createElement("ul", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            })), (children_2 = ofArray(["Man kan velge om avfallet går til ", createElement("b", {
                children: ["avfallsanlegg"],
            }), " eller ", createElement("b", {
                children: ["gjenvinning."],
            })]), createElement("p", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), createElement("p", {
                children: ["Dersom man har 0(null) verdier å registrere, klikk direkte på «registrer»."],
            })]),
        }), createElement("div", {
            className: "registration-content",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                const matchValue = model.ProjectInfo;
                return (matchValue != null) ? append(singleton_1(periodSelect(model, dispatch, matchValue)), delay(() => append(singleton_1(registrations(model, dispatch)), delay(() => {
                    let arg20, arg10;
                    const matchValue_1 = model.SelectedDate;
                    if (matchValue_1 != null) {
                        const sd = matchValue_1;
                        return singleton_1(createElement("div", {
                            key: (arg20 = (month(sd) | 0), (arg10 = (year(sd) | 0), toText(printf("%i-%i"))(arg10)(arg20))),
                            children: Interop_reactApi.Children.toArray([createElement(RegistrationComment, {
                                src: new Parent(0, model.ProjectId),
                                year: year(sd),
                                month: month(sd),
                                canEdit: model.CanReg,
                            })]),
                        }));
                    }
                    else {
                        return empty_1();
                    }
                })))) : singleton_1(createElement("div", {
                    style: {
                        display: "flex",
                        justifyContent: "center",
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorSmall()]),
                }));
            })))),
        }), createElement("div", {
            className: "registration-right",
            children: Interop_reactApi.Children.toArray([]),
        })]),
    });
}

export function WasteReg(wasteRegInputProps) {
    const projectId = wasteRegInputProps.projectId;
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(projectId));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjInfo(projectId, dispatch);
        fetchTypes(dispatch);
        fetchProjectRequired(projectId, dispatch);
        getPermissions(projectId, dispatch);
    });
    return view(patternInput[0], dispatch);
}

