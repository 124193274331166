import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { record_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tabs from "@material-ui/core/Tabs";
import { uncurry, equals } from "../.fable/fable-library.3.2.4/Util.js";
import Tab from "@material-ui/core/Tab";
import { singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { wastePlan } from "../Waste/WastePlan.js";
import { WasteReg } from "../Waste/WasteRegistration.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";

class RegForm extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waste", "WastePlan"];
    }
}

function RegForm$reflection() {
    return union_type("WasteView.RegForm", [], RegForm, () => [[], []]);
}

class Model extends Record {
    constructor(ProjectId, SelectedForm) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.SelectedForm = SelectedForm;
    }
}

function Model$reflection() {
    return record_type("WasteView.Model", [], Model, () => [["ProjectId", int32_type], ["SelectedForm", RegForm$reflection()]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectForm"];
    }
}

function Message$reflection() {
    return union_type("WasteView.Message", [], Message, () => [[["Item", RegForm$reflection()]]]);
}

function init(x) {
    return new Model(x, new RegForm(0));
}

function update(model, msg) {
    return new Model(model.ProjectId, msg.fields[0]);
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["value", equals(model.SelectedForm, new RegForm(0)) ? 0 : 1], ["textColor", "primary"], ["onChange", (_arg38, v) => {
                if (v === 0) {
                    dispatch(new Message(0, new RegForm(0)));
                }
                else {
                    dispatch(new Message(0, new RegForm(1)));
                }
            }], ["indicatorColor", "primary"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Sorteringsgrad"]]), MuiHelpers_createElement(Tab, [["label", "Avfallsplan"]])])]])]),
        }), createElement("div", {
            style: {},
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((model.SelectedForm.tag === 1) ? singleton(wastePlan(model.ProjectId)) : singleton(createElement(WasteReg, {
                projectId: model.ProjectId,
            }))))))),
        })]),
    });
}

export const wasteView = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.projectId));
    return view(patternInput[0], patternInput[1]);
}, void 0, uncurry(2, void 0), void 0, "wasteView", "/builds/serit/score/score/src/Client/Project/WasteView.fs", 69);

