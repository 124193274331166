import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { UserSearchResult$reflection, SearchInput$reflection, SearchInput, PermissionEntry, UserGroupAccess, PermissionEntry$reflection, UserGroupAccess$reflection, UserGroupUsers$reflection, User$reflection, UserGroup$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, union_type, string_type, array_type, record_type, bool_type, option_type, list_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { ofArray, toArray, empty, exists, filter, singleton, append, length, map } from "../.fable/fable-library.3.2.4/List.js";
import { Array_except, List_distinct } from "../.fable/fable-library.3.2.4/Seq2.js";
import { safeHash, equals, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { AccessControl_GetPermissionsForGroup } from "../Shared/Urls.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Functionality, AdministrationList, SysConfigList, ManagementList, HMSList, ProjectAdministrationList, Functionality__ToInt } from "../Shared/PermissionMapping.js";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { snackbarError, RotationAngle, rotatingChevronButton, loadingIndicatorSmall, genericDeleteDialog, loadingIndicatorMedium, PageElements_inlineCheckbox } from "../ViewHelpers.js";
import { empty as empty_1, append as append_1, singleton as singleton_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Clear from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import TableHead from "@material-ui/core/TableHead";
import { map as map_1 } from "../.fable/fable-library.3.2.4/Array.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddBox from "@material-ui/icons/AddBox";
import Delete from "@material-ui/icons/Delete";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

class SelectedModel extends Record {
    constructor(UserGroup, Permissions, UserGroupUsers, SelectedRemoveUser, SearchOpen, SelectedUser) {
        super();
        this.UserGroup = UserGroup;
        this.Permissions = Permissions;
        this.UserGroupUsers = UserGroupUsers;
        this.SelectedRemoveUser = SelectedRemoveUser;
        this.SearchOpen = SearchOpen;
        this.SelectedUser = SelectedUser;
    }
}

function SelectedModel$reflection() {
    return record_type("AccessControl.SelectedModel", [], SelectedModel, () => [["UserGroup", UserGroup$reflection()], ["Permissions", option_type(list_type(int32_type))], ["UserGroupUsers", option_type(list_type(User$reflection()))], ["SelectedRemoveUser", option_type(User$reflection())], ["SearchOpen", bool_type], ["SelectedUser", option_type(User$reflection())]]);
}

class Model extends Record {
    constructor(UserGroups, SelectedUserGroup, ErrorMsg, SelectedView) {
        super();
        this.UserGroups = UserGroups;
        this.SelectedUserGroup = SelectedUserGroup;
        this.ErrorMsg = ErrorMsg;
        this.SelectedView = (SelectedView | 0);
    }
}

function Model$reflection() {
    return record_type("AccessControl.Model", [], Model, () => [["UserGroups", option_type(array_type(UserGroup$reflection()))], ["SelectedUserGroup", option_type(SelectedModel$reflection())], ["ErrorMsg", option_type(string_type)], ["SelectedView", int32_type]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserGroupResponse", "UserGroupMembersResponse", "PermissionGrantResponse", "PermissionRevokeResponse", "AssignNewUserPermResponse", "RevokeUserPermResponse", "SelectUserGroup", "SelectUser", "SetRemoveUser", "SetShowSearch", "CloseUserGroup", "SelectView", "SetError"];
    }
}

function Message$reflection() {
    return union_type("AccessControl.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserGroup$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserGroup$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserGroupUsers$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserGroupUsers$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserGroupAccess$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserGroupAccess$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserGroupAccess$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserGroupAccess$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PermissionEntry$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", PermissionEntry$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PermissionEntry$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", PermissionEntry$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", SelectedModel$reflection()]], [["Item", User$reflection()]], [["Item", option_type(User$reflection())]], [["Item", bool_type]], [], [["Item", int32_type]], [["Item", option_type(string_type)]]]);
}

function init() {
    return new Model(void 0, void 0, void 0, 0);
}

function update(model, msg) {
    let x_1, matchValue_3, matchValue_5, s_2, matchValue_10, matchValue_12;
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                const userperms = res_1.fields[0];
                const matchValue = model.SelectedUserGroup;
                if (matchValue != null) {
                    const selectedGroup = matchValue;
                    if (selectedGroup.UserGroup.Id !== userperms.groupid) {
                        return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Hentet medlemmer fra feil gruppe.", model.SelectedView);
                    }
                    else {
                        return new Model(model.UserGroups, new SelectedModel(selectedGroup.UserGroup, selectedGroup.Permissions, (x_1 = map((x) => x.user, userperms.userpermissions), (length(x_1) > 0) ? x_1 : (void 0)), selectedGroup.SelectedRemoveUser, selectedGroup.SearchOpen, selectedGroup.SelectedUser), model.ErrorMsg, model.SelectedView);
                    }
                }
                else {
                    return model;
                }
            }
            else {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke hente gruppe medlemmer", model.SelectedView);
            }
        }
        case 4: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 0) {
                const permres = res_2.fields[0];
                const matchValue_1 = model.SelectedUserGroup;
                if (matchValue_1 != null) {
                    const selusrgrp = matchValue_1;
                    const matchValue_2 = selusrgrp.SelectedUser;
                    if (matchValue_2 != null) {
                        const selusr = matchValue_2;
                        if ((selusr.Id === permres.userid) ? (selusrgrp.UserGroup.Id === permres.groupid) : false) {
                            return new Model(model.UserGroups, new SelectedModel(selusrgrp.UserGroup, selusrgrp.Permissions, (matchValue_3 = selusrgrp.UserGroupUsers, (matchValue_3 != null) ? append(matchValue_3, singleton(selusr)) : singleton(selusr)), selusrgrp.SelectedRemoveUser, false, selusrgrp.SelectedUser), model.ErrorMsg, model.SelectedView);
                        }
                        else {
                            return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Systemet kan ha lagt til i feil gruppe.", model.SelectedView);
                        }
                    }
                    else {
                        return model;
                    }
                }
                else {
                    return model;
                }
            }
            else {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke tolke svar fra server.", model.SelectedView);
            }
        }
        case 5: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 0) {
                const permres_1 = res_3.fields[0];
                const matchValue_4 = model.SelectedUserGroup;
                if (matchValue_4 != null) {
                    const selusrgrp_1 = matchValue_4;
                    if (permres_1.groupid === selusrgrp_1.UserGroup.Id) {
                        return new Model(model.UserGroups, new SelectedModel(selusrgrp_1.UserGroup, selusrgrp_1.Permissions, (matchValue_5 = selusrgrp_1.UserGroupUsers, (matchValue_5 != null) ? ((length(matchValue_5) === 1) ? ((s_2 = matchValue_5, void 0)) : ((matchValue_5 != null) ? filter((x_2) => (x_2.Id !== permres_1.userid), matchValue_5) : (() => {
                            throw (new Error("Match failure"));
                        })())) : (void 0)), void 0, selusrgrp_1.SearchOpen, selusrgrp_1.SelectedUser), model.ErrorMsg, model.SelectedView);
                    }
                    else {
                        return model;
                    }
                }
                else {
                    return model;
                }
            }
            else {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke tolke svar fra server.", model.SelectedView);
            }
        }
        case 6: {
            return new Model(model.UserGroups, msg.fields[0], void 0, model.SelectedView);
        }
        case 10: {
            return new Model(model.UserGroups, void 0, void 0, model.SelectedView);
        }
        case 11: {
            return new Model(model.UserGroups, model.SelectedUserGroup, model.ErrorMsg, msg.fields[0]);
        }
        case 7: {
            const matchValue_6 = model.SelectedUserGroup;
            if (matchValue_6 != null) {
                const ugrp = matchValue_6;
                return new Model(model.UserGroups, new SelectedModel(ugrp.UserGroup, ugrp.Permissions, ugrp.UserGroupUsers, ugrp.SelectedRemoveUser, ugrp.SearchOpen, msg.fields[0]), model.ErrorMsg, model.SelectedView);
            }
            else {
                return model;
            }
        }
        case 8: {
            const matchValue_7 = model.SelectedUserGroup;
            if (matchValue_7 != null) {
                const grp = matchValue_7;
                return new Model(model.UserGroups, new SelectedModel(grp.UserGroup, grp.Permissions, grp.UserGroupUsers, msg.fields[0], grp.SearchOpen, grp.SelectedUser), model.ErrorMsg, model.SelectedView);
            }
            else {
                return model;
            }
        }
        case 9: {
            const matchValue_8 = model.SelectedUserGroup;
            if (matchValue_8 != null) {
                const grp_1 = matchValue_8;
                return new Model(model.UserGroups, new SelectedModel(grp_1.UserGroup, grp_1.Permissions, grp_1.UserGroupUsers, grp_1.SelectedRemoveUser, msg.fields[0], grp_1.SelectedUser), model.ErrorMsg, model.SelectedView);
            }
            else {
                return model;
            }
        }
        case 2: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke gi gruppe tilgang", model.SelectedView);
            }
            else {
                const resp = res_4.fields[0];
                const matchValue_9 = model.SelectedUserGroup;
                let pattern_matching_result, ugroup_1;
                if (matchValue_9 != null) {
                    if (matchValue_9.UserGroup.Id === resp.groupid) {
                        pattern_matching_result = 0;
                        ugroup_1 = matchValue_9;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return new Model(model.UserGroups, new SelectedModel(ugroup_1.UserGroup, (matchValue_10 = ugroup_1.Permissions, (matchValue_10 != null) ? List_distinct(append(matchValue_10, resp.functionality), {
                            Equals: (x_4, y) => (x_4 === y),
                            GetHashCode: (x_4) => numberHash(x_4),
                        }) : (void 0)), ugroup_1.UserGroupUsers, ugroup_1.SelectedRemoveUser, ugroup_1.SearchOpen, ugroup_1.SelectedUser), model.ErrorMsg, model.SelectedView);
                    }
                    case 1: {
                        return model;
                    }
                }
            }
        }
        case 3: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke fjerne tilgang for gruppe", model.SelectedView);
            }
            else {
                const resp_1 = res_5.fields[0];
                const matchValue_11 = model.SelectedUserGroup;
                let pattern_matching_result_1, ugroup_3;
                if (matchValue_11 != null) {
                    if (matchValue_11.UserGroup.Id === resp_1.groupid) {
                        pattern_matching_result_1 = 0;
                        ugroup_3 = matchValue_11;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return new Model(model.UserGroups, new SelectedModel(ugroup_3.UserGroup, (matchValue_12 = ugroup_3.Permissions, (matchValue_12 == null) ? (void 0) : filter((elem) => (!exists((y_1) => (elem === y_1), resp_1.functionality)), matchValue_12)), ugroup_3.UserGroupUsers, ugroup_3.SelectedRemoveUser, ugroup_3.SearchOpen, ugroup_3.SelectedUser), model.ErrorMsg, model.SelectedView);
                    }
                    case 1: {
                        return model;
                    }
                }
            }
        }
        case 12: {
            return new Model(model.UserGroups, model.SelectedUserGroup, msg.fields[0], model.SelectedView);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.UserGroups, model.SelectedUserGroup, "Noe gikk galt. Kunne ikke hente brukergrupper.", model.SelectedView);
            }
            else {
                return new Model(res.fields[0], model.SelectedUserGroup, model.ErrorMsg, model.SelectedView);
            }
        }
    }
}

function fetchUserGroups(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(UserGroup$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/accesscontrol/getall", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(UserGroup$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function giveGroupPermission(ugroup, funcId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const entry = new UserGroupAccess(ugroup, singleton(funcId));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: UserGroupAccess$reflection,
            })(entry);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserGroupAccess$reflection,
            });
            return Fetch_tryPost_5760677E("/api/accesscontrol/grant", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserGroupAccess$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

function retractGroupPermission(ugroup, funcId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const entry = new UserGroupAccess(ugroup, singleton(funcId));
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: UserGroupAccess$reflection,
            })(entry);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserGroupAccess$reflection,
            });
            return Fetch_tryPost_5760677E("/api/accesscontrol/revoke", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserGroupAccess$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchUserGroupPermissions(ugroup) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = AccessControl_GetPermissionsForGroup(ugroup);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserGroupAccess$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserGroupAccess$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => (Promise.resolve(_arg1))));
    }));
}

function fetchUserGroupMembers(ugroupId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/permissions/usergroup/%i"))(ugroupId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserGroupUsers$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserGroupUsers$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function assignUserPermission(user, groupid, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(7, user));
        const entry = new PermissionEntry(user.Id, groupid);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: PermissionEntry$reflection,
            })(entry);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: PermissionEntry$reflection,
            });
            return Fetch_tryPost_5760677E("/api/permissions/assign", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: PermissionEntry$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
}

function revokeUserPermission(userid, groupid, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const entry = new PermissionEntry(userid, groupid);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: PermissionEntry$reflection,
            })(entry);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: PermissionEntry$reflection,
            });
            return Fetch_tryPost_5760677E("/api/permissions/revoke", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: PermissionEntry$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(5, _arg1));
            return Promise.resolve();
        }));
    }));
}

function selectUserGroup(ugroup, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetchUserGroupPermissions(ugroup.Id).then(((_arg1) => {
        const resp = _arg1;
        dispatch(new Message(6, new SelectedModel(ugroup, (resp.tag === 0) ? resp.fields[0].functionality : ((dispatch(new Message(12, "Fant ikke rettigheter")), void 0)), void 0, void 0, false, void 0)));
        return fetchUserGroupMembers(ugroup.Id, dispatch).then((() => (Promise.resolve(undefined))));
    })))));
}

function togglePermission(groupId, funcId, newPermission, dispatch) {
    if (newPermission) {
        const pr = giveGroupPermission(groupId, funcId, dispatch);
        pr.then();
    }
    else {
        const pr_1 = retractGroupPermission(groupId, funcId, dispatch);
        pr_1.then();
    }
}

function drawAccessControlSection(dispatch, groupId, groupPermissions, title, functionalities) {
    let children;
    return createElement("div", {
        style: {
            marginTop: 15,
        },
        children: Interop_reactApi.Children.toArray([(children = singleton(MuiHelpers_createElement(Typography, [["variant", "h6"], ["children", title]])), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([toArray(map((funct) => {
            const functId = Functionality__ToInt(funct) | 0;
            const isChecked = exists((y) => (functId === y), groupPermissions);
            return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([PageElements_inlineCheckbox((_arg1) => {
                togglePermission(groupId, functId, !isChecked, dispatch);
            }, toString(funct), isChecked)])]])])]]);
        }, functionalities))])]])])]])]),
    });
}

function editRightsView(dispatch, ugroup) {
    const children_3 = toList(delay(() => {
        const matchValue = ugroup.Permissions;
        if (matchValue != null) {
            const permList = matchValue;
            const drawRow = (label, mapping) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([drawAccessControlSection(dispatch, ugroup.UserGroup.Id, permList, label, mapping)])]]);
            return singleton_1(MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([drawRow("Prosjektadministrasjon", ProjectAdministrationList), drawRow("HMS", HMSList), drawRow("Ledelsessystem", ManagementList), drawRow("Systemkonfigurasjon", SysConfigList), drawRow("Administrasjon", AdministrationList)])]])])]]));
        }
        else {
            return singleton_1(loadingIndicatorMedium());
        }
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    });
}

function NewUserSearch(newUserSearchInputProps) {
    let children_6;
    const dispatch = newUserSearchInputProps.dispatch;
    const ugroup = newUserSearchInputProps.ugroup;
    const patternInput = useFeliz_React__React_useState_Static_1505([]);
    const setSearchResult = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("");
    const updateField = patternInput_2[1];
    const fieldState = patternInput_2[0];
    const currentUsers = defaultArg(ugroup.UserGroupUsers, empty());
    const renderNoResults = createElement("div", {
        style: {
            color: "#aaa",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 100 + "%",
            width: 100 + "%",
        },
        children: Interop_reactApi.Children.toArray([createElement("span", {
            children: ["Ingen resultater..."],
        })]),
    });
    const clearButton = MuiHelpers_createElement(IconButton, [["onClick", (_arg2) => {
        updateField("");
        setSearchResult([]);
    }], ["disabled", fieldState.length === 0], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Clear, {
        style: {
            color: "",
        },
    })])]])])], ["size", "small"]]);
    const searchIcon = MuiHelpers_createElement(Tooltip, [["children", MuiHelpers_createElement(Icon, [["style", {
        margin: 5,
    }], ["children", Interop_reactApi.Children.toArray([react.createElement(Search, {})])]])], ["title", "Søk"]]);
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        dispatch(new Message(9, false));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Søk etter bruker"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([(children_6 = toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(FormControl, [["fullWidth", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Input, [["placeholder", "Søk blant brukere..."], ["startAdornment", searchIcon], ["endAdornment", clearButton], ["value", fieldState], ["autoFocus", true], ["fullWidth", true], ["onChange", (ev) => {
        const x = ev.target.value;
        updateField(x);
        window.clearTimeout(patternInput_1[0]);
        patternInput_1[1](window.setTimeout((_arg4) => {
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                if (x.length > 0) {
                    const req = new SearchInput(x, 1);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: SearchInput$reflection,
                        })(req);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: UserSearchResult$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/search/users", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: UserSearchResult$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1_1) => {
                        const res = _arg1_1;
                        if (res.tag === 0) {
                            setSearchResult(res.fields[0].Users);
                            return Promise.resolve();
                        }
                        else {
                            return Promise.resolve();
                        }
                    }));
                }
                else {
                    setSearchResult([]);
                    return Promise.resolve();
                }
            }));
            pr_1.then();
        }, 500));
    }]])])]])), delay(() => {
        const nonCurrentMatches_1 = Array_except(currentUsers, patternInput[0], {
            Equals: (x_2, y) => equals(x_2, y),
            GetHashCode: (x_2) => safeHash(x_2),
        });
        return singleton_1(createElement("div", {
            style: {
                height: 15 + "rem",
                width: 25 + "rem",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((nonCurrentMatches_1.length !== 0) ? singleton_1(MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", "Fullt navn"]]), MuiHelpers_createElement(TableCell, [["children", "Login"]]), MuiHelpers_createElement(TableCell, [["children", "Bedrift"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map_1((usr) => MuiHelpers_createElement(TableRow, [["style", {
                cursor: "pointer",
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", usr.Fullname]]), MuiHelpers_createElement(TableCell, [["children", usr.Login]]), MuiHelpers_createElement(TableCell, [["children", usr.Company]])])], ["onClick", (_arg1) => {
                const pr = assignUserPermission(usr, ugroup.UserGroup.Id, dispatch);
                pr.then();
            }]]), nonCurrentMatches_1)])]])])]])) : singleton_1(renderNoResults)))))),
        }));
    })))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))])]])])]]);
}

function usersInGroupView(dispatch, model, ugroup) {
    const addButton = MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(IconButton, [["onClick", (_arg1) => {
        dispatch(new Message(9, true));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]])])]])], ["label", "Legg annen bruker til gruppen"]]);
    let userTable;
    const matchValue = ugroup.UserGroupUsers;
    if (matchValue != null) {
        const users = matchValue;
        userTable = MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", "Fullt navn"]]), MuiHelpers_createElement(TableCell, [["children", "Login"]]), MuiHelpers_createElement(TableCell, [["children", "Bedrift"]]), MuiHelpers_createElement(TableCell, [["children", "Rediger"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([toArray(map((usr) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", usr.Fullname]]), MuiHelpers_createElement(TableCell, [["children", usr.Login]]), MuiHelpers_createElement(TableCell, [["children", usr.Company]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Fjern bruker"], ["children", MuiHelpers_createElement(IconButton, [["onClick", (_arg2) => {
            dispatch(new Message(8, usr));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Delete, {})])]])])]])]])])]])])]]), users))])]])])]]);
    }
    else {
        userTable = createElement("div", {
            style: {
                color: "#aaa",
                textAlign: "center",
            },
            children: "Ingen brukere i denne gruppen.",
        });
    }
    const children_9 = toList(delay(() => append_1(singleton_1(addButton), delay(() => append_1(singleton_1(userTable), delay(() => {
        let matchValue_1, u, value_46;
        return append_1((matchValue_1 = ugroup.SelectedRemoveUser, (matchValue_1 != null) ? ((u = matchValue_1, singleton_1(genericDeleteDialog(() => {
            dispatch(new Message(8, void 0));
        }, () => revokeUserPermission(u.Id, ugroup.UserGroup.Id, dispatch), [(value_46 = toText(printf("Dette vil fjerne %s fra %s."))(u.Fullname)(ugroup.UserGroup.Name), createElement("span", {
            children: [value_46],
        }))])))) : singleton_1(null)), delay(() => (ugroup.SearchOpen ? singleton_1(createElement(NewUserSearch, {
            ugroup: model,
            dispatch: dispatch,
        })) : singleton_1(null))));
    }))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    });
}

function returnButton(dispatch) {
    return MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(IconButton, [["onClick", (_arg1) => {
        dispatch(new Message(10));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ArrowBackIos, {})])]])])]])], ["label", "Tilbake"]]);
}

function selectedGroupView(model, ugroup, dispatch) {
    let children, children_2;
    const children_4 = ofArray([(children = ofArray([returnButton(dispatch), MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
        textAlign: "center",
    }], ["children", toText(printf("Gruppe: %s"))(ugroup.UserGroup.Name)]])]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["value", model.SelectedView], ["textColor", "primary"], ["indicatorColor", "primary"], ["onChange", (_arg38, v) => {
            dispatch(new Message(11, v));
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tab, [["label", "Grupperettigheter"]]), MuiHelpers_createElement(Tab, [["label", "Brukere i gruppe"]])])]])]),
    }), (children_2 = toList(delay(() => {
        const matchValue = model.SelectedView | 0;
        switch (matchValue) {
            case 0: {
                return singleton_1(editRightsView(dispatch, ugroup));
            }
            case 1: {
                return singleton_1(usersInGroupView(dispatch, ugroup, ugroup));
            }
            default: {
                return singleton_1(loadingIndicatorSmall());
            }
        }
    })), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    }))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    });
}

function listGroupsView(model, dispatch) {
    const matchValue = model.UserGroups;
    if (matchValue != null) {
        const ugroups = matchValue;
        const children_3 = ofArray([MuiHelpers_createElement(Typography, [["variant", "h6"], ["style", {
            margin: 1 + "rem",
        }], ["children", "Rettighetsgrupper"]]), MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Brukergruppe"]]), MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Aktiv"]]), MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Legg til automatisk"]]), MuiHelpers_createElement(TableCell, [["style", {
            border: (((0 + "px ") + "none") + " ") + "",
        }], ["children", "Rediger"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(map_1((e) => {
            let x_1;
            const perms = (permissions).some((x_1 = (Functionality__ToInt(new Functionality(43)) | 0), (y) => (x_1 === y)));
            return MuiHelpers_createElement(TableRow, toList(delay(() => append_1(singleton_1(["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", e.Name]]), MuiHelpers_createElement(TableCell, [["children", e.Enabled ? "Ja" : "Nei"]]), MuiHelpers_createElement(TableCell, [["children", e.AutoAdd ? "Ja" : "Nei"]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (perms ? singleton_1(rotatingChevronButton(new RotationAngle(0), () => {
                const pr = selectUserGroup(e, dispatch);
                pr.then();
            })) : singleton_1(null))))))], ["className", "rotate-row-cell"]])])]), delay(() => append_1(singleton_1(["className", "rotate-row-root"]), delay(() => {
                if (perms) {
                    return append_1(singleton_1(["onClick", (_arg2) => {
                        const pr_1 = selectUserGroup(e, dispatch);
                        pr_1.then();
                    }]), delay(() => append_1(singleton_1(["hover", true]), delay(() => singleton_1(["style", {
                        cursor: "pointer",
                    }])))));
                }
                else {
                    return empty_1();
                }
            })))))));
        }, ugroups.filter((x) => (x.Id !== 1)))))]])])]])]);
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_3)),
        });
    }
    else {
        return loadingIndicatorMedium();
    }
}

export function wrapInPaper(child) {
    return MuiHelpers_createElement(Paper, [["elevation", 4], ["style", {
        overflow: "auto",
        padding: 2 + "vw",
        maxWidth: 1000,
        width: 90 + "%",
    }], ["children", Interop_reactApi.Children.toArray([child])]]);
}

function view(model, dispatch) {
    let matchValue;
    return createElement("main", {
        style: {
            marginTop: 3 + "rem",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        },
        children: Interop_reactApi.Children.toArray([snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(12, void 0));
        }), wrapInPaper((matchValue = model.SelectedUserGroup, (matchValue != null) ? selectedGroupView(model, matchValue, dispatch) : listGroupsView(model, dispatch)))]),
    });
}

export function AccessControl() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = fetchUserGroups(dispatch);
        pr.then();
    });
    return view(patternInput[0], dispatch);
}

