import { minute, second, millisecond, now as now_1 } from "./.fable/fable-library.3.2.4/Date.js";
import { split, padRight, substring, printf, toText } from "./.fable/fable-library.3.2.4/String.js";
import { some } from "./.fable/fable-library.3.2.4/Option.js";
import { class_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { toString } from "./.fable/fable-library.3.2.4/Types.js";

export function Cons(fmt) {
    return fmt.cont((s) => {
        let arg30, arg20, arg10;
        const now = now_1();
        console.log(some((arg30 = (millisecond(now) | 0), (arg20 = (second(now) | 0), (arg10 = (minute(now) | 0), toText(printf("%02i:%02i:%03i %s"))(arg10)(arg20)(arg30)(s))))));
    });
}

export class Parse {
    constructor() {
    }
}

export function Parse$reflection() {
    return class_type("Log.Parse", void 0, Parse);
}

export function Parse_Head_Z721C83C5(msg) {
    if (msg === null) {
        return "";
    }
    else {
        const headPart = (msg.length > 50) ? substring(msg, 0, 50) : padRight(msg, 50, " ");
        const arr = split(headPart, [" "], null, 0);
        if (arr.length > 0) {
            return arr[0];
        }
        else {
            return headPart;
        }
    }
}

export function Parse_Head_4E60E31B(msg) {
    return Parse_Head_Z721C83C5(toString(msg));
}

