import { Union } from "../fable-library.3.2.4/Types.js";
import { union_type, class_type } from "../fable-library.3.2.4/Reflection.js";
import { ofArray, singleton } from "../fable-library.3.2.4/List.js";
import { Cmd_batch, Cmd_map } from "../Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgramModule_map } from "../Fable.Elmish.3.1.0/program.fs.js";

export class Navigable$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Change", "UserMsg"];
    }
}

export function Navigable$1$reflection(gen0) {
    return union_type("Elmish.Navigation.Navigable`1", [gen0], Navigable$1, () => [[["Item", class_type("Browser.Types.Location")]], [["Item", gen0]]]);
}

export function Navigation_modifyUrl(newUrl) {
    return singleton((_arg1) => {
        history.replaceState(void 0, "", newUrl);
    });
}

export function Navigation_newUrl(newUrl) {
    return singleton((_arg1) => {
        history.pushState(void 0, "", newUrl);
        void window.dispatchEvent(new CustomEvent()("NavigatedEvent"));
    });
}

export function Navigation_jump(n) {
    return singleton((_arg1) => {
        history.go(n);
    });
}

let ProgramModule_Internal_onChangeRef = (_arg1) => {
    throw (new Error("`onChangeRef` has not been initialized.\nPlease make sure you used Elmish.Navigation.Program.Internal.subscribe"));
};

export function ProgramModule_Internal_subscribe(dispatch) {
    let clo1, clo1_1, clo1_2;
    let lastLocation = void 0;
    ProgramModule_Internal_onChangeRef = ((_arg1) => {
        let value;
        let pattern_matching_result;
        if (lastLocation != null) {
            if (lastLocation === window.location.href) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                value = (void 0);
                break;
            }
            case 1: {
                lastLocation = window.location.href;
                value = dispatch(new Navigable$1(0, window.location));
                break;
            }
        }
        return void 0;
    });
    window.addEventListener("popstate", (clo1 = ProgramModule_Internal_onChangeRef, (arg10) => {
        clo1(arg10);
    }));
    window.addEventListener("hashchange", (clo1_1 = ProgramModule_Internal_onChangeRef, (arg10_1) => {
        clo1_1(arg10_1);
    }));
    window.addEventListener("NavigatedEvent", (clo1_2 = ProgramModule_Internal_onChangeRef, (arg10_2) => {
        clo1_2(arg10_2);
    }));
}

export function ProgramModule_Internal_unsubscribe() {
    let clo1, clo1_1, clo1_2;
    window.removeEventListener("popstate", (clo1 = ProgramModule_Internal_onChangeRef, (arg10) => {
        clo1(arg10);
    }));
    window.removeEventListener("hashchange", (clo1_1 = ProgramModule_Internal_onChangeRef, (arg10_1) => {
        clo1_1(arg10_1);
    }));
    window.removeEventListener("NavigatedEvent", (clo1_2 = ProgramModule_Internal_onChangeRef, (arg10_2) => {
        clo1_2(arg10_2);
    }));
}

export function ProgramModule_Internal_toNavigableWith(parser, urlUpdate, program, onLocationChange) {
    const map = (tupledArg) => [tupledArg[0], Cmd_map((arg0) => (new Navigable$1(1, arg0)), tupledArg[1])];
    return ProgramModule_map((userInit, unitVar1) => map(userInit(parser(window.location))), (userUpdate, msg, model_1) => map((msg.tag === 1) ? userUpdate(msg.fields[0], model_1) : urlUpdate(parser(msg.fields[0]), model_1)), (userView, model_4, dispatch_1) => userView(model_4, (arg_1) => dispatch_1(new Navigable$1(1, arg_1))), (userSetState, model_3, dispatch) => userSetState(model_3, (arg) => dispatch(new Navigable$1(1, arg))), (userSubscribe, model_2) => Cmd_batch(ofArray([singleton(onLocationChange), Cmd_map((arg0_1) => (new Navigable$1(1, arg0_1)), userSubscribe(model_2))])), program);
}

export function ProgramModule_toNavigable(parser, urlUpdate, program) {
    return ProgramModule_Internal_toNavigableWith(parser, urlUpdate, program, (dispatch) => {
        ProgramModule_Internal_subscribe(dispatch);
    });
}

