import { Union, Record } from "./.fable/fable-library.3.2.4/Types.js";
import { obj_type, string_type, list_type, bool_type, option_type, union_type, record_type, array_type, int32_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { printf, toText, join } from "./.fable/fable-library.3.2.4/String.js";
import { tryFind, sortBy, sortByDescending, truncate, append, map } from "./.fable/fable-library.3.2.4/Array.js";
import { UserInfo$reflection, UserListEntry$reflection, Helpers_IdName$reflection, Office$reflection, Company$reflection } from "./Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "./.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.4/Choice.js";
import { toArray, empty, ofArray, append as append_1, contains, singleton } from "./.fable/fable-library.3.2.4/List.js";
import { createObj, comparePrimitives, uncurry, safeHash, equals } from "./.fable/fable-library.3.2.4/Util.js";
import { List_distinct, List_except } from "./.fable/fable-library.3.2.4/Seq2.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, singleton as singleton_1, append as append_2, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { op_UnaryNegation_Int32 } from "./.fable/fable-library.3.2.4/Int32.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "./.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { MuiHelpers_createElement } from "./.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "./.fable/Feliz.1.45.0/Interop.fs.js";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import { compare, toShortDateString } from "./.fable/fable-library.3.2.4/Date.js";
import { createElement } from "react";
import * as react from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { errorBanner, RotationAngle, rotatingChevronButton, drawAllocation } from "./ViewHelpers.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.45.0/React.fs.js";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Icon from "@material-ui/core/Icon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { keyValueList } from "./.fable/fable-library.3.2.4/MapUtil.js";
import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";

class UserSearch extends Record {
    constructor(Companies, Offices, Departments, Educations) {
        super();
        this.Companies = Companies;
        this.Offices = Offices;
        this.Departments = Departments;
        this.Educations = Educations;
    }
}

function UserSearch$reflection() {
    return record_type("UserList.UserSearch", [], UserSearch, () => [["Companies", array_type(int32_type)], ["Offices", array_type(int32_type)], ["Departments", array_type(int32_type)], ["Educations", array_type(int32_type)]]);
}

function UserSearch_Default() {
    return new UserSearch(new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]));
}

function UserSearch__ToQueryString(this$) {
    return ((("?" + ((this$.Companies.length === 0) ? "" : join("", map((x) => toText(printf("\u0026company=%i"))(x), this$.Companies)))) + ((this$.Offices.length === 0) ? "" : join("", map((x_1) => toText(printf("\u0026office=%i"))(x_1), this$.Offices)))) + ((this$.Departments.length === 0) ? "" : join("", map((x_2) => toText(printf("\u0026dep=%i"))(x_2), this$.Departments)))) + ((this$.Educations.length === 0) ? "" : join("", map((x_3) => toText(printf("\u0026edu=%i"))(x_3), this$.Educations)));
}

class FilterCategory extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Company", "Office", "Education"];
    }
}

function FilterCategory$reflection() {
    return union_type("UserList.FilterCategory", [], FilterCategory, () => [[], [], []]);
}

function FilterCategory__get_AsString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Kontor";
        }
        case 2: {
            return "Utdanning";
        }
        default: {
            return "Selskap";
        }
    }
}

function FilterCategory__ActiveInCategory_Z66DEA559(this$, s) {
    switch (this$.tag) {
        case 1: {
            return s.Offices.length | 0;
        }
        case 2: {
            return s.Educations.length | 0;
        }
        default: {
            return s.Companies.length | 0;
        }
    }
}

class Model extends Record {
    constructor(Companies, Offices, EducationLevels, Users, Queries, Processing, ShownFilters, SelectedUser, UserInfos, ErrorMsg) {
        super();
        this.Companies = Companies;
        this.Offices = Offices;
        this.EducationLevels = EducationLevels;
        this.Users = Users;
        this.Queries = Queries;
        this.Processing = Processing;
        this.ShownFilters = ShownFilters;
        this.SelectedUser = SelectedUser;
        this.UserInfos = UserInfos;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("UserList.Model", [], Model, () => [["Companies", option_type(array_type(Company$reflection()))], ["Offices", option_type(array_type(Office$reflection()))], ["EducationLevels", option_type(array_type(Helpers_IdName$reflection()))], ["Users", array_type(UserListEntry$reflection())], ["Queries", UserSearch$reflection()], ["Processing", bool_type], ["ShownFilters", list_type(FilterCategory$reflection())], ["SelectedUser", option_type(UserListEntry$reflection())], ["UserInfos", array_type(UserInfo$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompaniesResponse", "OfficesResponse", "EducationResponse", "UsersResponse", "UserInfoResponse", "UpdateQuery", "SetSelectedUser", "ToggleFilter", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("UserList.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdName$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdName$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserListEntry$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserListEntry$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserInfo$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserInfo$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", UserSearch$reflection()]], [["Item", option_type(UserListEntry$reflection())]], [["Item", FilterCategory$reflection()]], []]);
}

function init() {
    return new Model(void 0, void 0, void 0, [], UserSearch_Default(), true, singleton(new FilterCategory(0)), void 0, [], void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, "Noe gikk galt. Kunne ikke laste kontor.");
            }
            else {
                return new Model(model.Companies, res_1.fields[0], model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, model.ErrorMsg);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, "Noe gikk galt. Kunne ikke laste utdanningsnivå.");
            }
            else {
                return new Model(model.Companies, model.Offices, res_2.fields[0], model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, model.ErrorMsg);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, false, model.ShownFilters, model.SelectedUser, model.UserInfos, "Noe gikk galt. Kunne ikke laste brukere.");
            }
            else {
                return new Model(model.Companies, model.Offices, model.EducationLevels, res_3.fields[0], model.Queries, false, model.ShownFilters, model.SelectedUser, model.UserInfos, model.ErrorMsg);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, "Noe gikk galt. Kunne ikke laste brukerinfo");
            }
            else {
                const ui = res_4.fields[0];
                if (model.UserInfos.some((x_4) => (x_4.id === ui.id))) {
                    return model;
                }
                else {
                    return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, append(model.UserInfos, [ui]), model.ErrorMsg);
                }
            }
        }
        case 5: {
            return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, msg.fields[0], model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, model.ErrorMsg);
        }
        case 6: {
            return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, msg.fields[0], model.UserInfos, model.ErrorMsg);
        }
        case 8: {
            return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, void 0);
        }
        case 7: {
            const category = msg.fields[0];
            return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, contains(category, model.ShownFilters, {
                Equals: (x_6, y) => equals(x_6, y),
                GetHashCode: (x_6) => safeHash(x_6),
            }) ? List_except([category], model.ShownFilters, {
                Equals: (x_7, y_1) => equals(x_7, y_1),
                GetHashCode: (x_7) => safeHash(x_7),
            }) : List_distinct(append_1(model.ShownFilters, singleton(category)), {
                Equals: (x_8, y_2) => equals(x_8, y_2),
                GetHashCode: (x_8) => safeHash(x_8),
            }), model.SelectedUser, model.UserInfos, model.ErrorMsg);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Companies, model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, "Noe gikk galt. Kunne ikke laste selskaper.");
            }
            else {
                return new Model(res.fields[0], model.Offices, model.EducationLevels, model.Users, model.Queries, model.Processing, model.ShownFilters, model.SelectedUser, model.UserInfos, model.ErrorMsg);
            }
        }
    }
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["top", 52]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(240)], ["marginLeft", 240]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["top", 52]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append_2(singleton_1(["display", "flex"]), delay(() => append_2(singleton_1(["alignItems", "center"]), delay(() => append_2(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append_2(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    const content = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["marginLeft", op_UnaryNegation_Int32(240)], ["backgroundColor", "#fff"]]));
    const contentShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", toText(printf("calc(100%% - %ipx)"))(240)]]));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        buttonChevron: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["transitionProperty", "transform"], ["transitionDuration", 300 + "ms"], ["fontSize", 1 + "rem"]])),
        collapseButtonDiv: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["cursor", "pointer"], ["width", 100 + "%"], ["display", "flex"], ["height", 48], ["alignItems", "center"]])),
        content: content,
        contentShift: contentShift,
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function fetchCompanies(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

function fetchOffices(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Office$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/office/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
}

function fetchEducationLevels(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/education/levels", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
}

function fetchUserInfo(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const path = "/api/mypage/userinfo" + toText(printf("/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserInfo$reflection,
            });
            return Fetch_tryGet_5760677E(path, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserInfo$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchUsers(qry, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5, qry));
        const qryVars = UserSearch__ToQueryString(qry);
        const requestPath = toText(printf("/api/user/list%s"))(qryVars);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserListEntry$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserListEntry$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
}

class DetailStylesType extends Record {
    constructor(Infocard, Image, ProjectCardRoot, ProjectCardImage, EducationCard, GridRoot) {
        super();
        this.Infocard = Infocard;
        this.Image = Image;
        this.ProjectCardRoot = ProjectCardRoot;
        this.ProjectCardImage = ProjectCardImage;
        this.EducationCard = EducationCard;
        this.GridRoot = GridRoot;
    }
}

function DetailStylesType$reflection() {
    return record_type("UserList.DetailStylesType", [], DetailStylesType, () => [["Infocard", string_type], ["Image", string_type], ["ProjectCardRoot", string_type], ["ProjectCardImage", string_type], ["EducationCard", string_type], ["GridRoot", string_type]]);
}

const detailStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const Infocard = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["height", 100 + "%"], ["paddingTop", 15], ["paddingBottom", 15], ["overflow", "hidden"], ["display", "flex"], ["alignItems", "center"], ["justifyContent", "center"], ["overflow", "auto"], ["flexDirection", "column"], ["overflow-wrap", "anywhere"]]));
    const Image = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", theme.spacing(20)], ["height", theme.spacing(20)]]));
    const EducationCard = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["paddingTop", 10], ["paddingBottom", 10], ["overflow-wrap", "anywhere"]]));
    o = (new DetailStylesType(Infocard, Image, StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["flexDirection", "column"], ["overflow", "hidden"], ["padding", 10]])), StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", theme.spacing(10)], ["height", theme.spacing(10)]])), EducationCard, StyleCreator$1__create_4EAC9E1E(styles, singleton(["width", 100 + "%"]))));
    return Object.assign({}, o);
});

function drawSelectedUser(user, userinfo, dispatch, s) {
    const notBorderedCell = (s_1) => MuiHelpers_createElement(TableCell, [["style", {
        borderStyle: "none",
        padding: 6,
        paddingLeft: 12,
        paddingRight: 12,
    }], ["children", s_1]]);
    const drawTextInfo = MuiHelpers_createElement(Paper, [["className", s.Infocard], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["className", s.Image], ["src", toText(printf("/api/user/profilepicture/thumbnail/%i"))(user.Id)], ["alt", user.Fullname]]), MuiHelpers_createElement(Typography, [["variant", "h6"], ["align", "center"], ["children", user.Fullname]]), MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_2(singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Brukernavn"), notBorderedCell(user.Username)])]])), delay(() => append_2(singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Telefon"), notBorderedCell(user.Phone)])]])), delay(() => append_2(singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Selskap"), notBorderedCell(user.Company)])]])), delay(() => append_2(singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Tittel"), notBorderedCell(user.Title)])]])), delay(() => {
        if (userinfo != null) {
            const ui = userinfo;
            return append_2(singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Email"), notBorderedCell(ui.email)])]])), delay(() => singleton_1(MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Kurs"), notBorderedCell(join(", ", ui.courses))])]]))));
        }
        else {
            return empty_1();
        }
    }))))))))))))]])])]])])]]);
    const renderRightSide = MuiHelpers_createElement(Grid, [["container", true], ["spacing", 2], ["alignContent", "space-between"], ["children", Interop_reactApi.Children.toArray(Array.from(map((c) => MuiHelpers_createElement(Grid, [["className", s.GridRoot], ["item", true], ["children", c]]), append([MuiHelpers_createElement(Paper, [["className", s.EducationCard], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("Utdanning"), notBorderedCell("Nivå"), notBorderedCell("Fullført")])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x) => MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell(x.Education), notBorderedCell(x.Level.Name), notBorderedCell(toText(printf("%i/%i"))(x.EndMonth)(x.EndYear))])]]), user.Education)])]])])]])])]])], (userinfo != null) ? map((p) => {
        let arg10_1, arg10_2;
        return MuiHelpers_createElement(Paper, [["className", s.ProjectCardRoot], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["container", true], ["spacing", 2], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["item", true], ["sm", 4], ["xs", 12], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "subtitle1"], ["children", p.projectname]]), MuiHelpers_createElement(Typography, toList(delay(() => append_2(singleton_1(["variant", "caption"]), delay(() => (p.active ? append_2(singleton_1(["color", "primary"]), delay(() => singleton_1(["children", "Pågår"]))) : append_2(singleton_1(["color", "error"]), delay(() => singleton_1(["children", "Ferdig"])))))))))])]]), MuiHelpers_createElement(Grid, [["item", true], ["sm", 6], ["xs", 12], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Typography, [["variant", "body2"], ["children", toText(printf("Rolle: %s(%d%%)"))(p.rolename)(p.allocated)]]), MuiHelpers_createElement(Typography, [["variant", "body2"], ["children", (arg10_1 = toShortDateString(p.plannedStart), toText(printf("Fra: %s"))(arg10_1))]]), MuiHelpers_createElement(Typography, [["variant", "body2"], ["children", (arg10_2 = toShortDateString(p.plannedEnd), toText(printf("Til: %s"))(arg10_2))]])])]]), MuiHelpers_createElement(Grid, [["item", true], ["sm", 2], ["xs", 12], ["children", Interop_reactApi.Children.toArray([createElement("a", {
            href: toText(printf("/project/%i"))(p.projectid),
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Avatar, [["variant", "rounded"], ["className", s.ProjectCardImage], ["alt", p.projectname], ["src", toText(printf("/api/projectimage/thumbnail/%i"))(p.projectid)]])]),
        })])]])])]])])]]);
    }, truncate(4, sortByDescending((x_1) => x_1.plannedStart, userinfo.projects, {
        Compare: (x_2, y) => compare(x_2, y),
    }))) : []))))]]);
    return MuiHelpers_createElement(Dialog, [["open", true], ["maxWidth", "md"], ["fullWidth", true], ["style", {
        width: 100 + "vw",
    }], ["onClose", (_arg14, v) => {
        dispatch(new Message(6, void 0));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["container", true], ["spacing", 2], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Grid, [["item", true], ["xs", 12], ["md", 4], ["children", drawTextInfo]]), MuiHelpers_createElement(Grid, [["item", true], ["xs", 12], ["md", 8], ["children", renderRightSide]])])]])])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["variant", "contained"], ["size", "small"], ["color", "primary"], ["href", toText(printf("/user/%i"))(user.Id)], ["children", "Gå til profil"]]), MuiHelpers_createElement(Button, [["variant", "contained"], ["size", "small"], ["color", "primary"], ["onClick", (_arg2) => {
        dispatch(new Message(6, void 0));
    }], ["children", "Lukk"]])])]])])]]);
}

function drawUserList(model, dispatch) {
    return MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", "Navn"]]), MuiHelpers_createElement(TableCell, [["children", "Telefon"]]), MuiHelpers_createElement(TableCell, [["children", "Selskap"]]), MuiHelpers_createElement(TableCell, [["children", "Kontor"]]), MuiHelpers_createElement(TableCell, [["children", "Tittel"]]), MuiHelpers_createElement(TableCell, [["children", "Allokert %"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x_1) => {
        let u;
        return MuiHelpers_createElement(TableRow, [["hover", true], ["style", {
            cursor: "pointer",
        }], ["onClick", (u = x_1, (_arg1) => {
            if (!model.UserInfos.some((x) => (x.id === u.Id))) {
                const pr = fetchUserInfo(u.Id, dispatch);
                pr.then();
            }
            dispatch(new Message(6, u));
        })], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", x_1.Fullname]]), MuiHelpers_createElement(TableCell, [["children", x_1.Phone]]), MuiHelpers_createElement(TableCell, [["children", x_1.Company]]), MuiHelpers_createElement(TableCell, [["children", x_1.Office]]), MuiHelpers_createElement(TableCell, [["children", x_1.Title]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([drawAllocation(x_1.Allocated)])]])])]]);
    }, model.Users)])]])])]]);
}

function view(model, dispatch) {
    let matchValue, matchValue_1, x_6, matchValue_2;
    const s = useStyles();
    const detailstyle = detailStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setDrawerOpen = patternInput[1];
    const drawerOpen = patternInput[0];
    const drawCategory = (category_1, lst) => {
        let category;
        const expanded_1 = contains(category_1, model.ShownFilters, {
            Equals: (x, y) => equals(x, y),
            GetHashCode: (x) => safeHash(x),
        });
        return toArray(singleton(MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListSubheader, [["children", Interop_reactApi.Children.toArray([(category = category_1, createElement("div", {
            className: s.collapseButtonDiv,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, keyValueList(toList(delay(() => append_2(singleton_1(["className", s.buttonChevron]), delay(() => (expanded_1 ? singleton_1(["style", {
                transform: ("rotate(" + 90) + "deg)",
            }]) : empty_1()))))), 1))])]]), MuiHelpers_createElement(Typography, [["style", {
                userSelect: "none",
            }], ["variant", "subtitle1"], ["children", FilterCategory__get_AsString(category)]]), MuiHelpers_createElement(Badge, [["style", {
                marginLeft: "auto",
            }], ["badgeContent", FilterCategory__ActiveInCategory_Z66DEA559(category, model.Queries)]])]),
            onClick: (_arg1) => {
                dispatch(new Message(7, category));
            },
        }))])]]), MuiHelpers_createElement(Collapse, [["in", expanded_1], ["timeout", "auto"], ["collapsedHeight", 0], ["children", lst]])])]])));
    };
    const listItem$0027 = (onClick, isChecked, title) => MuiHelpers_createElement(ListItem, [["button", true], ["dense", true], ["key", title], ["onClick", onClick], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Checkbox, [["edge", "start"], ["tabIndex", -1], ["disableRipple", true], ["checked", isChecked], ["inputProps", {
        ["aria-labelledby"]: join(" ", ["labelid"]),
    }]])])]]), MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
    const loading = createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"]])]),
    });
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_2(singleton_1(MuiHelpers_createElement(IconButton, [["className", drawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["color", "inherit"], ["edge", "start"], ["onClick", (_arg2) => {
            setDrawerOpen(!drawerOpen);
        }], ["children", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])]])), delay(() => (model.Processing ? singleton_1(MuiHelpers_createElement(CircularProgress, [])) : empty_1())))))))]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                setDrawerOpen(false);
            })]),
        }), MuiHelpers_createElement(Divider, []), drawCategory(new FilterCategory(0), (matchValue = model.Companies, (matchValue != null) ? map((y_3) => {
            const active = model.Queries.Companies.some((y_4) => (y_3.Id === y_4));
            return listItem$0027((_arg4) => {
                let inputRecord;
                const pr = fetchUsers((inputRecord = model.Queries, new UserSearch(active ? model.Queries.Companies.filter((z) => (z !== y_3.Id)) : append(new Int32Array([y_3.Id]), model.Queries.Companies, Int32Array), inputRecord.Offices, inputRecord.Departments, inputRecord.Educations)), dispatch);
                pr.then();
            }, model.Queries.Companies.some((y_5) => (y_3.Id === y_5)), y_3.Name);
        }, sortBy((y_1) => y_1.Name, matchValue, {
            Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
        })) : loading)), drawCategory(new FilterCategory(1), (matchValue_1 = model.Offices, (matchValue_1 != null) ? map((y_9) => {
            const active_1 = model.Queries.Offices.some((y_10) => (y_9.Id === y_10));
            return listItem$0027((_arg5) => {
                let inputRecord_1;
                const pr_1 = fetchUsers((inputRecord_1 = model.Queries, new UserSearch(inputRecord_1.Companies, active_1 ? model.Queries.Offices.filter((z_1) => (z_1 !== y_9.Id)) : append(new Int32Array([y_9.Id]), model.Queries.Offices, Int32Array), inputRecord_1.Departments, inputRecord_1.Educations)), dispatch);
                pr_1.then();
            }, active_1, toText(printf("%s/%s"))(y_9.Company)(y_9.Name));
        }, sortBy((y_7) => y_7.Name, (x_6 = matchValue_1, (model.Queries.Companies.length <= 0) ? x_6 : x_6.filter((x_7) => model.Queries.Companies.some((y_6) => (x_7.CompanyId === y_6)))), {
            Compare: (x_9, y_8) => comparePrimitives(x_9, y_8),
        })) : loading)), drawCategory(new FilterCategory(2), (matchValue_2 = model.EducationLevels, (matchValue_2 != null) ? map((y_13) => {
            const active_2 = model.Queries.Educations.some((y_14) => (y_13.Id === y_14));
            return listItem$0027((_arg6) => {
                let inputRecord_2;
                const pr_2 = fetchUsers((inputRecord_2 = model.Queries, new UserSearch(inputRecord_2.Companies, inputRecord_2.Offices, inputRecord_2.Departments, active_2 ? model.Queries.Educations.filter((z_2) => (z_2 !== y_13.Id)) : append(new Int32Array([y_13.Id]), model.Queries.Educations, Int32Array))), dispatch);
                pr_2.then();
            }, active_2, toText(printf("%s"))(y_13.Name));
        }, sortBy((y_11) => y_11.Name, matchValue_2, {
            Compare: (x_12, y_12) => comparePrimitives(x_12, y_12),
        })) : loading))])]]), createElement("main", {
            className: drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_2(singleton_1(createElement("div", {
                className: s.drawerHeader,
            })), delay(() => {
                let matchValue_3;
                return append_2((matchValue_3 = model.ErrorMsg, (matchValue_3 != null) ? singleton_1(errorBanner(matchValue_3, (_arg7) => {
                    dispatch(new Message(8));
                })) : ((empty_1()))), delay(() => append_2(singleton_1(createElement("div", {
                    style: createObj(toList(delay(() => append_2(singleton_1(["padding", 2 + "vw"]), delay(() => append_2(singleton_1(["maxWidth", 100 + "vw"]), delay(() => ((model.SelectedUser != null) ? singleton_1(["overflow", "hidden"]) : singleton_1(["overflowX", "auto"]))))))))),
                    children: Interop_reactApi.Children.toArray([drawUserList(model, dispatch)]),
                })), delay(() => {
                    const matchValue_4 = model.SelectedUser;
                    if (matchValue_4 != null) {
                        const u = matchValue_4;
                        return singleton_1(drawSelectedUser(u, tryFind((x_14) => (x_14.id === u.Id), model.UserInfos), dispatch, detailstyle));
                    }
                    else {
                        return empty_1();
                    }
                }))));
            })))))),
        })]),
    });
}

export const userList = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchCompanies(dispatch);
        pr.then();
        const pr_1 = fetchOffices(dispatch);
        pr_1.then();
        const pr_2 = fetchEducationLevels(dispatch);
        pr_2.then();
        const pr_3 = fetchUsers(model_1.Queries, dispatch);
        pr_3.then();
    }, []);
    return view(model_1, dispatch);
}, void 0, uncurry(2, void 0), void 0, "userList", "/builds/serit/score/score/src/Client/UserList.fs", 902);

