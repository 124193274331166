import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, record_type, string_type, option_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Project$reflection } from "../Shared/ApiDataTypes.js";
import { View, init as init_1, update as update_1, Message$reflection as Message$reflection_1, Model$reflection as Model$reflection_1 } from "./ProjectAdmin.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { Cmd_none, Cmd_map, Cmd_OfPromise_perform } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { empty } from "../.fable/fable-library.3.2.4/List.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Sync from "@material-ui/icons/Sync";
import { empty as empty_1, append, singleton, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { loadingIndicatorCenteredSmall, errorHeader } from "../ViewHelpers.js";

export class LoadStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Loading", "Completed", "NotFound", "Failed"];
    }
}

export function LoadStatus$reflection() {
    return union_type("Project.LoadStatus", [], LoadStatus, () => [[], [], [], []]);
}

export class Model extends Record {
    constructor(ProjectId, ProjectData, ErrorMsg, ProjectAdminModel, Status) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.ProjectData = ProjectData;
        this.ErrorMsg = ErrorMsg;
        this.ProjectAdminModel = ProjectAdminModel;
        this.Status = Status;
    }
}

export function Model$reflection() {
    return record_type("Project.Model", [], Model, () => [["ProjectId", int32_type], ["ProjectData", option_type(Project$reflection())], ["ErrorMsg", option_type(string_type)], ["ProjectAdminModel", option_type(Model$reflection_1())], ["Status", LoadStatus$reflection()]]);
}

export class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectResponse", "ProjectAdminMessage", "Refetch"];
    }
}

export function Message$reflection() {
    return union_type("Project.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Project$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Project$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", Message$reflection_1()]], []]);
}

export function init(projId) {
    return [new Model(projId, void 0, void 0, void 0, new LoadStatus(0)), Cmd_OfPromise_perform(() => {
        const requestPath = toText(printf("/api/project/%i"))(projId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Project$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Project$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        }));
    }, void 0, (arg0) => (new Message(0, arg0)))];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1: {
            const matchValue = model.ProjectAdminModel;
            if (matchValue != null) {
                const patternInput_1 = update_1(msg.fields[0], matchValue);
                return [new Model(model.ProjectId, model.ProjectData, model.ErrorMsg, patternInput_1[0], model.Status), Cmd_map((arg0_1) => (new Message(1, arg0_1)), patternInput_1[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 2: {
            return [new Model(model.ProjectId, model.ProjectData, model.ErrorMsg, model.ProjectAdminModel, new LoadStatus(0)), Cmd_OfPromise_perform(() => {
                const requestPath = toText(printf("/api/project/%i"))(model.ProjectId);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Project$reflection,
                    });
                    return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: Project$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                }));
            }, void 0, (arg0_2) => (new Message(0, arg0_2)))];
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const err = res.fields[0];
                let pattern_matching_result;
                if (err.tag === 2) {
                    if ((err.fields[0].status) === 404) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return [new Model(model.ProjectId, model.ProjectData, model.ErrorMsg, model.ProjectAdminModel, new LoadStatus(2)), Cmd_none()];
                    }
                    case 1: {
                        return [new Model(model.ProjectId, model.ProjectData, "Noe gikk galt. Kunne ikke laste prosjektdata.", model.ProjectAdminModel, new LoadStatus(3)), Cmd_none()];
                    }
                }
            }
            else {
                const x = res.fields[0];
                const patternInput = init_1(x);
                return [new Model(model.ProjectId, x, model.ErrorMsg, patternInput[0], new LoadStatus(1)), Cmd_map((arg0) => (new Message(1, arg0)), patternInput[1])];
            }
        }
    }
}

function drawNotFound(dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 100 + "%",
            height: 80 + "vh",
            textAlign: "center",
            padding: 5 + "vw",
        },
        children: Interop_reactApi.Children.toArray([createElement("h3", {
            className: "title is-3",
            children: "Fant ikke prosjektet.",
        }), createElement("p", {
            children: "Kunne ikke laste prosjektet fordi det ikke ble funnet. Prosjektet kan være slettet. Pass på at nettadressen er korrekt.",
        }), MuiHelpers_createElement(Button, [["style", {
            marginTop: 50,
        }], ["onClick", (_arg1) => {
            dispatch(new Message(2));
        }], ["startIcon", MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Sync, {})])]])], ["children", "Prøv på nytt"]])]),
    });
}

export function view(model, dispatch) {
    const children = toList(delay(() => {
        let matchValue_1;
        const matchValue = model.ProjectAdminModel;
        if (matchValue != null) {
            const mdl = matchValue;
            return singleton(createElement(View, {
                model: mdl,
                dispatch: (arg) => {
                    dispatch(new Message(1, arg));
                },
            }));
        }
        else {
            return append((matchValue_1 = model.ErrorMsg, (matchValue_1 != null) ? singleton(errorHeader(matchValue_1)) : ((empty_1()))), delay(() => {
                const matchValue_2 = model.Status;
                switch (matchValue_2.tag) {
                    case 0: {
                        return singleton(loadingIndicatorCenteredSmall());
                    }
                    case 2: {
                        return singleton(drawNotFound(dispatch));
                    }
                    default: {
                        return empty_1();
                    }
                }
            }));
        }
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

