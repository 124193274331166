import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { class_type, array_type, int32_type, union_type, record_type, option_type, bool_type, string_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { view as view_1, update as update_1, init as init_1, Message$reflection as Message$reflection_1, Model$reflection as Model$reflection_1 } from "./ProjectEdit.js";
import { view, init as init_2, update as update_2, Message$reflection as Message$reflection_2, Model$reflection as Model$reflection_2 } from "./ProjectPlan.js";
import { Project$reflection, Helpers_ResultMessage$reflection } from "../Shared/ApiDataTypes.js";
import { Cmd_none, Cmd_map, Cmd_OfPromise_either, Cmd_batch } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Project_deleteProject, ProjectPermission_getUserPermissionsInProject } from "../Promises.js";
import { singleton as singleton_1, ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { ProjectPermission, hasProjectPermission } from "./ProjectPermission.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import { Cons } from "../Log.js";
import { toText, printf } from "../.fable/fable-library.3.2.4/String.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { singleton, empty, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { RotationAngle, rotatingChevronButton, loadingIndicatorSmall, errorHeader } from "../ViewHelpers.js";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { op_UnaryNegation_Int32 } from "../.fable/fable-library.3.2.4/Int32.js";
import { useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { ProjectStatus__ColorCode, ProjectStatus_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { projectReg } from "./ProjectRegistration.js";
import { ProjectRole } from "./ProjectRole.js";
import { ProjectNotification } from "./ProjectNotification.js";
import { fullscreenProjectPrint } from "./ProjectPrint.js";
import { wastePDF } from "../Waste/WastePDF.js";
import { HmsReg } from "../HMS/HmsRegistration.js";
import { wasteView } from "./WasteView.js";
import { QualityRegistration } from "../Quality/ProjectQuality.js";

export class DeleteForm extends Record {
    constructor(ConfirmationInput, ProcessingRequest, ErrorMsg) {
        super();
        this.ConfirmationInput = ConfirmationInput;
        this.ProcessingRequest = ProcessingRequest;
        this.ErrorMsg = ErrorMsg;
    }
}

export function DeleteForm$reflection() {
    return record_type("ProjectAdmin.DeleteForm", [], DeleteForm, () => [["ConfirmationInput", string_type], ["ProcessingRequest", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

export class ProjectAdminTab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Edit", "Registration", "Roles", "Plan", "Notification", "Permission", "Delete", "Print", "WastePrint", "HmsRegistration", "Waste", "QualityControl"];
    }
}

export function ProjectAdminTab$reflection() {
    return union_type("ProjectAdmin.ProjectAdminTab", [], ProjectAdminTab, () => [[["Item", Model$reflection_1()]], [], [], [["Item", Model$reflection_2()]], [], [], [["Item", DeleteForm$reflection()]], [], [], [], [], []]);
}

export class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EditMessage", "PlanMessage", "PermissionsResponse", "PermissionsError", "GotoEdit", "GotoPlan", "GotoDelete", "GotoTab", "UpdateDeleteConfirmation", "ConfirmDelete", "DeleteProjectResponse", "DeleteProjectError", "PrintProjectPlan"];
    }
}

export function Message$reflection() {
    return union_type("ProjectAdmin.Message", [], Message, () => [[["Item", Message$reflection_1()]], [["Item", Message$reflection_2()]], [["Item", array_type(int32_type)]], [["Item", class_type("System.Exception")]], [], [], [], [["Item", ProjectAdminTab$reflection()]], [["Item", string_type]], [], [["Item", Helpers_ResultMessage$reflection()]], [["Item", class_type("System.Exception")]], []]);
}

export class Model extends Record {
    constructor(SelectedTab, Project, EditPermission) {
        super();
        this.SelectedTab = SelectedTab;
        this.Project = Project;
        this.EditPermission = EditPermission;
    }
}

export function Model$reflection() {
    return record_type("ProjectAdmin.Model", [], Model, () => [["SelectedTab", ProjectAdminTab$reflection()], ["Project", Project$reflection()], ["EditPermission", bool_type]]);
}

export function init(proj) {
    const patternInput = init_1(proj.Id, void 0, void 0, void 0);
    return [new Model(new ProjectAdminTab(0, patternInput[0]), proj, false), Cmd_batch(ofArray([Cmd_OfPromise_either((projId) => ProjectPermission_getUserPermissionsInProject(projId), proj.Id, (arg0) => (new Message(2, arg0)), (arg0_1) => (new Message(3, arg0_1))), Cmd_map((arg0_2) => (new Message(0, arg0_2)), patternInput[1])]))];
}

export function initWithPreloadedData(proj, builders, outcomeTypes, companies) {
    const patternInput = init_1(proj.Id, builders, outcomeTypes, companies);
    return [new Model(new ProjectAdminTab(0, patternInput[0]), proj, false), Cmd_batch(ofArray([Cmd_OfPromise_either((projId) => ProjectPermission_getUserPermissionsInProject(projId), proj.Id, (arg0) => (new Message(2, arg0)), (arg0_1) => (new Message(3, arg0_1))), Cmd_map((arg0_2) => (new Message(0, arg0_2)), patternInput[1])]))];
}

export function update(msg, model) {
    const matchValue = [msg, model.SelectedTab];
    let pattern_matching_result, emdl, emsg, pmdl, pmsg, perms, e, x, deleteForm_1, inpt, deleteForm_2, deleteForm_3, resp, deleteForm_4;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 1;
            pmdl = matchValue[1].fields[0];
            pmsg = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 2) {
        pattern_matching_result = 2;
        perms = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 3) {
        pattern_matching_result = 3;
        e = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 4) {
        pattern_matching_result = 4;
    }
    else if (matchValue[0].tag === 5) {
        pattern_matching_result = 5;
    }
    else if (matchValue[0].tag === 6) {
        pattern_matching_result = 6;
    }
    else if (matchValue[0].tag === 7) {
        pattern_matching_result = 7;
        x = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 8;
            deleteForm_1 = matchValue[1].fields[0];
            inpt = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 9) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 9;
            deleteForm_2 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 10) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 10;
            deleteForm_3 = matchValue[1].fields[0];
            resp = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 11) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 11;
            deleteForm_4 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 13;
        }
    }
    else if (matchValue[0].tag === 12) {
        pattern_matching_result = 12;
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 0;
        emdl = matchValue[1].fields[0];
        emsg = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 13;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(emsg, emdl);
            return [new Model(new ProjectAdminTab(0, patternInput[0]), model.Project, model.EditPermission), Cmd_map((arg0) => (new Message(0, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(pmsg, pmdl);
            return [new Model(new ProjectAdminTab(3, patternInput_1[0]), model.Project, model.EditPermission), Cmd_map((arg0_1) => (new Message(1, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            return [new Model(model.SelectedTab, model.Project, hasProjectPermission(new Functionality(2), perms) ? true : hasProjectPermission(new Functionality(54), perms)), Cmd_none()];
        }
        case 3: {
            const arg10 = toString(e);
            Cons(printf("ProjectAdmin: PermissionsError: %s"))(arg10);
            return [model, Cmd_none()];
        }
        case 4: {
            const patternInput_2 = init_1(model.Project.Id, void 0, void 0, void 0);
            return [new Model(new ProjectAdminTab(0, patternInput_2[0]), model.Project, model.EditPermission), Cmd_map((arg0_2) => (new Message(0, arg0_2)), patternInput_2[1])];
        }
        case 5: {
            const patternInput_3 = init_2(model.Project);
            return [new Model(new ProjectAdminTab(3, patternInput_3[0]), model.Project, model.EditPermission), Cmd_map((arg0_3) => (new Message(1, arg0_3)), patternInput_3[1])];
        }
        case 6: {
            return [new Model(new ProjectAdminTab(6, new DeleteForm("", false, void 0)), model.Project, model.EditPermission), Cmd_none()];
        }
        case 7: {
            return [new Model(x, model.Project, model.EditPermission), Cmd_none()];
        }
        case 8: {
            return [new Model(new ProjectAdminTab(6, new DeleteForm(inpt, deleteForm_1.ProcessingRequest, deleteForm_1.ErrorMsg)), model.Project, model.EditPermission), Cmd_none()];
        }
        case 9: {
            if (deleteForm_2.ConfirmationInput === model.Project.Name) {
                return [new Model(new ProjectAdminTab(6, new DeleteForm(deleteForm_2.ConfirmationInput, true, void 0)), model.Project, model.EditPermission), Cmd_OfPromise_either((projId) => Project_deleteProject(projId), model.Project.Id, (arg0_4) => (new Message(10, arg0_4)), (arg0_5) => (new Message(11, arg0_5)))];
            }
            else {
                return [new Model(new ProjectAdminTab(6, new DeleteForm(deleteForm_2.ConfirmationInput, deleteForm_2.ProcessingRequest, "Bekreftelses-input stemmer ikke med prosjektnavn")), model.Project, model.EditPermission), Cmd_none()];
            }
        }
        case 10: {
            if (resp.Result === "success") {
                window.location.reload();
                return [model, Cmd_none()];
            }
            else {
                return [new Model(new ProjectAdminTab(6, new DeleteForm(deleteForm_3.ConfirmationInput, deleteForm_3.ProcessingRequest, resp.Message)), model.Project, model.EditPermission), Cmd_none()];
            }
        }
        case 11: {
            return [new Model(new ProjectAdminTab(6, new DeleteForm(deleteForm_4.ConfirmationInput, deleteForm_4.ProcessingRequest, "Noe gikk galt. Kunne ikke slette prosjekt")), model.Project, model.EditPermission), Cmd_none()];
        }
        case 12: {
            window.print();
            return [model, Cmd_none()];
        }
        case 13: {
            return [model, Cmd_none()];
        }
    }
}

function deleteView(model, dispatch, deleteForm) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 70 + "vh",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                maxWidth: 800,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let matchValue;
                return append((matchValue = deleteForm.ErrorMsg, (matchValue == null) ? ((empty())) : singleton(errorHeader(matchValue))), delay(() => {
                    let value_11;
                    return append(singleton((value_11 = toText(printf("Ønsker du å slette prosjektet %s?"))(model.Project.Name), createElement("h3", {
                        children: [value_11],
                    }))), delay(() => append(singleton(createElement("p", {
                        children: ["Hvis du sletter prosjektet blir all data tilhørende dette prosjektet også slettet, med unntak av HMS-registreringer og logger. Når dataene er slettet er det ikke mulig å gjennopprette disse."],
                    })), delay(() => append(singleton(createElement("p", {
                        children: ["Dersom du kun ønsker å avslutte prosjektet kan du gjøre det under \"Rediger\""],
                    })), delay(() => append(singleton(createElement("p", {
                        children: ["Bekreft sletting av prosjekt ved å skrive inn navnet på prosjektet i feltet under og trykk på \"Slett prosjekt\""],
                    })), delay(() => {
                        let children;
                        return singleton((children = toList(delay(() => ((!deleteForm.ProcessingRequest) ? append(singleton(createElement("input", {
                            type: "text",
                            className: "form-control",
                            onChange: (ev) => {
                                dispatch(new Message(8, ev.target.value));
                            },
                            placeholder: "Prosjektnavn (case-sensitiv)",
                        })), delay(() => ((model.Project.Name === deleteForm.ConfirmationInput) ? singleton(createElement("button", {
                            className: "btn btn-danger",
                            onClick: (_arg1) => {
                                dispatch(new Message(9));
                            },
                            children: "Slett prosjekt",
                        })) : empty()))) : singleton(loadingIndicatorSmall())))), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children)),
                        })));
                    }))))))));
                }));
            })))),
        })]),
    });
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let arg10_3;
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["display", "flex"], ["height", toText(printf("calc(100vh - %ipx)"))(52)]]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["top", 52]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(240)], ["marginLeft", 240]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, singleton_1(["color", "#FFFFFF"]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton_1(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["top", 52], ["max-height", toText(printf("calc(100vh - %ipx)"))(52)]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append(singleton(["display", "flex"]), delay(() => append(singleton(["alignItems", "center"]), delay(() => append(singleton(["padding", theme.spacing(0, 1)]), delay(() => append(Object.entries(theme.mixins.toolbar), delay(() => singleton(["justifyContent", "space-between"]))))))))))));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        content: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", op_UnaryNegation_Int32(240)], ["overflowY", "scroll"], ["width", 100 + "%"]])),
        contentShift: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", (arg10_3 = ((240 + 30) | 0), toText(printf("calc(100vw - %ipx)"))(arg10_3))], ["minWidth", 400]])),
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        menuEntry: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["padding", 16], ["paddingBottom", 8], ["paddingTop", 8]])),
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

export function View(viewInputProps) {
    const dispatch = viewInputProps.dispatch;
    const model = viewInputProps.model;
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setDrawerOpen = patternInput[1];
    const drawerOpen = patternInput[0];
    const status = ProjectStatus_FromInt_Z524259A4(model.Project.Status);
    const menuButton$0027 = (title, icon, isActive, onClick) => MuiHelpers_createElement(ListItem, [["selected", isActive], ["button", true], ["key", title], ["onClick", onClick], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: icon,
    })])]]), MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
    const downloadButton = (title_1, href$0027) => MuiHelpers_createElement(ListItem, [["href", href$0027], ["target", "_blank"], ["button", true], ["component", "a"], ["key", title_1], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: "fas fa-download",
    })])]]), MuiHelpers_createElement(ListItemText, [["style", {
        color: "#757575",
    }], ["children", Interop_reactApi.Children.toArray([title_1])]])])]]);
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["style", {
            backgroundColor: ProjectStatus__ColorCode(status),
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["className", drawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["style", {
            color: "#FFFFFF",
        }], ["color", "inherit"], ["edge", "start"], ["onClick", (_arg1) => {
            setDrawerOpen(!drawerOpen);
        }], ["children", createElement("i", {
            className: "fas fa-bars",
        })]]), MuiHelpers_createElement(Typography, [["children", model.Project.Name]])])]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], ["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["children", Interop_reactApi.Children.toArray([createElement("i", {
                className: "fas fa-times",
                style: {
                    color: "#757575",
                },
            })])], ["component", "a"], ["href", "/projects"]]), rotatingChevronButton(new RotationAngle(1), () => {
                setDrawerOpen(false);
            })]),
        }), MuiHelpers_createElement(Divider, []), MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListSubheader, [["style", {
            backgroundColor: "#FFFFFF",
        }], ["children", "Prosjektinfo"]]), menuButton$0027("Rediger", "fas fa-edit", (model.SelectedTab.tag === 0) ? true : false, (_arg3) => {
            dispatch(new Message(4));
        }), menuButton$0027("Roller", "fas fa-users", (model.SelectedTab.tag === 2) ? true : false, (_arg4) => {
            dispatch(new Message(7, new ProjectAdminTab(2)));
        }), menuButton$0027("Prosjektplan", "fas fa-sitemap", (model.SelectedTab.tag === 3) ? true : false, (_arg5) => {
            dispatch(new Message(5));
        }), menuButton$0027("Varsling", "fas fa-bell", (model.SelectedTab.tag === 4) ? true : false, (_arg6) => {
            dispatch(new Message(7, new ProjectAdminTab(4)));
        }), menuButton$0027("Rettighetsstyring", "fas fa-key", (model.SelectedTab.tag === 5) ? true : false, (_arg7) => {
            dispatch(new Message(7, new ProjectAdminTab(5)));
        }), MuiHelpers_createElement(ListSubheader, [["style", {
            backgroundColor: "#FFFFFF",
        }], ["children", "Rapportering"]]), menuButton$0027("HMS", "fas fa-clipboard", (model.SelectedTab.tag === 9) ? true : false, (_arg8) => {
            dispatch(new Message(7, new ProjectAdminTab(9)));
        }), menuButton$0027("Sorteringsgrad", "fas fa-clipboard", (model.SelectedTab.tag === 10) ? true : false, (_arg9) => {
            dispatch(new Message(7, new ProjectAdminTab(10)));
        }), MuiHelpers_createElement(ListSubheader, [["style", {
            backgroundColor: "#FFFFFF",
        }], ["children", "Prosjektavslutning"]]), menuButton$0027("Kvalitet", "fas fa-poll", (model.SelectedTab.tag === 11) ? true : false, (_arg10) => {
            dispatch(new Message(7, new ProjectAdminTab(11)));
        }), menuButton$0027("Sluttrapport avfall", "fas fa-print", (model.SelectedTab.tag === 8) ? true : false, (_arg11) => {
            dispatch(new Message(7, new ProjectAdminTab(8)));
        }), MuiHelpers_createElement(ListSubheader, [["style", {
            backgroundColor: "#FFFFFF",
        }], ["children", "Last ned"]]), downloadButton("Prosjektdokumenter", toText(printf("/downloadproject/%i"))(model.Project.Id)), downloadButton("Attester", toText(printf("/download/attestations/%i"))(model.Project.Id)), MuiHelpers_createElement(ListSubheader, [["style", {
            backgroundColor: "#FFFFFF",
        }], ["children", "Mer"]]), menuButton$0027("Slett", "fas fa-trash", (model.SelectedTab.tag === 6) ? true : false, (_arg12) => {
            dispatch(new Message(6));
        })])]]), createElement("div", {
            style: {
                marginBottom: 100,
            },
        })])]]), createElement("main", {
            className: drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("div", {
                className: s.drawerHeader,
            })), delay(() => {
                const matchValue_10 = model.SelectedTab;
                switch (matchValue_10.tag) {
                    case 1: {
                        return singleton(projectReg(model.Project.Id));
                    }
                    case 2: {
                        return singleton(createElement(ProjectRole, {
                            projectId: model.Project.Id,
                        }));
                    }
                    case 3: {
                        return singleton(view(matchValue_10.fields[0], (arg_1) => {
                            dispatch(new Message(1, arg_1));
                        }));
                    }
                    case 4: {
                        return singleton(createElement(ProjectNotification, {
                            projectId: model.Project.Id,
                        }));
                    }
                    case 5: {
                        return singleton(createElement(ProjectPermission, {
                            projectId: model.Project.Id,
                        }));
                    }
                    case 6: {
                        return singleton(deleteView(model, dispatch, matchValue_10.fields[0]));
                    }
                    case 7: {
                        return singleton(fullscreenProjectPrint(model.Project.Id));
                    }
                    case 8: {
                        return singleton(wastePDF(model.Project.Id));
                    }
                    case 9: {
                        return singleton(createElement(HmsReg, {
                            projectId: model.Project.Id,
                        }));
                    }
                    case 10: {
                        return singleton(wasteView({
                            projectId: model.Project.Id,
                        }));
                    }
                    case 11: {
                        return singleton(createElement(QualityRegistration, {
                            projectId: model.Project.Id,
                        }));
                    }
                    default: {
                        return singleton(view_1(matchValue_10.fields[0], (arg) => {
                            dispatch(new Message(0, arg));
                        }));
                    }
                }
            })))))),
        })]),
    });
}

