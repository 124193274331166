import { compare, now, toUniversalTime, toString, parse, addMonths, create, month as month_1, year as year_1, daysInMonth } from "./.fable/fable-library.3.2.4/Date.js";
import { sortByDescending, append } from "./.fable/fable-library.3.2.4/Array.js";
import { compareArrays } from "./.fable/fable-library.3.2.4/Util.js";
import { join, split } from "./.fable/fable-library.3.2.4/String.js";
import { take, map } from "./.fable/fable-library.3.2.4/Seq.js";

export function tryFocus(elemId) {
    try {
        window.document.getElementById(elemId).focus();
    }
    catch (matchValue) {
    }
}

export function toLastDayOfMonth(dt) {
    const numDaysInMonth = daysInMonth(year_1(dt), month_1(dt)) | 0;
    return create(year_1(dt), month_1(dt), numDaysInMonth, 23, 59, 59);
}

export function toFirstDayOfMonth(dt) {
    return create(year_1(dt), month_1(dt), 1, 0, 0, 0);
}

export function toFirstDayOfPrevMonth(dt) {
    const tmp = addMonths(dt, -1);
    return create(year_1(tmp), month_1(tmp), 1, 0, 0, 0);
}

export function toLastDayOfPrevMonth(dt) {
    const tmp = addMonths(dt, -1);
    const numDaysInMonth = daysInMonth(year_1(tmp), month_1(tmp)) | 0;
    return create(year_1(tmp), month_1(tmp), numDaysInMonth, 23, 59, 59);
}

export function fromUtcToLocalString(utcString) {
    try {
        let copyOfStruct = parse(utcString);
        return toString(copyOfStruct, "yyyy-MM-dd HH:mm");
    }
    catch (matchValue) {
        return utcString;
    }
}

export function fromUtcToLocalDate(utcString) {
    try {
        let copyOfStruct = parse(utcString);
        return toString(copyOfStruct, "dd/MM/yyyy");
    }
    catch (matchValue) {
        return utcString;
    }
}

export function toUtcString(dt) {
    let copyOfStruct = toUniversalTime(dt);
    return toString(copyOfStruct, "yyyy-MM-dd HH:mmZ");
}

export function fromUtcString(dt) {
    try {
        return parse(dt);
    }
    catch (matchValue) {
        return now();
    }
}

export function toDateString(dt) {
    return toString(dt, "yyyy-MM-dd");
}

export function toDateStringNO(dt) {
    return toString(dt, "dd/MM/yy");
}

export function tryParseDateString(dt) {
    if (dt === "") {
        return void 0;
    }
    else {
        try {
            return parse(dt);
        }
        catch (matchValue) {
            return void 0;
        }
    }
}

export function asMonth(monthId) {
    switch (monthId) {
        case 1: {
            return "Januar";
        }
        case 2: {
            return "Februar";
        }
        case 3: {
            return "Mars";
        }
        case 4: {
            return "April";
        }
        case 5: {
            return "Mai";
        }
        case 6: {
            return "Juni";
        }
        case 7: {
            return "Juli";
        }
        case 8: {
            return "August";
        }
        case 9: {
            return "September";
        }
        case 10: {
            return "Oktober";
        }
        case 11: {
            return "November";
        }
        default: {
            return "Desember";
        }
    }
}

export function asAbbrivatedMonth(monthId) {
    switch (monthId) {
        case 1: {
            return "Jan";
        }
        case 2: {
            return "Feb";
        }
        case 3: {
            return "Mar";
        }
        case 4: {
            return "Apr";
        }
        case 5: {
            return "Mai";
        }
        case 6: {
            return "Jun";
        }
        case 7: {
            return "Jul";
        }
        case 8: {
            return "Aug";
        }
        case 9: {
            return "Sep";
        }
        case 10: {
            return "Okt";
        }
        case 11: {
            return "Nov";
        }
        default: {
            return "Des";
        }
    }
}

export function monthsInPeriod(periodStart, periodEnd) {
    const buildMonths = (x_mut, entries_mut) => {
        buildMonths:
        while (true) {
            const x = x_mut, entries = entries_mut;
            if (compare(x, periodEnd) > 0) {
                if ((year_1(x) === year_1(periodEnd)) ? (month_1(x) === month_1(periodEnd)) : false) {
                    return append([[year_1(x), month_1(x)]], entries);
                }
                else {
                    return entries;
                }
            }
            else {
                const y = append([[year_1(x), month_1(x)]], entries);
                x_mut = addMonths(x, 1);
                entries_mut = y;
                continue buildMonths;
            }
            break;
        }
    };
    return sortByDescending((tupledArg) => [tupledArg[0], tupledArg[1]], buildMonths(periodStart, []), {
        Compare: (x_1, y_1) => compareArrays(x_1, y_1),
    });
}

export function numWholeMonthsBetween(startDate, endDate) {
    if (compare(startDate, endDate) >= 0) {
        return 0;
    }
    else {
        const x = (y_mut, c_mut) => {
            x:
            while (true) {
                const y = y_mut, c = c_mut;
                if ((year_1(y) === year_1(startDate)) ? (month_1(y) === month_1(startDate)) : false) {
                    return c | 0;
                }
                else if (c < 100) {
                    y_mut = addMonths(y, -1);
                    c_mut = (c + 1);
                    continue x;
                }
                else {
                    return c | 0;
                }
                break;
            }
        };
        return x(endDate, 0) | 0;
    }
}

export function trySetValue(elemId, x) {
    try {
        window.document.getElementById(elemId).value = x;
    }
    catch (matchValue) {
    }
}

export function parseFileExt(fileExt) {
    let x;
    try {
        if ((x = fileExt, (x === "") ? true : (x == null))) {
            return "";
        }
        else {
            const y = split(fileExt, ["."], null, 0);
            return (y.length > 1) ? y[y.length - 1].toLocaleLowerCase() : "";
        }
    }
    catch (matchValue) {
        return "";
    }
}

export function take50FromString(s) {
    if ((s.length < 50) ? true : (s.length <= 0)) {
        return s;
    }
    else {
        return join("", map((value) => value, take(50, s.split("")))) + "...";
    }
}

