import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { empty as empty_1, item as item_1, length, singleton } from "../.fable/fable-library.3.2.4/List.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { parseFileExt, toDateStringNO } from "../Utils.js";
import { addMonths, utcNow } from "../.fable/fable-library.3.2.4/Date.js";
import { Helpers_ResultMessage$reflection, ProcessActivityDoc$reflection, ProcessActivityDocForm$reflection, ProcessActivityDocForm, ProcessActivityDoc } from "../Shared/ApiDataTypes.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty, map, singleton as singleton_1, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { PageElements_filenameToIcon, snackbarError } from "../ViewHelpers.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { record_type, lambda_type, unit_type, int32_type, obj_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Record } from "../.fable/fable-library.3.2.4/Types.js";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        tf: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["margin", 10])),
    };
    return Object.assign({}, o);
});

export const documentForm = FunctionComponent_Of_Z5A158BBF((props) => {
    let copyOfStruct;
    const patternInput = useFeliz_React__React_useState_Static_1505(new ProcessActivityDocForm(new ProcessActivityDoc(-1, props.activityId, -1, void 0, void 0, "", "", void 0, void 0, toDateStringNO((copyOfStruct = utcNow(), addMonths(copyOfStruct, 6))), void 0, [], "", false, 1, "", 1), "", "", ""));
    const updateForm = patternInput[1];
    const documentForm_1 = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const setErrorMsg = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const docSelected = patternInput_5[0];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(snackbarError(patternInput_4[0], () => {
            setErrorMsg(void 0);
        })), delay(() => ((result == null) ? append(singleton_1(createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("label", {
                style: {
                    cursor: "pointer",
                    textAlign: "center",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        display: "block",
                        fontSize: 2 + "rem",
                    },
                    className: "fas fa-cloud-upload-alt",
                }), (!docSelected) ? "Velg fil" : "Velg en annen fil", createElement("input", {
                    type: "file",
                    onChange: (ev) => {
                        let x_3, arg10_1, arg20;
                        const fileList = ev.target.files;
                        if (!(fileList == null)) {
                            const x_2 = toList(delay(() => map((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                            try {
                                if (length(x_2) > 0) {
                                    const file = item_1(0, x_2);
                                    const fileExt = parseFileExt(file.name);
                                    let filename;
                                    const matchValue = file.name;
                                    filename = (((x_3 = matchValue, (x_3 === "") ? true : (x_3 == null))) ? toText(printf("fil.%s"))(fileExt) : matchValue);
                                    console.log(some((arg10_1 = (file.size | 0), toText(printf("filesize: %i"))(arg10_1))));
                                    console.log(some((arg20 = file.name, toText(printf("filename: %s (%s)"))(filename)(arg20))));
                                    const reader = new FileReader();
                                    reader.addEventListener("load", (_arg3) => {
                                        let inputRecord;
                                        updateForm(new ProcessActivityDocForm(documentForm_1.Entry, filename, fileExt, reader.result));
                                        patternInput_5[1](true);
                                        if (documentForm_1.Entry.Name === "") {
                                            updateForm(new ProcessActivityDocForm((inputRecord = documentForm_1.Entry, new ProcessActivityDoc(inputRecord.Id, inputRecord.ActivityId, inputRecord.FileStoreId, inputRecord.PdfFileStoreId, inputRecord.SupFileStoreId, filename, inputRecord.Filename, inputRecord.PdfFilename, inputRecord.SupFilename, inputRecord.NextRevision, inputRecord.LastRevision, inputRecord.Tags, inputRecord.Desc, inputRecord.CompanyVersions, inputRecord.Version, inputRecord.RowPrefix, inputRecord.RowPos)), documentForm_1.Filename, documentForm_1.FileExt, documentForm_1.FileContent));
                                        }
                                    });
                                    const value_26 = reader.readAsDataURL(file);
                                }
                            }
                            catch (matchValue_1) {
                            }
                        }
                    },
                    style: {
                        display: "none",
                    },
                })]),
            })), delay(() => ((!docSelected) ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: "Ingen fil valgt",
            })) : singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(documentForm_1.Filename), documentForm_1.Filename]),
            }))))))))),
        })), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
            let inputRecord_1;
            updateForm(new ProcessActivityDocForm((inputRecord_1 = documentForm_1.Entry, new ProcessActivityDoc(inputRecord_1.Id, inputRecord_1.ActivityId, inputRecord_1.FileStoreId, inputRecord_1.PdfFileStoreId, inputRecord_1.SupFileStoreId, inputRecord_1.Name, inputRecord_1.Filename, inputRecord_1.PdfFilename, inputRecord_1.SupFilename, inputRecord_1.NextRevision, inputRecord_1.LastRevision, inputRecord_1.Tags, inputRecord_1.Desc, inputRecord_1.CompanyVersions, inputRecord_1.Version, Browser_Types_Event__Event_get_Value(e), inputRecord_1.RowPos)), documentForm_1.Filename, documentForm_1.FileExt, documentForm_1.FileContent));
        }], ["label", "Dokumentkode/prefix (valgfritt)"], ["defaultValue", documentForm_1.Entry.RowPrefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["required", true]), delay(() => append(singleton_1(["fullWidth", true]), delay(() => append(singleton_1(["onChange", (e_1) => {
            let inputRecord_2;
            const x_7 = Browser_Types_Event__Event_get_Value(e_1);
            updateForm(new ProcessActivityDocForm((inputRecord_2 = documentForm_1.Entry, new ProcessActivityDoc(inputRecord_2.Id, inputRecord_2.ActivityId, inputRecord_2.FileStoreId, inputRecord_2.PdfFileStoreId, inputRecord_2.SupFileStoreId, x_7, inputRecord_2.Filename, inputRecord_2.PdfFilename, inputRecord_2.SupFilename, inputRecord_2.NextRevision, inputRecord_2.LastRevision, inputRecord_2.Tags, inputRecord_2.Desc, inputRecord_2.CompanyVersions, inputRecord_2.Version, inputRecord_2.RowPrefix, inputRecord_2.RowPos)), documentForm_1.Filename, documentForm_1.FileExt, documentForm_1.FileContent));
            setValidName(x_7.length > 0);
        }]), delay(() => append(singleton_1(["label", "Dokumentnavn"]), delay(() => append(singleton_1(["defaultValue", documentForm_1.Entry.Name]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Dokumentnavn kan ikke være tomt"]))) : empty()))))))))))))))))))))), delay(() => append((documentForm_1.Entry.RowPrefix.length > 0) ? singleton_1(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                marginLeft: 10,
                fontStyle: "italic",
            },
            children: toText(printf("%s - %s"))(documentForm_1.Entry.RowPrefix)(documentForm_1.Entry.Name),
        })) : empty(), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
            let inputRecord_3;
            updateForm(new ProcessActivityDocForm((inputRecord_3 = documentForm_1.Entry, new ProcessActivityDoc(inputRecord_3.Id, inputRecord_3.ActivityId, inputRecord_3.FileStoreId, inputRecord_3.PdfFileStoreId, inputRecord_3.SupFileStoreId, inputRecord_3.Name, inputRecord_3.Filename, inputRecord_3.PdfFilename, inputRecord_3.SupFilename, inputRecord_3.NextRevision, inputRecord_3.LastRevision, inputRecord_3.Tags, Browser_Types_Event__Event_get_Value(e_2), inputRecord_3.CompanyVersions, inputRecord_3.Version, inputRecord_3.RowPrefix, inputRecord_3.RowPos)), documentForm_1.Filename, documentForm_1.FileExt, documentForm_1.FileContent));
        }], ["label", "Beskrivelse"], ["defaultValue", documentForm_1.Entry.Desc], ["variant", "outlined"]])), delay(() => singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["className", s.tf]), delay(() => append(singleton_1(["size", "small"]), delay(() => append(singleton_1(["onChange", (e_3) => {
            let inputRecord_4;
            const x_9 = Browser_Types_Event__Event_get_Value(e_3);
            try {
                const asInt = parse(x_9, 511, false, 32) | 0;
                updateForm(new ProcessActivityDocForm((inputRecord_4 = documentForm_1.Entry, new ProcessActivityDoc(inputRecord_4.Id, inputRecord_4.ActivityId, inputRecord_4.FileStoreId, inputRecord_4.PdfFileStoreId, inputRecord_4.SupFileStoreId, inputRecord_4.Name, inputRecord_4.Filename, inputRecord_4.PdfFilename, inputRecord_4.SupFilename, inputRecord_4.NextRevision, inputRecord_4.LastRevision, inputRecord_4.Tags, inputRecord_4.Desc, inputRecord_4.CompanyVersions, inputRecord_4.Version, inputRecord_4.RowPrefix, asInt)), documentForm_1.Filename, documentForm_1.FileExt, documentForm_1.FileContent));
                setValidRowPos(asInt > 0);
            }
            catch (matchValue_2) {
                setValidRowPos(false);
            }
        }]), delay(() => append(singleton_1(["label", "Radnummer"]), delay(() => append(singleton_1(["defaultValue", documentForm_1.Entry.RowPos]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["inputProps", {
            style: {
                textAlign: "center",
                maxWidth: 80,
            },
        }]), delay(() => ((!validRowPos) ? append(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty()))))))))))))))))))))))))))))) : singleton_1(createElement("span", {
            children: ["Dokument lagt til"],
        }))))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg4) => {
        props.onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Legg til"], ["onClick", (_arg5) => {
        if (((documentForm_1.Entry.Name.length > 0) ? validRowPos : false) ? docSelected : false) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: ProcessActivityDocForm$reflection,
                })(documentForm_1);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: ProcessActivityDoc$reflection,
                });
                return Fetch_tryPost_5760677E("/api/processactivitydoc", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: ProcessActivityDoc$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    patternInput_3[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    setErrorMsg("Noe gikk galt. Kunne ikke legge til dokument");
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
        else {
            setValidName(false);
        }
    }]])) : empty())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "documentForm", "/builds/serit/score/score/src/Client/Administration/ProcessActivityDoc.fs", 2585);

const deleteForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput_1[0];
    const setError = useFeliz_React__React_useState_Static_1505(void 0)[1];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett dokument"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!result) ? append(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette dette dokumentet?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle tilhørende versjoner",
        })))) : singleton_1(createElement("span", {
            children: ["Dokument slettet"],
        }))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        props.onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => int32_type,
            })(props.activityDocId);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryDelete_5760677E("/api/processactivitydoc", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            if (res.tag === 0) {
                const x = res.fields[0];
                if (x.Result === "success") {
                    patternInput_1[1](true);
                    return Promise.resolve();
                }
                else {
                    setError(x.Message);
                    return Promise.resolve();
                }
            }
            else {
                setError("Noe gikk galt. Kunne ikke slette dokument.");
                return Promise.resolve();
            }
        })))));
        pr.then();
    }]])) : empty())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "deleteForm", "/builds/serit/score/score/src/Client/Administration/ProcessActivityDoc.fs", 2838);

export class TreeEntryProps extends Record {
    constructor(x, onDelete) {
        super();
        this.x = x;
        this.onDelete = onDelete;
    }
}

export function TreeEntryProps$reflection() {
    return record_type("ProcessActivityDoc.TreeEntryProps", [], TreeEntryProps, () => [["x", ProcessActivityDoc$reflection()], ["onDelete", lambda_type(unit_type, unit_type)]]);
}

export function drawDocumentTreeEntry() {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useFeliz_React__React_useState_Static_1505(false);
        const setOpen = patternInput[1];
        const isOpen = patternInput[0];
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
        const setMenuOpen = patternInput_1[1];
        const isMenuOpen = patternInput_1[0];
        const anchor = useReact_useRef_1505(void 0);
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
        const showDeleteForm = patternInput_2[0];
        const setShowDeleteForm = patternInput_2[1];
        return createElement("div", {
            style: {
                marginTop: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(showDeleteForm ? singleton_1(deleteForm({
                activityDocId: props.x.Id,
                onClose: (didDelete) => {
                    if (didDelete) {
                        props.onDelete();
                    }
                    setShowDeleteForm(false);
                },
            })) : empty(), delay(() => singleton_1(createElement("div", {
                style: {
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 24,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    onClick: (_arg1) => {
                        setOpen(!isOpen);
                    },
                    style: {
                        display: "flex",
                    },
                    children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(props.x.Filename)]),
                }), createElement("span", {
                    onClick: (_arg2) => {
                        setOpen(!isOpen);
                    },
                    style: {
                        color: "#000080",
                    },
                    children: (props.x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(props.x.RowPrefix)(props.x.Name) : props.x.Name,
                }), MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                    marginLeft: 20,
                }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg3) => {
                    setMenuOpen(!isMenuOpen);
                }]]), MuiHelpers_createElement(Menu, [["open", isMenuOpen], ["onClose", (_arg16, v) => {
                    setMenuOpen(false);
                }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Slett dokument"], ["onClick", (_arg5) => {
                    setShowDeleteForm(!showDeleteForm);
                    setMenuOpen(false);
                }]])])]])]),
            })))))))),
        });
    }, void 0, uncurry(2, void 0), void 0, "drawDocumentTreeEntry", "/builds/serit/score/score/src/Client/Administration/ProcessActivityDoc.fs", 2917);
}

