import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { ProcessActivity$reflection, Helpers_ResultMessage$reflection, Process, Process$reflection } from "../Shared/ApiDataTypes.js";
import { lambda_type, unit_type, obj_type, int32_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { sortBy, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton, empty } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { StyleCreator$1_$ctor, StyleCreator$1__create_4EAC9E1E, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { createElement } from "react";
import * as react from "react";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { TreeEntryProps, drawActivityTreeEntry, activityForm } from "./ProcessActivity.js";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { snackbarError, loadingIndicatorSmall } from "../ViewHelpers.js";
import Add from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";

class Model extends Record {
    constructor(Processes, AddForm, ErrorMsg) {
        super();
        this.Processes = Processes;
        this.AddForm = AddForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("Process.Model", [], Model, () => [["Processes", option_type(array_type(Process$reflection()))], ["AddForm", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessesResponse", "ToggleAddForm", "AppendProcess", "RemoveProcess", "DismissError", "SetError"];
    }
}

function Message$reflection() {
    return union_type("Process.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Process$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Process$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", Process$reflection()]], [["Item", int32_type]], [], [["Item", string_type]]]);
}

function init() {
    return new Model(void 0, false, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Processes, !model.AddForm, model.ErrorMsg);
        }
        case 2: {
            return new Model((matchValue = model.Processes, (matchValue != null) ? append([msg.fields[0]], matchValue) : (void 0)), false, model.ErrorMsg);
        }
        case 3: {
            return new Model((matchValue_1 = model.Processes, (matchValue_1 != null) ? matchValue_1.filter((z) => (z.Id !== msg.fields[0])) : (void 0)), model.AddForm, model.ErrorMsg);
        }
        case 4: {
            return new Model(model.Processes, model.AddForm, void 0);
        }
        case 5: {
            return new Model(model.Processes, model.AddForm, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Processes, model.AddForm, "Noe gikk galt. Kunne ikke laste prosesser");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.ErrorMsg);
            }
        }
    }
}

function fetchProcesses(dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Process$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/process", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Process$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    const o = {
        tf: StyleCreator$1__create_4EAC9E1E(StyleCreator$1_$ctor(), singleton(["margin", 10])),
    };
    return Object.assign({}, o);
});

const addForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Process(-1, "", "", "", 1, props.parentId, [], [], 0, 0, 0, false));
    const updateForm = patternInput[1];
    const processForm = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setValidName = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const validRowPos = patternInput_2[0];
    const setValidRowPos = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const result = patternInput_3[0];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", (processForm.ParentId != null) ? "Legg til underprosess" : "Legg til prosess"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((result == null) ? append_1(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["autoFocus", true], ["fullWidth", true], ["onChange", (e) => {
            updateForm(new Process(processForm.Id, processForm.Name, processForm.Desc, Browser_Types_Event__Event_get_Value(e), processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.ChartCode, processForm.NumChildren, processForm.NumActivities, processForm.IsManagement));
        }], ["label", "Prosesskode/prefix (valgfritt)"], ["defaultValue", processForm.RowPrefix], ["size", "small"], ["variant", "outlined"]])), delay(() => append_1(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["className", s.tf]), delay(() => append_1(singleton_1(["required", true]), delay(() => append_1(singleton_1(["fullWidth", true]), delay(() => append_1(singleton_1(["onChange", (e_1) => {
            const x_3 = Browser_Types_Event__Event_get_Value(e_1);
            updateForm(new Process(processForm.Id, x_3, processForm.Desc, processForm.RowPrefix, processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.ChartCode, processForm.NumChildren, processForm.NumActivities, processForm.IsManagement));
            setValidName(x_3.length > 0);
        }]), delay(() => append_1(singleton_1(["label", "Prosessnavn"]), delay(() => append_1(singleton_1(["defaultValue", processForm.Name]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => ((!patternInput_1[0]) ? append_1(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Prosessnavn kan ikke være tomt"]))) : empty_1()))))))))))))))))))))), delay(() => append_1((processForm.RowPrefix.length > 0) ? singleton_1(createElement("span", {
            style: {
                fontSize: 0.8 + "rem",
                marginLeft: 10,
                fontStyle: "italic",
            },
            children: toText(printf("%s - %s"))(processForm.RowPrefix)(processForm.Name),
        })) : empty_1(), delay(() => append_1(singleton_1(MuiHelpers_createElement(TextField, [["className", s.tf], ["size", "small"], ["fullWidth", true], ["multiline", true], ["onChange", (e_2) => {
            updateForm(new Process(processForm.Id, processForm.Name, Browser_Types_Event__Event_get_Value(e_2), processForm.RowPrefix, processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.ChartCode, processForm.NumChildren, processForm.NumActivities, processForm.IsManagement));
        }], ["label", "Beskrivelse"], ["defaultValue", processForm.Desc], ["variant", "outlined"]])), delay(() => append_1(singleton_1(MuiHelpers_createElement(TextField, toList(delay(() => append_1(singleton_1(["className", s.tf]), delay(() => append_1(singleton_1(["size", "small"]), delay(() => append_1(singleton_1(["onChange", (e_3) => {
            const x_5 = Browser_Types_Event__Event_get_Value(e_3);
            try {
                const asInt = parse(x_5, 511, false, 32) | 0;
                updateForm(new Process(processForm.Id, processForm.Name, processForm.Desc, processForm.RowPrefix, asInt, processForm.ParentId, processForm.Children, processForm.Activities, processForm.ChartCode, processForm.NumChildren, processForm.NumActivities, processForm.IsManagement));
                setValidRowPos(asInt > 0);
            }
            catch (matchValue) {
                setValidRowPos(false);
            }
        }]), delay(() => append_1(singleton_1(["label", "Radnummer"]), delay(() => append_1(singleton_1(["defaultValue", processForm.RowPos]), delay(() => append_1(singleton_1(["variant", "outlined"]), delay(() => append_1(singleton_1(["inputProps", {
            style: {
                textAlign: "center",
                maxWidth: 80,
            },
        }]), delay(() => ((!validRowPos) ? append_1(singleton_1(["error", true]), delay(() => singleton_1(["helperText", "Ugyldig nummer. Oppgi ett nummer større eller lik 1."]))) : empty_1()))))))))))))))))))), delay(() => singleton_1(createElement("div", {
            style: {
                margin: 10,
                display: "flex",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(FormControlLabel, [["control", MuiHelpers_createElement(Checkbox, [["checked", processForm.IsManagement], ["onChange", (e_4) => {
                updateForm(new Process(processForm.Id, processForm.Name, processForm.Desc, processForm.RowPrefix, processForm.RowPos, processForm.ParentId, processForm.Children, processForm.Activities, processForm.ChartCode, processForm.NumChildren, processForm.NumActivities, Browser_Types_Event__Event_get_Checked(e_4)));
            }], ["color", "primary"]])], ["label", "Ledelsessystemprosess"]]), createElement("div", {
                style: {
                    paddingTop: 7,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Om prosessen skal vises i menyen i ledelsessystemet. Gjør prosessen og alle underprosesser, aktiviteter og dokumenter tilgjengelig for alle med tilgang til ledelsessystemet."], ["children", createElement("button", {
                    className: "icon-button",
                    style: {
                        fontSize: 1 + "rem",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: "fas fa-question-circle",
                    })]),
                })]])]),
            })]),
        })))))))))))) : singleton_1(createElement("span", {
            children: ["Prosess lagt til"],
        }))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        props.onClose(result);
    }]])), delay(() => ((result == null) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Legg til"], ["onClick", (_arg3) => {
        if ((processForm.Name.length > 0) ? validRowPos : false) {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Process$reflection,
                })(processForm);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Process$reflection,
                });
                return Fetch_tryPost_5760677E("/api/process", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Process$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                const res = _arg1;
                if (res.tag === 0) {
                    patternInput_3[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    props.dispatch(new Message(5, "Noe gikk galt. Kunne ikke legge til prosess"));
                    return Promise.resolve();
                }
            })))));
            pr.then();
        }
        else {
            setValidName(false);
        }
    }]])) : empty_1())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "addForm", "/builds/serit/score/score/src/Client/Administration/Process.fs", 79);

const deleteForm = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const result = patternInput_1[0];
    const s = useStyles();
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.onClose(result);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Slett prosess"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            minWidth: 350,
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((!result) ? append_1(singleton_1(createElement("span", {
            style: {
                fontWeight: "bold",
                fontSize: 1.1 + "rem",
            },
            children: "Er du sikker på at du vil slette denne prosessen?",
        })), delay(() => singleton_1(createElement("p", {
            style: {
                marginTop: 10,
            },
            children: "Dette vil også slette alle eventuelle underprosesser, aktiviteter og tilhørende dokumenter",
        })))) : singleton_1(createElement("span", {
            children: ["Prosess slettet"],
        }))))))),
    })])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg2) => {
        props.onClose(result);
    }]])), delay(() => ((!result) ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["color", "secondary"], ["children", "Slett"], ["onClick", (_arg3) => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: () => int32_type,
            })(props.processId);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Helpers_ResultMessage$reflection,
            });
            return Fetch_tryDelete_5760677E("/api/process", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Helpers_ResultMessage$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            const res = _arg1;
            if (res.tag === 0) {
                const x = res.fields[0];
                if (x.Result === "success") {
                    patternInput_1[1](true);
                    return Promise.resolve();
                }
                else {
                    props.dispatch(new Message(5, x.Message));
                    return Promise.resolve();
                }
            }
            else {
                props.dispatch(new Message(5, "Noe gikk galt. Kunne ikke slette prosess"));
                return Promise.resolve();
            }
        })))));
        pr.then();
    }]])) : empty_1())))))))]])])]]);
}, void 0, uncurry(2, void 0), void 0, "deleteForm", "/builds/serit/score/score/src/Client/Administration/Process.fs", 267);

class SubTreeProps extends Record {
    constructor(dispatch, x, onDelete) {
        super();
        this.dispatch = dispatch;
        this.x = x;
        this.onDelete = onDelete;
    }
}

function SubTreeProps$reflection() {
    return record_type("Process.SubTreeProps", [], SubTreeProps, () => [["dispatch", lambda_type(Message$reflection(), unit_type)], ["x", Process$reflection()], ["onDelete", lambda_type(unit_type, unit_type)]]);
}

function drawSubTree() {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useFeliz_React__React_useState_Static_1505(false);
        const setOpen = patternInput[1];
        const isOpen = patternInput[0];
        const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
        const setMenuOpen = patternInput_1[1];
        const isMenuOpen = patternInput_1[0];
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
        const setChildren = patternInput_2[1];
        const children = patternInput_2[0];
        const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
        const setActivities = patternInput_3[1];
        const activities = patternInput_3[0];
        const anchor = useReact_useRef_1505(void 0);
        const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
        const showAddForm = patternInput_4[0];
        const setShowAddForm = patternInput_4[1];
        const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
        const showDeleteForm = patternInput_5[0];
        const setShowDeleteForm = patternInput_5[1];
        const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
        const showActivityForm = patternInput_6[0];
        const setShowActivityForm = patternInput_6[1];
        const fetchChildren = () => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const requestPath = toText(printf("/api/process/children/%i"))(props.x.Id);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: () => array_type(Process$reflection()),
                    });
                    return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                        ResolveType: () => array_type(Process$reflection()),
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg1) => {
                    const res = _arg1;
                    if (res.tag === 0) {
                        setChildren(res.fields[0]);
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                }));
            }));
            pr.then();
        };
        const fetchActivities = () => {
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const requestPath_2 = toText(printf("/api/processactivity/ofprocess/%i"))(props.x.Id);
                return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: () => array_type(ProcessActivity$reflection()),
                    });
                    return Fetch_tryGet_5760677E(requestPath_2, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                        ResolveType: () => array_type(ProcessActivity$reflection()),
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg2) => {
                    const res_1 = _arg2;
                    if (res_1.tag === 0) {
                        setActivities(res_1.fields[0]);
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                }));
            }));
            pr_1.then();
        };
        return createElement("div", {
            style: {
                marginTop: 10,
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let c_1, a_1;
                const hasChildren = ((children != null) ? ((children.length > 0) ? ((c_1 = children, true)) : false) : false) ? true : (props.x.NumChildren > 0);
                const hasActivities = ((activities != null) ? ((activities.length > 0) ? ((a_1 = activities, true)) : false) : false) ? true : (props.x.NumActivities > 0);
                return append_1(showAddForm ? singleton_1(addForm({
                    dispatch: props.dispatch,
                    onClose: (x_2) => {
                        if (x_2 != null) {
                            const c_2 = x_2;
                            setChildren((children != null) ? append([c_2], children) : [c_2]);
                        }
                        setShowAddForm(false);
                    },
                    parentId: props.x.Id,
                })) : empty_1(), delay(() => append_1(showDeleteForm ? singleton_1(deleteForm({
                    dispatch: props.dispatch,
                    onClose: (didDelete) => {
                        if (didDelete) {
                            setChildren([]);
                            props.onDelete();
                        }
                        setShowDeleteForm(false);
                    },
                    processId: props.x.Id,
                })) : empty_1(), delay(() => append_1(showActivityForm ? singleton_1(activityForm({
                    onClose: (res_2) => {
                        if (res_2 != null) {
                            const a_2 = res_2;
                            setActivities((activities != null) ? append([a_2], activities) : [a_2]);
                        }
                        setShowActivityForm(false);
                    },
                    processId: props.x.Id,
                })) : empty_1(), delay(() => append_1(singleton_1(createElement("div", {
                    style: createObj(toList(delay(() => append_1(singleton_1(["cursor", "pointer"]), delay(() => append_1(singleton_1(["display", "flex"]), delay(() => append_1(singleton_1(["alignItems", "center"]), delay(() => (((!hasChildren) ? (!hasActivities) : false) ? singleton_1(["paddingLeft", 24]) : empty_1())))))))))),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("div", {
                        onClick: (_arg1_1) => {
                            if ((!isOpen) ? (((children == null) ? (props.x.NumChildren > 0) : false) ? true : ((activities == null) ? (props.x.NumActivities > 0) : false)) : false) {
                                fetchChildren();
                                fetchActivities();
                            }
                            setOpen(!isOpen);
                        },
                        style: {
                            display: "flex",
                        },
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((hasChildren ? true : hasActivities) ? (isOpen ? singleton_1(react.createElement(ExpandMore, {})) : singleton_1(react.createElement(ChevronRight, {}))) : empty_1()))))),
                    })), delay(() => append_1(singleton_1(createElement("span", {
                        onClick: (_arg2_1) => {
                            if ((!isOpen) ? (((children == null) ? (props.x.NumChildren > 0) : false) ? true : ((activities == null) ? (props.x.NumActivities > 0) : false)) : false) {
                                fetchChildren();
                                fetchActivities();
                            }
                            setOpen(!isOpen);
                        },
                        children: (props.x.RowPrefix.length > 0) ? toText(printf("%s - %s"))(props.x.RowPrefix)(props.x.Name) : props.x.Name,
                    })), delay(() => append_1(props.x.IsManagement ? singleton_1(MuiHelpers_createElement(Tooltip, [["title", "Ledelsessystemprosess. Kan velges fra menyen i ledelsessystemet og utforskes sammen med alle underprosesser, aktiviteter og dokumenter av alle med tilgang."], ["children", createElement("button", {
                        className: "icon-button",
                        style: {
                            fontSize: 12 + "px",
                            marginLeft: 10,
                            color: "#6495ED",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("i", {
                            className: "fas fa-sitemap",
                        })]),
                    })]])) : empty_1(), delay(() => append_1(singleton_1(MuiHelpers_createElement(IconButton, [["size", "small"], ["ref", anchor], ["style", {
                        marginLeft: 20,
                    }], ["children", react.createElement(MoreHoriz, {})], ["onClick", (_arg3) => {
                        setMenuOpen(!isMenuOpen);
                    }]])), delay(() => singleton_1(MuiHelpers_createElement(Menu, [["open", isMenuOpen], ["onClose", (_arg16, v) => {
                        setMenuOpen(false);
                    }], ["anchorEl", anchor.current], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["children", "Legg til aktivitet"], ["onClick", (_arg5) => {
                        setShowActivityForm(!showActivityForm);
                        setMenuOpen(false);
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Legg til underprosess"], ["onClick", (_arg6) => {
                        setShowAddForm(!showAddForm);
                        setMenuOpen(false);
                    }]]), MuiHelpers_createElement(MenuItem, [["children", "Slett prosess"], ["onClick", (_arg7) => {
                        setShowDeleteForm(!showDeleteForm);
                        setMenuOpen(false);
                    }]])])]])))))))))))))),
                })), delay(() => singleton_1(createElement("div", {
                    style: {
                        marginLeft: 12,
                        borderLeft: (((1 + "px ") + "dashed") + " ") + "#aaa",
                        paddingLeft: 10,
                    },
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                        if (!isOpen) {
                            return empty_1();
                        }
                        else {
                            return append_1(hasActivities ? ((activities != null) ? singleton_1(map((y_2) => createElement("div", {
                                key: toText(printf("activity-%i"))(y_2.Id),
                                children: Interop_reactApi.Children.toArray([drawActivityTreeEntry()(new TreeEntryProps(y_2, () => {
                                    setActivities((activities != null) ? activities.filter((d) => (d.Id !== y_2.Id)) : (void 0));
                                }))]),
                            }), sortBy((y) => y.RowPos, activities, {
                                Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
                            }))) : singleton_1(loadingIndicatorSmall())) : empty_1(), delay(() => (hasChildren ? ((children != null) ? singleton_1(map((y_5) => createElement("div", {
                                key: toText(printf("process-%i"))(y_5.Id),
                                children: Interop_reactApi.Children.toArray([drawSubTree()(new SubTreeProps(props.dispatch, y_5, () => {
                                    setChildren((children != null) ? children.filter((d_1) => (d_1.Id !== y_5.Id)) : (void 0));
                                }))]),
                            }), sortBy((y_3) => y_3.RowPos, children, {
                                Compare: (x_4, y_4) => comparePrimitives(x_4, y_4),
                            }))) : singleton_1(loadingIndicatorSmall())) : empty_1())));
                        }
                    })))),
                }))))))))));
            })))),
        });
    }, void 0, uncurry(2, void 0), void 0, "drawSubTree", "/builds/serit/score/score/src/Client/Administration/Process.fs", 347);
}

const drawTree = FunctionComponent_Of_Z5A158BBF((props) => {
    const parents = props.x.filter((y) => (y.ParentId == null));
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            marginBottom: 50,
        },
        children: Interop_reactApi.Children.toArray([map((y_3) => createElement("div", {
            key: toText(printf("process-%i"))(y_3.Id),
            children: Interop_reactApi.Children.toArray([drawSubTree()(new SubTreeProps(props.dispatch, y_3, () => {
                props.dispatch(new Message(3, y_3.Id));
            }))]),
        }), sortBy((y_1) => y_1.RowPos, parents, {
            Compare: (x, y_2) => comparePrimitives(x, y_2),
        }))]),
    });
}, void 0, uncurry(2, void 0), void 0, "drawTree", "/builds/serit/score/score/src/Client/Administration/Process.fs", 625);

function view(model, dispatch) {
    return createElement("div", {
        className: "content-column",
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(4));
        })), delay(() => append_1(model.AddForm ? singleton_1(addForm({
            dispatch: dispatch,
            onClose: (x) => {
                if (x != null) {
                    dispatch(new Message(2, x));
                }
                else {
                    dispatch(new Message(1));
                }
            },
            parentId: void 0,
        })) : empty_1(), delay(() => append_1(singleton_1(MuiHelpers_createElement(IconButton, [["size", "small"], ["children", react.createElement(Add, {})], ["onClick", (_arg2) => {
            dispatch(new Message(1));
        }]])), delay(() => {
            const matchValue = model.Processes;
            return (matchValue != null) ? singleton_1(drawTree({
                dispatch: dispatch,
                x: matchValue,
            })) : singleton_1(MuiHelpers_createElement(CircularProgress, []));
        })))))))))),
    });
}

export const processTree = FunctionComponent_Of_Z5A158BBF(() => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchProcesses(dispatch);
        pr.then();
    }, []);
    return view(patternInput[0], dispatch);
}, void 0, uncurry(2, void 0), void 0, "processTree", "/builds/serit/score/score/src/Client/Administration/Process.fs", 696);

