import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, bool_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Process$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { toArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, comparePrimitives, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { mapIndexed, sortBy, map } from "../.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { loadingIndicatorMedium, snackbarError, PageElements_filenameToIcon } from "../ViewHelpers.js";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ArrowForward from "@material-ui/icons/ArrowForward";
import IconButton from "@material-ui/core/IconButton";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import WrapText from "@material-ui/icons/WrapText";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";

class Model extends Record {
    constructor(ProcessId, Processes, Scroll, ErrorMsg) {
        super();
        this.ProcessId = (ProcessId | 0);
        this.Processes = Processes;
        this.Scroll = Scroll;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("ProcessMap.Model", [], Model, () => [["ProcessId", int32_type], ["Processes", option_type(array_type(Process$reflection()))], ["Scroll", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProcessesResponse", "ToggleScroll", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProcessMap.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Process$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Process$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", bool_type]], []]);
}

function init(processId) {
    return new Model(processId, void 0, true, void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            return new Model(model.ProcessId, model.Processes, msg.fields[0], model.ErrorMsg);
        }
        case 2: {
            return new Model(model.ProcessId, model.Processes, model.Scroll, void 0);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.ProcessId, model.Processes, model.Scroll, "Noe gikk galt. Kunne ikke laste prosesskart");
            }
            else {
                return new Model(model.ProcessId, res.fields[0], model.Scroll, model.ErrorMsg);
            }
        }
    }
}

function fetchMap(processId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/management/%i"))(processId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Process$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Process$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function DrawProcess(x) {
    return createElement("div", {
        style: {
            margin: 20,
            display: "flex",
            flexDirection: "column",
            minWidth: 250,
            border: (((1 + "px ") + "solid") + " ") + "gainsboro",
            borderRadius: 10,
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                backgroundColor: "#4169E1",
                padding: 8,
                ["border-top-right-radius"]: 10,
                ["border-top-left-radius"]: 10,
                color: "#FFFFFF",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((x.RowPrefix.length > 0) ? singleton(createElement("span", {
                style: {
                    marginRight: 5,
                },
                children: x.RowPrefix,
            })) : empty_1(), delay(() => singleton(createElement("span", {
                children: x.Name,
            })))))))),
        }), map((y_2) => createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    padding: 8,
                    backgroundColor: "#E6E6FA",
                },
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((y_2.RowPrefix.length > 0) ? singleton(createElement("span", {
                    style: {
                        marginRight: 5,
                    },
                    children: y_2.RowPrefix,
                })) : empty_1(), delay(() => singleton(createElement("span", {
                    children: y_2.Name,
                })))))))),
            }), MuiHelpers_createElement(List, [["dense", true], ["children", Interop_reactApi.Children.toArray([map((d) => MuiHelpers_createElement(ListItem, [["href", toText(printf("/api/processactivitydoc/download/%i"))(d.FileStoreId)], ["target", "_blank"], ["button", true], ["component", "a"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([PageElements_filenameToIcon(d.Filename)])]]), MuiHelpers_createElement(ListItemText, [["primary", d.Name]])])]]), y_2.Docs)])]])]),
        }), sortBy((y) => y.RowPos, x.Activities, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))]),
    });
}

function divider$0027() {
    return createElement("div", {
        style: {
            display: "flex",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ArrowForward, {})])]])]),
    });
}

function drawProcesses(model, dispatch, x) {
    return createElement("div", {
        style: createObj(toList(delay(() => append(singleton(["display", "flex"]), delay(() => (model.Scroll ? append(singleton(["overflowX", "scroll"]), delay(() => singleton(["paddingBottom", 50]))) : singleton(["flexWrap", "wrap"]))))))),
        children: Interop_reactApi.Children.toArray([mapIndexed((i, y_2) => toArray(toList(delay(() => append(singleton(createElement(DrawProcess, y_2)), delay(() => ((i !== (x.length - 1)) ? singleton(divider$0027()) : empty_1())))))), sortBy((y) => y.RowPos, x, {
            Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
        }))]),
    });
}

function displayOptions(model, dispatch) {
    return createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton(["size", "small"]), delay(() => append(model.Scroll ? singleton(["color", "primary"]) : empty_1(), delay(() => append(singleton(["children", Interop_reactApi.Children.toArray([react.createElement(SwapHoriz, {})])]), delay(() => singleton(["onClick", (_arg1) => {
            dispatch(new Message(1, true));
        }])))))))))), MuiHelpers_createElement(IconButton, toList(delay(() => append(singleton(["size", "small"]), delay(() => append((!model.Scroll) ? singleton(["color", "primary"]) : empty_1(), delay(() => append(singleton(["children", Interop_reactApi.Children.toArray([react.createElement(WrapText, {})])]), delay(() => singleton(["onClick", (_arg2) => {
            dispatch(new Message(1, false));
        }]))))))))))]),
    });
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            padding: 2 + "vw",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(2));
        })), delay(() => {
            const matchValue = model.Processes;
            if (matchValue != null) {
                const x = matchValue;
                return append(singleton(displayOptions(model, dispatch)), delay(() => singleton(drawProcesses(model, dispatch, x))));
            }
            else {
                return singleton(createElement("div", {
                    style: {
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 70 + "vh",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray([loadingIndicatorMedium()]),
                }));
            }
        })))))),
    });
}

export const processMap = FunctionComponent_Of_Z5A158BBF((props) => {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.processId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchMap(model_1.ProcessId, dispatch);
        pr.then();
    }, []);
    return view(model_1, dispatch);
}, void 0, uncurry(2, void 0), void 0, "processMap", "/builds/serit/score/score/src/Client/Management/ProcessMap.fs", 228);

