import { toString, Union } from "./.fable/fable-library.3.2.4/Types.js";
import { union_type } from "./.fable/fable-library.3.2.4/Reflection.js";
import { hasPermission } from "./LoginState.js";
import { Functionality } from "./Shared/PermissionMapping.js";
import { equals, createObj } from "./.fable/fable-library.3.2.4/Util.js";
import { append, empty, singleton, delay, toList } from "./.fable/fable-library.3.2.4/Seq.js";
import { singleton as singleton_1, ofArray } from "./.fable/fable-library.3.2.4/List.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.45.0/React.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.45.0/Interop.fs.js";
import { Page } from "./Pages.js";

export class MenuItem extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "MyPage", "Project", "User", "Company", "Reports", "Administration", "Management"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Min side";
            }
            case 2: {
                return "Prosjekter";
            }
            case 3: {
                return "Ansatte";
            }
            case 4: {
                return "Selskap";
            }
            case 5: {
                return "Rapporter";
            }
            case 6: {
                return "Administrasjon";
            }
            case 7: {
                return "Ledelsessystem";
            }
            default: {
                return "Hjem";
            }
        }
    }
}

export function MenuItem$reflection() {
    return union_type("Navbar.MenuItem", [], MenuItem, () => [[], [], [], [], [], [], [], []]);
}

export function MenuItem__Link(this$) {
    switch (this$.tag) {
        case 1: {
            return "/mypage";
        }
        case 2: {
            return "/projects";
        }
        case 3: {
            return "/members";
        }
        case 4: {
            return "/company";
        }
        case 5: {
            return "/report";
        }
        case 6: {
            return "/administration";
        }
        case 7: {
            return "/management";
        }
        default: {
            return "/";
        }
    }
}

export function MenuItem__HasPermissionToShow(this$) {
    switch (this$.tag) {
        case 1:
        case 2:
        case 3:
        case 4: {
            return true;
        }
        case 5: {
            return hasPermission(new Functionality(40));
        }
        case 6: {
            return [hasPermission(new Functionality(20)), hasPermission(new Functionality(22)), hasPermission(new Functionality(23)), hasPermission(new Functionality(25)), hasPermission(new Functionality(26)), hasPermission(new Functionality(27)), hasPermission(new Functionality(28)), hasPermission(new Functionality(29)), hasPermission(new Functionality(30)), hasPermission(new Functionality(24))].some((y) => (true === y));
        }
        case 7: {
            return hasPermission(new Functionality(46));
        }
        default: {
            return true;
        }
    }
}

function menuHeader(menuItem, isActive) {
    if (MenuItem__HasPermissionToShow(menuItem)) {
        const props = ofArray([["href", MenuItem__Link(menuItem)], ["children", toString(menuItem)], ["style", createObj(toList(delay(() => (isActive ? singleton(["color", "#f6f6f6"]) : empty()))))]]);
        return createElement("a", createObj(Helpers_combineClasses("navbar-item", props)));
    }
    else {
        return null;
    }
}

export function LogOut() {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const state = patternInput[0];
    const props_4 = toList(delay(() => append(singleton(["className", "has-dropdown"]), delay(() => append(singleton(["className", "is-hoverable"]), delay(() => append(state ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["onClick", (_arg1) => {
        patternInput[1](!state);
    }]), delay(() => {
        let s, elms;
        return singleton(["children", Interop_reactApi.Children.toArray([(s = (loggedInUsername), createElement("a", {
            className: "navbar-link",
            children: s,
        })), (elms = singleton_1(createElement("a", createObj(Helpers_combineClasses("navbar-item", ofArray([["href", "/logout"], ["children", "Logg ut"]]))))), createElement("div", {
            className: "navbar-dropdown",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))])]);
    }))))))))));
    return createElement("div", createObj(Helpers_combineClasses("navbar-item", props_4)));
}

export function NavigationBar(navigationBarInputProps) {
    let elms_1, elms, props_5, props_10;
    const currentPage = navigationBarInputProps.currentPage;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const menuState = patternInput[0];
    const props_12 = ofArray([["className", "is-black"], ["style", {
        backgroundColor: "#212121",
        zIndex: 1000000,
    }], ["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(elms = singleton_1(createElement("img", {
        src: "/images/logo.png",
    })), createElement("div", {
        className: "navbar-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_5 = ofArray([["onClick", (_arg1) => {
        patternInput[1](!menuState);
    }], ["children", Interop_reactApi.Children.toArray([createElement("span", {}), createElement("span", {}), createElement("span", {})])]]), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_5))))]), createElement("div", {
        className: "navbar-brand",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (props_10 = toList(delay(() => append(menuState ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elms_2, elms_3;
        return singleton(["children", Interop_reactApi.Children.toArray([(elms_2 = ofArray([menuHeader(new MenuItem(1), equals(currentPage, new Page(33))), menuHeader(new MenuItem(2), equals(currentPage, new Page(19))), menuHeader(new MenuItem(3), equals(currentPage, new Page(31))), menuHeader(new MenuItem(4), equals(currentPage, new Page(17))), menuHeader(new MenuItem(5), equals(currentPage, new Page(8))), menuHeader(new MenuItem(7), equals(currentPage, new Page(36))), menuHeader(new MenuItem(6), equals(currentPage, new Page(30)))]), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        })), (elms_3 = toList(delay(() => ((userIsLoggedIn) ? singleton(createElement(LogOut, null)) : empty()))), createElement("div", {
            className: "navbar-end",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_10))))])]]);
    return createElement("nav", createObj(Helpers_combineClasses("navbar", props_12)));
}

