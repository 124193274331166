import { Functionality__ToInt } from "./Shared/PermissionMapping.js";

export function permissionLevelToString(pLevel) {
    switch (pLevel) {
        case 1: {
            return "Systemadministrator";
        }
        case 2: {
            return "Applikasjonsadministrator";
        }
        case 3: {
            return "Prosjektadministrator";
        }
        case 4: {
            return "Superbruker";
        }
        case 5: {
            return "Prosjekteier";
        }
        case 6: {
            return "Medlem";
        }
        default: {
            return "Anonym";
        }
    }
}

export function hasPermission(perm) {
    let x;
    return (permissions).some((x = (Functionality__ToInt(perm) | 0), (y) => (x === y)));
}

