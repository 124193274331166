import { toString, Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { getUnionCases, makeUnion, int32_type, lambda_type, unit_type, record_type, bool_type, class_type, array_type, option_type, string_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { FileStore, FileStore$reflection } from "../Shared/ApiDataTypes.js";
import { Cons } from "../Log.js";
import { join, toText, printf } from "../.fable/fable-library.3.2.4/String.js";
import { Cmd_ofSub, Cmd_OfFunc_result, Cmd_none, Cmd_OfPromise_either } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { FileStore_addFileSystemOverriding, FileStore_addFileSystemProject, FileStore_delete, FileStore_update, FileStore_add, FileStore_getAll } from "../Promises.js";
import { append, map } from "../.fable/fable-library.3.2.4/Array.js";
import { map as map_1, empty as empty_1, singleton, append as append_1, delay, toList, toArray } from "../.fable/fable-library.3.2.4/Seq.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { equals, stringHash } from "../.fable/fable-library.3.2.4/Util.js";
import { toString as toString_1, now } from "../.fable/fable-library.3.2.4/Date.js";
import * as react from "react";
import { loadingIndicatorMedium, errorHeader, PageElements_textArea, Bootstrap_Input_Option, Bootstrap_Input_text, Bootstrap_Button_ButtonSize, Bootstrap_Button_ButtonClass, Bootstrap_Table_Option, Bootstrap_Button_Option, Bootstrap_Button_button, Bootstrap_Table_table } from "../ViewHelpers.js";
import { singleton as singleton_1, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { keyValueList } from "../.fable/fable-library.3.2.4/MapUtil.js";
import { Helpers_classList } from "../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { parse } from "../.fable/fable-library.3.2.4/Int32.js";

export class ViewTypes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UploadedFiles", "FileSysForm"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "Last opp fra filsystemet" : "Uploaded files";
    }
}

export function ViewTypes$reflection() {
    return union_type("FileStore.ViewTypes", [], ViewTypes, () => [[], []]);
}

export class LibTypes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Template", "Project"];
    }
}

export function LibTypes$reflection() {
    return union_type("FileStore.LibTypes", [], LibTypes, () => [[], []]);
}

export function LibTypes__get_IdValue(this$) {
    if (this$.tag === 1) {
        return [1, "Project"];
    }
    else {
        return [0, "Template"];
    }
}

export function LibTypes__get_Id(this$) {
    return LibTypes__get_IdValue(this$)[0];
}

export function LibTypes__get_Value(this$) {
    return LibTypes__get_IdValue(this$)[1];
}

export function LibTypes_ofId_Z524259A4(id) {
    if (id === 1) {
        return new LibTypes(1);
    }
    else {
        return new LibTypes(0);
    }
}

export function LibTypes_ofValue_Z721C83C5(v) {
    if (v.toLocaleLowerCase() === "project") {
        return new LibTypes(1);
    }
    else {
        return new LibTypes(0);
    }
}

export class FileCacheFields extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LibType", "ProjectName"];
    }
    toString() {
        const this$ = this;
        return (this$.tag === 1) ? "ProjectName" : "LibType";
    }
}

export function FileCacheFields$reflection() {
    return union_type("FileStore.FileCacheFields", [], FileCacheFields, () => [[], []]);
}

export class Model extends Record {
    constructor(ErrorMsg, FileStores, UploadFile, FilesSelected, ShowUploadForm, SelectForm, UpdateForm, DeleteForm, ViewType, ProjectName, LibType, SearchCriteria) {
        super();
        this.ErrorMsg = ErrorMsg;
        this.FileStores = FileStores;
        this.UploadFile = UploadFile;
        this.FilesSelected = FilesSelected;
        this.ShowUploadForm = ShowUploadForm;
        this.SelectForm = SelectForm;
        this.UpdateForm = UpdateForm;
        this.DeleteForm = DeleteForm;
        this.ViewType = ViewType;
        this.ProjectName = ProjectName;
        this.LibType = LibType;
        this.SearchCriteria = SearchCriteria;
    }
}

export function Model$reflection() {
    return record_type("FileStore.Model", [], Model, () => [["ErrorMsg", option_type(string_type)], ["FileStores", option_type(array_type(FileStore$reflection()))], ["UploadFile", option_type(FileStore$reflection())], ["FilesSelected", option_type(array_type(class_type("Browser.Types.File")))], ["ShowUploadForm", bool_type], ["SelectForm", option_type(class_type("Browser.Types.File"))], ["UpdateForm", option_type(FileStore$reflection())], ["DeleteForm", option_type(FileStore$reflection())], ["ViewType", ViewTypes$reflection()], ["ProjectName", option_type(string_type)], ["LibType", option_type(string_type)], ["SearchCriteria", option_type(string_type)]]);
}

export class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InitResponse", "InitError", "ShowUploadForm", "SelectedFiles", "SaveFile", "FilesLoaded", "UploadFile", "SelectFormDelete", "UploadFormClose", "UploadFormSave", "UploadFormResponse", "UploadFormError", "UpdateFileStore", "UpdateFormShow", "UpdateFormClose", "UpdateFormSave", "UpdateFormResponse", "UpdateFormError", "DeleteFormShow", "DeleteFormClose", "DeleteFormSave", "DeleteFormResponse", "DeleteFormError", "ShowView", "UpdProjectName", "SelectLibType", "FileCacheUpload", "FileCacheResponse", "FileCacheError", "SetSearchCriteria"];
    }
}

export function Message$reflection() {
    return union_type("FileStore.Message", [], Message, () => [[["Item", array_type(FileStore$reflection())]], [["Item", class_type("System.Exception")]], [], [["Item", class_type("Browser.Types.FileList")]], [["Item", class_type("Browser.Types.File")]], [], [["Item1", string_type], ["Item2", string_type]], [["Item", string_type]], [], [["Item", lambda_type(Message$reflection(), unit_type)]], [["Item", FileStore$reflection()]], [["Item", class_type("System.Exception")]], [["Item", FileStore$reflection()]], [["Item", FileStore$reflection()]], [], [], [["Item", FileStore$reflection()]], [["Item", class_type("System.Exception")]], [["Item", FileStore$reflection()]], [], [["Item", FileStore$reflection()]], [["Item", FileStore$reflection()]], [["Item", class_type("System.Exception")]], [["Item", ViewTypes$reflection()]], [["Item", string_type]], [["Item", int32_type]], [], [["Item", array_type(FileStore$reflection())]], [["Item", class_type("System.Exception")]], [["Item", string_type]]]);
}

export function init() {
    Cons(printf("FileStore.init: Entered ..."));
    return [new Model(void 0, void 0, void 0, void 0, false, void 0, void 0, void 0, new ViewTypes(0), "TestProsjekt1", toString(new LibTypes(0)), void 0), Cmd_OfPromise_either(FileStore_getAll, void 0, (arg0) => (new Message(0, arg0)), (arg0_1) => (new Message(1, arg0_1)))];
}

export function update(msg, model) {
    let arg10_2, arg10_5, arg10_7, arg10_8, arg10_13;
    switch (msg.tag) {
        case 1: {
            const e = msg.fields[0];
            const arg10_1 = toString(e);
            Cons(printf("FileStore.update: InitError Error=%s"))(arg10_1);
            return [new Model((arg10_2 = e.message, toText(printf("Feil ved lesning av opplastede filer: %s"))(arg10_2)), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 2: {
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, true, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 3: {
            const fileList = msg.fields[0];
            const thisFiles = map((i) => (fileList[i]), toArray(rangeDouble(0, 1, fileList.length - 1)));
            const matchValue = model.FilesSelected;
            if (matchValue == null) {
                return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, thisFiles, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_OfFunc_result(new Message(5))];
            }
            else {
                return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, Array_distinctBy((x) => x.name.toLocaleLowerCase(), append(matchValue, thisFiles), {
                    Equals: (x_1, y) => (x_1 === y),
                    GetHashCode: (x_1) => stringHash(x_1),
                }), model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_OfFunc_result(new Message(5))];
            }
        }
        case 5: {
            const matchValue_1 = model.FilesSelected;
            if (matchValue_1 == null) {
                Cons(printf("FilesLoaded: Entered with model.FilesSelecte.count=0"));
            }
            else {
                const arg10_3 = matchValue_1.length | 0;
                Cons(printf("FilesLoaded: Entered with model.FilesSelecte.count=%i"))(arg10_3);
            }
            return [model, Cmd_none()];
        }
        case 7: {
            const arg10_4 = model.FilesSelected != null;
            Cons(printf("SelectFormDelete: Entered ... FilesSelected.IsSome=%b"))(arg10_4);
            const matchValue_2 = model.FilesSelected;
            if (matchValue_2 == null) {
                return [model, Cmd_none()];
            }
            else {
                const newList_1 = matchValue_2.filter((x_2) => (x_2.name.toLocaleLowerCase() !== msg.fields[0].toLocaleLowerCase()));
                if (newList_1.length > 0) {
                    return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, newList_1, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
                }
                else {
                    return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, void 0, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
                }
            }
        }
        case 9: {
            const matchValue_3 = model.FilesSelected;
            if (matchValue_3 == null) {
                return [model, Cmd_none()];
            }
            else {
                const files_2 = matchValue_3;
                for (let i_1 = 0; i_1 <= (files_2.length - 1); i_1++) {
                    msg.fields[0](new Message(4, files_2[1]));
                }
                return [model, Cmd_none()];
            }
        }
        case 4: {
            const file = msg.fields[0];
            const reader = new FileReader();
            const value = reader.readAsDataURL(file);
            return [model, Cmd_ofSub((dispatch_1) => {
                reader.addEventListener("load", (_arg1) => {
                    dispatch_1(new Message(6, file.name, reader.result));
                });
            })];
        }
        case 6: {
            return [model, Cmd_OfPromise_either((entry) => FileStore_add(entry), new FileStore(0, msg.fields[0], "", msg.fields[1], now()), (arg0) => (new Message(10, arg0)), (arg0_1) => (new Message(11, arg0_1)))];
        }
        case 10: {
            const matchValue_4 = model.FileStores;
            if (matchValue_4 == null) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(void 0, append([msg.fields[0]], matchValue_4), model.UploadFile, void 0, false, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
        }
        case 11: {
            return [new Model((arg10_5 = msg.fields[0].message, toText(printf("Feil ved opplasting av filer: %s"))(arg10_5)), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 8: {
            return [new Model(void 0, model.FileStores, model.UploadFile, void 0, false, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 12: {
            const entry_1 = msg.fields[0];
            Cons(printf("UpdateFileStore: name=%s"))(entry_1.name);
            return [new Model(void 0, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, entry_1, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 13: {
            if (model.ShowUploadForm) {
                return [new Model(toText(printf("Opplasting pågår, redigering kan ikke gjøres nå.")), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
            else {
                window.scroll(0, 0);
                return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, msg.fields[0], model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
        }
        case 14: {
            return [new Model(void 0, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, void 0, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 15: {
            Cons(printf("UpdateFileStore: Entered ..."));
            const matchValue_5 = model.UpdateForm;
            if (matchValue_5 == null) {
                return [model, Cmd_none()];
            }
            else {
                return [model, Cmd_OfPromise_either((entry_3) => FileStore_update(entry_3), matchValue_5, (arg0_2) => (new Message(16, arg0_2)), (arg0_3) => (new Message(17, arg0_3)))];
            }
        }
        case 16: {
            const res = msg.fields[0];
            const matchValue_6 = model.FileStores;
            if (matchValue_6 == null) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(model.ErrorMsg, map((elem) => {
                    if (elem.id === res.id) {
                        return new FileStore(elem.id, elem.name, res.description, elem.content, elem.uploaded);
                    }
                    else {
                        return elem;
                    }
                }, matchValue_6), model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, void 0, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
        }
        case 17: {
            return [new Model((arg10_7 = msg.fields[0].message, toText(printf("Kunne ikke oppdatere entiteten, exc: %s"))(arg10_7)), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 18: {
            window.scroll(0, 0);
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, msg.fields[0], model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 19: {
            return [new Model(void 0, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, void 0, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 20: {
            return [model, Cmd_OfPromise_either((entry_6) => FileStore_delete(entry_6), msg.fields[0], (arg0_4) => (new Message(21, arg0_4)), (arg0_5) => (new Message(22, arg0_5)))];
        }
        case 21: {
            const matchValue_8 = model.FileStores;
            if (matchValue_8 == null) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(model.ErrorMsg, matchValue_8.filter((elem_1) => (elem_1.id !== msg.fields[0].id)), model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, void 0, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
        }
        case 22: {
            return [new Model((arg10_8 = msg.fields[0].message, toText(printf("Kunne ikke slette entiteten, exc: %s"))(arg10_8)), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 23: {
            const vt = msg.fields[0];
            const arg10_9 = toString(vt);
            Cons(printf("FileStore.update: ShowView with vt=%s"))(arg10_9);
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, vt, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 24: {
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, msg.fields[0], model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 25: {
            const lt = LibTypes_ofId_Z524259A4(msg.fields[0]);
            const arg10_10 = toString(lt);
            Cons(printf("FileStore.update: SelectLibType %s"))(arg10_10);
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, toString(lt), model.SearchCriteria), Cmd_none()];
        }
        case 26: {
            const matchValue_9 = model.LibType;
            if (matchValue_9 == null) {
                return [new Model("Du må angi LibType!", model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
            }
            else {
                const matchValue_10 = LibTypes_ofValue_Z721C83C5(matchValue_9);
                if (matchValue_10.tag === 1) {
                    const matchValue_11 = model.ProjectName;
                    if (matchValue_11 == null) {
                        return [new Model("Du må angi ProjectName!", model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
                    }
                    else {
                        return [new Model(void 0, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_OfPromise_either((prName) => FileStore_addFileSystemProject(prName), matchValue_11, (arg0_8) => (new Message(27, arg0_8)), (arg0_9) => (new Message(28, arg0_9)))];
                    }
                }
                else {
                    return [new Model(void 0, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_OfPromise_either(FileStore_addFileSystemOverriding, void 0, (arg0_6) => (new Message(27, arg0_6)), (arg0_7) => (new Message(28, arg0_7)))];
                }
            }
        }
        case 27: {
            const arg10_11 = msg.fields[0].length | 0;
            Cons(printf("FileStore.update: FileCacheResponse entered with fsArr.Length=%i"))(arg10_11);
            return [model, Cmd_none()];
        }
        case 28: {
            const e_4 = msg.fields[0];
            const arg10_12 = toString(e_4);
            Cons(printf("FileStore.update: FileCacheError: %s"))(arg10_12);
            return [new Model((arg10_13 = toString(e_4), toText(printf("FileCacheError: %s"))(arg10_13)), model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
        case 29: {
            const s = msg.fields[0];
            Cons(printf("FileStore.update: SetSearchCriteria: \"%s\""))(s);
            return [new Model(model.ErrorMsg, model.FileStores, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, s), Cmd_none()];
        }
        default: {
            const files = msg.fields[0];
            const arg10 = files.length | 0;
            Cons(printf("FileStore.update: InitResponse files.length=%i"))(arg10);
            return [new Model(model.ErrorMsg, files, model.UploadFile, model.FilesSelected, model.ShowUploadForm, model.SelectForm, model.UpdateForm, model.DeleteForm, model.ViewType, model.ProjectName, model.LibType, model.SearchCriteria), Cmd_none()];
        }
    }
}

export function drawUploadForm(model, dispatch) {
    return react.createElement("div", {
        className: "fs-fileupload-form",
    }, ...toList(delay(() => append_1(singleton(Bootstrap_Table_table(empty(), singleton_1(react.createElement("tr", {}, react.createElement("td", {}, react.createElement("div", {
        className: "fs-btn",
    }, react.createElement("label", {
        className: "fs-btn",
    }, react.createElement("i", {
        className: join(" ", ["fa", "fa-upload"]),
    }), react.createElement("span", {}, "Velg filer "), react.createElement("input", {
        style: {
            display: "none",
        },
        type: "file",
        multiple: true,
        onChange: (ev) => {
            dispatch(new Message(3, ev.target.files));
        },
    })))), react.createElement("td", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(4, "fs-btn"), new Bootstrap_Button_Option(2, (_arg1) => {
        dispatch(new Message(8));
    })]), singleton_1("Avbryt"))))))), delay(() => {
        const matchValue = model.FilesSelected;
        if (matchValue == null) {
            return empty_1();
        }
        else {
            return append_1(singleton(react.createElement("div", {
                className: "fs-form-response",
            }, Bootstrap_Table_table(ofArray([new Bootstrap_Table_Option(2), new Bootstrap_Table_Option(3)]), singleton_1(react.createElement("tbody", {
                style: {
                    backgroundColor: "white",
                },
            }, map((f) => react.createElement("tr", {}, react.createElement("td", {}, f.name), react.createElement("td", {}), react.createElement("td", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(6)), new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(0)), new Bootstrap_Button_Option(2, (ev_1) => {
                dispatch(new Message(7, f.name));
            })]), singleton_1(react.createElement("i", {
                className: join(" ", ["fa", "fa-trash"]),
            }))))), matchValue)))))), delay(() => singleton(react.createElement("div", {
                className: "fs-form-footer",
            }, Bootstrap_Table_table(empty(), singleton_1(react.createElement("tr", {}, react.createElement("td", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(4)), new Bootstrap_Button_Option(4, "fs-btn"), new Bootstrap_Button_Option(2, (_arg2) => {
                dispatch(new Message(9, dispatch));
            })]), singleton_1("Last opp"))))))))));
        }
    })))));
}

export function drawUpdateForm(model, dispatch, updateForm) {
    return react.createElement("div", {
        className: "fs-filestore-update-form",
    }, react.createElement("div", {
        className: join(" ", ["fs-center", "fs-title"]),
    }, "Rediger"), Bootstrap_Table_table(empty(), singleton_1(react.createElement("tbody", {}, react.createElement("td", keyValueList([Helpers_classList([["fs-label", true], ["fs-w10", true]])], 1), "Id"), react.createElement("td", {
        className: "fs-textbox",
    }, Bootstrap_Input_text(ofArray([new Bootstrap_Input_Option(8, singleton_1(["style", {
        maxWidth: "300px",
    }])), new Bootstrap_Input_Option(5, updateForm.id), new Bootstrap_Input_Option(3, true)]))), react.createElement("td", keyValueList([Helpers_classList([["fs-label", true], ["fs-w10", true]])], 1), "Navn"), react.createElement("td", {
        className: "fs-textbox",
    }, Bootstrap_Input_text(ofArray([new Bootstrap_Input_Option(8, singleton_1(["style", {
        maxWidth: "300px",
    }])), new Bootstrap_Input_Option(5, updateForm.name), new Bootstrap_Input_Option(3, true)]))), react.createElement("tr", {}, react.createElement("td", keyValueList([Helpers_classList([["fs-label", true], ["fs-w10", true]])], 1), "Beskrivelse"), react.createElement("td", {
        className: "fs-textbox",
    }, Bootstrap_Input_text(ofArray([new Bootstrap_Input_Option(8, singleton_1(["style", {
        maxWidth: "300px",
    }])), new Bootstrap_Input_Option(5, updateForm.description), new Bootstrap_Input_Option(2, (ev) => {
        dispatch(new Message(12, new FileStore(updateForm.id, updateForm.name, Browser_Types_Event__Event_get_Value(ev), updateForm.content, updateForm.uploaded)));
    })]))))))), react.createElement("table", {}, react.createElement("tbody", {}, react.createElement("tr", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(4)), new Bootstrap_Button_Option(2, (_arg1) => {
        dispatch(new Message(15));
    })]), singleton_1("Lagre")), Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(2, (_arg2) => {
        dispatch(new Message(14));
    })), singleton_1("Avbryt"))))));
}

export function drawTable(model, dispatch, entries) {
    let matchValue;
    Cons(printf("FileStore.drawTable: Entered ..."));
    return react.createElement("div", {
        className: "fs-fileupload",
    }, react.createElement("div", {}, react.createElement("table", {}, react.createElement("tbody", {}, react.createElement("tr", {}, react.createElement("td", {}, "Navn-filter:"), react.createElement("td", {}, PageElements_textArea((matchValue = model.SearchCriteria, (matchValue == null) ? "" : matchValue), 1, 80, (ev) => {
        dispatch(new Message(29, Browser_Types_Event__Event_get_Value(ev)));
    })))))), Bootstrap_Table_table(ofArray([new Bootstrap_Table_Option(2), new Bootstrap_Table_Option(3)]), ofArray([react.createElement("thead", {}, react.createElement("tr", {
        style: {
            whiteSpace: "nowrap",
        },
    }, react.createElement("td", {
        style: {
            width: "20%",
        },
    }, "Navn"), react.createElement("td", {
        style: {
            width: "60%",
        },
    }, "Beskrivelse"), react.createElement("td", {}, "Opplastet"))), react.createElement("tbody", {}, map((entry) => react.createElement("tr", {
        style: {
            whiteSpace: "nowrap",
        },
    }, react.createElement("td", {}, entry.name), react.createElement("td", {}, entry.description), react.createElement("td", {}, toString_1(entry.uploaded, "yyyy-MM-dd HH:mm:ss"))), entries))])));
}

export function drawFileSysForm(model, dispatch, ltName) {
    Cons(printf("FileStore.drawFileSysForm: Entered ..."));
    return "Not implemented!";
}

export function drawFileSysFormOld(model, dispatch, ltName) {
    Cons(printf("FileStore.drawFileSysForm: Entered ..."));
    let pn;
    const matchValue = model.ProjectName;
    pn = ((matchValue == null) ? "" : matchValue);
    const lt = LibTypes_ofValue_Z721C83C5(ltName);
    return react.createElement("div", {
        className: "fs-filecache",
    }, react.createElement("table", {}, react.createElement("tbody", {}, ...toList(delay(() => append_1(singleton(react.createElement("tr", {}, react.createElement("td", {
        className: "fs-label",
    }, "LibType"), react.createElement("td", {}, react.createElement("select", {
        onChange: (ev) => {
            try {
                dispatch(new Message(25, parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32)));
            }
            catch (matchValue_1) {
            }
        },
    }, ...toList(delay(() => {
        const lt_1 = LibTypes_ofValue_Z721C83C5(ltName);
        return append_1(singleton(react.createElement("option", {
            value: LibTypes__get_Id(lt_1),
        }, LibTypes__get_Value(lt_1))), delay(() => map_1((t) => react.createElement("option", {
            value: LibTypes__get_Id(t),
        }, LibTypes__get_Value(t)), map((case$) => makeUnion(case$, []), getUnionCases(LibTypes$reflection())))));
    })))))), delay(() => (equals(lt, new LibTypes(1)) ? singleton(react.createElement("tr", {}, react.createElement("td", {
        className: "fs-label",
    }, "Prosjektnavn"), react.createElement("td", {}, Bootstrap_Input_text(ofArray([new Bootstrap_Input_Option(5, pn), new Bootstrap_Input_Option(2, (ev_1) => {
        dispatch(new Message(24, Browser_Types_Event__Event_get_Value(ev_1)));
    })]))))) : empty_1()))))))), react.createElement("div", {
        className: "fs-form-footer",
    }, ...toList(delay(() => singleton(react.createElement("table", {}, react.createElement("tbody", {}, react.createElement("tr", {}, ...toList(delay(() => append_1(singleton(react.createElement("td", {}, Bootstrap_Button_button(ofArray([new Bootstrap_Button_Option(0, new Bootstrap_Button_ButtonClass(0)), new Bootstrap_Button_Option(4, "fs-btn-bordered"), new Bootstrap_Button_Option(1, new Bootstrap_Button_ButtonSize(1)), new Bootstrap_Button_Option(2, (_arg1) => {
        dispatch(new Message(26));
    }), new Bootstrap_Button_Option(4, join(" ", ["fs-btn-black", "fs-btn-sp"]))]), ofArray([react.createElement("i", {
        className: join(" ", ["far", "fa-check-circle"]),
    }), react.createElement("span", {}, " Last opp")])))), delay(() => singleton(react.createElement("td", {
        className: "fs-w100",
    }))))))))))))));
}

export function view(model, dispatch) {
    return react.createElement("div", keyValueList([Helpers_classList([["fs-main-body", true]])], 1), ...toList(delay(() => append_1(singleton(react.createElement("div", {}, ...toList(delay(() => {
        const matchValue = model.ErrorMsg;
        if (matchValue == null) {
            return empty_1();
        }
        else {
            return singleton(errorHeader(matchValue));
        }
    })))), delay(() => append_1(singleton(react.createElement("h2", {}, "Filer")), delay(() => append_1(singleton(react.createElement("div", {
        className: "fs-filestore-tabs",
    }, Bootstrap_Table_table(empty(), singleton_1(react.createElement("tbody", {}, react.createElement("tr", {}, ...toList(delay(() => append_1(singleton(react.createElement("td", {}, react.createElement("div", {
        className: "fs-tab-border",
    }, Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(2, (ev) => {
        dispatch(new Message(23, new ViewTypes(0)));
    })), singleton_1(toString(new ViewTypes(0))))))), delay(() => append_1(singleton(react.createElement("td", {}, react.createElement("div", {
        className: "fs-tab-border",
    }, Bootstrap_Button_button(singleton_1(new Bootstrap_Button_Option(2, (ev_1) => {
        dispatch(new Message(23, new ViewTypes(1)));
    })), singleton_1(toString(new ViewTypes(1))))))), delay(() => singleton(react.createElement("td", {
        className: "fs-w100",
    })))))))))))))), delay(() => singleton(react.createElement("div", {
        className: "fs-filestore-body",
    }, ...toList(delay(() => {
        if (model.ViewType.tag === 1) {
            const matchValue_3 = model.LibType;
            if (matchValue_3 == null) {
                return empty_1();
            }
            else {
                return singleton(drawFileSysForm(model, dispatch, matchValue_3));
            }
        }
        else {
            const matchValue_2 = model.FileStores;
            return (matchValue_2 == null) ? singleton(loadingIndicatorMedium()) : singleton(drawTable(model, dispatch, matchValue_2));
        }
    })))))))))))));
}

