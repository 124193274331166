import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { record_type, int32_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { createElement } from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { uncurry, createObj, equals } from "../.fable/fable-library.3.2.4/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { WasteReg } from "../Waste/WasteRegistration.js";
import { wastePlan } from "../Waste/WastePlan.js";
import { HmsReg } from "../HMS/HmsRegistration.js";
import { ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useReducer_2B9E6EA0 } from "../.fable/Feliz.1.45.0/React.fs.js";

class RegForm extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Hms", "Waste", "WastePlan"];
    }
}

function RegForm$reflection() {
    return union_type("ProjectRegistration.RegForm", [], RegForm, () => [[], [], []]);
}

class Model extends Record {
    constructor(ProjectId, SelectedForm) {
        super();
        this.ProjectId = (ProjectId | 0);
        this.SelectedForm = SelectedForm;
    }
}

function Model$reflection() {
    return record_type("ProjectRegistration.Model", [], Model, () => [["ProjectId", int32_type], ["SelectedForm", RegForm$reflection()]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SelectForm"];
    }
}

function Message$reflection() {
    return union_type("ProjectRegistration.Message", [], Message, () => [[["Item", RegForm$reflection()]]]);
}

function init(x) {
    return new Model(x, new RegForm(0));
}

function update(model, msg) {
    return new Model(model.ProjectId, msg.fields[0]);
}

function view(model, dispatch) {
    let props, props_2, props_4;
    const children = ofArray([createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([(props = toList(delay(() => append(equals(model.SelectedForm, new RegForm(0)) ? singleton(["className", "is-info"]) : empty(), delay(() => append(singleton(["style", {
            minWidth: 150 + "px",
            maxWidth: 250 + "px",
            fontSize: 0.8 + "rem",
        }]), delay(() => append(singleton(["onClick", (_arg1) => {
            dispatch(new Message(0, new RegForm(0)));
        }]), delay(() => singleton(["children", "HMS"]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props)))), (props_2 = toList(delay(() => append(equals(model.SelectedForm, new RegForm(1)) ? singleton(["className", "is-info"]) : empty(), delay(() => append(singleton(["style", {
            minWidth: 150 + "px",
            maxWidth: 250 + "px",
            fontSize: 0.8 + "rem",
        }]), delay(() => append(singleton(["onClick", (_arg2) => {
            dispatch(new Message(0, new RegForm(1)));
        }]), delay(() => singleton(["children", "Sorteringsgrad"]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_2)))), (props_4 = toList(delay(() => append(equals(model.SelectedForm, new RegForm(2)) ? singleton(["className", "is-info"]) : empty(), delay(() => append(singleton(["style", {
            minWidth: 150 + "px",
            maxWidth: 250 + "px",
            fontSize: 0.8 + "rem",
        }]), delay(() => append(singleton(["onClick", (_arg3) => {
            dispatch(new Message(0, new RegForm(2)));
        }]), delay(() => singleton(["children", "Avfallsplan"]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))]),
    }), createElement("div", {
        style: {},
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            const matchValue = model.SelectedForm;
            switch (matchValue.tag) {
                case 1: {
                    return singleton(createElement(WasteReg, {
                        projectId: model.ProjectId,
                    }));
                }
                case 2: {
                    return singleton(wastePlan(model.ProjectId));
                }
                default: {
                    return singleton(createElement(HmsReg, {
                        projectId: model.ProjectId,
                    }));
                }
            }
        })))),
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function projectReg(projId) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(props.projectId));
        return view(patternInput[0], patternInput[1]);
    }, void 0, uncurry(2, void 0), void 0, "projectReg", "/builds/serit/score/score/src/Client/Project/ProjectRegistration.fs", 87)({
        projectId: projId,
    });
}

