import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { Waste_WasteType, Helpers_ResultMessage$reflection, Waste_WasteType$reflection } from "../Shared/ApiDataTypes.js";
import { int32_type, obj_type, union_type, record_type, string_type, bool_type, option_type, array_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.4.1/Fable.React.FunctionComponent.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { SchemaField, SchemaField__ToId } from "../Shared/WasteMapping.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { MuiHelpers_reactElement, MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { tryFocus } from "../Utils.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { genericDeleteDialog, snackbarError, insertMaterialTableNorwegianLocalization, loadingIndicatorSmall } from "../ViewHelpers.js";
import { StyleImports_useTheme } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import material$002Dtable from "material-table";
import Icon from "@material-ui/core/Icon";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { empty as empty_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";

class Model extends Record {
    constructor(Types, AddForm, DeleteForm, ErrorMsg) {
        super();
        this.Types = Types;
        this.AddForm = AddForm;
        this.DeleteForm = DeleteForm;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("WasteType.Model", [], Model, () => [["Types", option_type(array_type(Waste_WasteType$reflection()))], ["AddForm", bool_type], ["DeleteForm", option_type(Waste_WasteType$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TypesResponse", "ToggleAddForm", "AddResponse", "UpdateResponse", "SetDelete", "DeleteResponse", "SetError", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("WasteType.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Waste_WasteType$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Waste_WasteType$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_WasteType$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_WasteType$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Waste_WasteType$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Waste_WasteType$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(Waste_WasteType$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], []]);
}

function init() {
    return new Model(void 0, false, void 0, void 0);
}

function update(model, msg) {
    let matchValue, matchValue_1, matchValue_3;
    switch (msg.tag) {
        case 1: {
            return new Model(model.Types, !model.AddForm, model.DeleteForm, model.ErrorMsg);
        }
        case 2: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Types, model.AddForm, model.DeleteForm, "Noe gikk galt. Kunne ikke opprette ny avfallstype.");
            }
            else {
                const x_1 = res_1.fields[0];
                return new Model((matchValue = model.Types, (matchValue != null) ? append([x_1], matchValue) : [x_1]), false, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 3: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Types, model.AddForm, model.DeleteForm, "Noe gikk galt. Kunne ikke oppdatere avfallstype.");
            }
            else {
                const x_2 = res_2.fields[0];
                return new Model((matchValue_1 = model.Types, (matchValue_1 != null) ? map((e) => {
                    if (e.Id === x_2.Id) {
                        return x_2;
                    }
                    else {
                        return e;
                    }
                }, matchValue_1) : [x_2]), model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
        case 4: {
            return new Model(model.Types, model.AddForm, msg.fields[0], model.ErrorMsg);
        }
        case 5: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Types, model.AddForm, model.DeleteForm, "Noe gikk galt. Kunne ikke slette avfallstype.");
            }
            else {
                const x_4 = res_3.fields[0];
                if (x_4.Result === "success") {
                    const matchValue_2 = model.DeleteForm;
                    if (matchValue_2 != null) {
                        const df = matchValue_2;
                        return new Model((matchValue_3 = model.Types, (matchValue_3 != null) ? matchValue_3.filter((e_1) => (e_1.Id !== df.Id)) : (void 0)), model.AddForm, void 0, model.ErrorMsg);
                    }
                    else {
                        return model;
                    }
                }
                else {
                    return new Model(model.Types, model.AddForm, model.DeleteForm, x_4.Message);
                }
            }
        }
        case 7: {
            return new Model(model.Types, model.AddForm, model.DeleteForm, void 0);
        }
        case 6: {
            return new Model(model.Types, model.AddForm, model.DeleteForm, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Types, model.AddForm, model.DeleteForm, "Noe gikk galt. Kunne ikke laste avfallstyper.");
            }
            else {
                return new Model(res.fields[0], model.AddForm, model.DeleteForm, model.ErrorMsg);
            }
        }
    }
}

function fetchTypes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/waste/types", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Waste_WasteType$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(0, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function tryAdd(x, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if ((x.Name.length > 0) ? (x.Code.length > 0) : false) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Waste_WasteType$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Waste_WasteType$reflection,
                });
                return Fetch_tryPost_5760677E("/api/waste/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Waste_WasteType$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(2, _arg1));
                return Promise.resolve();
            }));
        }
        else if (x.Code.length === 0) {
            dispatch(new Message(6, "Kode kan ikke være tom"));
            return Promise.resolve();
        }
        else {
            dispatch(new Message(6, "Navn kan ikke være tomt"));
            return Promise.resolve();
        }
    }));
    pr.then();
}

function tryUpdate(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        if (x.Name.length > 0) {
            return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                    ResolveType: Waste_WasteType$reflection,
                })(x);
                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                    ResolveType: Waste_WasteType$reflection,
                });
                return Fetch_tryPost_5760677E("/api/waste/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                    ResolveType: Waste_WasteType$reflection,
                }, {
                    ResolveType: () => obj_type,
                });
            })).then(((_arg1) => {
                dispatch(new Message(3, _arg1));
                return Promise.resolve();
            }));
        }
        else {
            dispatch(new Message(6, "Navn kan ikke være tomt"));
            return Promise.resolve();
        }
    }));
}

function tryDelete(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: () => int32_type,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryDelete_5760677E("/api/waste/types", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(5, _arg1));
        return Promise.resolve();
    })))));
}

const addFormDialog = FunctionComponent_Of_Z5A158BBF((props) => {
    let children, children_2;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Waste_WasteType(-1, "", "", "", false, true, false, true, SchemaField__ToId(new SchemaField(30)), false));
    const updateState = patternInput[1];
    const state = patternInput[0];
    const innerBody = createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20 + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    margin: 5,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["autoFocus", true], ["defaultValue", state.Code], ["id", "code-input"], ["onChange", (e) => {
                    updateState(new Waste_WasteType(state.Id, Browser_Types_Event__Event_get_Value(e), state.Name, state.Desc, state.Required, state.Sorted, state.Dangerous, state.Calculate, state.SchemaField, state.DirectOnly));
                }], ["onKeyUp", (kc) => {
                    if (kc.key === "Enter") {
                        tryFocus("name-input");
                    }
                }], ["label", "Kode"], ["variant", "outlined"], ["size", "small"]])]),
            }), createElement("div", {
                style: {
                    margin: 5,
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.Name], ["id", "name-input"], ["onChange", (e_1) => {
                    updateState(new Waste_WasteType(state.Id, state.Code, Browser_Types_Event__Event_get_Value(e_1), state.Desc, state.Required, state.Sorted, state.Dangerous, state.Calculate, state.SchemaField, state.DirectOnly));
                }], ["onKeyUp", (kc_1) => {
                    if (kc_1.key === "Enter") {
                        tryFocus("desc-input");
                    }
                }], ["label", "Navn"], ["variant", "outlined"], ["size", "small"]])]),
            })]),
        }), createElement("div", {
            style: {
                margin: 5,
                display: "flex",
                justifyContent: "center",
            },
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(TextField, [["defaultValue", state.Desc], ["fullWidth", true], ["style", {
                maxWidth: 400,
            }], ["id", "desc-input"], ["multiline", true], ["onChange", (e_2) => {
                updateState(new Waste_WasteType(state.Id, state.Code, state.Name, Browser_Types_Event__Event_get_Value(e_2), state.Required, state.Sorted, state.Dangerous, state.Calculate, state.SchemaField, state.DirectOnly));
            }], ["label", "Beskrivelse"], ["variant", "outlined"], ["size", "small"]])]),
        }), (children = ofArray([MuiHelpers_createElement(FormControlLabel, [["label", "Påkrevd"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.Required], ["color", "primary"], ["onChange", (e_3) => {
            updateState(new Waste_WasteType(state.Id, state.Code, state.Name, state.Desc, Browser_Types_Event__Event_get_Checked(e_3), state.Sorted, state.Dangerous, state.Calculate, state.SchemaField, state.DirectOnly));
        }]])]]), MuiHelpers_createElement(FormControlLabel, [["label", "Sortert"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.Sorted], ["color", "primary"], ["onChange", (e_4) => {
            updateState(new Waste_WasteType(state.Id, state.Code, state.Name, state.Desc, state.Required, Browser_Types_Event__Event_get_Checked(e_4), state.Dangerous, state.Calculate, state.SchemaField, state.DirectOnly));
        }]])]])]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), (children_2 = ofArray([MuiHelpers_createElement(FormControlLabel, [["label", "Farlig"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.Dangerous], ["color", "primary"], ["onChange", (e_5) => {
            updateState(new Waste_WasteType(state.Id, state.Code, state.Name, state.Desc, state.Required, state.Sorted, Browser_Types_Event__Event_get_Checked(e_5), state.Calculate, state.SchemaField, state.DirectOnly));
        }]])]]), MuiHelpers_createElement(FormControlLabel, [["label", "Inkl. i sorteringsgrad"], ["control", MuiHelpers_createElement(Checkbox, [["checked", state.Calculate], ["color", "primary"], ["onChange", (e_6) => {
            updateState(new Waste_WasteType(state.Id, state.Code, state.Name, state.Desc, state.Required, state.Sorted, state.Dangerous, Browser_Types_Event__Event_get_Checked(e_6), state.SchemaField, state.DirectOnly));
        }]])]])]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        }))]),
    });
    return MuiHelpers_createElement(Dialog, [["open", true], ["onClose", (_arg14, v) => {
        props.dispatch(new Message(1));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", "Legg til Avfallstype"]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerBody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg2) => {
        props.dispatch(new Message(1));
    }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg3) => {
        tryAdd(state, props.dispatch);
    }], ["disabled", (state.Name.length === 0) ? true : (state.Code.length === 0)], ["color", "primary"], ["children", "Legg til"]])])]])])]]);
}, void 0, uncurry(2, void 0), void 0, "addFormDialog", "/builds/serit/score/score/src/Client/Waste/WasteType.fs", 157);

function drawMaterialTable(model, dispatch) {
    let value_39;
    const matchValue = model.Types;
    if (matchValue == null) {
        return loadingIndicatorSmall();
    }
    else {
        const entries = matchValue;
        const theme = StyleImports_useTheme();
        return MuiHelpers_reactElement(material$002Dtable, createObj(ofArray([["style", {
            backgroundColor: theme.palette.background.paper,
            maxWidth: 1000,
            padding: 2 + "vw",
            width: 90 + "%",
        }], ["title", "Avfallstyper"], ["data", Array.from(ofArray(entries))], ["columns", [{
            title: "Kode",
            field: "Code",
        }, {
            title: "Navn",
            field: "Name",
        }, {
            title: "Påkrevd",
            field: "Required",
            type: "boolean",
        }, {
            title: "Sortert",
            field: "Sorted",
            type: "boolean",
        }, {
            title: "Farlig",
            field: "Dangerous",
            type: "boolean",
        }, {
            title: "Inkl. i sorteringsgrad",
            field: "Calculate",
            type: "boolean",
        }]], ["editable", {
            onRowUpdate: (delegateArg0, delegateArg1) => tryUpdate(delegateArg0, dispatch),
        }], ["actions", [createObj(ofArray([(value_39 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_39]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_1, delegateArg1_1) => {
            dispatch(new Message(1));
        }]])), (arg) => {
            let value_47;
            return createObj(ofArray([(value_47 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_47]), ["tooltip", "Slett"], ["onClick", (delegateArg0_2, delegateArg1_2) => {
                dispatch(new Message(4, arg));
            }]]));
        }]], insertMaterialTableNorwegianLocalization(), ["options", {
            grouping: false,
            search: false,
            pageSize: (entries.length > 5) ? 10 : 5,
        }]])));
    }
}

function view(model, dispatch) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 3 + "rem",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(7));
        })), delay(() => append_1(singleton(drawMaterialTable(model, dispatch)), delay(() => append_1(model.AddForm ? singleton(addFormDialog({
            dispatch: dispatch,
        })) : empty_1(), delay(() => {
            let value_7;
            const matchValue = model.DeleteForm;
            if (matchValue == null) {
                return empty_1();
            }
            else {
                const delRow = matchValue;
                return singleton(genericDeleteDialog(() => {
                    dispatch(new Message(4, void 0));
                }, () => tryDelete(delRow.Id, dispatch), [(value_7 = toText(printf("Valgt navn: %A"))(delRow.Name), createElement("h3", {
                    children: [value_7],
                }))]));
            }
        })))))))))),
    });
}

export function WasteTypes() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchTypes(dispatch);
    });
    return view(patternInput[0], dispatch);
}

