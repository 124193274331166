import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, list_type, string_type, option_type, union_type, record_type, array_type, int32_type, bool_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { join, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { sortByDescending, sortBy, append as append_2, map } from "../.fable/fable-library.3.2.4/Array.js";
import { Helpers_IdNameDesc$reflection, Helpers_IdName$reflection, Office$reflection, Company$reflection, Project$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { toArray, empty, singleton, append, contains, ofArray } from "../.fable/fable-library.3.2.4/List.js";
import { comparePrimitives, createObj, uncurry, safeHash, equals } from "../.fable/fable-library.3.2.4/Util.js";
import { List_distinct, List_except } from "../.fable/fable-library.3.2.4/Seq2.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ProjectStatus__ColorCode, ProjectStatus_FromInt_Z524259A4 } from "../Shared/Shared.js";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { op_UnaryNegation_Int32 } from "../.fable/fable-library.3.2.4/Int32.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Icon from "@material-ui/core/Icon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { keyValueList } from "../.fable/fable-library.3.2.4/MapUtil.js";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { snackbarError, RotationAngle, rotatingChevronButton } from "../ViewHelpers.js";
import { hasPermission } from "../LoginState.js";
import { Functionality } from "../Shared/PermissionMapping.js";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { ProjectPrintModal } from "./ProjectPrint.js";
import { projectForm } from "./ProjectForm.js";

class ProjectSearch extends Record {
    constructor(Active, Inactive, Planning, InProcess, DelayedStart, Overtime, Completed, Construction, Landscaping, Companies, Offices, BuildingCategory, Builders) {
        super();
        this.Active = Active;
        this.Inactive = Inactive;
        this.Planning = Planning;
        this.InProcess = InProcess;
        this.DelayedStart = DelayedStart;
        this.Overtime = Overtime;
        this.Completed = Completed;
        this.Construction = Construction;
        this.Landscaping = Landscaping;
        this.Companies = Companies;
        this.Offices = Offices;
        this.BuildingCategory = BuildingCategory;
        this.Builders = Builders;
    }
}

function ProjectSearch$reflection() {
    return record_type("ProjectList.ProjectSearch", [], ProjectSearch, () => [["Active", bool_type], ["Inactive", bool_type], ["Planning", bool_type], ["InProcess", bool_type], ["DelayedStart", bool_type], ["Overtime", bool_type], ["Completed", bool_type], ["Construction", bool_type], ["Landscaping", bool_type], ["Companies", array_type(int32_type)], ["Offices", array_type(int32_type)], ["BuildingCategory", array_type(int32_type)], ["Builders", array_type(int32_type)]]);
}

function ProjectSearch_Default() {
    return new ProjectSearch(true, false, true, true, true, true, true, true, true, new Int32Array([]), new Int32Array([]), new Int32Array([]), new Int32Array([]));
}

function ProjectSearch__ToQueryString(this$) {
    const bool$0027 = (qry, incl) => {
        if (incl) {
            return toText(printf("\u0026%s=true"))(qry);
        }
        else {
            return "";
        }
    };
    const status$0027 = (qry_1, incl_1) => {
        if (incl_1) {
            return toText(printf("\u0026status=%s"))(qry_1);
        }
        else {
            return "";
        }
    };
    const cat$0027 = (qry_2, incl_2) => {
        if (incl_2) {
            return toText(printf("\u0026cat=%s"))(qry_2);
        }
        else {
            return "";
        }
    };
    return (((((((((((("?" + bool$0027("active", this$.Active)) + bool$0027("inactive", this$.Inactive)) + status$0027("planning", this$.Planning)) + status$0027("inprocess", this$.InProcess)) + status$0027("delayed", this$.DelayedStart)) + status$0027("overtime", this$.Overtime)) + status$0027("completed", this$.Completed)) + cat$0027("construction", this$.Construction)) + cat$0027("landscaping", this$.Landscaping)) + ((this$.Companies.length === 0) ? "" : join("", map((x) => toText(printf("\u0026company=%i"))(x), this$.Companies)))) + ((this$.Offices.length === 0) ? "" : join("", map((x_1) => toText(printf("\u0026office=%i"))(x_1), this$.Offices)))) + ((this$.BuildingCategory.length === 0) ? "" : join("", map((x_2) => toText(printf("\u0026buildcat=%i"))(x_2), this$.BuildingCategory)))) + ((this$.Builders.length === 0) ? "" : join("", map((x_3) => toText(printf("\u0026builder=%i"))(x_3), this$.Builders)));
}

class FilterCategory extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Activity", "ProjectStatus", "Category", "Company", "Office", "BuildingCat", "ProjectBuilder"];
    }
}

function FilterCategory$reflection() {
    return union_type("ProjectList.FilterCategory", [], FilterCategory, () => [[], [], [], [], [], [], []]);
}

function FilterCategory__get_AsString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Prosjektstatus";
        }
        case 2: {
            return "Kategori";
        }
        case 3: {
            return "Selskap";
        }
        case 4: {
            return "Kontor";
        }
        case 5: {
            return "Bygningskategori";
        }
        case 6: {
            return "Byggherre";
        }
        default: {
            return "Aktivitet";
        }
    }
}

function FilterCategory__ActiveInCategory_Z4540D279(this$, s) {
    const boolAsInt = (b) => b;
    switch (this$.tag) {
        case 1: {
            return ((((boolAsInt(s.Planning) + boolAsInt(s.InProcess)) + boolAsInt(s.DelayedStart)) + boolAsInt(s.Overtime)) + boolAsInt(s.Completed)) | 0;
        }
        case 2: {
            return (boolAsInt(s.Landscaping) + boolAsInt(s.Construction)) | 0;
        }
        case 3: {
            return s.Companies.length | 0;
        }
        case 4: {
            return s.Offices.length | 0;
        }
        case 5: {
            return s.BuildingCategory.length | 0;
        }
        case 6: {
            return s.Builders.length | 0;
        }
        default: {
            return (boolAsInt(s.Active) + boolAsInt(s.Inactive)) | 0;
        }
    }
}

class Model extends Record {
    constructor(Projects, Companies, Offices, ProjectOutcomeTypes, Builders, Processing, Queries, ShowForm, ErrorMsg, ExpandedFilters, Print) {
        super();
        this.Projects = Projects;
        this.Companies = Companies;
        this.Offices = Offices;
        this.ProjectOutcomeTypes = ProjectOutcomeTypes;
        this.Builders = Builders;
        this.Processing = Processing;
        this.Queries = Queries;
        this.ShowForm = ShowForm;
        this.ErrorMsg = ErrorMsg;
        this.ExpandedFilters = ExpandedFilters;
        this.Print = Print;
    }
}

function Model$reflection() {
    return record_type("ProjectList.Model", [], Model, () => [["Projects", array_type(Project$reflection())], ["Companies", option_type(array_type(Company$reflection()))], ["Offices", option_type(array_type(Office$reflection()))], ["ProjectOutcomeTypes", option_type(array_type(Helpers_IdName$reflection()))], ["Builders", option_type(array_type(Helpers_IdNameDesc$reflection()))], ["Processing", bool_type], ["Queries", ProjectSearch$reflection()], ["ShowForm", bool_type], ["ErrorMsg", option_type(string_type)], ["ExpandedFilters", list_type(FilterCategory$reflection())], ["Print", option_type(int32_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProjectsResponse", "CompaniesResponse", "OfficesResponse", "ProjectOutcomeTypeResponse", "BuildersResponse", "UpdateQueries", "ToggleForm", "ToggleFilter", "TogglePrint", "DismissError"];
    }
}

function Message$reflection() {
    return union_type("ProjectList.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Project$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Project$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Company$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Company$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Office$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Office$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdName$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdName$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(Helpers_IdNameDesc$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(Helpers_IdNameDesc$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", ProjectSearch$reflection()]], [], [["Item", FilterCategory$reflection()]], [["Item", option_type(int32_type)]], []]);
}

function init() {
    return new Model([], void 0, void 0, void 0, void 0, true, ProjectSearch_Default(), false, void 0, ofArray([new FilterCategory(0), new FilterCategory(1), new FilterCategory(2)]), void 0);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, "Noe gikk galt. Kunne ikke laste selskaper.", model.ExpandedFilters, model.Print);
            }
            else {
                return new Model(model.Projects, res_1.fields[0], model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, "Noe gikk galt. Kunne ikke laste kontor.", model.ExpandedFilters, model.Print);
            }
            else {
                return new Model(model.Projects, model.Companies, res_2.fields[0], model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, "Noe gikk galt. Kunne ikke laste bygningskategori.", model.ExpandedFilters, model.Print);
            }
            else {
                return new Model(model.Projects, model.Companies, model.Offices, res_3.fields[0], model.Builders, model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, "Noe gikk galt. Kunne ikke laste byggherrer.", model.ExpandedFilters, model.Print);
            }
            else {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, res_4.fields[0], model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
            }
        }
        case 5: {
            return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, msg.fields[0], model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
        }
        case 6: {
            return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, !model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
        }
        case 8: {
            return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, msg.fields[0]);
        }
        case 9: {
            return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, void 0, model.ExpandedFilters, model.Print);
        }
        case 7: {
            const category = msg.fields[0];
            return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, model.Processing, model.Queries, model.ShowForm, model.ErrorMsg, contains(category, model.ExpandedFilters, {
                Equals: (x_7, y) => equals(x_7, y),
                GetHashCode: (x_7) => safeHash(x_7),
            }) ? List_except([category], model.ExpandedFilters, {
                Equals: (x_8, y_1) => equals(x_8, y_1),
                GetHashCode: (x_8) => safeHash(x_8),
            }) : List_distinct(append(model.ExpandedFilters, singleton(category)), {
                Equals: (x_9, y_2) => equals(x_9, y_2),
                GetHashCode: (x_9) => safeHash(x_9),
            }), model.Print);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Projects, model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, false, model.Queries, model.ShowForm, "Noe gikk galt. Kunne ikke laste prosjektliste.", model.ExpandedFilters, model.Print);
            }
            else {
                return new Model(res.fields[0], model.Companies, model.Offices, model.ProjectOutcomeTypes, model.Builders, false, model.Queries, model.ShowForm, model.ErrorMsg, model.ExpandedFilters, model.Print);
            }
        }
    }
}

function fetchProjects(qry, dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        dispatch(new Message(5, qry));
        let requestPath;
        const httpQuery = ProjectSearch__ToQueryString(qry);
        requestPath = toText(printf("/api/projects%s"))(httpQuery);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(Project$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(Project$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
    pr.then();
}

function fetchCompanies(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Company$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/company/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Company$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(1, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchOffices(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Office$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/office/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Office$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(2, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchProjectOutcomes(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/projectoutcometype/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdName$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(3, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function fetchBuilders(dispatch) {
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        });
        return Fetch_tryGet_5760677E("/api/builder/all", void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: () => array_type(Helpers_IdNameDesc$reflection()),
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    })))));
    pr.then();
}

function projectTile(x, dispatch) {
    let props_2, elms;
    const projStatus = ProjectStatus_FromInt_Z524259A4(x.Status);
    const props_15 = ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: 200,
        minWidth: 200,
        height: 300,
        margin: 5,
    }], ["children", Interop_reactApi.Children.toArray([createElement("a", {
        href: toText(printf("/project/%i"))(x.Id),
        children: Interop_reactApi.Children.toArray([(props_2 = ofArray([["style", {
            cursor: "pointer",
            height: 160,
        }], ["children", Interop_reactApi.Children.toArray([createElement("figure", {
            style: {
                textAlign: "center",
                backgroundColor: "#f6f6f6",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 100 + "%",
            },
            children: Interop_reactApi.Children.toArray([createElement("img", {
                style: {
                    maxHeight: 160 + "px",
                },
                src: toText(printf("/api/projectimage/thumbnail/%i"))(x.Id),
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("card-image", props_2))))]),
    }), (elms = ofArray([createElement("a", {
        style: {
            textDecorationLine: "underline",
        },
        href: toText(printf("/project/%i"))(x.Id),
        children: x.Name,
    }), createElement("br", {}), createElement("a", {
        href: toText(printf("/project/%i"))(x.Id),
        style: {
            fontSize: 0.8 + "rem",
            color: ProjectStatus__ColorCode(projStatus),
            textDecorationLine: "underline",
        },
        children: toString(projStatus),
    }), createElement("div", {
        style: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("span", {
            style: {
                fontSize: 0.7 + "rem",
                display: "inline-block",
            },
            children: toText(printf("Prosjektinfo: %i%%"))(x.InfoScore),
        })), delay(() => {
            let children;
            const matchValue = x.ContactPerson;
            if (matchValue != null) {
                const contactPerson = matchValue;
                return singleton_1((children = singleton(MuiHelpers_createElement(Tooltip, [["title", toText(printf("Kontaktperson: %s, %s, %s"))(contactPerson.Name)(contactPerson.Phone)(contactPerson.Email)], ["children", createElement("button", {
                    className: "icon-button",
                    style: {
                        fontSize: 1 + "rem",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: "fas fa-id-card",
                    })]),
                })]])), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }
            else {
                return empty_1();
            }
        })))))),
    })]), createElement("div", {
        className: "card-content",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("card", props_15)));
}

const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const theme = arg;
    const styles = StyleCreator$1_$ctor();
    const root = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "flex"]));
    const appBar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["zIndex", 20], ["top", 52]]));
    const appBarShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", toText(printf("calc(100%% - %ipx)"))(240)], ["marginLeft", 240]]));
    const toolbar = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["color", "#2F4F4F"], ["backgroundColor", "#F5F5F5"]]));
    const menuButton = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginRight", theme.spacing(2)], ["color", "#2F4F4F"]]));
    const hide = StyleCreator$1__create_4EAC9E1E(styles, singleton(["display", "none"]));
    const drawer = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["flexShrink", 0]]));
    const drawerPaper = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["width", 240], ["top", 52]]));
    const drawerHeader = StyleCreator$1__create_4EAC9E1E(styles, toList(delay(() => append_1(singleton_1(["display", "flex"]), delay(() => append_1(singleton_1(["alignItems", "center"]), delay(() => append_1(singleton_1(["padding", theme.spacing(0, 1)]), delay(() => append_1(Object.entries(theme.mixins.toolbar), delay(() => singleton_1(["justifyContent", "flex-end"]))))))))))));
    const content = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["flexGrow", 1], ["marginLeft", op_UnaryNegation_Int32(240)], ["backgroundColor", "#fff"]]));
    const contentShift = StyleCreator$1__create_4EAC9E1E(styles, ofArray([["marginLeft", 0], ["width", toText(printf("calc(100%% - %ipx)"))(240)]]));
    o = {
        appBar: appBar,
        appBarShift: appBarShift,
        buttonChevron: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["transitionProperty", "transform"], ["transitionDuration", 300 + "ms"], ["fontSize", 1 + "rem"]])),
        collapseButtonDiv: StyleCreator$1__create_4EAC9E1E(styles, ofArray([["cursor", "pointer"], ["width", 100 + "%"], ["display", "flex"], ["height", 48], ["alignItems", "center"]])),
        content: content,
        contentShift: contentShift,
        drawer: drawer,
        drawerHeader: drawerHeader,
        drawerPaper: drawerPaper,
        hide: hide,
        menuButton: menuButton,
        root: root,
        toolbar: toolbar,
    };
    return Object.assign({}, o);
});

function view(model, dispatch) {
    const s = useStyles();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const setDrawerOpen = patternInput[1];
    const drawerOpen = patternInput[0];
    const drawCategory = (category_1, lst) => {
        let category;
        const expanded_1 = contains(category_1, model.ExpandedFilters, {
            Equals: (x, y) => equals(x, y),
            GetHashCode: (x) => safeHash(x),
        });
        return toArray(singleton(MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListSubheader, [["children", Interop_reactApi.Children.toArray([(category = category_1, createElement("div", {
            className: s.collapseButtonDiv,
            children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, keyValueList(toList(delay(() => append_1(singleton_1(["className", s.buttonChevron]), delay(() => (expanded_1 ? singleton_1(["style", {
                transform: ("rotate(" + 90) + "deg)",
            }]) : empty_1()))))), 1))])]]), MuiHelpers_createElement(Typography, [["style", {
                userSelect: "none",
            }], ["variant", "subtitle1"], ["children", FilterCategory__get_AsString(category)]]), MuiHelpers_createElement(Badge, [["style", {
                marginLeft: "auto",
            }], ["badgeContent", FilterCategory__ActiveInCategory_Z4540D279(category, model.Queries)]])]),
            onClick: (_arg1) => {
                dispatch(new Message(7, category));
            },
        }))])]]), MuiHelpers_createElement(Collapse, [["in", expanded_1], ["timeout", "auto"], ["collapsedHeight", 0], ["children", Interop_reactApi.Children.toArray(Array.from(lst))]])])]])));
    };
    const loading = createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CircularProgress, [["size", 0.8 + "rem"]])]),
    });
    const listItem$0027 = (newQry, isChecked, title) => MuiHelpers_createElement(ListItem, [["button", true], ["dense", true], ["key", title], ["onClick", (_arg2) => {
        fetchProjects(newQry, dispatch);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Checkbox, [["edge", "start"], ["tabIndex", -1], ["disableRipple", true], ["checked", isChecked], ["inputProps", {
        ["aria-labelledby"]: join(" ", ["labelid"]),
    }]])])]]), MuiHelpers_createElement(ListItemText, [["primary", title]])])]]);
    return createElement("div", {
        className: s.root,
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(CssBaseline, []), MuiHelpers_createElement(AppBar, [["className", drawerOpen ? toText(printf("%s %s"))(s.appBar)(s.appBarShift) : s.appBar], ["position", "absolute"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Toolbar, [["className", s.toolbar], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(MuiHelpers_createElement(IconButton, [["className", drawerOpen ? toText(printf("%s %s"))(s.menuButton)(s.hide) : s.menuButton], ["color", "inherit"], ["edge", "start"], ["onClick", (_arg3) => {
            setDrawerOpen(!drawerOpen);
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Menu, {})])]])])]])), delay(() => (model.Processing ? singleton_1(MuiHelpers_createElement(CircularProgress, [])) : empty_1())))))))]])])]]), MuiHelpers_createElement(Drawer, [["className", s.drawer], ["classes.paper", s.drawerPaper], ["variant", "persistent"], ["anchor", "left"], ["open", drawerOpen], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("div", {
            className: s.drawerHeader,
            children: Interop_reactApi.Children.toArray([rotatingChevronButton(new RotationAngle(1), () => {
                setDrawerOpen(false);
            })]),
        })), delay(() => append_1(hasPermission(new Functionality(14)) ? append_1(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => singleton_1(MuiHelpers_createElement(Button, [["startIcon", react.createElement(Add, {})], ["size", "small"], ["variant", "contained"], ["color", "primary"], ["style", {
            margin: 5,
        }], ["onClick", (_arg5) => {
            dispatch(new Message(6));
        }], ["children", "Nytt prosjekt"]])))) : empty_1(), delay(() => append_1(singleton_1(MuiHelpers_createElement(Divider, [])), delay(() => {
            let inputRecord, inputRecord_1;
            return append_1(singleton_1(drawCategory(new FilterCategory(0), [listItem$0027((inputRecord = model.Queries, new ProjectSearch(!model.Queries.Active, inputRecord.Inactive, inputRecord.Planning, inputRecord.InProcess, inputRecord.DelayedStart, inputRecord.Overtime, inputRecord.Completed, inputRecord.Construction, inputRecord.Landscaping, inputRecord.Companies, inputRecord.Offices, inputRecord.BuildingCategory, inputRecord.Builders)), model.Queries.Active, "Aktiv"), listItem$0027((inputRecord_1 = model.Queries, new ProjectSearch(inputRecord_1.Active, !model.Queries.Inactive, inputRecord_1.Planning, inputRecord_1.InProcess, inputRecord_1.DelayedStart, inputRecord_1.Overtime, inputRecord_1.Completed, inputRecord_1.Construction, inputRecord_1.Landscaping, inputRecord_1.Companies, inputRecord_1.Offices, inputRecord_1.BuildingCategory, inputRecord_1.Builders)), model.Queries.Inactive, "Avsluttet")])), delay(() => {
                let inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5;
                return append_1(singleton_1(drawCategory(new FilterCategory(1), [listItem$0027((inputRecord_2 = model.Queries, new ProjectSearch(inputRecord_2.Active, inputRecord_2.Inactive, !model.Queries.Planning, inputRecord_2.InProcess, inputRecord_2.DelayedStart, inputRecord_2.Overtime, inputRecord_2.Completed, inputRecord_2.Construction, inputRecord_2.Landscaping, inputRecord_2.Companies, inputRecord_2.Offices, inputRecord_2.BuildingCategory, inputRecord_2.Builders)), model.Queries.Planning, "Under planlegging"), listItem$0027((inputRecord_3 = model.Queries, new ProjectSearch(inputRecord_3.Active, inputRecord_3.Inactive, inputRecord_3.Planning, inputRecord_3.InProcess, !model.Queries.DelayedStart, inputRecord_3.Overtime, inputRecord_3.Completed, inputRecord_3.Construction, inputRecord_3.Landscaping, inputRecord_3.Companies, inputRecord_3.Offices, inputRecord_3.BuildingCategory, inputRecord_3.Builders)), model.Queries.DelayedStart, "Forsinket start"), listItem$0027((inputRecord_4 = model.Queries, new ProjectSearch(inputRecord_4.Active, inputRecord_4.Inactive, inputRecord_4.Planning, !model.Queries.InProcess, inputRecord_4.DelayedStart, inputRecord_4.Overtime, inputRecord_4.Completed, inputRecord_4.Construction, inputRecord_4.Landscaping, inputRecord_4.Companies, inputRecord_4.Offices, inputRecord_4.BuildingCategory, inputRecord_4.Builders)), model.Queries.InProcess, "Pågår"), listItem$0027((inputRecord_5 = model.Queries, new ProjectSearch(inputRecord_5.Active, inputRecord_5.Inactive, inputRecord_5.Planning, inputRecord_5.InProcess, inputRecord_5.DelayedStart, !model.Queries.Overtime, inputRecord_5.Completed, inputRecord_5.Construction, inputRecord_5.Landscaping, inputRecord_5.Companies, inputRecord_5.Offices, inputRecord_5.BuildingCategory, inputRecord_5.Builders)), model.Queries.Overtime, "På overtid")])), delay(() => {
                    let inputRecord_6, inputRecord_7;
                    return append_1(singleton_1(drawCategory(new FilterCategory(2), [listItem$0027((inputRecord_6 = model.Queries, new ProjectSearch(inputRecord_6.Active, inputRecord_6.Inactive, inputRecord_6.Planning, inputRecord_6.InProcess, inputRecord_6.DelayedStart, inputRecord_6.Overtime, inputRecord_6.Completed, !model.Queries.Construction, inputRecord_6.Landscaping, inputRecord_6.Companies, inputRecord_6.Offices, inputRecord_6.BuildingCategory, inputRecord_6.Builders)), model.Queries.Construction, "Bygg"), listItem$0027((inputRecord_7 = model.Queries, new ProjectSearch(inputRecord_7.Active, inputRecord_7.Inactive, inputRecord_7.Planning, inputRecord_7.InProcess, inputRecord_7.DelayedStart, inputRecord_7.Overtime, inputRecord_7.Completed, inputRecord_7.Construction, !model.Queries.Landscaping, inputRecord_7.Companies, inputRecord_7.Offices, inputRecord_7.BuildingCategory, inputRecord_7.Builders)), model.Queries.Landscaping, "Anlegg")])), delay(() => {
                        let matchValue;
                        return append_1(singleton_1(drawCategory(new FilterCategory(3), (matchValue = model.Companies, (matchValue != null) ? map((y_3) => {
                            let inputRecord_8;
                            return listItem$0027((inputRecord_8 = model.Queries, new ProjectSearch(inputRecord_8.Active, inputRecord_8.Inactive, inputRecord_8.Planning, inputRecord_8.InProcess, inputRecord_8.DelayedStart, inputRecord_8.Overtime, inputRecord_8.Completed, inputRecord_8.Construction, inputRecord_8.Landscaping, model.Queries.Companies.some((y_4) => (y_3.Id === y_4)) ? model.Queries.Companies.filter((z) => (z !== y_3.Id)) : append_2(new Int32Array([y_3.Id]), model.Queries.Companies, Int32Array), inputRecord_8.Offices, inputRecord_8.BuildingCategory, inputRecord_8.Builders)), model.Queries.Companies.some((y_5) => (y_3.Id === y_5)), y_3.Name);
                        }, sortBy((y_1) => y_1.Name, matchValue, {
                            Compare: (x_2, y_2) => comparePrimitives(x_2, y_2),
                        })) : [loading]))), delay(() => {
                            let matchValue_1, x_6;
                            return append_1(singleton_1(drawCategory(new FilterCategory(4), (matchValue_1 = model.Offices, (matchValue_1 != null) ? map((y_9) => {
                                let inputRecord_9;
                                return listItem$0027((inputRecord_9 = model.Queries, new ProjectSearch(inputRecord_9.Active, inputRecord_9.Inactive, inputRecord_9.Planning, inputRecord_9.InProcess, inputRecord_9.DelayedStart, inputRecord_9.Overtime, inputRecord_9.Completed, inputRecord_9.Construction, inputRecord_9.Landscaping, inputRecord_9.Companies, model.Queries.Offices.some((y_10) => (y_9.Id === y_10)) ? model.Queries.Offices.filter((z_1) => (z_1 !== y_9.Id)) : append_2(new Int32Array([y_9.Id]), model.Queries.Offices, Int32Array), inputRecord_9.BuildingCategory, inputRecord_9.Builders)), model.Queries.Offices.some((y_11) => (y_9.Id === y_11)), y_9.Name);
                            }, sortBy((y_7) => y_7.Name, (x_6 = matchValue_1, (model.Queries.Companies.length <= 0) ? x_6 : x_6.filter((x_7) => model.Queries.Companies.some((y_6) => (x_7.CompanyId === y_6)))), {
                                Compare: (x_9, y_8) => comparePrimitives(x_9, y_8),
                            })) : [loading]))), delay(() => {
                                let matchValue_2;
                                return append_1(singleton_1(drawCategory(new FilterCategory(5), (matchValue_2 = model.ProjectOutcomeTypes, (matchValue_2 != null) ? map((y_14) => {
                                    let inputRecord_10;
                                    return listItem$0027((inputRecord_10 = model.Queries, new ProjectSearch(inputRecord_10.Active, inputRecord_10.Inactive, inputRecord_10.Planning, inputRecord_10.InProcess, inputRecord_10.DelayedStart, inputRecord_10.Overtime, inputRecord_10.Completed, inputRecord_10.Construction, inputRecord_10.Landscaping, inputRecord_10.Companies, inputRecord_10.Offices, model.Queries.BuildingCategory.some((y_15) => (y_14.Id === y_15)) ? model.Queries.BuildingCategory.filter((z_2) => (z_2 !== y_14.Id)) : append_2(new Int32Array([y_14.Id]), model.Queries.BuildingCategory, Int32Array), inputRecord_10.Builders)), model.Queries.BuildingCategory.some((y_16) => (y_14.Id === y_16)), y_14.Name);
                                }, sortBy((y_12) => y_12.Name, matchValue_2, {
                                    Compare: (x_13, y_13) => comparePrimitives(x_13, y_13),
                                })) : [loading]))), delay(() => {
                                    let matchValue_3;
                                    return singleton_1(drawCategory(new FilterCategory(6), (matchValue_3 = model.Builders, (matchValue_3 != null) ? map((y_19) => {
                                        let inputRecord_11;
                                        return listItem$0027((inputRecord_11 = model.Queries, new ProjectSearch(inputRecord_11.Active, inputRecord_11.Inactive, inputRecord_11.Planning, inputRecord_11.InProcess, inputRecord_11.DelayedStart, inputRecord_11.Overtime, inputRecord_11.Completed, inputRecord_11.Construction, inputRecord_11.Landscaping, inputRecord_11.Companies, inputRecord_11.Offices, inputRecord_11.BuildingCategory, model.Queries.Builders.some((y_20) => (y_19.id === y_20)) ? model.Queries.Builders.filter((z_3) => (z_3 !== y_19.id)) : append_2(new Int32Array([y_19.id]), model.Queries.Builders, Int32Array))), model.Queries.Builders.some((y_21) => (y_19.id === y_21)), y_19.name);
                                    }, sortBy((y_17) => y_17.name, matchValue_3, {
                                        Compare: (x_17, y_18) => comparePrimitives(x_17, y_18),
                                    })) : [loading])));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }))))))))))]]), createElement("main", {
            className: drawerOpen ? toText(printf("%s %s"))(s.content)(s.contentShift) : s.content,
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_1(createElement("div", {
                className: s.drawerHeader,
            })), delay(() => append_1(singleton_1(snackbarError(model.ErrorMsg, () => {
                dispatch(new Message(9));
            })), delay(() => {
                let matchValue_4, projId;
                return append_1((matchValue_4 = model.Print, (matchValue_4 != null) ? ((projId = (matchValue_4 | 0), singleton_1(createElement(ProjectPrintModal, {
                    projId: projId,
                    onClose: () => {
                        dispatch(new Message(8, void 0));
                    },
                })))) : ((empty_1()))), delay(() => {
                    let children_8;
                    return singleton_1((children_8 = toList(delay(() => append_1(model.ShowForm ? singleton_1(projectForm({
                        onClose: (didCreate) => {
                            if (didCreate) {
                                fetchProjects(model.Queries, dispatch);
                            }
                            dispatch(new Message(6));
                        },
                    })) : empty_1(), delay(() => singleton_1(createElement("div", {
                        style: {
                            padding: 2 + "vw",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        },
                        children: Interop_reactApi.Children.toArray([map((x_21) => projectTile(x_21, dispatch), sortByDescending((e) => e.Id, model.Projects, {
                            Compare: (x_20, y_22) => comparePrimitives(x_20, y_22),
                        }))]),
                    })))))), createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                    })));
                }));
            })))))))),
        })]),
    });
}

export function ProjectList() {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchProjects(model_1.Queries, dispatch);
        fetchCompanies(dispatch);
        fetchOffices(dispatch);
        fetchProjectOutcomes(dispatch);
        fetchBuilders(dispatch);
    });
    return view(model_1, dispatch);
}

