import { Record, Union } from "../.fable/fable-library.3.2.4/Types.js";
import { Print_CVData, Helpers_ResultMessage$reflection, UserCourse$reflection, UserEducation$reflection, UserEmployment$reflection, Print_CVData$reflection, UserInfo$reflection, ExternalProjectReference$reflection, UserReferenceProject$reflection } from "../Shared/ApiDataTypes.js";
import { obj_type, record_type, array_type, option_type, string_type, bool_type, union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { take50FromString, fromUtcString } from "../Utils.js";
import { toString, year } from "../.fable/fable-library.3.2.4/Date.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { singleton, ofArray, empty } from "../.fable/fable-library.3.2.4/List.js";
import { numberHash, arrayHash, equalArrays, createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { Array_distinct } from "../.fable/fable-library.3.2.4/Seq2.js";
import { tryFind, map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Value, Browser_Types_Event__Event_get_Checked } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Button from "@material-ui/core/Button";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { some } from "../.fable/fable-library.3.2.4/Option.js";
import { loadingIndicatorCenteredSmall, snackbarError, loadingIndicatorMedium } from "../ViewHelpers.js";

class ProjectRef extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Internal", "External"];
    }
}

function ProjectRef$reflection() {
    return union_type("CVPrint.ProjectRef", [], ProjectRef, () => [[["Item", UserReferenceProject$reflection()]], [["Item", ExternalProjectReference$reflection()]]]);
}

function ProjectRef__get_Name(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Project;
    }
    else {
        return this$.fields[0].ProjectName;
    }
}

function ProjectRef__get_Period(this$) {
    if (this$.tag === 1) {
        const x_1 = this$.fields[0];
        if ((x_1.PeriodStart === "") ? (x_1.PeriodEnd === "") : false) {
            return "Ikke oppgitt.";
        }
        else if (x_1.PeriodEnd !== "") {
            let arg10_1;
            let copyOfStruct = fromUtcString(x_1.PeriodEnd);
            arg10_1 = year(copyOfStruct);
            return toText(printf("%i"))(arg10_1);
        }
        else {
            let arg10_2;
            let copyOfStruct_1 = fromUtcString(x_1.PeriodStart);
            arg10_2 = year(copyOfStruct_1);
            return toText(printf("%i"))(arg10_2);
        }
    }
    else {
        const x = this$.fields[0];
        const arg20 = toString(x.PeriodEnd, "MM/yyyy");
        const arg10 = toString(x.PeriodStart, "MM/yyyy");
        return toText(printf("%s - %s"))(arg10)(arg20);
    }
}

function ProjectRef__get_Role(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].ExternalRole;
    }
    else {
        return this$.fields[0].Role;
    }
}

function ProjectRef__get_Id(this$) {
    if (this$.tag === 1) {
        return toText(printf("external-%i"))(this$.fields[0].Id);
    }
    else {
        return toText(printf("internal-%i"))(this$.fields[0].ProjectId);
    }
}

function ProjectRef__get_Code(this$) {
    if (this$.tag === 1) {
        return "external";
    }
    else {
        return "internal";
    }
}

function ProjectRef__get_Ref(this$) {
    if (this$.tag === 1) {
        return this$.fields[0].Id | 0;
    }
    else {
        return this$.fields[0].ProjectId | 0;
    }
}

class GenerationStep extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Waiting", "Failed", "Success"];
    }
}

function GenerationStep$reflection() {
    return union_type("CVPrint.GenerationStep", [], GenerationStep, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]]]);
}

class Model extends Record {
    constructor(User, CVForm, ProjectRefsLoaded, ExternalRefsLoaded, Step, ProjectReferences, ExternalProjects, Employments, Educations, Courses, ErrorMsg, FormError) {
        super();
        this.User = User;
        this.CVForm = CVForm;
        this.ProjectRefsLoaded = ProjectRefsLoaded;
        this.ExternalRefsLoaded = ExternalRefsLoaded;
        this.Step = Step;
        this.ProjectReferences = ProjectReferences;
        this.ExternalProjects = ExternalProjects;
        this.Employments = Employments;
        this.Educations = Educations;
        this.Courses = Courses;
        this.ErrorMsg = ErrorMsg;
        this.FormError = FormError;
    }
}

function Model$reflection() {
    return record_type("CVPrint.Model", [], Model, () => [["User", UserInfo$reflection()], ["CVForm", option_type(Print_CVData$reflection())], ["ProjectRefsLoaded", bool_type], ["ExternalRefsLoaded", bool_type], ["Step", GenerationStep$reflection()], ["ProjectReferences", array_type(UserReferenceProject$reflection())], ["ExternalProjects", array_type(ExternalProjectReference$reflection())], ["Employments", array_type(UserEmployment$reflection())], ["Educations", array_type(UserEducation$reflection())], ["Courses", array_type(UserCourse$reflection())], ["ErrorMsg", option_type(string_type)], ["FormError", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CVDataResponse", "ProjectReferenceResponse", "ExternalReferenceResponse", "EmploymentResponse", "EducationResponse", "CoursesResponse", "SetStep", "SetError", "GenerateResponse"];
    }
}

function Message$reflection() {
    return union_type("CVPrint.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Print_CVData$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Print_CVData$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserReferenceProject$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserReferenceProject$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(ExternalProjectReference$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(ExternalProjectReference$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserEmployment$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserEmployment$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserEducation$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserEducation$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserCourse$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserCourse$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", GenerationStep$reflection()]], [["Item", option_type(string_type)]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]]]);
}

function init(user) {
    return new Model(user, void 0, true, true, new GenerationStep(0, false), [], [], [], [], [], void 0, void 0);
}

function update$0027(model, x) {
    const matchValue = model.CVForm;
    if (matchValue == null) {
        return model;
    }
    else {
        return new Model(model.User, x(matchValue), model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, model.ErrorMsg, model.FormError);
    }
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke laste prosjektreferenser.", model.FormError);
            }
            else {
                return new Model(model.User, model.CVForm, true, model.ExternalRefsLoaded, model.Step, res_1.fields[0], model.ExternalProjects, model.Employments, model.Educations, model.Courses, model.ErrorMsg, model.FormError);
            }
        }
        case 2: {
            const res_2 = msg.fields[0];
            if (res_2.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke laste prosjektreferanser.", model.FormError);
            }
            else {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, true, model.Step, model.ProjectReferences, res_2.fields[0], model.Employments, model.Educations, model.Courses, model.ErrorMsg, model.FormError);
            }
        }
        case 3: {
            const res_3 = msg.fields[0];
            if (res_3.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke hente ansettelser.", model.FormError);
            }
            else {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, res_3.fields[0], model.Educations, model.Courses, model.ErrorMsg, model.FormError);
            }
        }
        case 4: {
            const res_4 = msg.fields[0];
            if (res_4.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke hente utdannelse.", model.FormError);
            }
            else {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, res_4.fields[0], model.Courses, model.ErrorMsg, model.FormError);
            }
        }
        case 5: {
            const res_5 = msg.fields[0];
            if (res_5.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke hente kurs og etterutdanning.", model.FormError);
            }
            else {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, res_5.fields[0], model.ErrorMsg, model.FormError);
            }
        }
        case 6: {
            return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, msg.fields[0], model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, model.ErrorMsg, model.FormError);
        }
        case 7: {
            return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, msg.fields[0], model.FormError);
        }
        case 8: {
            const res_6 = msg.fields[0];
            if (res_6.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, new GenerationStep(1, "Noe gikk galt. Kunne ikke generere PDF"), model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke generere PDF", model.FormError);
            }
            else {
                const r = res_6.fields[0];
                if (r.Result === "success") {
                    return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, new GenerationStep(2, r.Message), model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, void 0, model.FormError);
                }
                else {
                    return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, new GenerationStep(1, r.Message), model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, r.Message, model.FormError);
                }
            }
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.User, model.CVForm, model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, "Noe gikk galt. Kunne ikke laste informasjon. ", model.FormError);
            }
            else {
                return new Model(model.User, res.fields[0], model.ProjectRefsLoaded, model.ExternalRefsLoaded, model.Step, model.ProjectReferences, model.ExternalProjects, model.Employments, model.Educations, model.Courses, model.ErrorMsg, model.FormError);
            }
        }
    }
}

function fetchCVData(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/cvprint/userdata/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: Print_CVData$reflection,
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: Print_CVData$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchEducation(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/education/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserEducation$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserEducation$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(4, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchCourses(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/course/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserCourse$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserCourse$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(5, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchEmployment(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/employment/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserEmployment$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserEmployment$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(3, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchExternalRefs(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/mypage/externalreferences/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(ExternalProjectReference$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(ExternalProjectReference$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(2, _arg1));
            return Promise.resolve();
        }));
    }));
}

function fetchInternalRefs(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/mypage/referenceprojects/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserReferenceProject$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserReferenceProject$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function splitView(included, excluded) {
    let children_2, children, children_6, children_4;
    const subtable = (lst) => {
        const props_1 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([createElement("tbody", {
            children: Interop_reactApi.Children.toArray([lst]),
        })])]]);
        return createElement("table", createObj(Helpers_combineClasses("table", props_1)));
    };
    const props_11 = ofArray([["className", "is-fullwidth"], ["style", {
        marginBottom: 40,
    }], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton((children = ofArray([createElement("td", {
        style: {
            width: 50 + "%",
            textAlign: "center",
        },
        children: "Inkluder",
    }), createElement("td", {
        style: {
            width: 50 + "%",
            textAlign: "center",
        },
        children: "Ekskluder",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_6 = singleton((children_4 = ofArray([createElement("td", {
        style: {
            borderRight: (((1 + "px ") + "solid") + " ") + "#aaa",
        },
        children: Interop_reactApi.Children.toArray([subtable(included)]),
    }), createElement("td", {
        style: {
            borderLeft: (((1 + "px ") + "solid") + " ") + "#aaa",
            textAlign: "right",
        },
        children: Interop_reactApi.Children.toArray([subtable(excluded)]),
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    }))])]]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_11)));
}

function View(viewInputProps) {
    let children_2, children_4, children_6, children_8, children_10, children_12, children_14, children_16;
    const dispatch = viewInputProps.dispatch;
    const model = viewInputProps.model;
    const matchValue = model.CVForm;
    if (matchValue != null) {
        const patternInput = useFeliz_React__React_useState_Static_1505(matchValue);
        const state = patternInput[0];
        const setState = patternInput[1];
        const includeMostRelevant = (e_1) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, Array_distinct(append(state.MostRelevant, [[ProjectRef__get_Code(e_1), ProjectRef__get_Ref(e_1)]]), {
                Equals: (x, y_1) => equalArrays(x, y_1),
                GetHashCode: (x) => arrayHash(x),
            }), state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        };
        const descriptiveText = (t_1) => createElement("p", {
            style: {
                fontSize: 0.8 + "rem",
            },
            children: t_1,
        });
        const descriptiveTextOthers = descriptiveText("Prosjekter listet under \"mest relevante\" vil ikke inkluderes her.");
        const labeledTitle = (title, active, onchange) => MuiHelpers_createElement(FormControlLabel, [["label", title], ["control", MuiHelpers_createElement(Checkbox, [["checked", active], ["onChange", (e_6) => {
            onchange(Browser_Types_Event__Event_get_Checked(e_6));
        }], ["color", "primary"]])]]);
        const labeledTextField = (title_1, value_13, onchange_1) => {
            const children = ofArray([MuiHelpers_createElement(Typography, [["children", title_1]]), MuiHelpers_createElement(TextField, [["onChange", (e_7) => {
                onchange_1(Browser_Types_Event__Event_get_Value(e_7));
            }], ["placeholder", title_1], ["defaultValue", value_13], ["multiline", true], ["rowsMax", 8], ["variant", "outlined"], ["fullWidth", true], ["style", {
                marginBottom: 40,
            }]])]);
            return createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(children)),
            });
        };
        const renderMostRelevantInner = (onclick, e_8) => createElement("tr", {
            style: {
                cursor: "pointer",
            },
            onClick: (_arg1_1) => {
                onclick(e_8);
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: ProjectRef__get_Name(e_8),
            }), createElement("td", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: ProjectRef__get_Role(e_8),
            }), createElement("td", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: (e_8.tag === 1) ? "Ekstern" : "Intern",
            })]),
        });
        const genericRowRender = (onclick_1, one, two) => createElement("tr", {
            style: {
                cursor: "pointer",
            },
            onClick: (_arg2) => {
                onclick_1();
            },
            children: Interop_reactApi.Children.toArray([createElement("td", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: one,
            }), createElement("td", {
                style: {
                    fontSize: 0.8 + "rem",
                },
                children: two,
            })]),
        });
        const renderSpecifiedRow = (onclick_2, e_9) => genericRowRender(() => {
            onclick_2(e_9);
        }, ProjectRef__get_Name(e_9), ProjectRef__get_Role(e_9));
        const renderRemainingInternal = map((e_12) => renderMostRelevantInner(includeMostRelevant, new ProjectRef(0, e_12)), model.ProjectReferences.filter((e_11) => (!state.MostRelevant.some((tupledArg_1) => (toText(printf("internal-%i"))(e_11.ProjectId) === toText(printf("%s-%i"))(tupledArg_1[0])(tupledArg_1[1]))))));
        const renderRemainingExternal = map((e_14) => renderMostRelevantInner(includeMostRelevant, new ProjectRef(1, e_14)), model.ExternalProjects.filter((e_13) => (!state.MostRelevant.some((tupledArg_2) => (toText(printf("external-%i"))(e_13.Id) === toText(printf("%s-%i"))(tupledArg_2[0])(tupledArg_2[1]))))));
        const refNotInMostRelevant = (ref_1) => {
            if (state.IncludeMostRelevant) {
                return !state.MostRelevant.some((tupledArg_3) => (toText(printf("%s-%i"))(tupledArg_3[0])(tupledArg_3[1]) === ProjectRef__get_Id(ref_1)));
            }
            else {
                return true;
            }
        };
        const renderIncludedProjectRefs = (refArray, onclick_3) => map((e_16) => renderSpecifiedRow(onclick_3, e_16), refArray.filter(refNotInMostRelevant).filter((e_15) => {
            let x_6;
            return ((e_15.tag === 1) ? state.ExternalReferences : state.ProjectReferences).some((x_6 = (ProjectRef__get_Ref(e_15) | 0), (y_21) => (x_6 === y_21)));
        }));
        const renderExcludedProjectRefs = (refArray_1, onclick_4) => map((e_18) => renderSpecifiedRow(onclick_4, e_18), refArray_1.filter(refNotInMostRelevant).filter((e_17) => {
            let x_7;
            return !((e_17.tag === 1) ? state.ExternalReferences : state.ProjectReferences).some((x_7 = (ProjectRef__get_Ref(e_17) | 0), (y_22) => (x_7 === y_22)));
        }));
        const renderIncludedEmployment = map((e_20) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment.filter((y_11) => (e_20.Id !== y_11)), state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_20.Company, take50FromString(e_20.Desc)), model.Employments.filter((e_19) => state.Employment.some((y_23) => (e_19.Id === y_23))));
        const renderExcludedEmployment = map((e_22) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, Array_distinct(append(state.Employment, new Int32Array([e_22.Id]), Int32Array), {
                Equals: (x_3, y_9) => (x_3 === y_9),
                GetHashCode: (x_3) => numberHash(x_3),
            }), state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_22.Company, take50FromString(e_22.Desc)), model.Employments.filter((e_21) => (!state.Employment.some((y_24) => (e_21.Id === y_24)))));
        const renderIncludedEducation = map((e_24) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education.filter((y_15) => (e_24.Id !== y_15)), state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_24.Education, e_24.Level.Name), model.Educations.filter((e_23) => state.Education.some((y_25) => (e_23.Id === y_25))));
        const renderExcludedEducation = map((e_26) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, Array_distinct(append(state.Education, new Int32Array([e_26.Id]), Int32Array), {
                Equals: (x_4, y_13) => (x_4 === y_13),
                GetHashCode: (x_4) => numberHash(x_4),
            }), state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_26.Education, e_26.Level.Name), model.Educations.filter((e_25) => (!state.Education.some((y_26) => (e_25.Id === y_26)))));
        const renderIncludedCourses = map((e_28) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses.filter((y_19) => (e_28.Id !== y_19)), state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_28.Name, toText(printf("%i/%i - %i/%i"))(e_28.StartMonth)(e_28.StartYear)(e_28.EndMonth)(e_28.EndYear)), model.Courses.filter((e_27) => state.Courses.some((y_27) => (e_27.Id === y_27))));
        const renderExcludedCourses = map((e_30) => genericRowRender(() => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, Array_distinct(append(state.Courses, new Int32Array([e_30.Id]), Int32Array), {
                Equals: (x_5, y_17) => (x_5 === y_17),
                GetHashCode: (x_5) => numberHash(x_5),
            }), state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        }, e_30.Name, toText(printf("%i/%i - %i/%i"))(e_30.StartMonth)(e_30.StartYear)(e_30.EndMonth)(e_30.EndYear)), model.Courses.filter((e_29) => (!state.Courses.some((y_28) => (e_29.Id === y_28)))));
        const children_26 = ofArray([(children_2 = singleton(labeledTextField("Beskrivelse", state.Desc, (s_3) => {
            setState(new Print_CVData(state.UserId, s_3, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_2)),
        })), (children_4 = singleton(labeledTextField("Nøkkelkompetanse", state.KeyCompetencies, (s_4) => {
            setState(new Print_CVData(state.UserId, state.Desc, s_4, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        })), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = toList(delay(() => append_1(singleton_1(labeledTitle("Mest relevante prosjektreferanser", state.IncludeMostRelevant, (b_1) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, b_1, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        })), delay(() => (state.IncludeMostRelevant ? append_1(singleton_1(descriptiveText("Prosjekter listet her vil ikke inkluderes i listene under.")), delay(() => singleton_1(splitView(map((ref) => {
            const i_9 = ref[1] | 0;
            let p_2;
            if (ref[0] === "internal") {
                const matchValue_1 = tryFind((z) => (z.ProjectId === i_9), model.ProjectReferences);
                p_2 = ((matchValue_1 == null) ? (void 0) : (new ProjectRef(0, matchValue_1)));
            }
            else {
                const matchValue_2 = tryFind((z_1) => (z_1.Id === i_9), model.ExternalProjects);
                p_2 = ((matchValue_2 == null) ? (void 0) : (new ProjectRef(1, matchValue_2)));
            }
            if (p_2 != null) {
                return renderMostRelevantInner((e) => {
                    setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant.filter((tupledArg) => (toText(printf("%s-%i"))(tupledArg[0])(tupledArg[1]) !== ProjectRef__get_Id(e))), state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
                }, p_2);
            }
            else {
                return null;
            }
        }, state.MostRelevant), append(renderRemainingInternal, renderRemainingExternal))))) : empty_1()))))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        })), (children_8 = toList(delay(() => append_1(singleton_1(labeledTitle("Prosjektreferanser", state.IncludeInternal, (b_2) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, b_2, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        })), delay(() => {
            if (state.IncludeInternal) {
                const internalRefs = map((arg0_6) => (new ProjectRef(0, arg0_6)), model.ProjectReferences);
                return append_1(singleton_1(descriptiveTextOthers), delay(() => singleton_1(splitView(renderIncludedProjectRefs(internalRefs, (e_3) => {
                    setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences.filter((i_1) => (ProjectRef__get_Ref(e_3) !== i_1)), state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
                }), renderExcludedProjectRefs(internalRefs, (e_2) => {
                    setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, Array_distinct(append(state.ProjectReferences, new Int32Array([ProjectRef__get_Ref(e_2)]), Int32Array), {
                        Equals: (x_1, y_3) => (x_1 === y_3),
                        GetHashCode: (x_1) => numberHash(x_1),
                    }), state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
                })))));
            }
            else {
                return empty_1();
            }
        })))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_8)),
        })), (children_10 = toList(delay(() => append_1(singleton_1(labeledTitle("Eksterne prosjektreferanser", state.IncludeExternal, (b_3) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, b_3, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
        })), delay(() => {
            if (state.IncludeExternal) {
                const externalRefs = map((arg0_7) => (new ProjectRef(1, arg0_7)), model.ExternalProjects);
                return append_1(singleton_1(descriptiveTextOthers), delay(() => singleton_1(splitView(renderIncludedProjectRefs(externalRefs, (e_5) => {
                    setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences.filter((i_2) => (ProjectRef__get_Ref(e_5) !== i_2)), state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
                }), renderExcludedProjectRefs(externalRefs, (e_4) => {
                    setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, Array_distinct(append(state.ExternalReferences, new Int32Array([ProjectRef__get_Ref(e_4)]), Int32Array), {
                        Equals: (x_2, y_6) => (x_2 === y_6),
                        GetHashCode: (x_2) => numberHash(x_2),
                    }), state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, state.IncludeCourses));
                })))));
            }
            else {
                return empty_1();
            }
        })))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
        })), (children_12 = toList(delay(() => append_1(singleton_1(labeledTitle("Ansettelser", state.IncludeEmployment, (b_4) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, b_4, state.IncludeEducation, state.IncludeCourses));
        })), delay(() => (state.IncludeEmployment ? append_1(singleton_1(descriptiveTextOthers), delay(() => singleton_1(splitView(renderIncludedEmployment, renderExcludedEmployment)))) : empty_1()))))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
        })), (children_14 = toList(delay(() => append_1(singleton_1(labeledTitle("Utdannelse", state.IncludeEducation, (b_5) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, b_5, state.IncludeCourses));
        })), delay(() => (state.IncludeEducation ? append_1(singleton_1(descriptiveTextOthers), delay(() => singleton_1(splitView(renderIncludedEducation, renderExcludedEducation)))) : empty_1()))))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
        })), (children_16 = toList(delay(() => append_1(singleton_1(labeledTitle("Kurs or etterutdanning", state.IncludeCourses, (b_6) => {
            setState(new Print_CVData(state.UserId, state.Desc, state.KeyCompetencies, state.MostRelevant, state.ProjectReferences, state.ExternalReferences, state.Employment, state.Education, state.Courses, state.IncludeMostRelevant, state.IncludeInternal, state.IncludeExternal, state.IncludeEmployment, state.IncludeEducation, b_6));
        })), delay(() => (state.IncludeCourses ? append_1(singleton_1(descriptiveTextOthers), delay(() => singleton_1(splitView(renderIncludedCourses, renderExcludedCourses)))) : empty_1()))))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_16)),
        })), createElement("div", {
            style: {
                display: "grid",
                justifyContent: "center",
                marginBottom: 20,
                minHeight: 60 + "px",
            },
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let children_20, children_22, children_18;
                const matchValue_3 = model.Step;
                switch (matchValue_3.tag) {
                    case 1: {
                        return singleton_1((children_20 = singleton(MuiHelpers_createElement(Button, [["variant", "outlined"], ["style", {
                            height: 61 + "%",
                        }], ["onClick", (_arg11) => {
                            dispatch(new Message(6, new GenerationStep(0, false)));
                        }], ["children", "Prøv på nytt"]])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_20)),
                        })));
                    }
                    case 2: {
                        return append_1(singleton_1((children_22 = singleton(MuiHelpers_createElement(Typography, [["children", "PDF generert!"]])), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_22)),
                        }))), delay(() => {
                            let children_24;
                            return singleton_1((children_24 = singleton(MuiHelpers_createElement(Button, [["variant", "outlined"], ["href", toText(printf("/printcache/download/%s"))(matchValue_3.fields[0])], ["target", "_blank"], ["children", "Last ned"]])), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_24)),
                            })));
                        }));
                    }
                    default: {
                        return singleton_1((children_18 = toList(delay(() => ((!matchValue_3.fields[0]) ? singleton_1(MuiHelpers_createElement(Button, [["style", {
                            height: 61 + "%",
                        }], ["variant", "outlined"], ["onClick", (_arg10) => {
                            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                                    ResolveType: Print_CVData$reflection,
                                })(state);
                                const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                                    ResolveType: Helpers_ResultMessage$reflection,
                                });
                                return Fetch_tryPost_5760677E("/api/cvprint/generate", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                                    ResolveType: Helpers_ResultMessage$reflection,
                                }, {
                                    ResolveType: () => obj_type,
                                });
                            })).then(((_arg1) => {
                                dispatch(new Message(6, new GenerationStep(0, true)));
                                dispatch(new Message(8, _arg1));
                                return Promise.resolve();
                            })))));
                            pr.then();
                        }], ["children", "Generer CV"]])) : singleton_1(loadingIndicatorMedium())))), createElement("div", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                        })));
                    }
                }
            })))),
        }), snackbarError(model.ErrorMsg, () => {
            dispatch(new Message(7, void 0));
        })]);
        return createElement("main", {
            children: Interop_reactApi.Children.toArray(Array.from(children_26)),
        });
    }
    else {
        return loadingIndicatorCenteredSmall();
    }
}

export function CvPrint(userInfo) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(userInfo));
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = fetchCVData(userInfo.id, dispatch);
        pr.then();
        const pr_1 = fetchEducation(userInfo.id, dispatch);
        pr_1.then();
        const pr_2 = fetchCourses(userInfo.id, dispatch);
        pr_2.then();
        const pr_3 = fetchEmployment(userInfo.id, dispatch);
        pr_3.then();
        const pr_4 = fetchExternalRefs(userInfo.id, dispatch);
        pr_4.then();
        const pr_5 = fetchInternalRefs(userInfo.id, dispatch);
        pr_5.then();
    });
    return createElement(View, {
        model: patternInput[0],
        dispatch: dispatch,
    });
}

