import { Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, unit_type, bool_type, class_type, string_type, option_type, array_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Helpers_Date__ToLocalDate, Helpers_TimePeriod$reflection, ProjectVacationRequest$reflection, ProjectVacationRequest, Helpers_TimePeriod, Helpers_Date_FromDateTime_7F9DDECF, UserProjectVacation$reflection, UserAllocation$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryPost_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { now } from "../.fable/fable-library.3.2.4/Date.js";
import { addDays } from "date-fns";
import { FSharpMap__Add, FSharpMap__Remove, FSharpMap__ContainsKey, empty } from "../.fable/fable-library.3.2.4/Map.js";
import { Array_distinctBy } from "../.fable/fable-library.3.2.4/Seq2.js";
import { sortBy as sortBy_1, sortByDescending, map, tryFind, append } from "../.fable/fable-library.3.2.4/Array.js";
import { comparePrimitives, equals, createObj, uncurry, numberHash } from "../.fable/fable-library.3.2.4/Util.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { singleton as singleton_1, ofArray, toArray, empty as empty_1 } from "../.fable/fable-library.3.2.4/List.js";
import { printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { snackbarError, PageElements_inlineCheckbox, drawAllocation, loadingIndicatorSmall } from "../ViewHelpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { empty as empty_2, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Paper from "@material-ui/core/Paper";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import date$002Dfns from "@date-io/date-fns";
import TableHead from "@material-ui/core/TableHead";

class Model extends Record {
    constructor(CompanyId, Users, ErrorMsg, PeriodStart, PeriodEnd, FilterOverAllocated, UserAllocations, ExpandedUserAllocs) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.Users = Users;
        this.ErrorMsg = ErrorMsg;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.FilterOverAllocated = FilterOverAllocated;
        this.UserAllocations = UserAllocations;
        this.ExpandedUserAllocs = ExpandedUserAllocs;
    }
}

function Model$reflection() {
    return record_type("CompanyAllocation.Model", [], Model, () => [["CompanyId", int32_type], ["Users", option_type(array_type(UserAllocation$reflection()))], ["ErrorMsg", option_type(string_type)], ["PeriodStart", class_type("System.DateTime")], ["PeriodEnd", class_type("System.DateTime")], ["FilterOverAllocated", bool_type], ["UserAllocations", array_type(UserProjectVacation$reflection())], ["ExpandedUserAllocs", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, unit_type])]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UsersResponse", "UserAllocationResponse", "UpdatePeriodStart", "UpdatePeriodEnd", "ToggleFilterOverAllocated", "DismissError", "ToggleUserExpanded"];
    }
}

function Message$reflection() {
    return union_type("CompanyAllocation.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserAllocation$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserAllocation$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserProjectVacation$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserProjectVacation$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [], [["Item", int32_type]]]);
}

function init(compId) {
    return new Model(compId, void 0, void 0, now(), addDays(now(), 7), false, [], empty());
}

function update(model, msg) {
    switch (msg.tag) {
        case 2: {
            const newDate = msg.fields[0];
            const tp = new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(newDate), Helpers_Date_FromDateTime_7F9DDECF(model.PeriodEnd));
            return new Model(model.CompanyId, void 0, model.ErrorMsg, newDate, model.PeriodEnd, model.FilterOverAllocated, [], model.ExpandedUserAllocs);
        }
        case 3: {
            const newDate_1 = msg.fields[0];
            const tp_1 = new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(model.PeriodStart), Helpers_Date_FromDateTime_7F9DDECF(newDate_1));
            return new Model(model.CompanyId, void 0, model.ErrorMsg, model.PeriodStart, newDate_1, model.FilterOverAllocated, [], model.ExpandedUserAllocs);
        }
        case 4: {
            return new Model(model.CompanyId, model.Users, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, !model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAllocs);
        }
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.CompanyId, model.Users, "Noe gikk galt. Kunne ikke laste prosjekter og ferie for ansatt", model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAllocs);
            }
            else {
                return new Model(model.CompanyId, model.Users, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, Array_distinctBy((e) => e.userid, append([res_1.fields[0]], model.UserAllocations), {
                    Equals: (x, y) => (x === y),
                    GetHashCode: (x) => numberHash(x),
                }), model.ExpandedUserAllocs);
            }
        }
        case 5: {
            return new Model(model.CompanyId, model.Users, void 0, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAllocs);
        }
        case 6: {
            const uid = msg.fields[0] | 0;
            return new Model(model.CompanyId, model.Users, model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, FSharpMap__ContainsKey(model.ExpandedUserAllocs, uid) ? FSharpMap__Remove(model.ExpandedUserAllocs, uid) : FSharpMap__Add(model.ExpandedUserAllocs, uid, void 0));
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.CompanyId, model.Users, "Noe gikk galt. Kunne ikke laste ansatte.", model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAllocs);
            }
            else {
                return new Model(model.CompanyId, res.fields[0], model.ErrorMsg, model.PeriodStart, model.PeriodEnd, model.FilterOverAllocated, model.UserAllocations, model.ExpandedUserAllocs);
            }
        }
    }
}

function getProjectsAndVacationsForUser(timestart, timeend, userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const vacationsRequest = new ProjectVacationRequest(userId, timestart, timeend);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: ProjectVacationRequest$reflection,
            })(vacationsRequest);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: UserProjectVacation$reflection,
            });
            return Fetch_tryPost_5760677E("/api/user/allocation/period", some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: UserProjectVacation$reflection,
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(1, _arg1));
            return Promise.resolve();
        }));
    }));
}

function getCompanyUserAllocations(timestart, timeend, compId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const timePeriod = new Helpers_TimePeriod(Helpers_Date_FromDateTime_7F9DDECF(timestart), Helpers_Date_FromDateTime_7F9DDECF(timeend));
        const requestPath = toText(printf("/api/users/allocated/company/%i"))(compId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                ResolveType: Helpers_TimePeriod$reflection,
            })(timePeriod);
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserAllocation$reflection()),
            });
            return Fetch_tryPost_5760677E(requestPath, some(body), empty_1(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserAllocation$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function renderRow(model, dispatch, u) {
    let ref;
    const expanded = FSharpMap__ContainsKey(model.ExpandedUserAllocs, u.user.Id);
    let drawDetailsPanel;
    const notBorderedCell = (s) => MuiHelpers_createElement(TableCell, [["style", {
        borderStyle: "none",
    }], ["children", s]]);
    const matchValue = tryFind((e) => (e.userid === u.user.Id), model.UserAllocations);
    if (matchValue != null) {
        const s_1 = matchValue;
        drawDetailsPanel = createElement("div", {
            children: Interop_reactApi.Children.toArray([(ref = s_1, MuiHelpers_createElement(Table, [["size", "small"], ["style", {
                width: "initial",
            }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([map((x) => {
                let arg10, arg10_1;
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell("På ferie"), notBorderedCell((arg10 = Helpers_Date__ToLocalDate(x.PeriodStart), toText(printf("fra %s"))(arg10))), notBorderedCell((arg10_1 = Helpers_Date__ToLocalDate(x.PeriodEnd), toText(printf("til %s"))(arg10_1)))])]]);
            }, ref.vacation), map((x_1) => {
                let arg10_3, arg10_4;
                return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([notBorderedCell(toText(printf("På prosjekt %s som %s(%d%%)"))(x_1.project)(x_1.role)(x_1.allocated)), notBorderedCell((arg10_3 = Helpers_Date__ToLocalDate(x_1.period.PeriodStart), toText(printf("fra %s"))(arg10_3))), notBorderedCell((arg10_4 = Helpers_Date__ToLocalDate(x_1.period.PeriodEnd), toText(printf("til %s"))(arg10_4)))])]]);
            }, ref.projects)])]])])]]))]),
        });
    }
    else {
        drawDetailsPanel = loadingIndicatorSmall();
    }
    const performExpand = (_arg1) => {
        if (!model.UserAllocations.some((e_1) => (e_1.userid === u.user.Id))) {
            const pr = getProjectsAndVacationsForUser(model.PeriodStart, model.PeriodEnd, u.user.Id, dispatch);
            pr.then();
        }
        dispatch(new Message(6, u.user.Id));
    };
    return toArray(ofArray([MuiHelpers_createElement(TableRow, [["hover", true], ["style", {
        cursor: "pointer",
    }], ["onClick", performExpand], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Tooltip, [["title", "Vis detaljer"], ["children", MuiHelpers_createElement(IconButton, [["onClick", (e_2) => {
        e_2.stopPropagation();
        performExpand();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(ChevronRight, {
        style: createObj(toList(delay(() => append_1(singleton(["transitionDuration", 300 + "ms"]), delay(() => append_1(singleton(["transitionProperty", "transform"]), delay(() => (expanded ? singleton(["transform", ("rotate(" + 90) + "deg)"]) : empty_2())))))))),
    })])]])])]])]]), MuiHelpers_createElement(Tooltip, [["title", "Gå til bruker"], ["children", MuiHelpers_createElement(IconButton, [["onClick", (e_3) => {
        e_3.stopPropagation();
    }], ["href", toText(printf("/user/%i"))(u.user.Id)], ["children", MuiHelpers_createElement(Avatar, [["src", toText(printf("/api/user/profilepicture/thumbnail/%i"))(u.user.Id)], ["alt", u.user.Fullname]])]])]])])]]), MuiHelpers_createElement(TableCell, [["children", u.user.Fullname]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([drawAllocation(u.allocated)])]]), MuiHelpers_createElement(TableCell, [["children", (u.overlapsproj ? u.overlapsvac : false) ? "Ferie og Prosjekt" : (u.overlapsvac ? "Ferie" : (u.overlapsproj ? "Prosjekt" : ""))]])])]]), MuiHelpers_createElement(TableRow, [["style", createObj(toList(delay(() => append_1(singleton(["padding", 0]), delay(() => ((!expanded) ? singleton(["display", "none"]) : empty_2()))))))], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["colSpan", 4], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Collapse, [["collapsedHeight", 0], ["in", expanded], ["timeout", "auto"], ["children", drawDetailsPanel]])])]])])]])]));
}

class RowType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Name", "Allocated"];
    }
}

function RowType$reflection() {
    return union_type("CompanyAllocation.RowType", [], RowType, () => [[], []]);
}

class SortByType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Asc", "Desc"];
    }
}

function SortByType$reflection() {
    return union_type("CompanyAllocation.SortByType", [], SortByType, () => [[["Item", RowType$reflection()]], [["Item", RowType$reflection()]]]);
}

function DrawAllocatedTable(drawAllocatedTableInputProps) {
    let children, props_2, el;
    const dispatch = drawAllocatedTableInputProps.dispatch;
    const model = drawAllocatedTableInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(new SortByType(0, new RowType(0)));
    const sortBy = patternInput[0];
    const setSortBy = patternInput[1];
    const sortArrow = (rowid) => react.createElement(ArrowDownward, {
        style: createObj(toList(delay(() => append_1(singleton(["fontSize", 15 + "px"]), delay(() => append_1(singleton(["position", "absolute"]), delay(() => append_1(singleton(["transitionDuration", 300 + "ms"]), delay(() => append_1(singleton(["transitionProperty", "transform"]), delay(() => ((sortBy.tag === 1) ? (equals(sortBy.fields[0], rowid) ? singleton(["transform", ("rotate(" + 180) + "deg)"]) : singleton(["display", "none"])) : (equals(sortBy.fields[0], rowid) ? singleton(["transform", ("rotate(" + 0) + "deg)"]) : singleton(["display", "none"])))))))))))))),
    });
    return MuiHelpers_createElement(Paper, [["elevation", 0], ["children", Interop_reactApi.Children.toArray([(children = singleton_1((props_2 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DatePicker, [["label", "Start Dato"], ["autoOk", true], ["variant", "inline"], ["value", model.PeriodStart], ["format", "dd MMMM yyyy"], ["onChange", (d) => {
        const newStart = defaultArg(d, now());
        const pr = getCompanyUserAllocations(newStart, model.PeriodEnd, model.CompanyId, dispatch);
        pr.then();
        dispatch(new Message(2, newStart));
    }], ["style", {
        margin: 5,
    }]]), MuiHelpers_createElement(DatePicker, [["label", "Slutt Dato"], ["autoOk", true], ["value", model.PeriodEnd], ["variant", "inline"], ["format", "dd MMMM yyyy"], ["onChange", (dto) => {
        const newEnd = defaultArg(dto, addDays(now(), 7));
        const pr_1 = getCompanyUserAllocations(model.PeriodStart, newEnd, model.CompanyId, dispatch);
        pr_1.then();
        dispatch(new Message(3, newEnd));
    }], ["style", {
        margin: 5,
    }]])])]]), (el = MuiPickersUtilsProvider, MuiHelpers_createElement(el, toList(delay(() => {
        let value_31;
        return append_1(singleton((value_31 = date$002Dfns, ["utils", value_31])), delay(() => props_2));
    })))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })), PageElements_inlineCheckbox((_arg1) => {
        dispatch(new Message(4));
    }, "Filtrer ut overallokerte", model.FilterOverAllocated), MuiHelpers_createElement(Table, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
    }], ["children", ""]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        cursor: "pointer",
        userSelect: "none",
    }], ["children", Interop_reactApi.Children.toArray(["Navn", sortArrow(new RowType(0))])], ["onClick", (_arg2) => {
        if (sortBy.tag === 1) {
            if (equals(sortBy.fields[0], new RowType(0))) {
                setSortBy(new SortByType(0, new RowType(0)));
            }
            else {
                setSortBy(new SortByType(0, new RowType(0)));
            }
        }
        else if (equals(sortBy.fields[0], new RowType(0))) {
            setSortBy(new SortByType(1, new RowType(0)));
        }
        else {
            setSortBy(new SortByType(0, new RowType(0)));
        }
    }]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        cursor: "pointer",
        userSelect: "none",
    }], ["children", Interop_reactApi.Children.toArray(["Allokert", sortArrow(new RowType(1))])], ["onClick", (_arg3) => {
        if (sortBy.tag === 1) {
            if (equals(sortBy.fields[0], new RowType(1))) {
                setSortBy(new SortByType(0, new RowType(1)));
            }
            else {
                setSortBy(new SortByType(0, new RowType(1)));
            }
        }
        else if (equals(sortBy.fields[0], new RowType(1))) {
            setSortBy(new SortByType(1, new RowType(1)));
        }
        else {
            setSortBy(new SortByType(0, new RowType(1)));
        }
    }]]), MuiHelpers_createElement(TableCell, [["style", {
        border: (((0 + "px ") + "none") + " ") + "",
        userSelect: "none",
    }], ["children", "Overlapp"]])])]])])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let usrs;
        const matchValue = model.Users;
        if (matchValue != null) {
            const u = matchValue;
            const users = model.FilterOverAllocated ? u.filter((e) => (e.allocated < 100)) : u;
            return (users.length === 0) ? singleton("Fant ingen brukere") : singleton(map((u_1) => renderRow(model, dispatch, u_1), (usrs = users, (sortBy.tag === 1) ? ((sortBy.fields[0].tag === 1) ? ((array_3) => sortByDescending((x_6) => x_6.allocated, array_3, {
                Compare: (x_7, y_3) => comparePrimitives(x_7, y_3),
            })) : ((array_2) => sortByDescending((x_4) => x_4.user.Fullname, array_2, {
                Compare: (x_5, y_2) => comparePrimitives(x_5, y_2),
            })))(usrs) : ((sortBy.fields[0].tag === 1) ? ((array_1) => sortBy_1((x_2) => x_2.allocated, array_1, {
                Compare: (x_3, y_1) => comparePrimitives(x_3, y_1),
            })) : ((array) => sortBy_1((x) => x.user.Fullname, array, {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            })))(usrs))));
        }
        else {
            return singleton("Laster...");
        }
    }))))]])])]])])]]);
}

function view(model, dispatch) {
    const children = ofArray([snackbarError(model.ErrorMsg, () => {
        dispatch(new Message(5));
    }), createElement(DrawAllocatedTable, {
        model: model,
        dispatch: dispatch,
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Allocations(allocationsInputProps) {
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), init(allocationsInputProps.companyId));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = getCompanyUserAllocations(model_1.PeriodStart, model_1.PeriodEnd, model_1.CompanyId, dispatch);
        pr.then();
    });
    return view(model_1, dispatch);
}

