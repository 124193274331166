import { Union } from "../.fable/fable-library.3.2.4/Types.js";
import { union_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { ofArray, singleton } from "../.fable/fable-library.3.2.4/List.js";

export class Functionality extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViewHome", "EditHome", "EditProject", "DownloadAttestation", "DownloadReference", "DownloadReferenceAndAttest", "DeleteProject", "EditProjectRole", "EditProjectNotification", "EditProjectPermission", "EditProjectPlan", "RegisterProjectHMS", "RegisterProjectWaste", "RegisterProjectQuality", "CreateProject", "SetProjectRequireHMS", "SetProjectRequireWaste", "ViewDocument", "EditDocument", "ViewProcess", "EditProcess", "DeleteProcess", "EditRole", "EditProjectOutcomeType", "EditFilingKey", "EditActionClass", "EditBreeam", "EditBuilder", "EditCompany", "EditEnergyClass", "EditInteraction", "EditFileStore", "EditEducationLevels", "EditProcessActivity", "DeleteProcessActivity", "DownloadOriginalDocument", "EditWasteType", "ControlWaste", "EditHmsType", "ControlHms", "ViewHmsReport", "ViewWasteReport", "EditUserPermission", "EditUserGroupPermission", "EditConfig", "EditLimitValues", "ViewManagement", "EditManagement", "EditCompanyRoles", "EditCompanyPermission", "EditEmployeeProfile", "PrintEmployeeCV", "EditCompanyNotifications", "EditCompanyDocuments", "OverallEditProject", "OverallEditHms", "OverallDownloadDocument", "OverallEditWaste", "OverallCVPrint", "OverallEditQuality", "OverallEditCompany"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Rediger hjem";
            }
            case 2: {
                return "Rediger prosjekt";
            }
            case 3: {
                return "Last ned attester";
            }
            case 4: {
                return "Last ned referanser";
            }
            case 5: {
                return "Last ned referanser og attester";
            }
            case 6: {
                return "Slett prosjekt";
            }
            case 7: {
                return "Rediger prosjektroller";
            }
            case 8: {
                return "Rediger prosjektvarslinger";
            }
            case 9: {
                return "Rediger prosjektrettigheter";
            }
            case 10: {
                return "Rediger prosjektplan";
            }
            case 11: {
                return "Registrer HMS";
            }
            case 12: {
                return "Registrer avfall";
            }
            case 13: {
                return "Registrer kvalitet";
            }
            case 14: {
                return "Opprett prosjekt";
            }
            case 15: {
                return "Endre om prosjekt skal kreve HMS-registrering";
            }
            case 16: {
                return "Endre om prosjekt skal kreve sorteringsgrad-registrering";
            }
            case 17: {
                return "Se dokumenter";
            }
            case 18: {
                return "Legg til/rediger/slett dokumenter";
            }
            case 19: {
                return "Se prosesser";
            }
            case 20: {
                return "Legg til/rediger prosesser";
            }
            case 21: {
                return "Slett prosesser";
            }
            case 22: {
                return "Legg til/rediger/slett roller";
            }
            case 23: {
                return "Legg til/rediger/slett byggningskategorier";
            }
            case 24: {
                return "Legg til/rediger/slett arkivnøkler";
            }
            case 25: {
                return "Legg til/rediger/slett tiltaksklasser";
            }
            case 26: {
                return "Legg til/rediger/slett breeams";
            }
            case 27: {
                return "Legg til/rediger/slett byggherrer";
            }
            case 28: {
                return "Legg til/rediger/slett selskap";
            }
            case 29: {
                return "Legg til/rediger/slett energiklasser";
            }
            case 30: {
                return "Legg til/rediger/slett samspill";
            }
            case 31: {
                return "Legg til/erstatt/slett opplastede filer";
            }
            case 32: {
                return "Rediger utdanningsnivå";
            }
            case 33: {
                return "Legg til/rediger prosessaktivitet";
            }
            case 34: {
                return "Slett prosessaktivitet";
            }
            case 35: {
                return "Last ned original dokument";
            }
            case 36: {
                return "Legg til/rediger/slett avfallstyper";
            }
            case 37: {
                return "Etterkontroll sorteringsgrad";
            }
            case 40: {
                return "Se HMS-rapport";
            }
            case 38: {
                return "Legg til/rediger/slett HMS-typer";
            }
            case 39: {
                return "Etterkontroll HMS";
            }
            case 41: {
                return "Se sorteringsgrad-rapport";
            }
            case 42: {
                return "Gi/fjern brukerrettigheter";
            }
            case 43: {
                return "Rediger brukergrupper";
            }
            case 44: {
                return "Legg til/rediger/slett systemkonfigurasjon";
            }
            case 45: {
                return "Rediger grenseverdier";
            }
            case 46: {
                return "Se ledelsessystem";
            }
            case 47: {
                return "Rediger ledesessystem";
            }
            case 48: {
                return "Rediger selskapsroller";
            }
            case 49: {
                return "Rediger selskapsrettigheter";
            }
            case 50: {
                return "Rediger profil til ansatt";
            }
            case 51: {
                return "Skriv ut CV til ansatt";
            }
            case 52: {
                return "Rediger varsling på selskapsnivå";
            }
            case 53: {
                return "Rediger dokumenter på selskapsnivå";
            }
            case 54: {
                return "Overordnet prosjekt redigering";
            }
            case 55: {
                return "Overordnet HMS redigering";
            }
            case 56: {
                return "Overordnet dokument nedlasting";
            }
            case 57: {
                return "Overordnet sorteringsgrad redigering";
            }
            case 58: {
                return "Utskrift av brukeres CV på tvers av selskap";
            }
            case 59: {
                return "Overordnet kvalitet redigering";
            }
            case 60: {
                return "Overordnet selskap redigering";
            }
            default: {
                return "Vis hjem";
            }
        }
    }
}

export function Functionality$reflection() {
    return union_type("PermissionMapping.Functionality", [], Functionality, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Functionality__ToInt(this$) {
    switch (this$.tag) {
        case 1: {
            return 101;
        }
        case 2: {
            return 1001;
        }
        case 3: {
            return 1002;
        }
        case 4: {
            return 1003;
        }
        case 5: {
            return 1004;
        }
        case 6: {
            return 1005;
        }
        case 7: {
            return 1006;
        }
        case 8: {
            return 1007;
        }
        case 9: {
            return 1008;
        }
        case 10: {
            return 1009;
        }
        case 11: {
            return 1011;
        }
        case 13: {
            return 1017;
        }
        case 14: {
            return 1010;
        }
        case 15: {
            return 1012;
        }
        case 12: {
            return 1013;
        }
        case 16: {
            return 1014;
        }
        case 17: {
            return 2001;
        }
        case 18: {
            return 2002;
        }
        case 19: {
            return 2003;
        }
        case 20: {
            return 2004;
        }
        case 22: {
            return 2006;
        }
        case 23: {
            return 2008;
        }
        case 24: {
            return 2010;
        }
        case 25: {
            return 2012;
        }
        case 26: {
            return 2014;
        }
        case 27: {
            return 2016;
        }
        case 28: {
            return 2018;
        }
        case 29: {
            return 2022;
        }
        case 30: {
            return 2026;
        }
        case 31: {
            return 2040;
        }
        case 32: {
            return 2042;
        }
        case 21: {
            return 2043;
        }
        case 33: {
            return 2045;
        }
        case 34: {
            return 2046;
        }
        case 35: {
            return 2047;
        }
        case 36: {
            return 2048;
        }
        case 37: {
            return 2049;
        }
        case 40: {
            return 3009;
        }
        case 38: {
            return 3010;
        }
        case 39: {
            return 3011;
        }
        case 41: {
            return 3012;
        }
        case 42: {
            return 4001;
        }
        case 43: {
            return 4003;
        }
        case 44: {
            return 4007;
        }
        case 45: {
            return 4008;
        }
        case 46: {
            return 5000;
        }
        case 47: {
            return 5001;
        }
        case 48: {
            return 6001;
        }
        case 49: {
            return 6002;
        }
        case 50: {
            return 6003;
        }
        case 51: {
            return 6004;
        }
        case 52: {
            return 6005;
        }
        case 53: {
            return 6006;
        }
        case 54: {
            return 9001;
        }
        case 55: {
            return 9002;
        }
        case 56: {
            return 9003;
        }
        case 57: {
            return 9004;
        }
        case 58: {
            return 9005;
        }
        case 59: {
            return 9006;
        }
        case 60: {
            return 9007;
        }
        default: {
            return 100;
        }
    }
}

export const OverallDownloadDocumentIncludes = singleton(new Functionality(35));

export const OverallEditProjectIncludes = ofArray([new Functionality(14), new Functionality(2), new Functionality(3), new Functionality(4), new Functionality(5), new Functionality(7), new Functionality(8), new Functionality(9), new Functionality(10), new Functionality(11), new Functionality(15), new Functionality(16)]);

export const OverallEditHmsIncludes = ofArray([new Functionality(40), new Functionality(11), new Functionality(15), new Functionality(16), new Functionality(41)]);

export const ProjectAdministrationList = ofArray([new Functionality(14), new Functionality(6), new Functionality(54)]);

export const ProjectList = [new Functionality(2), new Functionality(7), new Functionality(9), new Functionality(8), new Functionality(10), new Functionality(11), new Functionality(12), new Functionality(13), new Functionality(3), new Functionality(4), new Functionality(5), new Functionality(15), new Functionality(16)];

export const AdministrationList = ofArray([new Functionality(17), new Functionality(18), new Functionality(19), new Functionality(20), new Functionality(21), new Functionality(22), new Functionality(23), new Functionality(24), new Functionality(25), new Functionality(26), new Functionality(27), new Functionality(28), new Functionality(29), new Functionality(30), new Functionality(31), new Functionality(32), new Functionality(33), new Functionality(34), new Functionality(36), new Functionality(37), new Functionality(60)]);

export const HMSList = ofArray([new Functionality(40), new Functionality(38), new Functionality(39), new Functionality(55)]);

export const WasteList = ofArray([new Functionality(41), new Functionality(37), new Functionality(57)]);

export const SysConfigList = ofArray([new Functionality(42), new Functionality(43), new Functionality(44), new Functionality(45)]);

export const ManagementList = ofArray([new Functionality(46), new Functionality(47)]);

export const OtherList = singleton(new Functionality(58));

export const CompanyList = [new Functionality(48), new Functionality(49), new Functionality(50), new Functionality(51), new Functionality(52), new Functionality(53)];

