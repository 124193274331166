import { Union } from "../.fable/fable-library.3.2.4/Types.js";
import { union_type } from "../.fable/fable-library.3.2.4/Reflection.js";

export class SchemaField extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Wood", "PaperCardboard", "Glass", "Iron", "Plaster", "Plastic", "Concrete", "ContaminatedConcrete", "EE", "OrganicSolvents", "PaintGlueSealant", "Quicksilver", "FluorecentTubes", "PressureImpregnatedWood", "PolymerizingSubstance", "OrganicWasteNonHalogen", "KreosotImpregnatedWood", "WasteWithPhthalates", "EnvHarmfulBlowingAgents", "PCBxPCT", "PCBIsolationGlass", "KFKxHKFKxHFKxFluorCarbons", "Asbest", "OrdinaryExtra1", "OrdinaryExtra2", "OrdinaryExtra3", "DangerousExtra1", "DangerousExtra2", "DangerousExtra3", "Mixed", "NotMapped"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "Papir, papp og kartong";
            }
            case 2: {
                return "Glass";
            }
            case 3: {
                return "Jern og andre metaller";
            }
            case 4: {
                return "Gipsbaserte materialer";
            }
            case 5: {
                return "Plast";
            }
            case 6: {
                return "Betong, tegl, lett klinker og lignende";
            }
            case 7: {
                return "Forurenset betong og tegl (under grensen for farlig avfall)";
            }
            case 8: {
                return "EE-avfall (elektriske og elektroniske produker)";
            }
            case 9: {
                return "7041-42 Organiske løsemidler";
            }
            case 10: {
                return "7051-55 Maling, lim, lakk, fugemasser, spraybokser m.m (også \"tomme\" fugemasse-patroner)";
            }
            case 11: {
                return "7081 Kvikksølv-holdig avfall";
            }
            case 12: {
                return "7086 Lysstoffrør";
            }
            case 13: {
                return "7098 Trykkimpregnert trevirke (CCA)";
            }
            case 14: {
                return "7121-23 Polymeriserende stoff, isocyanater og herdere";
            }
            case 15: {
                return "7152 Organisk avfall uten halogen (f.eks. avfall med kulltjære)";
            }
            case 16: {
                return "7154 Kreosot impregnert trevirke";
            }
            case 17: {
                return "7156 Avfall med ftalater (PVC eller vinyl)";
            }
            case 18: {
                return "5157 Kassert isolasjon med miljøskadelige blåsemidler som KFK og HKFK (skumisolasjon)";
            }
            case 19: {
                return "7210 PCB og PCT-holdig avfall (fugemasser og annet)";
            }
            case 20: {
                return "7211 PCB-holdige isolerglassruter";
            }
            case 21: {
                return "7240 KFK/HKFK/HFK og fluorkarboner (fra kjøleanlegg etc)";
            }
            case 22: {
                return "Asbest";
            }
            case 23: {
                return "Annet ordinært 1";
            }
            case 24: {
                return "Annet ordinært 2";
            }
            case 25: {
                return "Annet ordinært 3";
            }
            case 26: {
                return "Annet farlig 1";
            }
            case 27: {
                return "Annet farlig 2";
            }
            case 28: {
                return "Annet farlig 3";
            }
            case 29: {
                return "Blandet avfall/restavfall";
            }
            case 30: {
                return "Ingen/ikke inkluder";
            }
            default: {
                return "Trevirke (ikke kreosot- og CCA-impregnert)";
            }
        }
    }
}

export function SchemaField$reflection() {
    return union_type("WasteMapping.SchemaField", [], SchemaField, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function SchemaField__ToId(this$) {
    switch (this$.tag) {
        case 1: {
            return 102;
        }
        case 2: {
            return 103;
        }
        case 3: {
            return 104;
        }
        case 4: {
            return 105;
        }
        case 5: {
            return 106;
        }
        case 6: {
            return 107;
        }
        case 7: {
            return 108;
        }
        case 8: {
            return 109;
        }
        case 9: {
            return 201;
        }
        case 10: {
            return 202;
        }
        case 11: {
            return 203;
        }
        case 12: {
            return 204;
        }
        case 13: {
            return 205;
        }
        case 14: {
            return 206;
        }
        case 15: {
            return 207;
        }
        case 16: {
            return 208;
        }
        case 17: {
            return 209;
        }
        case 18: {
            return 210;
        }
        case 19: {
            return 211;
        }
        case 20: {
            return 212;
        }
        case 21: {
            return 213;
        }
        case 22: {
            return 214;
        }
        case 23: {
            return 301;
        }
        case 24: {
            return 302;
        }
        case 25: {
            return 303;
        }
        case 26: {
            return 401;
        }
        case 27: {
            return 402;
        }
        case 28: {
            return 403;
        }
        case 29: {
            return 501;
        }
        case 30: {
            return 1;
        }
        default: {
            return 101;
        }
    }
}

export function SchemaField_FromId_Z524259A4(x) {
    switch (x) {
        case 101: {
            return new SchemaField(0);
        }
        case 102: {
            return new SchemaField(1);
        }
        case 103: {
            return new SchemaField(2);
        }
        case 104: {
            return new SchemaField(3);
        }
        case 105: {
            return new SchemaField(4);
        }
        case 106: {
            return new SchemaField(5);
        }
        case 107: {
            return new SchemaField(6);
        }
        case 108: {
            return new SchemaField(7);
        }
        case 109: {
            return new SchemaField(8);
        }
        case 201: {
            return new SchemaField(9);
        }
        case 202: {
            return new SchemaField(10);
        }
        case 203: {
            return new SchemaField(11);
        }
        case 204: {
            return new SchemaField(12);
        }
        case 205: {
            return new SchemaField(13);
        }
        case 206: {
            return new SchemaField(14);
        }
        case 207: {
            return new SchemaField(15);
        }
        case 208: {
            return new SchemaField(16);
        }
        case 209: {
            return new SchemaField(17);
        }
        case 210: {
            return new SchemaField(18);
        }
        case 211: {
            return new SchemaField(19);
        }
        case 212: {
            return new SchemaField(20);
        }
        case 213: {
            return new SchemaField(21);
        }
        case 214: {
            return new SchemaField(22);
        }
        case 301: {
            return new SchemaField(23);
        }
        case 302: {
            return new SchemaField(24);
        }
        case 303: {
            return new SchemaField(25);
        }
        case 401: {
            return new SchemaField(26);
        }
        case 402: {
            return new SchemaField(27);
        }
        case 403: {
            return new SchemaField(28);
        }
        case 501: {
            return new SchemaField(29);
        }
        default: {
            return new SchemaField(30);
        }
    }
}

export function SchemaField_Ordinary() {
    return [new SchemaField(0), new SchemaField(1), new SchemaField(2), new SchemaField(3), new SchemaField(4), new SchemaField(5), new SchemaField(6), new SchemaField(7), new SchemaField(8), new SchemaField(23), new SchemaField(24), new SchemaField(25)];
}

export function SchemaField_Dangerous() {
    return [new SchemaField(9), new SchemaField(10), new SchemaField(11), new SchemaField(12), new SchemaField(13), new SchemaField(14), new SchemaField(15), new SchemaField(16), new SchemaField(17), new SchemaField(18), new SchemaField(19), new SchemaField(20), new SchemaField(21), new SchemaField(22), new SchemaField(26), new SchemaField(27), new SchemaField(28)];
}

export function SchemaField_All() {
    return [new SchemaField(0), new SchemaField(1), new SchemaField(2), new SchemaField(3), new SchemaField(4), new SchemaField(5), new SchemaField(6), new SchemaField(7), new SchemaField(8), new SchemaField(23), new SchemaField(24), new SchemaField(25), new SchemaField(9), new SchemaField(10), new SchemaField(11), new SchemaField(12), new SchemaField(13), new SchemaField(14), new SchemaField(15), new SchemaField(16), new SchemaField(17), new SchemaField(18), new SchemaField(19), new SchemaField(20), new SchemaField(21), new SchemaField(22), new SchemaField(26), new SchemaField(27), new SchemaField(28), new SchemaField(29)];
}

