import { toString, Union, Record } from "../.fable/fable-library.3.2.4/Types.js";
import { obj_type, union_type, record_type, string_type, option_type, array_type, bool_type, int32_type } from "../.fable/fable-library.3.2.4/Reflection.js";
import { Attestation$reflection, AttestationForm$reflection, AttestationForm, UserCourse, Helpers_ResultMessage$reflection, UserCourse$reflection } from "../Shared/ApiDataTypes.js";
import { Fetch_tryDelete_5760677E, Fetch_tryPost_5760677E, Fetch_tryGet_5760677E, FetchError$reflection } from "../.fable/Thoth.Fetch.2.0.0/Fetch.fs.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.2.4/Choice.js";
import { create, year, month, now } from "../.fable/fable-library.3.2.4/Date.js";
import { map, append } from "../.fable/fable-library.3.2.4/Array.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { split, printf, toText } from "../.fable/fable-library.3.2.4/String.js";
import { promise } from "../.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import { Auto_generateDecoder_7848D058 } from "../.fable/Thoth.Json.4.0.0/Decode.fs.js";
import { ofArray, cons, singleton as singleton_1, item as item_2, empty } from "../.fable/fable-library.3.2.4/List.js";
import { createObj, uncurry } from "../.fable/fable-library.3.2.4/Util.js";
import { Auto_generateEncoder_Z127D9D79 } from "../.fable/Thoth.Json.4.0.0/Encode.fs.js";
import { defaultArg, some } from "../.fable/fable-library.3.2.4/Option.js";
import { useReact_useEffect_Z101E1A95, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../.fable/Feliz.1.45.0/React.fs.js";
import { Interop_reactApi } from "../.fable/Feliz.1.45.0/Interop.fs.js";
import { empty as empty_1, map as map_1, singleton, append as append_1, delay, toList } from "../.fable/fable-library.3.2.4/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { rangeDouble } from "../.fable/fable-library.3.2.4/Range.js";
import FileCopySharp from "@material-ui/icons/FileCopySharp";
import { MuiHelpers_reactElement, MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { genericDeleteDialog, snackbarError, loadingIndicatorSmall, insertMaterialTableNorwegianLocalization, PageElements_filenameToIcon } from "../ViewHelpers.js";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import { nb } from "date-fns/locale";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import date$002Dfns from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import material$002Dtable from "material-table";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";

class Model extends Record {
    constructor(UserId, CanEdit, Courses, AddForm, SelectedCourse, Delete, ErrorMsg) {
        super();
        this.UserId = (UserId | 0);
        this.CanEdit = CanEdit;
        this.Courses = Courses;
        this.AddForm = AddForm;
        this.SelectedCourse = SelectedCourse;
        this.Delete = Delete;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model$reflection() {
    return record_type("MyPage.Course.Model", [], Model, () => [["UserId", int32_type], ["CanEdit", bool_type], ["Courses", option_type(array_type(UserCourse$reflection()))], ["AddForm", option_type(UserCourse$reflection())], ["SelectedCourse", option_type(UserCourse$reflection())], ["Delete", option_type(UserCourse$reflection())], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CoursesResponse", "SelectCourse", "Abort", "AddCourse", "AddUpdateResponse", "DeleteCourse", "DeleteResponse", "SetError"];
    }
}

function Message$reflection() {
    return union_type("MyPage.Course.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(UserCourse$reflection()), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", array_type(UserCourse$reflection())]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", UserCourse$reflection()]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [UserCourse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", UserCourse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(UserCourse$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Helpers_ResultMessage$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Helpers_ResultMessage$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function init(userId, canEdit) {
    return new Model(userId, canEdit, void 0, void 0, void 0, void 0, void 0);
}

function update(model, msg) {
    let copyOfStruct, copyOfStruct_1, copyOfStruct_2, copyOfStruct_3, matchValue_1, matchValue_2, matchValue_4;
    switch (msg.tag) {
        case 1: {
            return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, msg.fields[0], model.Delete, model.ErrorMsg);
        }
        case 3: {
            return new Model(model.UserId, model.CanEdit, model.Courses, new UserCourse(-1, model.UserId, "", (copyOfStruct = now(), month(copyOfStruct)), ((copyOfStruct_1 = now(), year(copyOfStruct_1))) - 1, (copyOfStruct_2 = now(), month(copyOfStruct_2)), (copyOfStruct_3 = now(), year(copyOfStruct_3)), []), model.SelectedCourse, model.Delete, model.ErrorMsg);
        }
        case 2: {
            return new Model(model.UserId, model.CanEdit, model.Courses, void 0, void 0, void 0, model.ErrorMsg);
        }
        case 4: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 1) {
                return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, model.Delete, "Noe gikk galt. Kunne ikke legge til kurs.");
            }
            else {
                const x_3 = res_1.fields[0];
                const matchValue = [model.AddForm, model.SelectedCourse];
                if (matchValue[0] != null) {
                    return new Model(model.UserId, model.CanEdit, (matchValue_1 = model.Courses, (matchValue_1 == null) ? [x_3] : append([x_3], matchValue_1)), void 0, model.SelectedCourse, model.Delete, model.ErrorMsg);
                }
                else if (matchValue[1] != null) {
                    return new Model(model.UserId, model.CanEdit, (matchValue_2 = model.Courses, (matchValue_2 == null) ? [x_3] : map((z) => {
                        if (z.Id === x_3.Id) {
                            return x_3;
                        }
                        else {
                            return z;
                        }
                    }, matchValue_2)), model.AddForm, void 0, model.Delete, model.ErrorMsg);
                }
                else {
                    return model;
                }
            }
        }
        case 5: {
            return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, msg.fields[0], model.ErrorMsg);
        }
        case 6: {
            const res_2 = msg.fields[0];
            const matchValue_3 = model.Delete;
            if (matchValue_3 == null) {
                return model;
            }
            else {
                const d = matchValue_3;
                if (res_2.tag === 1) {
                    return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, model.Delete, "Noe gikk galt. Kunne ikke slette kurs.");
                }
                else {
                    const x_5 = res_2.fields[0];
                    if (x_5.Result === "success") {
                        return new Model(model.UserId, model.CanEdit, (matchValue_4 = model.Courses, (matchValue_4 != null) ? matchValue_4.filter((z_1) => (z_1.Id !== d.Id)) : (void 0)), model.AddForm, model.SelectedCourse, void 0, model.ErrorMsg);
                    }
                    else {
                        return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, model.Delete, x_5.Message);
                    }
                }
            }
        }
        case 7: {
            return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, model.Delete, msg.fields[0]);
        }
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.UserId, model.CanEdit, model.Courses, model.AddForm, model.SelectedCourse, model.Delete, "Noe gikk galt. Kunne ikke laste kurs for bruker.");
            }
            else {
                return new Model(model.UserId, model.CanEdit, res.fields[0], model.AddForm, model.SelectedCourse, model.Delete, model.ErrorMsg);
            }
        }
    }
}

function fetchCourses(userId, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const requestPath = toText(printf("/api/course/%i"))(userId);
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                ResolveType: () => array_type(UserCourse$reflection()),
            });
            return Fetch_tryGet_5760677E(requestPath, void 0, empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                ResolveType: () => array_type(UserCourse$reflection()),
            }, {
                ResolveType: () => obj_type,
            });
        })).then(((_arg1) => {
            dispatch(new Message(0, _arg1));
            return Promise.resolve();
        }));
    }));
}

function commitCourse(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((x.Name.length > 0) ? (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserCourse$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: UserCourse$reflection,
        });
        return Fetch_tryPost_5760677E("/api/course", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: UserCourse$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(4, _arg1));
        return Promise.resolve();
    }))) : (Promise.resolve()))));
}

function deleteCourse(x, dispatch) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
            ResolveType: UserCourse$reflection,
        })(x);
        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
            ResolveType: Helpers_ResultMessage$reflection,
        });
        return Fetch_tryDelete_5760677E("/api/course", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
            ResolveType: Helpers_ResultMessage$reflection,
        }, {
            ResolveType: () => obj_type,
        });
    })).then(((_arg1) => {
        dispatch(new Message(6, _arg1));
        return Promise.resolve();
    })))));
}

function FormDialog(props) {
    let elms_3, props_12, elms_2, props_16;
    let selectedState;
    const matchValue = [props.model.AddForm, props.model.SelectedCourse];
    selectedState = ((matchValue[1] != null) ? matchValue[1] : ((matchValue[0] != null) ? matchValue[0] : (void 0)));
    if (selectedState != null) {
        const initState = selectedState;
        const patternInput = useFeliz_React__React_useState_Static_1505(initState);
        const state = patternInput[0];
        const setState = patternInput[1];
        const patternInput_1 = [create(initState.StartYear, initState.StartMonth, 1), create(initState.EndYear, initState.EndMonth, 1)];
        const patternInput_2 = useFeliz_React__React_useState_Static_1505(patternInput_1[0]);
        const startDate = patternInput_2[0];
        const patternInput_3 = useFeliz_React__React_useState_Static_1505(patternInput_1[1]);
        const endDate = patternInput_3[0];
        const patternInput_4 = useFeliz_React__React_useState_Static_1505(["", ""]);
        const setAttestState = patternInput_4[1];
        const attestState = patternInput_4[0];
        let fileUpload;
        const props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1((props_12 = ofArray([["className", "has-name"], ["style", {
            margin: 5,
        }], ["children", Interop_reactApi.Children.toArray([(elms_2 = toList(delay(() => append_1(singleton(createElement("input", createObj(cons(["type", "file"], Helpers_combineClasses("file-input", singleton_1(["onChange", (ev) => {
            const fileList = ev.target.files;
            if (!(fileList == null)) {
                const file = item_2(0, toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
                const reader = new FileReader();
                reader.onload = ((_arg1_1) => {
                    setAttestState([file.name, toString(reader.result)]);
                });
                reader.readAsDataURL(file);
            }
        }])))))), delay(() => {
            let elms_1, elms, children_1;
            return append_1(singleton((elms_1 = ofArray([(elms = singleton_1((children_1 = singleton_1(react.createElement(FileCopySharp, {})), createElement("i", {
                children: Interop_reactApi.Children.toArray(Array.from(children_1)),
            }))), createElement("span", {
                className: "file-icon",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), createElement("span", {
                children: ["Legg til attest"],
            })]), createElement("span", {
                className: "file-cta",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), delay(() => ((attestState[0].length > 0) ? singleton(createElement("span", {
                className: "file-name",
                children: attestState[0],
            })) : empty_1())));
        })))), createElement("label", {
            className: "file-label",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("file", props_12))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        })), (props_16 = ofArray([["style", {
            textAlign: "right",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => ((attestState[0].length > 0) ? singleton(MuiHelpers_createElement(Button, [["children", "Legg til"], ["style", {
            margin: 5,
        }], ["color", "primary"], ["size", "small"], ["variant", "outlined"], ["onClick", (_arg2_1) => {
            const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let splitted;
                const matchValue_1 = attestState[1];
                if (matchValue_1.length > 0) {
                    const filename = attestState[0];
                    const req = new AttestationForm(state.Id, matchValue_1, filename, (splitted = split(filename, ["."], null, 0), (splitted.length > 1) ? splitted[splitted.length - 1] : "png"), state.UserId);
                    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        const body = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                            ResolveType: AttestationForm$reflection,
                        })(req);
                        const decoder = Auto_generateDecoder_7848D058(void 0, void 0, {
                            ResolveType: Attestation$reflection,
                        });
                        return Fetch_tryPost_5760677E("/api/course/attestation", some(body), empty(), void 0, void 0, void 0, uncurry(2, decoder), {
                            ResolveType: Attestation$reflection,
                        }, {
                            ResolveType: () => obj_type,
                        });
                    })).then(((_arg1) => {
                        const res = _arg1;
                        setAttestState(["", ""]);
                        if (res.tag === 0) {
                            setState(new UserCourse(state.Id, state.UserId, state.Name, state.StartMonth, state.StartYear, state.EndMonth, state.EndYear, append(state.Attestations, [res.fields[0]])));
                            return Promise.resolve();
                        }
                        else {
                            props.dispatch(new Message(7, "add was not successful"));
                            return Promise.resolve();
                        }
                    }));
                }
                else {
                    props.dispatch(new Message(7, "content was empty"));
                    return Promise.resolve();
                }
            }));
            pr.then();
        }]])) : empty_1())))))]]), createElement("div", createObj(Helpers_combineClasses("column", props_16))))])]);
        fileUpload = createElement("div", createObj(Helpers_combineClasses("columns", props_18)));
        const attestTable = MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray([createElement("b", {
            children: ["Attester"],
        })])]]), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(map((att) => {
            let children_10;
            return MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("a", {
                style: {
                    marginRight: 5 + "px",
                },
                href: toText(printf("/api/course/attestation/download/%i"))(att.Id),
                target: "_blank",
                children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(att.Filename), att.Filename]),
            })])]]), MuiHelpers_createElement(TableCell, [["align", "right"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(IconButton, [["size", "small"], ["onClick", (_arg3) => {
                const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    const body_1 = Auto_generateEncoder_Z127D9D79(void 0, void 0, void 0, {
                        ResolveType: Attestation$reflection,
                    })(att);
                    const decoder_1 = Auto_generateDecoder_7848D058(void 0, void 0, {
                        ResolveType: Attestation$reflection,
                    });
                    return Fetch_tryDelete_5760677E("/api/course/attestation", some(body_1), empty(), void 0, void 0, void 0, uncurry(2, decoder_1), {
                        ResolveType: Attestation$reflection,
                    }, {
                        ResolveType: () => obj_type,
                    });
                })).then(((_arg2) => {
                    const res_1 = _arg2;
                    if (res_1.tag === 0) {
                        setState(new UserCourse(state.Id, state.UserId, state.Name, state.StartMonth, state.StartYear, state.EndMonth, state.EndYear, state.Attestations.filter((x_1) => (x_1.Id !== res_1.fields[0].Id))));
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                })))));
                pr_1.then();
            }], ["children", Interop_reactApi.Children.toArray([(children_10 = singleton_1(react.createElement(DeleteOutlined, {})), createElement("i", {
                children: Interop_reactApi.Children.toArray(Array.from(children_10)),
            }))])]])])]])])]]);
        }, state.Attestations)))]])])]]);
        let datePickers;
        const props_29 = ofArray([["locale", nb], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DatePicker, [["label", "Start Dato"], ["autoOk", true], ["views", ["year", "month"]], ["value", defaultArg(startDate, null)], ["onChange", patternInput_2[1]], ["variant", "inline"], ["style", {
            margin: 5,
        }]]), MuiHelpers_createElement(DatePicker, [["label", "Slutt Dato"], ["autoOk", true], ["views", ["year", "month"]], ["value", defaultArg(endDate, null)], ["variant", "inline"], ["onChange", patternInput_3[1]], ["style", {
            margin: 5,
        }]])])]]);
        const el = MuiPickersUtilsProvider;
        datePickers = MuiHelpers_createElement(el, toList(delay(() => {
            let value_89;
            return append_1(singleton((value_89 = date$002Dfns, ["utils", value_89])), delay(() => props_29));
        })));
        let innerBody;
        const props_35 = ofArray([["className", "is-vcentered"], ["style", {
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: 20 + "px",
        }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let elms_4;
            return append_1(singleton((elms_4 = singleton_1(MuiHelpers_createElement(TextField, [["defaultValue", state.Name], ["label", "Navn"], ["style", {
                margin: 5,
            }], ["onChange", (e) => {
                setState(new UserCourse(state.Id, state.UserId, Browser_Types_Event__Event_get_Value(e), state.StartMonth, state.StartYear, state.EndMonth, state.EndYear, state.Attestations));
            }], ["required", true]])), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
            }))), delay(() => append_1(singleton(createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray([datePickers]),
            })), delay(() => {
                let props_33;
                return (props.model.SelectedCourse != null) ? singleton((props_33 = ofArray([["className", "is-full"], ["children", Interop_reactApi.Children.toArray([fileUpload, attestTable])]]), createElement("div", createObj(Helpers_combineClasses("column", props_33))))) : singleton(null);
            }))));
        }))))]]);
        innerBody = createElement("div", createObj(Helpers_combineClasses("columns", props_35)));
        return MuiHelpers_createElement(Dialog, [["onClose", (delegateArg0, delegateArg1) => {
            props.dispatch(new Message(2));
        }], ["open", true], ["style", {
            minWidth: 33 + "vw",
        }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", Interop_reactApi.Children.toArray([(props.model.SelectedCourse == null) ? "Legg til kurs" : "Rediger kurs"])]]), MuiHelpers_createElement(DialogContent, [["children", Interop_reactApi.Children.toArray([innerBody])]]), MuiHelpers_createElement(DialogActions, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(Button, [["onClick", (_arg6) => {
            props.dispatch(new Message(2));
        }], ["color", "primary"], ["children", "Avbryt"]]), MuiHelpers_createElement(Button, [["onClick", (_arg7) => {
            let matchValue_3, ed, sd, StartYear, StartMonth, EndYear;
            const pr_2 = commitCourse((matchValue_3 = [startDate, endDate], (matchValue_3[0] != null) ? ((matchValue_3[1] != null) ? ((ed = matchValue_3[1], (sd = matchValue_3[0], (StartYear = (year(sd) | 0), (StartMonth = (month(sd) | 0), (EndYear = (year(ed) | 0), new UserCourse(state.Id, state.UserId, state.Name, StartMonth, StartYear, month(ed), EndYear, state.Attestations))))))) : state) : state), props.dispatch);
            pr_2.then();
        }], ["disabled", state.Name.length === 0], ["color", "primary"], ["children", "Bekreft"]])])]])])]]);
    }
    else {
        return null;
    }
}

function drawMaterialTable(model, dispatch) {
    const matchValue = model.Courses;
    if (matchValue != null) {
        const courses = matchValue;
        return MuiHelpers_reactElement(material$002Dtable, createObj(toList(delay(() => append_1(singleton(["components", {
            Container: (properties) => MuiHelpers_createElement(Paper, toList(delay(() => append_1(Object.entries(properties), delay(() => singleton(["elevation", 0])))))),
        }]), delay(() => append_1(singleton(["style", {
            maxWidth: 1000,
            width: 100 + "%",
            marginBottom: 2 + "em",
        }]), delay(() => append_1(singleton(["title", "Kurs"]), delay(() => append_1(singleton(["data", Array.from(ofArray(courses))]), delay(() => append_1(singleton(["columns", [{
            title: "Navn",
            field: "Name",
        }, {
            title: "Periode (Mnd/År)",
            field: "StartMonth",
            render: (delegateArg0, delegateArg1) => {
                const course_1 = delegateArg0;
                return toText(printf("%d/%d - %d/%d"))(course_1.StartMonth)(course_1.StartYear)(course_1.EndMonth)(course_1.EndYear);
            },
            customSort: (delegateArg0_1, delegateArg1_1, delegateArg2) => {
                const b = delegateArg1_1;
                const a = delegateArg0_1;
                const yearcmp = (a.StartYear - b.StartYear) | 0;
                return ((yearcmp === 0) ? (a.StartMonth - b.StartMonth) : yearcmp) | 0;
            },
        }]]), delay(() => append_1(singleton(["detailPanel", [{
            tooltip: "Vis detaljer",
            render: (course_3) => createElement("div", {
                style: {
                    marginTop: 1 + "em",
                    marginBottom: 1 + "em",
                    width: 90 + "%",
                    marginLeft: 5 + "%",
                    marginRight: 5 + "%",
                },
                children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Table, [["size", "small"], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([createElement("b", {
                    children: ["Attester:"],
                })])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(map((x) => {
                    const children_1 = singleton_1(createElement("a", {
                        style: {
                            marginRight: 5 + "px",
                        },
                        href: toText(printf("/api/course/attestation/download/%i"))(x.Id),
                        target: "_blank",
                        children: Interop_reactApi.Children.toArray([PageElements_filenameToIcon(x.Filename), x.Filename]),
                    }));
                    return createElement("div", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                    });
                }, course_3.Attestations)))]])])]])])]])])]])]),
            }),
        }]]), delay(() => append_1(singleton(["onRowClick", (delegateArg0_2, delegateArg1_2, delegateArg2_1) => {
            delegateArg2_1();
        }]), delay(() => {
            let value_66;
            return append_1(model.CanEdit ? singleton(["actions", [createObj(ofArray([(value_66 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(AddBox, {})])]]), ["icon", () => value_66]), ["tooltip", "Legg til"], ["isFreeAction", true], ["onClick", (delegateArg0_3, delegateArg1_3) => {
                dispatch(new Message(3));
            }]])), (arg) => {
                let value_74;
                return createObj(ofArray([(value_74 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(DeleteOutline, {})])]]), ["icon", () => value_74]), ["tooltip", "Slett"], ["onClick", (delegateArg0_4, delegateArg1_4) => {
                    dispatch(new Message(5, arg));
                }]]));
            }, (arg_1) => {
                let value_80;
                return createObj(ofArray([(value_80 = MuiHelpers_createElement(Icon, [["children", Interop_reactApi.Children.toArray([react.createElement(Edit, {})])]]), ["icon", () => value_80]), ["tooltip", "Rediger"], ["onClick", (delegateArg0_5, delegateArg1_5) => {
                    dispatch(new Message(1, arg_1));
                }]]));
            }]]) : empty_1(), delay(() => append_1(singleton(insertMaterialTableNorwegianLocalization()), delay(() => singleton(["options", {
                grouping: false,
                paging: false,
                search: false,
                pageSize: courses.length,
                defaultExpanded: true,
            }])))));
        }))))))))))))))))));
    }
    else {
        return loadingIndicatorSmall();
    }
}

function view(model, dispatch) {
    const children = toList(delay(() => append_1(singleton(snackbarError(model.ErrorMsg, () => {
        dispatch(new Message(7, void 0));
    })), delay(() => append_1(singleton(createElement(FormDialog, {
        dispatch: dispatch,
        model: model,
    })), delay(() => append_1(singleton(drawMaterialTable(model, dispatch)), delay(() => {
        let value;
        const matchValue = model.Delete;
        if (matchValue != null) {
            const delC = matchValue;
            return singleton(genericDeleteDialog(() => {
                dispatch(new Message(5, void 0));
            }, () => deleteCourse(delC, dispatch), [(value = toText(printf("Valgt kurs: %A"))(delC.Name), createElement("h3", {
                children: [value],
            }))]));
        }
        else {
            return empty_1();
        }
    }))))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function UserCourses(userCoursesInputProps) {
    const initialModel = init(userCoursesInputProps.userId, userCoursesInputProps.canEdit);
    const patternInput = useReact_useReducer_2B9E6EA0((model, msg) => update(model, msg), initialModel);
    const dispatch = patternInput[1];
    useReact_useEffect_Z101E1A95(() => {
        const pr = fetchCourses(initialModel.UserId, dispatch);
        pr.then();
    }, []);
    return view(patternInput[0], dispatch);
}

